import moment from "moment";


export default {
    actions: {
        async fetchValidateBasketTourTourists({commit, dispatch, getters, state}, tour) {
            let data = {
                result: true,
                message: "",
                link: "",
            }
            //console.log('validation: ', tour)
            //Смотрим для какой страны нужны данные
            commit('updateValidationCountry', getters.getLocalization)
            //Смотрим тур внутри страны или заграничный
            let isInside = 'inside'
            if (tour.countryFromId !== tour.countryId) {
                 isInside = 'outside'
            }
            let countriesLikeInside=[233909] //Абхазия
            if(countriesLikeInside.includes(tour.countryId)){
                isInside = 'inside'
            }
            // //Начинаем валидировать туристиков
            if (tour.travellers.length > 0) {
                let validationFields = state.basketTourist[state.country].tour[isInside]
                for (let traveller in tour.travellers) {
                    let validationData = {
                        traveller: tour.travellers[traveller],
                        validationFields: validationFields,
                        tour: tour,
                    }
                    data = await dispatch('validateTraveller', validationData)
                    if (!data.result) {
                        break
                    }
                }
            } else {
                data.result = false
            }

            //Временное решение
            if (tour.travellers.length === 0) {
                data.result = false
                data.message = _trans('page/basket.fillTouristsShort')//'Заполните туристов' //$trans('page/basket.fillTouristsShort')
                data.link = 'page/basket.fillTouristsShort';
            }
            commit('updateIsValidationSuccessful', data.result);
            return data
        },
        async fetchValidateBasketServiceTourists({commit, dispatch, getters, state}, tour) {
            let data = {
                result: true,
                message: "",
                link: "",
            }
            //console.log('validation: ', tour)
            //Смотрим для какой страны нужны данные
            commit('updateValidationCountry', getters.getLocalization)
            let isInside = 'inside'

            // //Начинаем валидировать туристиков
            if (tour.travellers.length > 0) {
                let validationFields = state.basketTourist[state.country].tour[isInside]
                for (let traveller in tour.travellers) {
                    let validationData = {
                        traveller: tour.travellers[traveller],
                        validationFields: validationFields,
                        tour: tour,
                    }
                    data = await dispatch('validateTraveller', validationData)
                    if (!data.result) {
                        break
                    }
                }
            } else {
                data.result = false
            }

            //Временное решение
            if (tour.travellers.length === 0) {
                data.result = false
                data.message = _trans('page/basket.fillTouristsShort')//'Заполните туристов' //$trans('page/basket.fillTouristsShort')
                data.link = 'page/basket.fillTouristsShort';
            }
            commit('updateIsValidationSuccessful', data.result);
            return data
        },
        async fetchValidateCustomerInfo({commit, dispatch, getters, state}, customerInfo) {
            let data = {
                result: true,
                message: "",
            }
            //Смотрим для какой страны нужны данные
            commit('updateValidationCountry', 'ru')//getters.getLocalization)
            //Валидируем заказчика
            let validationFields = state.customerInfo[state.country]
            let validationData = {
                customer: customerInfo,
                validationFields: validationFields
            }
            console.log('validateCustomer starts')
            data = await dispatch('validateCustomer', validationData);
            commit('updateIsValidationSuccessful', data.result);
            return data
        },
        validateTraveller(ctx, validationData) {
            let data = {
                result: true,
                message: ""
            }
            console.log('validationData', validationData)
            let traveller = validationData.traveller;
            let validationFields = validationData.validationFields;
            let tour = validationData.tour
            // Проверяем данные документов, если не выбран документ - "нет паспорта"
            if(traveller.IsNoPassport === false || traveller.IsNoPassport === undefined) {
                //Фамилия на латинском
                if (validationFields.document.lastNameLatin && !traveller.document.lastNameLatin) {
                    data.result = false
                    data.message = _trans('page/basket.touristSurnameEmpty')
                }
                let regular = /^[a-zA-Z]+$/
                if (validationFields.document.lastNameLatin && traveller.document.lastNameLatin && !traveller.document.lastNameLatin.match(regular)) {
                    data.result = false
                    data.message = _trans('page/basket.surNameNotLatin')
                }

                //Имя на латинском
                if (validationFields.document.firstNameLatin && !traveller.document.firstNameLatin) {
                    data.result = false
                    data.message = _trans('page/basket.touristNameEmpty')
                }
                if (validationFields.document.firstNameLatin && traveller.document.firstNameLatin && !traveller.document.firstNameLatin.match(regular)) {
                    data.result = false
                    data.message = _trans('page/basket.nameNotLatin')
                }

                //Фамилия
                if (validationFields.document.lastName && !traveller.document.lastName) {
                    data.result = false
                    data.message = _trans('page/basket.touristSurnameEmpty')
                }
                regular = /^[А-Яа-яёЁЇїІіЄєҐґIi]+$/
                if (validationFields.document.lastName && traveller.document.lastName && !traveller.document.lastName.match(regular)) {
                    data.result = false
                    data.message = _trans('page/basket.surNameWrong')
                }

                //Имя
                if (validationFields.document.firstName && !traveller.document.firstName) {
                    data.result = false
                    data.message = _trans('page/basket.touristNameEmpty')
                }
                if (validationFields.document.firstName && traveller.document.firstName && !traveller.document.firstName.match(regular)) {
                    data.result = false
                    data.message = _trans('page/basket.nameWrong')
                }

                //Отчество
                if (validationFields.document.middleName && !traveller.document.middleName) {
                    data.result = false
                    data.message = "Отчество не заполнено"//_trans('page/basket.middleNameEmpty')
                }
                if (validationFields.document.middleName && traveller.document.middleName && !traveller.document.middleName.match(regular)) {
                    data.result = false
                    data.message = _trans('page/basket.middleNameWrong')
                }

                //Серия документа
                if (validationFields.document.series && !traveller.document.series && traveller.document.type !== 'idCard') {
                    data.result = false
                    data.message = _trans('page/basket.touristSeriesEmpty')
                }
                if (validationFields.document.series && traveller.document.series && traveller.document.type !== 'idCard') {
                    //Формируем регулярку
                    if (traveller.nationalityId === 210357 && traveller.document.type === 'passport') {
                        regular = /^[0-9]+$/
                    } else if (traveller.nationalityId === 196637 && traveller.document.type === 'passport') {
                        regular = /^[А-Яа-яёЁЇїІіЄєҐґIi]+$/
                    } else if (traveller.nationalityId === 210357 && traveller.document.type === 'internationalPassport') {
                        regular = /^[0-9]+$/
                    } else if (traveller.nationalityId === 196637 && traveller.document.type === 'internationalPassport') {
                        regular = /^[A-Za-z]+$/
                    } else {
                        regular = null
                    }

                    if (traveller.nationalityId === 210357 && traveller.document.type === 'passport' && (traveller.document.series.length !== 4 || !traveller.document.series.match(regular))) {
                        data.result = false
                        data.message = _trans('page/basket.series4Numbers')
                    } else if (traveller.nationalityId === 196637 && traveller.document.type === 'passport' && (traveller.document.series.length !== 2 || !traveller.document.series.match(regular))) {
                        data.result = false
                        data.message = _trans('page/basket.series2Letters')
                    } else if (traveller.nationalityId === 210357 && traveller.document.type === 'internationalPassport' && (traveller.document.series.length !== 2 || !traveller.document.series.match(regular))) {
                        data.result = false
                        data.message = _trans('page/basket.series2Numbers')
                    } else if (traveller.nationalityId === 196637 && traveller.document.type === 'internationalPassport' && (traveller.document.series.length !== 2 || !traveller.document.series.match(regular))) {
                        data.result = false
                        data.message = _trans('page/basket.series2LatinLetters')
                    }
                }

                //Номер документа
                if (validationFields.document.number && !traveller.document.number) {
                    data.result = false
                    data.message = _trans('page/basket.touristNumberEmpty')
                }
                regular = /^[0-9]+$/
                if (validationFields.document.number && traveller.document.number) {
                    if (!traveller.document.number.match(regular)) {
                        data.result = false
                        data.message = _trans('page/basket.number6Numbers')
                    } else if (traveller.nationalityId === 196637 && traveller.document.type === 'internationalPassport' && traveller.document.number.length !== 6) {
                        data.result = false
                        data.message = _trans('page/basket.number6Numbers')
                    } else if (traveller.nationalityId === 210357 && traveller.document.type === 'internationalPassport' && traveller.document.number.length !== 7) {
                        data.result = false
                        data.message = _trans('page/basket.number7Numbers')
                    } else if (traveller.nationalityId === 210357 && traveller.document.type === 'passport' && traveller.document.number.length !== 6) {
                        data.result = false
                        data.message = _trans('page/basket.number6Numbers')
                    }
                }

                //Дата окончания срока действия документа
                if (validationFields.document.expirationDate && moment(traveller.document.expirationDate).format('YYYY-MM-DD') === '0001-01-01') {
                    data.result = false
                    data.message = _trans('page/basket.expirationDateNotNull')
                }
                let daysToAdd = 1
                let period = ctx.getters.getPassportExpirationPeriods.find(item=>item.countryId === tour.countryId)
                let dayToCompare
                if(period){
                    if(period.month && period.validateOn==='start'){
                        dayToCompare = moment(tour.startDate).add(period.month, 'months').add(1, 'days')
                    }
                    if(period.month && period.validateOn==='end'){
                        dayToCompare = moment(tour.endDate).add(period.month, 'months').add(1, 'days')
                    }
                    if(!period.month){
                        dayToCompare = moment(tour.endDate).add(period.days, 'days')
                    }
                }
                if (validationFields.document.expirationDate && moment(traveller.document.expirationDate).format('YYYY-MM-DD') !== '0001-01-01' && moment(traveller.document.expirationDate).isBefore(dayToCompare)) {
                    data.result = false
                    data.message = _trans('page/basket.expirationDateNotSuitable')
                }
            }

            if (validationFields.birthDay && moment(traveller.birthDay).format('YYYY-MM-DD') === '0001-01-01') {
                data.result = false
                data.message = _trans('page/basket.touristBirthDayEmpty')
            }
            if (validationFields.birthDay && moment(traveller.birthDay).format('YYYY-MM-DD') !== '0001-01-01' && moment(traveller.birthDay).isAfter(moment(new Date))) {
                data.result = false
                data.message = _trans('page/basket.dateNotMoreThanCurrent')
            }

            return data
        },
        validateCustomer(ctx, validationData) {
            let data = {
                result: true,
                message: ""
            }
            //console.log('validationCustomerData: ', validationData)
             let customer = validationData.customer
             let validationFields = validationData.validationFields
             if(customer.document && !customer.document.address){
                 data.result = false
                 data.message =_trans('page/basket.adressNotNull')
             }
            // if (validationFields.name && !customer.name) {
            //     data.result = false
            //     data.message = 'Не заполнено имя Заказчика'
            // }
            // if (validationFields.birthDay && !customer.birthDay) {
            //     data.result = false
            //     data.message = 'Не заполнено день рождение заказчика'
            // }
            // if (validationFields.email && !customer.email) {
            //     data.result = false
            //     data.message = 'Не заполнен email заказчика'
            // }
            // if (validationFields.phones && !customer.phones) {
            //     data.result = false
            //     data.message = 'Не заполнен телефон заказчика'
            // }
            // //Паспорт
            // for (let key in validationFields.passport) {
            //     if (validationFields.passport[key] && !customer.passport[key]) {
            //         data.result = false
            //         data.message = 'Не заполнен паспорт Заказчика'
            //         break
            //     }
            // }
            // //Загран пасспорт
            // for (let key in validationFields.internationalPassport) {
            //     if (validationFields.internationalPassport[key] && !customer.internationalPassport[key]) {
            //         data.result = false
            //         data.message = 'Не заполнен загран паспорт заказчика'
            //         break
            //     }
            // }

            return data
        },
        async fetchValidateBasketExcursionTourists({commit, dispatch, getters, state}, excursion) {
            let data = {
                result: true,
                message: "",
                link: "",
            }
            // //Начинаем валидировать туристиков
            if (excursion.travellers.length > 0) {
                // let validationFields = state.basketTourist[state.country].tour[isInside]
                // for (let traveller in tour.travellers) {
                //     let validationData = {
                //         traveller: tour.travellers[traveller],
                //         validationFields: validationFields,
                //         tour: tour,
                //     }
                //     data = await dispatch('validateTraveller', validationData)
                //     if (!data.result) {
                //         break
                //     }
                // }
            }

            //Временное решение
            if (excursion.travellers.length === 0) {
                data.result = false
                data.message = _trans('page/basket.fillTouristsShort')//'Заполните туристов' //$trans('page/basket.fillTouristsShort')
                data.link = 'page/basket.fillTouristsShort';
            }
            commit('updateIsValidationSuccessful', data.result);
            return data
        }
    },
    state: {
        country: "",
        basketTourist: {
            ru: {
                tour: {
                    inside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: true,
                            firstNameLatin: true,
                            middleName: true,
                            lastName: true,
                            lastNameLatin: true,
                            series: true,
                            number: true,
                            expirationDate: false,
                        },
                    },
                    outside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: false,
                            firstNameLatin: true,
                            middleName: false,
                            lastName: false,
                            lastNameLatin: true,
                            series: true,
                            number: true,
                            expirationDate: true,
                        },
                    },
                }
            },
            ua: {
                tour: {
                    inside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: true,
                            firstNameLatin: true,
                            middleName: true,
                            lastName: true,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                    outside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: false,
                            firstNameLatin: true,
                            middleName: false,
                            lastName: false,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                }
            },
            by: {
                tour: {
                    inside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: true,
                            firstNameLatin: true,
                            middleName: true,
                            lastName: true,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                    outside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: false,
                            firstNameLatin: true,
                            middleName: false,
                            lastName: false,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                }
            },
            lt: {
                tour: {
                    inside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: true,
                            firstNameLatin: true,
                            middleName: true,
                            lastName: true,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                    outside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: false,
                            firstNameLatin: true,
                            middleName: false,
                            lastName: false,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                }
            },
            lv: {
                tour: {
                    inside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: true,
                            firstNameLatin: true,
                            middleName: true,
                            lastName: true,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                    outside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: false,
                            firstNameLatin: true,
                            middleName: false,
                            lastName: false,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                }
            },
            ee: {
                tour: {
                    inside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: true,
                            firstNameLatin: true,
                            middleName: true,
                            lastName: true,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                    outside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: false,
                            firstNameLatin: true,
                            middleName: false,
                            lastName: false,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                }
            },
            kz: {
                tour: {
                    inside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: true,
                            firstNameLatin: true,
                            middleName: true,
                            lastName: true,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                    outside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: false,
                            firstNameLatin: true,
                            middleName: false,
                            lastName: false,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                }
            },
            cm: {
                tour: {
                    inside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: true,
                            firstNameLatin: true,
                            middleName: true,
                            lastName: true,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                    outside: {
                        birthDay: true,
                        sex: true,
                        nationalityId: true,
                        citizen: true,
                        IsNoPassport: true,
                        document: {
                            firstName: false,
                            firstNameLatin: true,
                            middleName: false,
                            lastName: false,
                            lastNameLatin: true,
                            series: false,
                            number: true,
                            expirationDate: false,
                        },
                    },
                }
            }
        },
        customerInfo: {
            ru: {
                name: true,
                surname: false,
                middleName: false,
                birthDay: false,
                sex: true,
                nationalityId: true,
                citizen: true,
                IsNoPassport: true,
                phones: true,
                email: true,
                document: {
                    firstName: false,
                    firstNameLatin: true,
                    middleName: false,
                    lastName: false,
                    lastNameLatin: true,
                    series: true,
                    number: true,
                    expirationDate: false,
                },
            }
        },
        passportExpirationPeriods:[
            {
                countryId: 233909, //Абхазия
                days: 1,
                month: null,
                validateOn: 'end',
            },
            {
                countryId: 433579, //Азербайджан
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 341911, //Албания
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 235572, //Армения
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 18764, //Болгария
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 188015, //Венгрия
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 18741, //Греция
                days: 92,
                month: null,
                validateOn: 'end',
            },
            {
                countryId: 16004, //Доминиканская
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 18498, //Египет
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 17762, //Израиль
                days: 180,
                month: 6,
                validateOn: 'start',
            },
            {
                countryId: 250722, //Индонезия
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 18747, //Испания
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 367404, //Казахстан
                days: 0,
                month: null,
                validateOn: 'end',
            },
            {
                countryId: 759622, //Катар
                days: 180,
                month: 6,
                validateOn: 'start',
            },
            {
                countryId: 18772, //Кипр
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 16006, //Куба
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 293483, //Маврикий
                days: 1,
                month: null,
                validateOn: 'end',
            },
            {
                countryId: 275410, //Мальдивы
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 380522, //Норвегия
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 20613, //ОАЭ
                days: 180,
                month: 6,
                validateOn: 'start',
            },
            {
                countryId: 277953, //Оман
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 284350, //Сейшелы
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 209834, //Сербия
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 288271, //Словения
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 20625, //Тайланд
                days: 180,
                month: 6,
                validateOn: 'start',
            },
            {
                countryId: 18464, //Тунис
                days: 1,
                month: null,
                validateOn: 'end',
            },
            {
                countryId: 18803, //Турция
                days: 123,
                month: 4,
                validateOn: 'start',
            },
            {
                countryId: 18810, //Хорватия
                days: 180,
                month: 6,
                validateOn: 'end',
            },
            {
                countryId: 18750, //Швейцария
                days: 92,
                month: 3,
                validateOn: 'end',
            },
            {
                countryId: 20629, //Шри Ланка
                days: 180,
                month: 6,
                validateOn: 'start',
            },
            {
                countryId: 293645, //Вьетнам
                days: 180,
                month: 6,
                validateOn: 'start',
            },

        ],
        isValidationSuccessful: true,
    },
    mutations: {
        updateValidationCountry(state, payload) {
            state.country = payload
            //console.log('state.country: ', state.country)
        },
        updateIsValidationSuccessful(state, payload){
            state.isValidationSuccessful = payload
        },
    },
    getters: {
        validationGetTourFieldsRequired: state => tour => {
            //Россия Туры
            if (state.country === 'ru' && tour.countryFromId === tour.countryId) {
                return state.basketTourist.ru.tour.inside
            }
            if (state.country === 'ru' && tour.countryFromId !== tour.countryId) {
                return state.basketTourist.ru.tour.outside
            }
        },
        getIsValidationSuccessful: state=>{
            return state.isValidationSuccessful
        },
        getPassportExpirationPeriods: state=>{
            return state.passportExpirationPeriods
        }
    }
}
