import Vue from 'vue'
import Vuex from 'vuex'

import airports from '../store/modules/airports';
import agency from '../store/modules/agent/agency';
import agent from '../store/modules/agent/agent';
import agentBonuses from "../store/modules/agent/agentBonuses";
import agentContracts from "../store/modules/agent/agentContracts";
import agentMessages from "../store/modules/agent/agentMessages";
import agentPayments from "../store/modules/agent/agentPayments";
import agentPlanFact from "../store/modules/agent/agentPlanFact";
import agentReports from "../store/modules/agent/agentReports";
import agentSales from "../store/modules/agent/agentSales";
import claimsFIT from "../store/modules/agent/claimsFIT";
import hotTours from "../store/modules/hotTours";
import avia from "../store/modules/search/avia";
import log from "../store/modules/log";
import breadcrumbs from "../store/modules/breadcrumbs";
import common from "../store/modules/common";
import customerOrders from "../store/modules/customer/customerOrders";
import customerPayment from "../store/modules/customer/customerPayment";
import customerPaxes from "../store/modules/customer/customerPaxes";
import customerAppeals from "../store/modules/customer/customerAppeals";
import currentPage from "../store/modules/currentPage";
import claims from '../store/modules/agent/claims';
import geo from "../store/modules/geo";
import effects from "../store/modules/effects";
import insurance from "../store/modules/search/tourSearchInsurance";
import kiwiTransfer from "../store/modules/search/kiwiTransfer";
import loyalty from "../store/modules/customer/loyalty";
import loyaltyCountry from '../store/modules/customer/loyaltyCountry';
import menu from '../store/modules/menu';
import mosaic from "../store/modules/mosaic";
import user from "../store/modules/customer/user";
import references from "../store/modules/references";
import tabs from "../store/modules/tabs";
import towns from '../store/modules/towns';
import tourSearchServices from "../store/modules/search/tourSearchServices";
import popups from '../store/modules/popups';
import account from "../store/modules/account";
import localization from "../store/modules/localization";
import hotels from "../store/modules/hotels";
import tourSearchAvia from "../store/modules/search/tourSearchAvia";
import tourSearchHotels from "../store/modules/search/tourSearchHotels";
import basket from "../store/modules/basket";
import staticPage from "../store/modules/staticPage";
import aviaOptions from "../store/modules/search/aviaOptions";
import marketplace from "../store/modules/marketplace/marketplace";
import validation from "../store/modules/validation";
import tourDetails from "../store/modules/search/tourDetails";
import sendLead from "../store/modules/sendLead";
import wishList from "../store/modules/wishList";
import mainBox from "../store/modules/mainBox";
import dataLayer from "../store/modules/dataLayer";
import advCake from "../store/modules/advCake";
import flocktory from "../store/modules/flocktory";
import offices from "../store/modules/offices";
import counters from "../store/modules/counters";
import mailSender from "../store/modules/mailSender";
import orderStatusCheck from "../store/modules/orderStatusCheck";
import tourSearchWeekend from "../store/modules/search/tourSearchWeekend";
import directions from "../store/modules/directions";
import marketBasket from "../store/modules/marketplace/marketBasket";
import agentStatusCheck from "../store/modules/agentStatusCheck";
import compare from "../store/modules/search/compare";
import tooltips from "../store/modules/tooltips";
import severX from "../store/modules/severX";


Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        agency,
        agent,
        airports,
        agentBonuses,
        agentContracts,
        agentMessages,
        agentPayments,
        agentPlanFact,
        agentReports,
        agentSales,
        claimsFIT,
        avia,
        hotTours,
        log,
        breadcrumbs,
        common,
        customerOrders,
        customerPayment,
        customerPaxes,
        customerAppeals,
        currentPage,
        claims,
        geo,
        effects,
        insurance,
        kiwiTransfer,
        loyalty,
        loyaltyCountry,
        menu,
        mosaic,
        user,
        references,
        tabs,
        tourSearchAvia,
        towns,
        popups,
        account,
        localization,
        hotels,
        tourSearchServices,
        basket,
        tourSearchHotels,
        staticPage,
        aviaOptions,
        marketplace,
        validation,
        tourDetails,
        sendLead,
        wishList,
        mainBox,
        dataLayer,
        advCake,
        flocktory,
        offices,
        counters,
        mailSender,
        orderStatusCheck,
        tourSearchWeekend,
        directions,
        marketBasket,
        agentStatusCheck,
        compare,
        tooltips,
        severX,
    }
});


export default store


