import variables from "../../../plugins/variables"
import axios from "axios"

export default {
    actions: {
        async fetchAgentReportsGet(ctx){
            const url = variables.routeAgentReports
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                })
                ctx.commit('updateAgentReports', await res.data)
            } catch(e){
                console.log('Ошибка в actions fetchAgentReportsGet', e)
            }
        },
        async fetchAgentReportsParameters(ctx, id){
            const url = variables.routeAgentReportsParameters + '/' + id
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                })
                ctx.commit('updateAgentReportsParameters', await res.data)
            } catch(e){
                console.log('Ошибка в actions fetchAgentReportsParameters' + e)
            }
        },
        async fetchAgentReportsGetJob(ctx, data){
            try {
                const url = variables.routeAgentReportsJob
                console.log('data', data)
                let res = await axios({
                    url,
                    method: 'POST',
                    data,
                })
                if(await res.data.jobId){
                    return res.data.jobId
                } else {
                    return false
                }
            } catch(e){
                console.log('Ошибка в action fetchAgentReportsGetJob' + e)
            }
        }
    },
    state: {
        agentReports: [],
        agentReportsParameters: [],

    },
    mutations: {
        updateAgentReports(ctx, payload){
            let agentReports = []
            try {
                payload.forEach(item => {
                    let report = {
                        value: null,
                        name: null,
                    }
                    report.value = item.id
                    report.name = item.name
                    agentReports.push(report)
                })
                ctx.agentReports = agentReports
            } catch(e){
                console.log('Ошибка в мутации updateAgentReports ', e)
            }
        },
        updateAgentReportsParameters(ctx, payload){
            try{
                ctx.agentReportsParameters = payload
            } catch(e){
                console.log('Ошибка в мутации updateAgetnReportsParameters', e)
            }
        },
    },
    getters: {
        getAgentReports: state => {
            return state.agentReports
        },
        getAgentReportsParameters: state => {
            return state.agentReportsParameters
        }
    }
}
