import variables from "../../../plugins/variables";
import axios from 'axios';
import moment from 'moment';

export default {
    actions: {
        //Получение данных из аккаунта Само
        async fetchUser(ctx){
            let url = variables.routeUser
            try {
                const res = await axios({
                    url,
                    method: 'POST'
                })
                const userInfo = await res.data
                ctx.commit('updateUserInfo', userInfo)
            } catch(e) {
                console.log('Ошибка в actions fetchUser', e)
            }
        },
        //Получение данных из профиля
        async fetchUserProfile(ctx){
            let url = variables.routeUserProfile
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                const userInfo = await res.data
                //Получение справочника по национальностям
                await ctx.dispatch('fetchGetNationalities')
                let nationalities = ctx.getters.getNationalities
                let locale = ctx.getters.getLocalization
                userInfo.locale = locale
                userInfo.nationalities = nationalities
                ctx.commit('updateUserProfile', userInfo)
            } catch(e) {
                console.log('Ошибка в actions fetchUserProfile', e)
            }
        },
        //Сохранение данных в профиль. Документы при этом не изменяются
        //Чтобы сохранились изменения в общегражданском паспорте (который получам с помощью геттера getUserRegularPassport)
        //Нужно сначала вызвать мутацию updateRegularPassport()
        //Загран паспорт и другие документы логично реализовать по аналогии
        async saveUserProfile(ctx){
            let url = variables.routeUserProfile
            let data = ctx.state.changedProfile

            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data,
                })
                if(await res.status === 200){
                    // console.log('Успешное обновление: ', res)
                }
               // ctx.commit('updateUserProfile', userInfo)
            } catch(e) {
                console.log('Ошибка в методе saveUserProfile', e)
            }
        },
        async getUserAvatar(ctx, data){
            let url = variables.routeUserAvatar
            try {
                const res = await axios.get(url,{
                    responseType: 'arraybuffer'
                })
                if(await res.status === 200){
                    let contType = res.headers['content-type']
                    const buffer = Buffer.from(res.data, 'binary').toString('base64');
                    ctx.commit('setAvatar', 'data:' + contType + ';base64,'+ buffer)
                }
            } catch(e) {
                console.log('Ошибка запроса на получение данных ' + url, e)
            }
        },
        async uploadUserAvatar(ctx, data){

            let dataURI = data.avatar.path
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            let file = new Blob([ia], { type: mimeString })
            let formData = new FormData()
            formData.append('formFile', file, data.avatar.name)

            let url = variables.routeUserAvatar
            try {
                const res = await axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if(await res.status === 200){
                    ctx.commit('setAvatar', dataURI)
                }
            } catch {
                console.log('Ошибка запроса на обновление аватара ' + url)
            }
        },
    },
    state: {
        userAvatar:null,
        userInfo: {
            "name": null,
            "lastName": null,
            "patronymic": null,
            "address": null,
            "birthDate": null,
            "passport": {
                "series": null,
                "number": null,
                "issueDate": null,
                "issuingAuthority": null,
                "issuingAuthorityCode": null
            }
        },
        //объект отражает текущее состояние профиля (на сервере)
        //не должен изменяться и использоваться в компонентах
        profile:{},
        //Содержит все документы
        documents: [],
        changedProfile: {
            person: {
                lastName: "",
                firstName: "",
                patronymic: "",
                birthDate: "",
                gender: 0,
                ageGroup: 1,
                identificationNumber: ""
            },
            contactInformation: {
                phone: "",
                email: ""
            },
            nationalityCountryIds: [],
            documents: [],
        },
        //Для текущей локали общегражданский паспорт может быть только 1
        // regularPassport: {
        //     type: null,
        //     series: "",
        //     number: "",
        //     address: "",
        //     issuingAuthority: "",
        //     issueDate: "",
        //     expirationDate: null,
        //     inn: null,
        //     nationalityCountryId: null
        // },
        // //Загран паспартов может быть несколько
        // foreignPassports: [
        //     {
        //         type: null,
        //         series: "",
        //         number: "",
        //         address: "",
        //         issuingAuthority: "",
        //         issueDate: "",
        //         expirationDate: null,
        //         inn: null,
        //         nationalityCountryId: null
        //     }
        // ],
        isLoading: false, //Переключается в true когда данные о пользователе были хоть раз загружены
        isLoadChanged: false, //Переключается в true когда в changeInfo загружается информация о пользователе,
        //Справочник национальностей
        nationalities: [],
        //Текущая локаль
        locale: 'ru',
    },
    mutations: {
        //Изменение данных из аккаунта само
        updateUserInfo(state, userInfo){
            try {
                state.userInfo = userInfo

                //Почта, указанная при регистрации
                //state.client.contactInformation.email = userInfo.email

                //Загружается информация о пользователеле в changedInfo если она не была загружена ранее
                // if(state.isLoadChanged === false){
                //     state.changedInfo = {...state.userInfo}
                //     state.changedInfo.passport = {...state.userInfo.passport}
                //     state.isLoadChanged = true
                // }
            } catch(e) {
                console.log('Ошибка в мутации updateUserInfo', e)
            }
        },
        updateUserProfile(state, payload){
            state.isLoading = true
            //Запись национальностей из справочника в state
            if(Array.isArray(payload.nationalities)){
                state.nationalities = payload.nationalities
            }
            //Запись текущей локали в state
            if(payload.locale) state.locale = payload.locale

            try{
                if(payload.client !== null){
                    //В информацию по клиенту документы не ложим, они лежат отдельно
                    let client = {
                        person: {
                            lastName: "",
                            firstName: "",
                            patronymic: "",
                            birthDate: "01.01.2000",
                            gender: 0,
                            ageGroup: 1,
                            identificationNumber: ""
                        },
                        nationalityCountryIds: []
                    }
                    client.person = payload.client.person
                    client.contactInformation = {
                        phone: "",
                        email: "",
                    } //payload.client.contactInformation
                    client.nationalityCountryIds = payload.client.nationalityCountryIds
                    //у клиента документы изменяются только с помощью мутаций перед отправкой на сервер
                    //само состояние документов на фронте отражает state.documents
                    client.documents = payload.client.documents
                    //Документы в state храняться отдельно от юзера. И загружаются только один раз
                    //Т.к. после первой загрузки они могли изменяться и не быть сохраненными на сервере
                    //формируется перебором и клонированием чтобы избежать проблем с символическими ссылками
                    if(state.documents.length === 0){
                        if(Array.isArray(payload.client.documents)){
                            payload.client.documents.forEach(document => {
                                state.documents.push(Object.assign({}, document))
                            })
                        }
                    }

                    // //Заграничные пасспорта
                    // let foreignPassports = payload.client.documents.filter(item => {
                    //     if(item.type === 1) return true
                    // })
                    // if(foreignPassports.length === 0){
                    //     foreignPassports.push({
                    //         type: 1,
                    //         series: "",
                    //         number: "",
                    //         address: "",
                    //         issuingAuthority: "",
                    //         issueDate: "",
                    //         expirationDate: null,
                    //         inn: null,
                    //         nationalityCountryId: null
                    //     })
                    // }

                    //state.foreignPassports = foreignPassports
                    state.profile = payload.client
                    state.changedProfile = client
                }
            } catch(e){
                console.log('Ошибка в мутации updateUserProfile', e)
            }
        },
        //Вызывается перед сохранением профиля юзера, чтобы сохранились изменения паспорта
        //Если параметр не передан то изменяется пасспорт по локали, иначе по nationalityId
        updateRegularPassport(state, nationalityId=null){
            let countryId = nationalityId
            if(!countryId){
                let nationality = {
                    id: 0,
                    title: '',
                    titleLatin: '',
                }

                switch(state.locale){
                    case 'cm':
                    case 'ru':
                        //россия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Russia')                        
                        if(nationality) countryId = nationality.id
                        break
                    case 'ua':
                        //украина
                        nationality = state.nationalities.find(item => item.titleLatin === 'Ukraine')
                        if(nationality) countryId = nationality.id
                        break
                    case 'by':
                        //беларусь
                        nationality = state.nationalities.find(item => item.titleLatin === 'Belarus')
                        if(nationality) countryId = nationality.id
                        break
                    case 'kz':
                        //казахстан
                        nationality = state.nationalities.find(item => item.titleLatin === 'Kazakhstan')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lv':
                        //латвия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Latvia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ee':
                        //эстония
                        nationality = state.nationalities.find(item => item.titleLatin === 'Estonia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lt':
                        //литва
                        nationality = state.nationalities.find(item => item.titleLatin === 'Lithuania')
                        if(nationality) countryId = nationality.id
                        break
                }
            }

            try {
                //Находим измененный общегражданский пасспорт с соответствующим id
                let regularPassport = state.documents.find(item => {
                    if(item.type === 2 && item.nationalityCountryId === countryId){
                        return true
                    }
                })
                //Находим этот паспорт в профиле пользователя
                let userRegularPassport = state.changedProfile.documents.find(item => {
                    if(item.type === 2 && item.nationalityCountryId === countryId){
                        return true
                    }
                })
                //Если паспорт не найден возможно гражданство не было указано
                if(!userRegularPassport){
                    userRegularPassport = state.changedProfile.documents.find(item => {
                        if(item.type === 2 && item.nationalityCountryId === 0) return true
                    })
                }
                //Если все равно не найден, тогда это новый паспорт
                if(!userRegularPassport){
                    state.changedProfile.documents.push(regularPassport)
                    userRegularPassport = regularPassport
                }
                //Переписываем поля полученного паспорта
                userRegularPassport.firstName = regularPassport.firstName || ''
                userRegularPassport.lastName = regularPassport.lastName || ''
                userRegularPassport.patronimicName = regularPassport.patronimicName || ''
                userRegularPassport.address = regularPassport.address || ''
                userRegularPassport.expirationDate = regularPassport.expirationDate || moment().format()
                userRegularPassport.inn = regularPassport.inn || ''
                userRegularPassport.issueDate = regularPassport.issueDate || moment().format()
                userRegularPassport.issuingAuthority = regularPassport.issuingAuthority || ''
                userRegularPassport.departmentCode = regularPassport.departmentCode || ''
                userRegularPassport.nationalityCountryId = regularPassport.nationalityCountryId
                userRegularPassport.number = regularPassport.number || ''
                userRegularPassport.series = regularPassport.series || ''
                userRegularPassport.type = regularPassport.type || ''
            } catch(e) {
                console.log('Ошибка в мутации updateRegularPassport', e)
            }
        },
        //Вызывается перед сохранением профиля юзера, чтобы сохранились изменения паспорта
        //Если параметр не передан то изменяется пасспорт по локали, иначе по nationalityId
        updateForeignPassport(state, nationalityId=null){
            let countryId = nationalityId
            if(!countryId){
                let nationality = {
                    id: 0,
                    title: '',
                    titleLatin: '',
                }

                switch(state.locale){
                    case 'cm':
                    case 'ru':
                        //россия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Russia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ua':
                        //украина
                        nationality = state.nationalities.find(item => item.titleLatin === 'Ukraine')
                        if(nationality) countryId = nationality.id
                        break
                    case 'by':
                        //беларусь
                        nationality = state.nationalities.find(item => item.titleLatin === 'Belarus')
                        if(nationality) countryId = nationality.id
                        break
                    case 'kz':
                        //казахстан
                        nationality = state.nationalities.find(item => item.titleLatin === 'Kazakhstan')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lv':
                        //латвия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Latvia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ee':
                        //эстония
                        nationality = state.nationalities.find(item => item.titleLatin === 'Estonia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lt':
                        //литва
                        nationality = state.nationalities.find(item => item.titleLatin === 'Lithuania')
                        if(nationality) countryId = nationality.id
                        break
                }
            }

            try {
                //Находим измененный загран пасспорт с соответствующим id
                let foreignPassport = state.documents.find(item => {
                    if(item.type === 1 && item.nationalityCountryId === countryId){
                        return true
                    }
                })
                //Находим этот паспорт в профиле пользователя
                let userForeignPassport = state.changedProfile.documents.find(item => {
                    if(item.type === 1 && item.nationalityCountryId === countryId){
                        return true
                    }
                })
                //Если паспорт не найден тогда это новый паспорт
                if(!userForeignPassport){
                    state.changedProfile.documents.push(foreignPassport)
                    userForeignPassport = foreignPassport
                }
                //Переписываем поля полученного паспорта
                userForeignPassport.firstName = foreignPassport.firstName || ''
                userForeignPassport.lastName = foreignPassport.lastName || ''
                userForeignPassport.patronimicName = foreignPassport.patronimicName || ''
                userForeignPassport.address = foreignPassport.address || ''
                userForeignPassport.expirationDate = foreignPassport.expirationDate || moment().format()
                userForeignPassport.inn = foreignPassport.inn || ''
                userForeignPassport.issueDate = foreignPassport.issueDate || moment().format()
                userForeignPassport.issuingAuthority = foreignPassport.issuingAuthority || ''
                userForeignPassport.departmentCode = foreignPassport.departmentCode || ''
                userForeignPassport.nationalityCountryId = foreignPassport.nationalityCountryId
                userForeignPassport.number = foreignPassport.number || ''
                userForeignPassport.series = foreignPassport.series || ''
                userForeignPassport.type = foreignPassport.type || ''
            } catch(e) {
                console.log('Ошибка в мутации updateForeignPassport', e)
            }
        },
        //Вызывается перед сохранением профиля юзера, чтобы сохранились изменения ID-карты
        //Если параметр не передан то изменяется ID-карта по локали, иначе по nationalityId
        updateIdCard(state, nationalityId=null){
            let countryId = nationalityId
            if(!countryId){
                let nationality = {
                    id: 0,
                    title: '',
                    titleLatin: '',
                }

                switch(state.locale){
                    case 'cm':
                    case 'ru':
                        //россия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Russia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ua':
                        //украина
                        nationality = state.nationalities.find(item => item.titleLatin === 'Ukraine')
                        if(nationality) countryId = nationality.id
                        break
                    case 'by':
                        //беларусь
                        nationality = state.nationalities.find(item => item.titleLatin === 'Belarus')
                        if(nationality) countryId = nationality.id
                        break
                    case 'kz':
                        //казахстан
                        nationality = state.nationalities.find(item => item.titleLatin === 'Kazakhstan')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lv':
                        //латвия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Latvia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ee':
                        //эстония
                        nationality = state.nationalities.find(item => item.titleLatin === 'Estonia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lt':
                        //литва
                        nationality = state.nationalities.find(item => item.titleLatin === 'Lithuania')
                        if(nationality) countryId = nationality.id
                        break
                }
            }

            try {
                //Находим измененную ID-карту с соответствующим id
                let idCard = state.documents.find(item => {
                    if(item.type === 5 && item.nationalityCountryId === countryId){
                        return true
                    }
                })
                //Находим эту ID-карту в профиле пользователя
                let userIdCard = state.changedProfile.documents.find(item => {
                    if(item.type === 5 && item.nationalityCountryId === countryId){
                        return true
                    }
                })
                //Если ID-карта не найдена то заводим
                if(!userIdCard){
                    state.changedProfile.documents.push(idCard)
                    userIdCard = idCard
                }
                //Переписываем поля полученного паспорта
                userIdCard.firstName = idCard.firstName || ''
                userIdCard.lastName = idCard.lastName || ''
                userIdCard.patronimicName = idCard.patronimicName || ''
                userIdCard.address = idCard.address || ''
                userIdCard.expirationDate = idCard.expirationDate || moment().format()
                userIdCard.inn = idCard.inn || ''
                userIdCard.issueDate = idCard.issueDate || moment().format()
                userIdCard.issuingAuthority = idCard.issuingAuthority || ''
                userIdCard.departmentCode = idCard.departmentCode || ''
                userIdCard.nationalityCountryId = idCard.nationalityCountryId
                userIdCard.number = idCard.number || ''
                userIdCard.series = idCard.series || ''
                userIdCard.type = idCard.type || ''
            } catch(e) {
                console.log('Ошибка в мутации updateIdCard', e)
            }
        },
        setUserAvatar(state,data){
            state.userAvatar = data
        },
    },
    getters: {
        getUserInfo(state){
            return state.userInfo
        },
        getUserProfile(state){
            return state.changedProfile
        },
        //Позволяет получить общегражданский пасспорт по локали
        //если nationalityId не передан
        //или по nationalityId
        getUserRegularPassport: state => (nationalityId=null) => {
            if(!state.isLoading) return null

            let countryId = nationalityId

            if(!countryId){
                let nationality = {
                    id: 0,
                    title: '',
                    titleLatin: '',
                }

                switch(state.locale){
                    case 'cm':
                    case 'ru':
                        //россия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Russia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ua':
                        //украина
                        nationality = state.nationalities.find(item => item.titleLatin === 'Ukraine')
                        if(nationality) countryId = nationality.id
                        break
                    case 'by':
                        //беларусь
                        nationality = state.nationalities.find(item => item.titleLatin === 'Belarus')
                        if(nationality) countryId = nationality.id
                        break
                    case 'kz':
                        //казахстан
                        nationality = state.nationalities.find(item => item.titleLatin === 'Kazakhstan')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lv':
                        //латвия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Latvia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ee':
                        //эстония
                        nationality = state.nationalities.find(item => item.titleLatin === 'Estonia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lt':
                        //литва
                        nationality = state.nationalities.find(item => item.titleLatin === 'Lithuania')
                        if(nationality) countryId = nationality.id
                        break
                }
            }
            //Общегражданский паспорт имеет тип 2. Их может быть несколько
            //Выбираем в зависимости от локали или переданного nationalityId
            let passport = state.documents.find(item => {
                if(item.type === 2 && item.nationalityCountryId === countryId) return true
            })
            //Если паспорт не найден проверяются общегражданские паспорта где не указана национальность
            if(!passport){
                passport = state.documents.find(item => {
                    if(item.type === 2 && item.nationalityCountryId === 0) return true
                })
            }
            //если подходящего паспорта нет
            if(!passport){
                passport = {
                    type: 2,
                    firstName: "",
                    lastName: "",
                    patronimicName: "",
                    series: "",
                    number: "",
                    address: "",
                    issuingAuthority: "",
                    issueDate: "",
                    expirationDate: null,
                    inn: null,
                    departmentCode: "",
                    nationalityCountryId: countryId
                }
                state.documents.push(passport)
            }
            return passport
        },
        //Позволяет получить загран пасспорт по локали
        //если nationalityId не передан
        //или по nationalityId
        getUserForeignPassport: state => (nationalityId=null) => {
            if(!state.isLoading) return null

            let countryId = nationalityId

            if(!countryId){
                let nationality = {
                    id: 0,
                    title: '',
                    titleLatin: '',
                }

                switch(state.locale){
                    case 'cm':
                    case 'ru':
                        //россия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Russia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ua':
                        //украина
                        nationality = state.nationalities.find(item => item.titleLatin === 'Ukraine')
                        if(nationality) countryId = nationality.id
                        break
                    case 'by':
                        //беларусь
                        nationality = state.nationalities.find(item => item.titleLatin === 'Belarus')
                        if(nationality) countryId = nationality.id
                        break
                    case 'kz':
                        //казахстан
                        nationality = state.nationalities.find(item => item.titleLatin === 'Kazakhstan')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lv':
                        //латвия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Latvia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ee':
                        //эстония
                        nationality = state.nationalities.find(item => item.titleLatin === 'Estonia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lt':
                        //литва
                        nationality = state.nationalities.find(item => item.titleLatin === 'Lithuania')
                        if(nationality) countryId = nationality.id
                        break
                }
            }
            //Загран паспорт имеет тип 1. Их может быть несколько (как минимум для каждой страны)
            //В текущей реализации не проработана логика нескольких загран паспортов для одной страны
            //Выбираем в зависимости от локали или переданного nationalityId
            let passport = state.documents.find(item => {
                if(item.type === 1 && item.nationalityCountryId === countryId) return true
            })
            //если подходящего паспорта нет
            if(!passport){
                passport = {
                    type: 1,
                    firstName: "",
                    lastName: "",
                    patronimicName: "",
                    series: "",
                    number: "",
                    address: "",
                    issuingAuthority: "",
                    issueDate: "",
                    expirationDate: null,
                    inn: null,
                    departmentCode: "",
                    nationalityCountryId: countryId
                }
                state.documents.push(passport)
            }
            return passport
        },
        //Позволяет получить id карту по локали
        //если nationalityId не передан
        //или по nationalityId
        getUserIdCard: state => (nationalityId=null) => {
            if(!state.isLoading) return null

            let countryId = nationalityId

            if(!countryId){
                let nationality = {
                    id: 0,
                    title: '',
                    titleLatin: '',
                }

                switch(state.locale){
                    case 'cm':
                    case 'ru':
                        //россия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Russia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ua':
                        //украина
                        nationality = state.nationalities.find(item => item.titleLatin === 'Ukraine')
                        if(nationality) countryId = nationality.id
                        break
                    case 'by':
                        //беларусь
                        nationality = state.nationalities.find(item => item.titleLatin === 'Belarus')
                        if(nationality) countryId = nationality.id
                        break
                    case 'kz':
                        //казахстан
                        nationality = state.nationalities.find(item => item.titleLatin === 'Kazakhstan')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lv':
                        //латвия
                        nationality = state.nationalities.find(item => item.titleLatin === 'Latvia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'ee':
                        //эстония
                        nationality = state.nationalities.find(item => item.titleLatin === 'Estonia')
                        if(nationality) countryId = nationality.id
                        break
                    case 'lt':
                        //литва
                        nationality = state.nationalities.find(item => item.titleLatin === 'Lithuania')
                        if(nationality) countryId = nationality.id
                        break
                }
            }
            //Id карта имеет тип 5. Их может быть несколько (для каждой страны)
            //Выбираем в зависимости от локали или переданного nationalityId
            let idCard = state.documents.find(item => {
                if(item.type === 5 && item.nationalityCountryId === countryId) return true
            })
            //если подходящего паспорта нет
            if(!idCard){
                idCard = {
                    type: 5,
                    firstName: "",
                    lastName: "",
                    patronimicName: "",
                    series: "",
                    number: "",
                    address: "",
                    issuingAuthority: "",
                    issueDate: "",
                    expirationDate: null,
                    inn: null,
                    departmentCode: "",
                    nationalityCountryId: countryId
                }
                state.documents.push(idCard)
            }
            return idCard
        },
        getUserRegularPassports(state){
            return state.documents.filter(item => item.type === 2)
        },
        getUserForeignPassports(state){
            return state.documents.filter(item => item.type === 1)
        },
        getUserIdCards(state){
            return state.documents.filter(item => item.type === 5)
        },
        getUserBirthCertificate(state){
            return state.documents.filter(item => item.type === 3)
        },
        getUserAvatar(state){
            return state.userAvatar
        },
    }
}
