import variables from "../../../plugins/variables";
import axios from 'axios';
import moment from "moment";


export default {
    actions: {
        async searchHotelsHot(ctx, payload) {
            let str = '?';
            Object.keys(payload.data).map(function (key) {
                let elem = key + '=' + payload.data[key] + '&'
                if (key === 'minStartDate' || key === 'maxStartDate') {
                    elem = key + '=' + moment(payload.data[key]).format('YYYY-MM-DD') + '&'
                }
                str = str + elem;
            })
            let queryString = str.slice(0, -1);
            //console.log(queryString)

            let url = variables.routeSearchTourHot + '/' + payload.tab + queryString;
            // url ='http://127.0.0.1:8000/api/search/country?departureCityId=274286&arrivalCountryId=18803&arrivalRegionIds=&arrivalCityIds=&hotelIds=&minStartDate=2021-06-17&maxStartDate=2021-06-29&minNightsCount=7&maxNightsCount=14&adults=2&children=&searchLevel=&isGeoInfoRequired=false'
            // try {
            const fetchHotels = await axios({
                url,
                method: 'GET',
            })
            return fetchHotels.data
        },
        async searchHotels(ctx, payload) {
            ctx.commit('updateHotelsLoad', true)
            let str = '?';
            Object.keys(payload.data).map(function (key) {
                let elem = key + '=' + payload.data[key] + '&'
                if (key === 'minStartDate' || key === 'maxStartDate') {
                    elem = key + '=' + moment(payload.data[key]).format('YYYY-MM-DD') + '&'
                }
                str = str + elem;
            })
            let queryString = str.slice(0, -1);
            //console.log(queryString)
            ctx.commit('updateHotelsQueryString', queryString)
            let url = variables.routeSearchTour + '/' + payload.tab + queryString;
            // url ='http://127.0.0.1:8000/api/search/country?departureCityId=274286&arrivalCountryId=18803&arrivalRegionIds=&arrivalCityIds=&hotelIds=&minStartDate=2021-06-17&maxStartDate=2021-06-29&minNightsCount=7&maxNightsCount=14&adults=2&children=&searchLevel=&isGeoInfoRequired=false'
            // try {
            const fetchHotels = await axios({
                url,
                method: 'GET',
            })
            // console.log(url, fetchHotels.data)
            ctx.commit('updateHotelsMinStartDate', payload.minStartDate)
            ctx.commit('updateHotelsMaxStartDate', payload.maxStartDate)
            //console.log('fetchHotels data', fetchHotels.data)
            ctx.commit('updateHotels', fetchHotels.data)
            //Очищаем фильтры в данных по турам
            eventBus.$emit('clearHotelDataFilters')
            //} catch {
            //    console.log('Ошибка получения ответа от ' + url)
            //}

            ctx.commit('updateHotelSearchFilterData', payload)
            ctx.commit('updateHotelsLoad', false)
            ctx.commit('updateHotelsLoadStatus', true)
            return fetchHotels.data
        },
        async searchHotelsCompilation(ctx, payload) {
            let str = '?';
            Object.keys(payload.data).map(function (key) {
                let elem = key + '=' + payload.data[key] + '&'
                if (key === 'minStartDate' || key === 'maxStartDate') {
                    elem = key + '=' + moment(payload.data[key]).format('YYYY-MM-DD') + '&'
                }
                str = str + elem;
            })
            let queryString = str.slice(0, -1);

            let url = variables.routeSearchTour + '/' + payload.tab + queryString;
            const fetchHotels = await axios({
                url,
                method: 'GET',
            })
            ctx.commit('updateHotelsCompilation', fetchHotels.data)
        },
        async fetchHotelArrivalPointsFromEs({commit, getters, state}, payload){
            //try {
            let isWeekendTours = !!payload.onlyWithWeekendTours
            let url = variables.routeGetAviaArrivalCountriesFromEs + payload.departureCityId  + '/' + isWeekendTours + '/' + payload.arrivalPlaceName
            const destinationData = await axios({
                url: url,
                method: 'GET'
            });
            const destination = destinationData.data;
            let localization = getters.getLocalization
            let isRusNames = true

            //если нет локали в бд, будут подставлятся английские названия
            if(false){
                isRusNames = false
            }

            let destination_payload = {
                destinationCountriesEs: [],
                destinationRegionsEs: [],
                destinationCitiesEs: [],
                destinationHotelsEs: [],
            }
            //Для Крыма оставляем только Крым
            if(state.mixConfig === 'crimea'){
                destination.searchFromCountries = []
                destination.searchFromRegions = []
                destination.searchFromTowns = []
                destination.searchFromHotels = []
                destination.searchFromCountries.push({
                    id: 821577,
                    internationalName: "Р.",
                    name: "Крым",
                    regions: [],
                    stateIso: ""
                })
            }
            //Добавляем Крым, если его нет
            if(!destination.searchFromCountries.find(item=>item.id === 821577) &&  'россия, крым'.includes(payload.arrivalPlaceName.toLowerCase()) && localization ==='ru'){
                destination.searchFromCountries.push({
                    id: 821577,
                    internationalName: "Р.",
                    name: "Крым",
                    regions: [],
                    stateIso: ""
                })
            }
            //Переименовываем крым
            if(destination.searchFromCountries.find(country=>country.id===821577)){
                let index = destination.searchFromCountries.findIndex(country=>country.id===821577)
                destination.searchFromCountries[index].name = "Крым"
            }
            if(localization ==='ua' && destination.searchFromCountries.find(country=>country.id===821577)){
                let index = destination.searchFromCountries.findIndex(country=>country.id===821577)
                destination.searchFromCountries.splice(index,1)
                //console.log('Убрали крым')
            }
            if(localization ==='ua' && destination.searchFromCountries.find(country=>country.id===210357)){
                let index = destination.searchFromCountries.findIndex(country=>country.id===210357)
                destination.searchFromCountries.splice(index,1)
            }
            if(localization ==='ua' && destination.searchFromHotels.find(country=>country.id===210357)){
                let index = destination.searchFromHotels.findIndex(country=>country.id===210357)
                destination.searchFromHotels.splice(index,1)
            }
            //Страны
            for (let country in destination.searchFromCountries){
                destination.searchFromCountries[country]["checked"] = false
                if(!isRusNames){
                    destination.searchFromCountries[country]["name"] = destination.searchFromCountries[country].internationalName
                }
            }
            destination_payload.destinationCountriesEs = destination.searchFromCountries

            //Регионы
            let destinationRegions = []
            for (let country in destination.searchFromRegions){
                for (let region in destination.searchFromRegions[country].regions){
                    destination.searchFromRegions[country].regions[region]["checked"] = false
                    if(isRusNames){
                        destination.searchFromRegions[country].regions[region]["countryName"] = destination.searchFromRegions[country].name
                    } else {
                        destination.searchFromRegions[country].regions[region]['name'] = destination.searchFromRegions[country].regions[region].internationalName
                        destination.searchFromRegions[country].regions[region]["countryName"] = destination.searchFromRegions[country].internationalName
                    }

                    destinationRegions.push(destination.searchFromRegions[country].regions[region])
                }
            }
            destination_payload.destinationRegionsEs = destinationRegions

            //Города
            let destinationCities = []
            for (let country in destination.searchFromTowns){
                for (let region in destination.searchFromTowns[country].regions){
                    for (let city in destination.searchFromTowns[country].regions[region].towns){
                        destination.searchFromTowns[country].regions[region].towns[city]["checked"] = false
                        destination.searchFromTowns[country].regions[region].towns[city]["countryId"] = destination.searchFromTowns[country].id
                        if(isRusNames){
                            destination.searchFromTowns[country].regions[region].towns[city]["countryName"] = destination.searchFromTowns[country].name
                            destination.searchFromTowns[country].regions[region].towns[city]["regionName"] = destination.searchFromTowns[country].regions[region].name
                        } else {
                            destination.searchFromTowns[country].regions[region].towns[city]['name'] = destination.searchFromTowns[country].regions[region].towns[city].internationalName
                            destination.searchFromTowns[country].regions[region].towns[city]["countryName"] = destination.searchFromTowns[country].internationalName
                            destination.searchFromTowns[country].regions[region].towns[city]["regionName"] = destination.searchFromTowns[country].regions[region].internationalName
                        }

                        destinationCities.push(destination.searchFromTowns[country].regions[region].towns[city])
                    }
                }
            }
            destination_payload.destinationCitiesEs = destinationCities

            //Отели
            let destinationHotels = []
            for (let country in destination.searchFromHotels){
                for (let region in destination.searchFromHotels[country].regions){
                    for (let city in destination.searchFromHotels[country].regions[region].towns){
                        for (let hotel in destination.searchFromHotels[country].regions[region].towns[city].hotels){
                            destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["checked"] = false
                            destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["countryId"] = destination.searchFromHotels[country].id
                            if(isRusNames){
                                destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["countryName"] = destination.searchFromHotels[country].name
                                destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["regionName"] = destination.searchFromHotels[country].regions[region].name
                            } else{
                                destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["countryName"] = destination.searchFromHotels[country].internationalName
                                destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["regionName"] = destination.searchFromHotels[country].regions[region].internationalName
                            }
                            if(!destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["name"]){
                                destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["name"] = destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel].internationalName
                            }

                            destinationHotels.push(destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel])
                        }
                    }
                }
            }
            destination_payload.destinationHotelsEs = destinationHotels

            console.log('destination_payload', destination_payload)
            commit('updateHotelDestinationElastic', destination_payload)
            //} catch {
            //    console.log('Ошибка получения ответа от ' + variables.routeTourSearchCountries)
            //}
        },

        async checkHotelCountry(ctx, country) {
            //console.log(country)

            let url = variables.routeTourSearchCitiesByCountry + '/?id=' + country.id;
            try {
                const fetchCities = await axios({
                    url,
                    method: 'GET',
                })
                //console.log(fetchCities.data)
                ctx.commit('updateHotelCitiesFromCheckedCountries', fetchCities.data)

            } catch {
                ctx.commit('updateHotelCitiesFromCheckedCountries', [])

                console.log('Ошибка получения ответа от ' + url)
            }
        },
        async fetchHotelRegionsByCountry(ctx, country) {
            //console.log(country)
            ctx.commit('updateCheckedCountries', country)

            let url = variables.routeTourSearchRegionsByCountry + '/?id=' + country.id;
            try {
                const fetchRegions = await axios({
                    url,
                    method: 'GET',
                })
                ctx.commit('updateHotelRegionsFromCheckedCountries', fetchRegions.data)

            } catch {
                ctx.commit('updateHotelRegionsFromCheckedCountries', [])
                console.log('Ошибка получения ответа от ' + url)
            }
        },
        checkHotelCountryEs(ctx, country){
            ctx.commit('updateHotelCheckedCountriesEs', country)
        },
        checkHotelRegionEs(ctx, region){
            ctx.commit('updateHotelCheckedRegionsEs', region)
        },
        checkHotelCityEs(ctx, city){
            ctx.commit('updateHotelCheckedCitiesEs', city)
        },
        checkHotelHotelEs(ctx, hotel){
            ctx.commit('updateHotelCheckedHotelsEs', hotel)
        },

    },
    mutations: {
        updateHotelsQueryString(state, payload){
            state.queryString = payload
        },
        updateHotelRegionsFromCheckedCountries(state, regions) {
            state.hotelRegionsFromCheckedCountries = Object.values(regions).sort((a, b) => a.localizedName > b.localizedName ? 1 : -1);
        },
        updateHotelCitiesFromCheckedCountries(state, cities) {
            state.hotelCitiesFromCheckedCountries = Object.values(cities).sort((a, b) => a.localizedName > b.localizedName ? 1 : -1);
        },
        updateHotelsLoad(state, payload) {
            state.hotelsLoad = payload
        },
        updateHotelsLoadStatus(state, payload){
            state.hotelsLoadStatus = payload
        },
        updateHotelsMinStartDate(state, value){
            state.hotelFilterData.minStartDate = value
        },
        updateHotelsMaxStartDate(state, value){
            state.hotelFilterData.maxStartDate = value
        },
        updateHotels(state, data) {
            // console.log('data: ', data)
            // console.log('start update hotels ==========')
            if (
                (Array.isArray(data.tours) && data.tours.length === 0)
                ||
                !Array.isArray(data.tours)
            ) {
                state.tours = []
            } else {
                // добавляем реактивность
                data.tours.forEach(tour => tour.currentOffer = {})
                state.tours = data.tours.filter(tour => {
                    let result = true
                    if(tour.hotel.name.indexOf('Infotour') === 0){
                        result = false
                    }
                    return result
                })
                // TODO временное решение присваивать id тура = id отеля. Пока не будет приходить корректный id тура с бэка
                // state.tours.forEach((tour, idx) => {
                //     console.log(data.tours[idx].hotel.id)
                //     tour.id = data.tours[idx].hotel.id
                // })
                state.country = data.country
                state.cities = data.cities
                state.concept = data.attributes
                // получаем и передаем в объект тура массив с удобствами, спец.сервисами и regionId для каждого отеля
                state.tours.forEach(tour => {
                    let mealTypeObj = null
                    tour.accommodationPriceOffer.forEach(offer => {
                        offer.price.currencyAlias = state.currency.find(cur => cur.id === offer.price.currencyId).name
                        // force проставляем тип питания по mealTypeGroupId

                        // СТАРАЯ логика
                        // offer.mealTypeName = state.mealTypesNaming.find(el => el.id === offer.mealTypeGroupId).nameRU
                        // offer.mealTypeNameEN = state.mealTypesNaming.find(el => el.id === offer.mealTypeGroupId).nameEN

                        // НОВАЯ логика с проверкой (костыль)
                        // если mealTypeGroupId = 0, НО при этом приходит аббревиатура типа питания (хотя по умолчанию "0" это "нет данных" )
                        if (offer.mealTypeGroupId === 0 && offer.mealTypeName) {
                            // находим объект с подходящим названием типа питания
                            mealTypeObj = state.mealTypesNaming.find(el => el.name === offer.mealTypeName)
                            // если объект не найден ищем по id
                            if(mealTypeObj){
                                offer.mealTypeGroupId = mealTypeObj.id
                                offer.mealTypeName = mealTypeObj.nameRU
                                offer.mealTypeNameEN = mealTypeObj.nameEN
                            } else {
                                offer.mealTypeGroupId = null
                                offer.mealTypeName = null
                                offer.mealTypeNameEN = null
                            }
                        } else {
                            offer.mealTypeName = state.mealTypesNaming.find(el => el.id === offer.mealTypeGroupId).nameRU
                            offer.mealTypeNameEN = state.mealTypesNaming.find(el => el.id === offer.mealTypeGroupId).nameEN
                        }
                    })
                    // сохраняем объект с принудительно добавленным типом в общий массив типов питания (получаем с бэка)
                    // необходимо для работы фильтров т.к. если с бэка придут все mealTypeGroupId = 0,тогда и в общем массиве типов питания будет только mealTypeGroupId = 0
                    if (mealTypeObj) {
                        data.mealTypes.push({
                            mealTypeGroupId: mealTypeObj.id,
                            nameEN: mealTypeObj.nameEN,
                            nameRU: mealTypeObj.nameRU,
                        })
                    }

                    // минимальная цена предложения на верхний уровень
                    tour.currentOffer = tour.accommodationPriceOffer[0]

                    tour.hotel.hotelServices.forEach(service => {
                        let hotelServicesIds = state.hotelServicesNaming.map(el => el.id)
                        if (hotelServicesIds.includes(service.id)) {
                            service.name = state.hotelServicesNaming.find(item => item.id === service.id).nameTrans
                        }
                    })

                    // const services = []
                    // tour.hotel.hotelServices.forEach(service => {
                    //     const srv = data.hotelServices.find(item => item.id === service)
                    //     services.push(srv)
                    // })
                    // tour.hotel.hotelServices = services
                    //
                    // const specials = []
                    // tour.hotel.specialServices.forEach(special => {
                    //     const spec = data.specialServices.find(item => item.id === special)
                    //     specials.push(spec)
                    // })
                    // tour.hotel.specialServices = specials

                    // передаем regionId
                    const cityId = tour.hotel.cityId
                    /*Проверка на найденный город*/
                    tour.weatherHistory = []
                    if (data.cities.find(city => city.id === cityId)) {
                        tour.hotel.regionId = data.cities.find(city => city.id === cityId).region
                        //Добавляем данные по погоде
                        let weather = data.cities.find(city => city.id === cityId).weatherHistory
                        let startMonth = moment(state.hotelFilterData.minStartDate)
                        startMonth.locale('en')
                        startMonth = startMonth.format('MMMM')
                        let startMonthLocalized = moment(state.hotelFilterData.minStartDate)
                        startMonthLocalized = startMonthLocalized.format('MMMM')
                        startMonthLocalized = startMonthLocalized.charAt(0).toUpperCase() + startMonthLocalized.slice(1)

                        let endMonth = moment(state.hotelFilterData.maxStartDate)
                        endMonth.locale('en')
                        endMonth = endMonth.format('MMMM')
                        let endMonthLocalized = moment(state.hotelFilterData.maxStartDate)
                        endMonthLocalized = endMonthLocalized.format('MMMM')
                        endMonthLocalized = endMonthLocalized.charAt(0).toUpperCase() + endMonthLocalized.slice(1)
                        //console.log('weather: ', weather)
                        //console.log('startMonth ', startMonth)
                        let weatherItem = weather.find(item => item.month === startMonth)
                        if(weatherItem){
                            weatherItem.localizedMonth = startMonthLocalized
                            tour.weatherHistory.push(weatherItem)
                        }
                        if (startMonth !== endMonth) {
                            weatherItem = weather.find(item => item.month === endMonth)
                            if(weatherItem){
                                weatherItem.localizedMonth = endMonthLocalized
                                tour.weatherHistory.push(weatherItem)
                            }
                        }
                    } else {
                        console.log('Не найден город: ', cityId)
                        tour.hotel.regionId = 0
                    }
                    let excursionPayload = {
                        id: tour.excursionProgramId,
                        name: tour.excursionProgramName
                    }
                    let excursionExist = state.excursionPrograms.find(item => item.id === excursionPayload.id)
                    if(!excursionExist) {
                        state.excursionPrograms.push(excursionPayload)
                    }

                })
                state.hotelServices = data.hotelServices
                // временная заглушка, не показывать элементы с mealTypeGroupId = 0
                // state.mealTypes = data.mealTypes.filter(el => el.mealTypeGroupId !== 0)

                let hotelServicesIds = state.hotelServicesNaming.map(el => el.id)
                state.hotelServices.forEach(type => {
                    if (hotelServicesIds.includes(type.id)) {
                        type.name = state.hotelServicesNaming.find(item => item.id === type.id).nameTrans
                    }
                })
                // очищаем mealTypes от дублей и сохраняем в state
                const table = {};
                // data.mealTypes = data.mealTypes.filter(({mealTypeGroupId}) =>(!table[mealTypeGroupId] && (table[mealTypeGroupId] = 1)));
                state.mealTypes = data.mealTypes.filter(({mealTypeGroupId}) =>(!table[mealTypeGroupId] && (table[mealTypeGroupId] = 1)));
                state.mealTypes.forEach(type => {
                    type.id = type.mealTypeGroupId
                    type.nameRU = state.mealTypesNaming.find(item => item.id === type.mealTypeGroupId).nameRU
                    type.nameEN = state.mealTypesNaming.find(item => item.id === type.mealTypeGroupId).nameEN
                })
                // TODO Имена приходят не всегда, например при поиске "Куда"-"Кипр"
                //console.log(state.mealTypesNaming)
                // console.log(state.mealTypes)
                /*state.mealTypes.forEach(type => {
                    if(type.name === null && type.id!== 0) {
                        type.name = state.mealTypesNaming.find(item => item.id === type.mealTypeGroupId).fullName
                       // console.log(type.name)
                    }
                })*/
                state.regions = data.regions
                state.specialServices = data.specialServices
                state.tourTypes = data.tourTypes
                state.transportTypes = data.transportTypes
                state.restTypes = data.excursionProgramRestTypes
                //Для отображения туров без типа отдыха
                let restTypeNone ={
                    id: 9999,
                    localizedName: "Без типа отдыха",
                    name: "withoutType",
                }
                state.restTypes.push(restTypeNone)
                state.hotel = data.hotel
                state.meals = data.meals
                state.offers = data.offers
                state.rooms = data.rooms

                // let data = Object.assign([], state.tours)
                // data.sort((a,b) => a.accommodationPriceOffer[0].price.value > b.accommodationPriceOffer[0].price.value ? 1 : -1)
                // state.tours = data

                // console.log(state)
            }
        },
        updateHotelsCompilation(state, payload){
            for(let i=0; i< payload.tours.length; i++){
                let tourIndex = state.hotelsCompilation.findIndex(tour=>tour.id===payload.tours[i].id)
                if(tourIndex!==-1 && state.hotelsCompilation[tourIndex].accommodationPriceOffer[0].price.value > payload.tours[i].accommodationPriceOffer[0].price.value){
                    //console.log('Нашли такой же тур, но дешевле, заменям')
                    state.hotelsCompilation[tourIndex] = payload.tours[i]
                }
                if(tourIndex===-1){
                    state.hotelsCompilation.push(payload.tours[i])
                }
            }
            state.hotelsCompilationRestTypes = payload.excursionProgramRestTypes
            //Для отображения туров без типа отдыха
            let restTypeNone ={
                id: 9999,
                localizedName: "Без типа отдыха",
                name: "withoutType",
            }
            state.hotelsCompilationRestTypes.push(restTypeNone)
            //state.hotelsCompilation= payload.tours
        },
        updateHotelSearchFilterData(state, payload){
            state.hotelFilterData.adults = payload.data.adults
            state.hotelFilterData.minStartDate = payload.data.minStartDate
            state.hotelFilterData.maxStartDate = payload.data.maxStartDate
            state.hotelFilterData.minNightsCount = payload.data.minNightsCount
            state.hotelFilterData.maxNightsCount = payload.data.maxNightsCount
            //console.log(state.hotelFilterData)
        },
        updateHotelDestinationElastic(state, destination){
            state.destinationCountriesEs = destination.destinationCountriesEs
            state.destinationRegionsEs = destination.destinationRegionsEs
            state.destinationCitiesEs = destination.destinationCitiesEs
            state.destinationHotelsEs = destination.destinationHotelsEs
        },
        updateHotelCheckedCountriesEs(state, country){
            for (let item in state.destinationCountriesEs) {
                //убираем чекбоксы с остальных
                if (country.id !== state.destinationCountriesEs[item].id) {
                    state.destinationCountriesEs[item].checked = false
                }
                //Ставим чекбокс
                if (country.id === state.destinationCountriesEs[item].id) {
                    state.destinationCountriesEs[item].checked = country.checked
                }
                //Если страна выбрана, то ставим её, если нет то стираем все значения
                if(country.checked){
                    state.arrivalPlaceNameEs = country.name
                } else{
                    state.arrivalPlaceNameEs = ""
                }
            }
            //Убираем галочки с Регионов и городов
            for (let item in state.destinationRegionsEs) {
                state.destinationRegionsEs[item].checked = false
            }
            for (let item in state.destinationCitiesEs) {
                state.destinationCitiesEs[item].checked = false
            }
            for (let item in state.destinationHotelsEs) {
                state.destinationHotelsEs[item].checked = false
            }
        },
        updateHotelCheckedRegionsEs(state, region){
            for (let item in state.destinationRegionsEs) {
                //убираем чекбоксы с остальных
                if (region.id !== state.destinationRegionsEs[item].id) {
                    state.destinationRegionsEs[item].checked = false
                }
                //Если регион выбран то добавляем его в поиск и ставим его страну
                if(region.checked){
                    state.arrivalPlaceNameEs = region.countryName + ': ' + region.name
                } else{
                    state.arrivalPlaceNameEs = ""
                }
            }
            //Убираем галочки со стран и городов
            for (let item in state.destinationCountriesEs) {
                state.destinationCountriesEs[item].checked = false
            }
            for (let item in state.destinationCitiesEs) {
                state.destinationCitiesEs[item].checked = false
            }
            for (let item in state.destinationHotelsEs) {
                state.destinationHotelsEs[item].checked = false
            }
        },
        updateHotelCheckedCitiesEs(state, city){
            for (let item in state.destinationCitiesEs) {
                //убираем чекбоксы с остальных
                if (city.id !== state.destinationCitiesEs[item].id) {
                    state.destinationCitiesEs[item].checked = false
                }
                //Если город выбран то добавляем его в поиск и ставим его страну и регион
                if(city.checked){
                    state.arrivalPlaceNameEs = city.countryName + ': ' + city.regionName + ', ' + city.name
                } else{
                    state.arrivalPlaceNameEs = ""
                }
            }
            //Убираем галочки со стран и регионов
            for (let item in state.destinationCountriesEs) {
                state.destinationCountriesEs[item].checked = false
            }
            for (let item in state.destinationRegionsEs) {
                state.destinationRegionsEs[item].checked = false
            }
            for (let item in state.destinationHotelsEs) {
                state.destinationHotelsEs[item].checked = false
            }
        },
        updateHotelCheckedHotelsEs(state, hotel){
            for (let item in state.destinationHotelsEs) {
                //убираем чекбоксы с остальных
                if (hotel.id !== state.destinationHotelsEs[item].id) {
                    state.destinationHotelsEs[item].checked = false
                }
                //Если отель выбран то добавляем его в поиск и ставим его страну и регион
                if(hotel.checked){
                    state.arrivalPlaceNameEs = hotel.countryName + ': ' + hotel.regionName + ', ' + hotel.name
                } else{
                    state.arrivalPlaceNameEs = ""
                }
            }
            //Убираем галочки со стран и регионов
            for (let item in state.destinationCountriesEs) {
                state.destinationCountriesEs[item].checked = false
            }
            for (let item in state.destinationRegionsEs) {
                state.destinationRegionsEs[item].checked = false
            }
            for (let item in state.destinationCitiesEs) {
                state.destinationCitiesEs[item].checked = false
            }
        },
    },
    state: {
        mixConfig: process.env.MIX_CONFIG,
        tours: [],
        hotelsCompilation:[],
        hotelsCompilationRestTypes:[],
        country: [],
        cities: [],
        concept: [],
        hotelServices: [],
        mealTypes: [],
        regions: [],
        specialServices: [],
        tourTypes: [],
        transportTypes: [],
        restTypes:[],
        hotel: [],
        meals: [],
        offers: [],
        excursionPrograms: [],
        hotelsLoad: false,
        hotelsLoadStatus: false,
        hotelCitiesFromCheckedCountries: [],
        hotelRegionsFromCheckedCountries: [],
        stars: [
            {id: 0, title: 'Без звезд'},
            {id: 5, title: '5 звезд'},
            {id: 4, title: '4 звезды'},
            {id: 3, title: '3 звезды'},
            {id: 2, title: '2 звезды'},
            {id: 1, title: '1 звезда'}],
        currency: [
            {id: "1", name: 'RUB'},
            {id: "2", name: 'USD'},
            {id: "3", name: 'EUR'},
            {id: "4", name: 'UAH'},
            {id: "11", name: 'KZT'},
            {id: "12", name: 'BYN'},
        ],
        defaultMinTourPrice: 5000,
        defaultMaxTourPrice: 100000,
        mealTypesNaming: [
            //'Завтрак'
            {id: 10001, name: 'BB', nameRU: _trans('tourSearch/countryData.mealBB'),trslt:'tourSearch/countryData.mealBB', nameEN: 'Bed & Breakfast'},
            //'Полупансион'
            {id: 10002, name: 'HB', nameRU: _trans('tourSearch/countryData.mealHB'),trslt:'tourSearch/countryData.mealHB', nameEN: 'Half Board'},
            //'Полный пансион'
            {id: 10003, name: 'FB', nameRU: _trans('tourSearch/countryData.mealFB'),trslt:'tourSearch/countryData.mealFB', nameEN: 'Full Board'},
            //'Все включено'
            {id: 10004, name: 'AI', nameRU: _trans('tourSearch/countryData.mealAI'),trslt:'tourSearch/countryData.mealAI', nameEN: 'All Inclusive'},
            //'Без питания'
            {id: 10005, name: 'RO', nameRU: _trans('tourSearch/countryData.mealRO'),trslt:'tourSearch/countryData.mealRO', nameEN: 'Room Only'},
            //'Ультра все включено'
            {id: 8, name: 'AUI', nameRU: _trans('tourSearch/countryData.mealAUI'),trslt:'tourSearch/countryData.mealAUI', nameEN: 'Ultra All Inclusive'},
            //'По программе'
            {id: 4, name: 'PRG', nameRU: _trans('tourSearch/countryData.mealPRG'),trslt:'tourSearch/countryData.mealPRG', nameEN: 'Program'},
            //'Нет данных'
            {id: 0, name: 'N/A', nameRU: _trans('tourSearch/countryData.mealNoData'),trslt:'tourSearch/countryData.mealNoData', nameEN: 'No data'},
            {id: 10, name: 'N/A', nameRU: _trans('tourSearch/countryData.mealNoData'),trslt:'tourSearch/countryData.mealNoData', nameEN: 'No data'},
        ],
        hotelServicesNaming: [
            {id: 402, nameTrans: _trans('tourSearch/countryData.service_sandbeach'),trslt:'tourSearch/countryData.service_sandbeach', nameEN: 'Sand beach'},
            {id: 139, nameTrans: _trans('tourSearch/countryData.service_openpool'),trslt:'tourSearch/countryData.service_openpool', nameEN: 'Open pool'},
            {id: 156, nameTrans: _trans('tourSearch/countryData.service_waterslides'),trslt:'tourSearch/countryData.service_waterslides', nameEN: 'Waterslides'},
            {id: 190, nameTrans: _trans('tourSearch/countryData.service_kidsclub'),trslt:'tourSearch/countryData.service_kidsclub', nameEN: 'Kids club'},
            {id: 307, nameTrans: _trans('tourSearch/countryData.service_animation'),trslt:'tourSearch/countryData.service_animation', nameEN: 'Animation'},
            {id: 32,  nameTrans: _trans('tourSearch/countryData.service_citycenter'),trslt:'tourSearch/countryData.service_citycenter', nameEN: 'City center'},
            {id: 163, nameTrans: _trans('tourSearch/countryData.service_centernightlife'),trslt:'tourSearch/countryData.service_centernightlife', nameEN: 'Center for nightlife'},
            {id: 110, nameTrans: _trans('tourSearch/countryData.service_shuttletobeach'),trslt:'tourSearch/countryData.service_shuttletobeach', nameEN: 'Shuttle-bus to the beach'},
            {id: 93,  nameTrans: _trans('tourSearch/countryData.service_firstline'),trslt:'tourSearch/countryData.service_firstline', nameEN: 'First line of the beach'},
            {id: 157, nameTrans: _trans('tourSearch/countryData.service_tworoomsuites'),trslt:'tourSearch/countryData.service_tworoomsuites', nameEN: 'Two-room suites'},
            {id: 401, nameTrans: _trans('tourSearch/countryData.service_heatedpool'),trslt:'tourSearch/countryData.service_heatedpool', nameEN: 'Heated pool'},
            {id: 400, nameTrans: _trans('tourSearch/countryData.service_adultsonly'),trslt:'tourSearch/countryData.service_adultsonly', nameEN: 'Adults only hotel'},
            {id: 492, nameTrans: _trans('tourSearch/countryData.service_freewifi'),trslt:'tourSearch/countryData.service_freewifi', nameEN: 'Free Wi-Fi'},
            {id: 83,  nameTrans: _trans('tourSearch/countryData.service_conference'),trslt:'tourSearch/countryData.service_conference', nameEN: 'Conference halls'},
            {id: 7,   nameTrans: _trans('tourSearch/countryData.service_toucanClub'),trslt:'tourSearch/countryData.service_toucanClub', nameEN: 'Toucan Club'},
            {id: 67,  nameTrans: _trans('tourSearch/countryData.service_exclusive'),trslt:'tourSearch/countryData.service_exclusive', nameEN: 'Exclusive'},
            {id: 158, nameTrans: _trans('tourSearch/countryData.service_childrenmenu'),trslt:'tourSearch/countryData.service_childrenmenu', nameEN: 'Children menu'},
            {id: 518, nameTrans: _trans('tourSearch/countryData.service_featured'),trslt:'tourSearch/countryData.service_featured', nameEN: 'Featured'},
            {id: 160, nameTrans: _trans('tourSearch/countryData.service_alacarte'),trslt:'tourSearch/countryData.service_alacarte', nameEN: 'A La Carte Restaurant'},
            {id: 128, nameTrans: _trans('tourSearch/countryData.service_diving'),trslt:'tourSearch/countryData.service_diving', nameEN: 'Diving'},
            {id: 141, nameTrans: _trans('tourSearch/countryData.service_eco'),trslt:'tourSearch/countryData.service_eco', nameEN: 'Eco'},
            {id: 44,  nameTrans: _trans('tourSearch/countryData.service_indoorpool'),trslt:'tourSearch/countryData.service_indoorpool', nameEN: 'Indoor pool'},
            {id: 28,  nameTrans: _trans('tourSearch/countryData.service_golf'),trslt:'tourSearch/countryData.service_golf', nameEN: 'Golf'},
            {id: 165, nameTrans: _trans('tourSearch/countryData.service_sports'),trslt:'tourSearch/countryData.service_sports', nameEN: 'Sports entertainment'},
            {id: 142, nameTrans: _trans('tourSearch/countryData.service_familyholidays'),trslt:'tourSearch/countryData.service_familyholidays', nameEN: 'For family holidays'},
            {id: 501, nameTrans: _trans('tourSearch/countryData.service_couples'),trslt:'tourSearch/countryData.service_couples', nameEN: 'For couples'},
            {id: 189, nameTrans: _trans('tourSearch/countryData.service_kitchen'),trslt:'tourSearch/countryData.service_kitchen', nameEN: 'Kitchen Area'},
            {id: 115, nameTrans: _trans('tourSearch/countryData.service_resortcenter'),trslt:'tourSearch/countryData.service_resortcenter', nameEN: 'Center of the resort'},
            {id: 25,  nameTrans: _trans('tourSearch/countryData.service_tennis'),trslt:'tourSearch/countryData.service_tennis', nameEN: 'Tennis'},
            {id: 517, nameTrans: _trans('tourSearch/countryData.service_bestsellers'),trslt:'tourSearch/countryData.service_bestsellers', nameEN: 'Bestsellers'},
            {id: 22,  nameTrans: _trans('tourSearch/countryData.service_newlyweds'),trslt:'tourSearch/countryData.service_newlyweds', nameEN: 'For newlyweds'},
            {id: 521, nameTrans: _trans('tourSearch/countryData.service_360degrees'),trslt:'tourSearch/countryData.service_360degrees', nameEN: '360 degrees'},
            {id: 323, nameTrans: _trans('tourSearch/countryData.service_worldcuisines'),trslt:'tourSearch/countryData.service_worldcuisines', nameEN: 'World cuisines'},
            {id: 172, nameTrans: _trans('tourSearch/countryData.service_healthyeating'),trslt:'tourSearch/countryData.service_healthyeating', nameEN: 'Healthy eating'},
            {id: 314, nameTrans: _trans('tourSearch/countryData.service_welcomegifts'),trslt:'tourSearch/countryData.service_welcomegifts', nameEN: 'Welcome gifts'},
        ],
        hotelFilterData:{
            adults: 0,
            minStartDate: "",
            maxStartDate: "",
            minNightsCount: 7,
            maxNightsCount: 14,
        },
        destinationCountriesEs:[],
        destinationRegionsEs:[],
        destinationCitiesEs:[],
        destinationHotelsEs:[],
        arrivalPlaceNameEs: "",
        queryString: "",
    },
    getters: {
        getHotelsQueryString(state){
            return state.queryString
        },
        getHotelRegionsFromCheckedCountries(state) {
            return state.hotelRegionsFromCheckedCountries;
        },
        getHotelCitiesFromCheckedCountries(state) {
            return state.hotelCitiesFromCheckedCountries;
        },
        getHotelsLoad(state) {
            return state.hotelsLoad
        },
        getHotelsLoadStatus(state){
            return state.hotelsLoadStatus
        },
        getHotels(state) {
            return state.tours;
        },
        getHotelsCompilation(state){
            return state.hotelsCompilation
        },
        getHotelsCompilationRestTypes(state){
            return state.hotelsCompilationRestTypes
        },
        getExcursionPrograms(state) {
            return state.excursionPrograms
        },
        getHotelsRegions(state){
            let result = []
            let ids = new Set()
            state.regions.forEach(item => {
                ids.add(item.id)
            })
            ids.forEach(id => {
                let elem = state.regions.find(item => item.id === id)
                result.push(elem)
            })
            result.sort((a,b) => {
                return a.name > b.name ? 1 : -1
            })
            return result
        },
        getHotelsCities(state){
            let result = []
            let ids = new Set()
            state.cities.forEach(item => {
                ids.add(item.id)
            })
            ids.forEach(id => {
                let elem = state.cities.find(item => item.id === id)
                result.push(elem)
            })
            result.sort((a,b) => {
                return a.name > b.name ? 1 : -1
            })
            return result
        },
        getHotelRegionNameById: state => id => {
            //console.log('regions', state.regions)
            return state.regions.find(region => {
                // console.log('region.id', region.id)
                // console.log('id', id)
                return region.id === id
            })
        },
        getHotelTourTypes(state){
            return state.tourTypes
        },
        getHotelRestTypes(state){
            return state.restTypes
        },
        getHotelMealTypes(state){
            return state.mealTypes
        },
        getHotelConcepts(state){
            return state.concept
        },
        getHotelServices(state){
            return state.hotelServices
        },
        getHotelSpecials(state){
            return state.specialServices
        },
        getHotelsCurrency(state){
            let result = null
            if(Array.isArray(state.tours) && state.tours.length > 0){
                result = state.tours[0].currentOffer.price.currencyAlias
            }
            return result
        },
        getHotelCountryName(state) {
            return state.country.name
        },
        getHotelCityById: state => id => {
            return state.cities.find(city => city.id === id)
        },
        getMinHotelPrice(state) {
            if (state.tours.length > 0) {
                let minPrice = state.tours[0].currentOffer.price.value
                state.tours.forEach(function (tour) {
                    if (tour.currentOffer.price.value < minPrice) {
                        minPrice = tour.currentOffer.price.value
                    }
                })
                return minPrice
            } else {
                return state.defaultMinTourPrice;
            }
        },
        getMaxHotelPrice(state) {
            if (state.tours.length > 0) {
                let maxPrice = state.tours[0].currentOffer.price.value
                state.tours.forEach(function (tour) {
                    if (tour.currentOffer.price.value > maxPrice) {
                        maxPrice = tour.currentOffer.price.value
                    }
                })
                return maxPrice
            } else {
                return state.defaultMaxTourPrice;
            }
        },
        getHotelSearchFilterData(state){
            return state.hotelFilterData
        },
        getHotelDestinationCountriesEs(state){
            return Object.values(state.destinationCountriesEs).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1
                }
                return 0
            })
        },
        getHotelDestinationRegionsEs(state){
            return Object.values(state.destinationRegionsEs).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1
                }
                return 0
            })
        },
        getHotelDestinationCitiesEs(state){
            return Object.values(state.destinationCitiesEs).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1
                }
                return 0
            })
        },
        getHotelDestinationHotelsEs(state){
            return Object.values(state.destinationHotelsEs).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1
                }
                return 0
            })
        },
        getHotelArrivalPlaceNameEs(state){
            return state.arrivalPlaceNameEs
        },
    },
}
