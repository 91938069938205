import variables from "../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchGetAirportsByCityEbg(ctx, cityId){
            const url = variables.routeMosaic + '/getAirportsByCity/' + cityId
            let result = []
            try{
                let res = await axios({
                    url,
                })
                if(Array.isArray(await res.data)){
                    result = res.data
                }
                return result
            }catch(e){
                console.log('Ошибка в actions fetchGetAirportsByCityEbg', e)
            }
        },
        async fetchGetAirportsByCountryEbg(ctx, countryId){
            const url = variables.routeMosaic + '/getAirportsByCountry/' + countryId
            let result = []
            try{
                let res = await axios({
                    url,
                })
                if(Array.isArray(await res.data)){
                    result = res.data
                }
                return result
            }catch(e){
                console.log('Ошибка в actions fetchGetAirportsByCountryEbg', e)
            }
        },
        async fetchGetCityByIso(ctx, iso) {
            const url = variables.routeMosaic + '/getCityByAirportIso/' + iso
            try {
                let res = await axios({
                    url,
                })
                return res.data

            } catch(e) {
                console.log('Ошибка в actions fetchGetCityByIso', e)
            }
        },
        async fetchGetDepartureCities(ctx) {
            const url = variables.routeTourSearchCitiesOfLocation

            try {
                let res = await axios({
                    url,
                })
                ctx.commit('updateDepartureCities', res.data)
                return res.data

            } catch(e) {
                console.log('Ошибка в actions fetchGetDepartureCities', e)
            }
        },
        async fetchGetArrivalCountries(ctx, cityEbgId) {
            const url = variables.routeGetAviaArrivalCountries + cityEbgId
            try {
                const arrivalCountries = await axios({
                    url,
                    method: 'GET',
                });
                ctx.commit('updateArrivalCountries', arrivalCountries.data)
                return arrivalCountries.data
            } catch(e) {
                console.log('Ошибка получения ответа от ' + variables.routeGetAviaArrivalCountries + cityEbgId)
            }
        },
        async fetchGetArrivalCities(ctx, data){
            // let data = {
            //     departureCityId: null,
            //     arrivalCountryId: null,
            //     tourTypeId: null,
            // }
            let result = []
            if(!data.departureCityId || !data.arrivalCountryId) return result
            let url = variables.routeGetAviaArrivalCities
            try {
                let res = await axios({
                    url,
                    method: 'post',
                    data: data,
                });
                if(Array.isArray(res.data)){
                    result = res.data
                }
                return result
            } catch(e) {
                console.log('Ошибка в actions fetchGetArrivalCities - ' + e)
            }
        },
        async fetchCountriesCards(ctx) {
            const url = variables.routeCountriesCards
            // console.log('url', url)
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                });
                const data = await res.data
                ctx.commit('mutCountriesCards', data)
                return data
            } catch(e) {
                console.log('Ошибка получения ответа от ' + variables.routeCountriesCards, e)
            }
        },
        async fetchAllHotelsByCountryEbg(ctx, payload){
            let result = []
            let countryId = payload.countryId
            let tourType = payload.tourType ? payload.tourType:null
            try {
                const url = variables.routeMosaic + '/getAllHotelsByCountryEbg'
                //Алгоритм позволяет не запрашивать повторно отели для страны
                //Если запрос уже был отправлен
                //Независимо от того получены результаты или еще нет
                let allHotels = ctx.state.allHotels
                let isHotels = allHotels.some(elem => elem.arrivalCountryEbgId === countryId)
                if(isHotels){
                    //Результат получим когда разрезолвяться все запросы
                    //Чтобы избежать пустого результата
                    await Promise.all(ctx.state.allHotelsPromiseContainer)
                    let obj = allHotels.find(elem => elem.arrivalCountryEbgId === countryId)
                    if(Array.isArray(obj.hotels)) result = obj.hotels
                } else {
                    ctx.commit('updateAddCountryToAllHotels', countryId)
                    let res = axios({
                        url,
                        method: 'POST',
                        data: {
                            'arrivalCountryId': countryId,
                            'tourType': tourType? tourType: ''
                        }
                    })
                    ctx.state.allHotelsPromiseContainer.push(res)
                    res = await res
                    if(Array.isArray(res.data)) result = res.data
                    // console.log('resultFetch', result)
                    ctx.commit('updateAllHotels', {
                        arrivalCountryEbgId: countryId,
                        hotels: result
                    })
                }
            } catch(e){
                console.log('Ошибка в actions fetchAllHotelsByCountryEbg', e)
            }
            return result
        },
        //Получение объекта с массивами стран, отелей, регионов и городов
        //По городу отправления и пункту прибытия (несколько букв)
        //Копия метода fetchArrivalPointsFromEs, только в нужном модуле
        async fetchArrivalDestination({commit, getters, state}, payload) {
            // let payload = {
            //     departureCityId: null,
            //     arrivalPlaceName: null,
            //     useCache: null, //может быть не указан
            // }
            try {
                let destination = {
                    searchFromCountries: [],
                    searchFromHotels: [],
                    searchFromRegions: [],
                    searchFromTowns: []
                }
                if (payload.departureCityId) {
                    let isWeekendTours = !!payload.onlyWithWeekendTours
                    let routePath = payload.useCache ? variables.routeGetAviaArrivalCountriesFromEsCache : variables.routeGetAviaArrivalCountriesFromEs
                    let url = routePath + payload.departureCityId + '/' + isWeekendTours + '/' + payload.arrivalPlaceName
                    // let url = variables.routeGetAviaArrivalCountriesFromEs + payload.departureCityId + '/' + payload.arrivalPlaceName
                    const destinationData = await axios({
                        url: url,
                        method: 'GET',
                        data: {
                            useCache: true,
                        }
                    });
                    destination = destinationData.data
                }
                let localization = getters.getLocalization
                let isRusNames = true
                //если нет локали в бд, будут подставлятся английские названия
                if (false) {
                    isRusNames = false
                }

                let destination_payload = {
                    destinationCountriesEs: [],
                    destinationRegionsEs: [],
                    destinationCitiesEs: [],
                    destinationHotelsEs: [],
                }
                //Добавляем Крым, если его нет
                if (!destination.searchFromCountries.find(item => item.id === 821577) && 'россия, крым'.includes(payload.arrivalPlaceName.toLowerCase()) && localization === 'ru') {
                    destination.searchFromCountries.push({
                        id: 821577,
                        internationalName: "Р.",
                        name: "Крым",
                        regions: [],
                        stateIso: ""
                    })
                }

                //Страны
                for (let country in destination.searchFromCountries) {
                    destination.searchFromCountries[country]["checked"] = false
                    if (!isRusNames) {
                        destination.searchFromCountries[country]["name"] = destination.searchFromCountries[country].internationalName
                    }
                }

                destination_payload.destinationCountriesEs = destination.searchFromCountries

                //Регионы
                let destinationRegions = []
                for (let country in destination.searchFromRegions) {
                    for (let region in destination.searchFromRegions[country].regions) {
                        destination.searchFromRegions[country].regions[region]["checked"] = false
                        if (isRusNames) {
                            destination.searchFromRegions[country].regions[region]["countryName"] = destination.searchFromRegions[country].name
                        } else {
                            destination.searchFromRegions[country].regions[region]["name"] = destination.searchFromRegions[country].regions[region].internationalName
                            destination.searchFromRegions[country].regions[region]["countryName"] = destination.searchFromRegions[country].internationalName
                        }
                        destinationRegions.push(destination.searchFromRegions[country].regions[region])
                    }
                }
                destination_payload.destinationRegionsEs = destinationRegions

                //Города
                let destinationCities = []
                for (let country in destination.searchFromTowns) {
                    for (let region in destination.searchFromTowns[country].regions) {
                        for (let city in destination.searchFromTowns[country].regions[region].towns) {
                            destination.searchFromTowns[country].regions[region].towns[city]["checked"] = false
                            destination.searchFromTowns[country].regions[region].towns[city]["countryId"] = destination.searchFromTowns[country].id
                            if (isRusNames) {
                                destination.searchFromTowns[country].regions[region].towns[city]["countryName"] = destination.searchFromTowns[country].name
                                destination.searchFromTowns[country].regions[region].towns[city]["regionName"] = destination.searchFromTowns[country].regions[region].name
                            } else {
                                destination.searchFromTowns[country].regions[region].towns[city]["name"] = destination.searchFromTowns[country].regions[region].towns[city].internationalName
                                destination.searchFromTowns[country].regions[region].towns[city]["countryName"] = destination.searchFromTowns[country].internationalName
                                destination.searchFromTowns[country].regions[region].towns[city]["regionName"] = destination.searchFromTowns[country].regions[region].internationalName
                            }
                            destinationCities.push(destination.searchFromTowns[country].regions[region].towns[city])
                        }
                    }
                }
                destination_payload.destinationCitiesEs = destinationCities

                //Отели
                let destinationHotels = []
                for (let country in destination.searchFromHotels) {
                    for (let region in destination.searchFromHotels[country].regions) {
                        for (let city in destination.searchFromHotels[country].regions[region].towns) {
                            for (let hotel in destination.searchFromHotels[country].regions[region].towns[city].hotels) {
                                destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["checked"] = false
                                destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["countryId"] = destination.searchFromHotels[country].id
                                if (isRusNames) {
                                    destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["countryName"] = destination.searchFromHotels[country].name
                                    destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["regionName"] = destination.searchFromHotels[country].regions[region].name
                                } else {
                                    destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["countryName"] = destination.searchFromHotels[country].internationalName
                                    destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["regionName"] = destination.searchFromHotels[country].regions[region].internationalName
                                }
                                if (!destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["name"]) {
                                    destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel]["name"] = destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel].internationalName
                                }
                                destinationHotels.push(destination.searchFromHotels[country].regions[region].towns[city].hotels[hotel])
                            }
                        }
                    }
                }
                destination_payload.destinationHotelsEs = destinationHotels

                //Не было необходимости формировать state
                //commit('updateArrivalDestinations', destination_payload)
                return destination_payload
            } catch (e) {
                console.log('Ошибка в actions fetchArrivalDestination ', e)
            }
        },
    },
    state: {
        departureCities: [],
        arrivalCountries: [],
        countriesCards:[],
        allHotels: [ //Массив объектов, используется для оптимизации запросов
            // {
            //     arrivalCountryEbgId: 18803,
            //     hotels: [],
            // }
        ],
        //Нужен для нормальной работы actions fetchAllHotelsByCountryEbg
        allHotelsPromiseContainer: [],
    },
    mutations: {
        updateDepartureCities(state, departureCities) {
            state.departureCities = departureCities
        },
        updateArrivalCountries(state, country) {
            state.arrivalCountries = country
        },
        mutCountriesCards(state, payload) {
            state.countriesCards = payload
        },
        updateAddCountryToAllHotels(state, arrivalCountryEbgId){
            state.allHotels.push({
                arrivalCountryEbgId,
                hotels: []
            })
        },
        updateAllHotels(state, data){
            let obj = state.allHotels.find(elem => elem.arrivalCountryEbgId === data.arrivalCountryEbgId)
            obj.hotels = data.hotels
        },
    },
    getters: {
        getDepartureCities(state) {
            return state.departureCities;
        },
        getArrivalCountries(state) {
            return state.arrivalCountries
        },
        getCountriesCards(state) {
            return state.countriesCards
        },
    }
}
