<template>
        <div>
            <slot name="header" ></slot>
            <slot name="default" v-if="show"></slot>
            <slot name="footer"></slot>
<!--            <who-are-you-popup v-if="!$nopopup" v-show="whoAreYouPopupShow" @open="whoAreYouPopupShow=true" @close="whoAreYouPopupShow=false"></who-are-you-popup>-->
            <android-app-popup v-if="!$nopopup" v-show="androidPopupShow" @open="androidPopupShow=true" @close="androidPopupShow=false"></android-app-popup>
            <ScalableVectorGraphics />
            <cookie-notification v-if="!$nopopup"/>
            <div id="finishload" v-if="finishLoad"></div>
<!--            <mobileBannerApplication/>-->
        </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    props:['geoCdnLink','onlyOneLocale','diffCabs'],
    components: {
        whoAreYouPopup: () => import("./elements/whoAreYouPopup"),
        ScalableVectorGraphics: () => import("./elements/utils/ScalableVectorGraphics"),
        cookieNotification:()=>import("./elements/notifications/cookieNotification"),
        androidAppPopup: () => import("./elements/androidAppPopup"),
        // mobileBannerApplication:()=>import(/* webpackChunkName: "mobileBannerApplication"*/ "./elements/banners/mobileBannerApplication"),
    },
    data(){
        return {
            finishLoad:false,
            noPopup:false,
            show:false,
            whoAreYouPopupShow:false,
            androidPopupShow:false,
            ops: {
                rail: {
                    opacity: '0',
                    background: undefined,
                    border: '1px solid transparent',
                    size: '8px'
                },
                bar: {
                    background: '#18A0FB',
                    opacity: 0.6,
                    keepShow: false,
                    size: '6px',
                    minSize: 0.1
                },
                scrollButton: {
                    enable: false,
                    background: '#cecece'
                },
                scrollPanel: {
                    easing: 'easeInQuad',
                    speed: 800,
                    scrollingX: false,
                },
                vuescroll: {
                    wheelScrollDuration: 0,
                    wheelDirectionReverse: false
                }
            }
        }
    },
    async mounted() {
        setTimeout(()=>{
            this.finishLoad = true
        },25000)
        this.show = true
        this.$store.commit('updateOnlyOneLocale', this.onlyOneLocale)
        this.$store.commit('updateDiffCabs', this.diffCabs)

        this.$store.dispatch('pushDataLayerMainPage')

        moment.locale(window.siteLocale);

        await this.$store.dispatch('fetchLocalization')

        let version = '30'

        if (localStorage.getItem('version') !== version){
            let dq_user_type = localStorage.getItem('dq_user_type')
            localStorage.clear()
            localStorage.setItem('version',version)
            if (dq_user_type){
                localStorage.setItem('dq_user_type',dq_user_type)
            }

        }

        // проверяем и актуализируем localStorage
        let minDate = localStorage.getItem('minDate')
        if (moment(minDate).isBefore(moment())) {
            minDate = moment().add(1, 'days').format('YYYY-MM-DD')
            let maxDate = moment(minDate).add(7, 'days').format('YYYY-MM-DD')
            localStorage.setItem('minDate', minDate)
            localStorage.setItem('maxDate', maxDate)
        }
        if (localStorage.getItem('departureCityId') === null){
            localStorage.setItem('departureCityId', '274286')
        }
        if (localStorage.getItem('departureCityName') === null){
            localStorage.setItem('departureCityName','Москва')
        }


        // this.$store.dispatch('fetchDataLayerCountry')
        this.$store.dispatch('fetchMainboxViewSectionOfSite')

        this.$nextTick(() => {
            this.$store.dispatch('pushDataLayerNotEcommerce');
        });
        await this.searchByCompare()
        eventBus.$emit("searchByCompare")
    },
    methods: {
        ...mapActions({
            searchByCompare: "searchByCompare",
        }),
    },
    computed:{
        ...mapGetters(['getLocalization'])
    },

}

</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity 5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
}
#app{
    display: block;
}
#app-body{
    display: block!important;
}
</style>
