import variables from "../../plugins/variables";
import moment from "moment";
import axios from "axios";

export default {
    actions: {
        async fetchBasketBook({dispatch, commit, getters}) {
            let url = variables.routeBasketBook
            if(getters.getUserRole ==='customer'){
                await dispatch('fetchUser')
            }
            if(getters.getUserInfo.email){
                getters.getBasket.customerInfo.email = getters.getUserInfo.email
                //await dispatch('saveBasket');
            }
            if(getters.getUserInfo.phone){
                getters.getBasket.customerInfo.phones = getters.getUserInfo.phone
            }
            if(getters.getUserRole ==='agent'){ //Стираем данные заказчика, если агент
                if(getters.getBasket.insurance.length){ //Для страховок первый турист считается клиентом
                    getters.getBasket.customerInfo = getters.getBasket.insurance[0].travellers.find(item=>item.id===1)
                } else{
                    getters.getBasket.customerInfo = null
                }

            }
            await dispatch('saveBasket')
            //ctx.commit('updateIsBasketBookLoading', true)
            console.log(getters.getBasket)
            try {
                const basket = await axios({
                    url,
                    method: 'POST'
                })
                let data = getters.getBasket
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url, e)
                let status = {
                    status: 'ValidationFailed',
                    message: "",
                    trace: [
                        {
                            message: '',
                        }
                    ]
                }
                commit('updateBasketStatus', status)
                commit('updateIsBasketBookLoading', false)
           }
            //ctx.commit('updateIsBasketBookLoading', false)
        },
        async fetchBasketBookService({dispatch, commit, getters}){
            let basketService
            if (getters.getBasketExcursions.length){
                basketService = getters.getBasketExcursions[0]
            }
            if (getters.getBasketServices.length){
                basketService = getters.getBasketServices[0]
            }
            let serviceObject = {
                serviceKey: basketService.id,
                service: {
                    id: basketService.number,
                    type: basketService.serviceCategory,
                    client: {
                        person: {
                            lastName: getters.getUserInfo.lastName,
                            firstName: getters.getUserInfo.name,
                            patronymic: getters.getUserInfo.patronymic,
                            birthDate: getters.getUserInfo.birthDate,
                            gender: 1,
                            nationalityId: 367404,
                            identityDocument: 1,
                        },
                        contactInformation: {
                            phone: getters.getUserInfo.phone,
                            email: getters.getUserInfo.email
                        },
                        passport: {
                            series: getters.getUserInfo.passport.series,
                            number: getters.getUserInfo.passport.number,
                            address: getters.getUserInfo.address,
                            issuingAuthority: getters.getUserInfo.passport.issuingAuthority,
                            issueDate: getters.getUserInfo.passport.issueDate
                        }
                    },
                    pickUpAddress: getters.getBasket.partnerComment,
                    paxes: [],
                    promoCode: "",
                    claimGroup: "FunSun"
                }
            }
            let touristsCount = basketService.adults + basketService.children
            for(let i = 0; i < touristsCount; i++){
                let number = ''
                for(let n = 0; n<7; n++){ //генерируем номер
                    number += Math.floor(Math.random() * 10).toString()
                }
                let series = ''
                for(let s = 0; s<2; s++){ //генерируем серию
                    series += Math.floor(Math.random() * 10).toString()
                }
                let pax = {
                    id: i,
                    person: {
                        lastName: "EXCURSION",
                        firstName: "TOURIST",
                        patronymic: "PATRONYMIC",
                        birthDate: "1998-04-13T23:55:14.378Z",
                        gender: 1
                    },
                    document: {
                        type: 3,
                        series: series,
                        number: number,
                        expirationDate: "2030-04-17T13:22:14.378Z"
                    },
                    contactInformation: {
                        phone: getters.getUserInfo.phone,
                        email: getters.getUserInfo.email
                    },
                    nationalityId: 367404
                }
                serviceObject.service.paxes.push(pax)
            }
            let url = variables.routeTourServices + '/reserve'
            let data = serviceObject
            const reserve = await axios({
                url,
                method: 'POST',
                data,
            })
            let result = await reserve.data
            return result
        },
        async saveBasket({dispatch, getters, commit, state}) {
            if(state.disableSave === true){
                return;
            }
            let result = false
            try{
                let url = variables.routeBasket;
                let data = getters.getBasket;

                const basket = await axios({
                    url,
                    method: 'POST',
                    data,
                })

                if(basket.status === 200) result = true

                if (result === true && typeof basket.data != 'undefined') {
                    let dfBasket = JSON.parse(basket.data);
                    if (typeof dfBasket.draftBody != 'undefined') {
                        localStorage.setItem('myCart', JSON.stringify(dfBasket.draftBody));
                    }
                }
                data.packetData = getters.getPacketData
                commit('updateCheckIsOneClickBooking', getters)
            } catch(e){
                console.log('Ошибка в actions saveBasket', e)
            }

            return result
        },
        async loadBasket({commit,state, getters}) {
            let url = variables.routeBasket;
            //try {
            const basket = await axios({
                url,
                method: 'GET'
            });
            let data = await basket.data
            commit('updateBasketDraftId', data.id);
            if (data !== "cart_empty" && !data.validationErrors) {
                commit('updateBasketData', data)
                commit('updateCheckIsOneClickBooking', getters)
            } else {
                if (localStorage.getItem("myCart") !== null) {
                    let dfBasket = localStorage.getItem("myCart")
                    commit('updateBasketData', dfBasket);
                    commit('updateCheckIsOneClickBooking', getters);
                }
            }
            state.isCreatedBasket = true
        },
        async fetchBasketStatus({commit, getters}, draftId = null) {
            let url = variables.routeBasketStatus;
            try {
                let payloadData = {
                    draftId: draftId
                }
                const status = await axios({
                    url,
                    method: 'POST',
                    data: payloadData
                });
                let data = await status.data
                commit('updateBasketStatus', data)
            } catch {
               console.log('Ошибка получения ответа от ' + url)
            }
        },
        async fetchBasketPaymentConfirm(ctx, payload) {
            let url = variables.routeBasketConfirmPayment
            await axios({
                url,
                method: 'POST',
                data: payload,
            })
        },
        async fetchBasketOrders(ctx, orderId) {
            let url = variables.routeBasketOrders;
            //try {
            const status = await axios({
                url,
                method: 'GET'
            });
            let data = await status.data
            let result = {
                isOrder: false,
                status: null,
            }
            //Если среди заказов находим нужный то записываем его данные в корзину для отображения
            status.data.forEach(function (item) {
                if (item.backOfficeId === parseInt(orderId)) {
                    ctx.commit('updateBasketData', item)
                    result.isOrder = true
                    result.status = item.status
                    result.data = item
                }
            })
            return result
        },
        async fetchBasketOrder(ctx, orderId) {
            let url = variables.routeBasketOrder + '/' + orderId;
            let result = {
                isOrder: false,
                status: null,
                data: null,
            }
            try {
                const status = await axios({
                    url,
                    method: 'GET',
                });
                let data = await status.data

                ctx.commit('updateBasketData', data)
                ctx.commit('updateBasketDraftId', data.id);
                result.isOrder = true
                result.status = data.status
                result.data = data
                return result
            } catch {
                return result
            }
        },
        async fetchBasketOrderForCheckStatus(ctx, [orderId,accountId]) {
            let url = `${variables.routeBasketOrder}/${orderId}/${accountId}`;
            let result = {
                isOrder: false,
                status: null,
                data: null,
            }
            try {
                const status = await axios({
                    url,
                    method: 'GET',
                });
                let data = await status.data

                ctx.commit('updateBasketData', data)
                result.isOrder = true
                result.status = data.status
                result.data = data
                return result
            } catch {
                return result
            }
        },
        async fetchAbandonedBasketById(ctx, id) {
            let url = variables.routeAbandonedBasketById + '/' + id;
            let result = {
                isOrder: false,
                status: null,
                data: null,
            }
            try {
                const status = await axios({
                    url,
                    method: 'GET',
                });
                let data = await status.data

                result.isOrder = true
                result.status = data.status
                result.data = data
                return result
            } catch {
                return result
            }
        },
        async fetchBasketOrderTravellers(ctx, orderId){
            let url = variables.routeBasketOrderTravellers + '/' + orderId
            try {
                const status = await axios({
                    url,
                    method: 'GET',
                });
                let data = await status.data
                return data
            } catch {
                return null
            }
        },
        async fetchUpdateBasketOrderTravellers(ctx, payload){
            let url = variables.routeBasketOrderTravellers
            try {
                const status = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                });
            } catch {
                console.log('Ошибка в методе: ', url)
            }
        },
        async fetchAddTransferToBasket(ctx, transfer) {
            // Прилетает готовый объект
            // let transfer = {
            //     id: null,
            //     transferId: null,
            //     travellers: [],
            //     from: {
            //         typeId: null,
            //         cityId: null,
            //         countryId: null,
            //         cityName: null,
            //         hotelId: null,
            //         hotelName: null,
            //         iata: null,
            //         address: null,
            //     },
            //     to: {
            //         typeId: null,
            //         cityId: null,
            //         countryId: null,
            //         cityName: null,
            //         hotelId: null,
            //         hotelName: null,
            //         iata: null,
            //         address: null,
            //     },
            //     date: "",
            //     price: null,
            //     imgUrl: "",
            //     type: {
            //         provider: 'samotour',//samotour, kiwi
            //         id: 1,//1-AOA,2-AO,3-OA,4-OO,5-AO
            //     },
            //     currency: {
            //         currencyId: 1,
            //         currencyAlias: "RUB"
            //     },
            //     name: "",
            //     passengers: {
            //         adults: null,
            //         children: null,
            //         infants: null,
            //     },
            //     labelName: "",
            //     comment: "",
            //     clientPhone: "",
            //     services: {
            //         drinkingWater: 0,
            //         childChair: 0,
            //         childBooster: 0,
            //         childAutoCradle: 0,
            //     },
            //     serviceKey: null,
            //     searchParams: null, //Объект с параметрами поиска
            // }
            let id = ctx.getters.getUniqueBasketId
            transfer.id = id
            ctx.commit('updateAddTransferToBasket', transfer)
            await ctx.dispatch('saveBasket')

            return id
        },
        async fetchSafeBasketLink(ctx) {
            return new Promise((resolve, reject) => {
                axios.get(variables.routeBasketSafeLink).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        async fetchSafeBasketLinkClear(ctx) {
            axios.get(variables.routeBasketSafeLinkClear).then(r => {}).catch(e => {});
        },
        async disableSaveBasket(ctx) {
            ctx.commit('updateDisableSaveBasket');
        },
        async fetchAddServiceToBasket(ctx, payload) {
            let mainBoxServiceId = null
            let mainBoxServicePrice = null
            try {
                switch (payload.category.code) {
                    case 'Transfer':
                        let transfer = {
                            id: null,
                            travellers: [],
                            from: {
                                cityId: null,
                                cityName: null,
                                hotelId: null,
                                hotelName: null,
                                iata: null,
                                address: null,
                            },
                            to: {
                                cityId: null,
                                cityName: null,
                                hotelId: null,
                                hotelName: null,
                                iata: null,
                                address: null,
                            },
                            date: "",
                            price: null,
                            imgUrl: "",
                            type: {
                                provider: 'samotour',
                                id: 1,
                            },
                            currency: {
                                currencyId: 1,
                                currencyAlias: "RUB"
                            },
                            name: "",
                            tourists: null,
                            labelName: "",
                            comment: "",
                            clientPhone: "",
                            services: null
                        }
                        if (payload.transferType == 'samotour') {
                            transfer.id = payload.id
                            transfer.name = payload.name
                            transfer.travellers = []
                            transfer.date = payload.startDate
                            transfer.price = payload.price.value
                            transfer.currency.currencyId = payload.price.currencyId
                            transfer.currency.currencyAlias = payload.price.currencyAlias
                            transfer.imgUrl = payload.imgUrl

                            transfer.transferType = payload.transferType
                            transfer.fromCityId = payload.searchParams.sourceId
                            transfer.toCityId = payload.searchParams.targetId
                            transfer.tourists = payload.paxes.maxPeopleCount
                            transfer.labelName = payload.labelName
                            transfer.comment = payload.comment
                            transfer.clientPhone = payload.clientPhone
                            transfer.samoTransferCountry = payload.searchParams.countryName
                            transfer.samoTransferCity = payload.searchParams.sourceName

                            transfer.services = payload.services
                            //Для самотура Доп параметры
                            transfer.samoPhotoUrls = payload.photoUrls

                            transfer.samoStartDate = payload.searchParams.startDate
                            transfer.samoEndDate = payload.searchParams.endDate
                            transfer.samoTransferCategory = payload.category
                            //Костыль для параметров поиска само
                            payload.searchParamsSamo.currencyId = payload.price.currencyId
                            //Конец костыля
                            transfer.searchParams = payload.searchParamsSamo
                            transfer.samoShortDescription = payload.shortDescription
                            transfer.serviceKey = payload.serviceKey
                            transfer.countryId = payload.searchParamsSamo.countryId
                            transfer.isIndividual = payload.searchParamsSamo.isIndividual
                            //Хер знает откуда брать.
                            //PS. Я тоже не знаю.
                            transfer.addInfant = false
                        }
                        if (payload.transferType == 'kiwi') {
                            transfer.id = payload.id
                            transfer.name = payload.type.name.ru
                            transfer.travellers = []
                            transfer.date = payload.searchParams.startDate
                            if (payload.route.placeFrom.type === 2) {
                                transfer.from = payload.route.placeFrom.name.ru
                            } else {
                                transfer.from = payload.searchParams.addressFrom
                            }

                            if (payload.route.placeTo.type === 2) {
                                transfer.to = payload.route.placeTo.name.ru
                            } else {
                                transfer.from = payload.searchParams.addressTo
                            }
                            transfer.price = payload.price.rub.cost
                            transfer.currency.currencyId = payload.price.rub.currency.id
                            transfer.currency.currencyAlias = payload.price.rub.currency.code
                            transfer.to = payload.route.placeTo.name.ru
                            transfer.imgUrl = payload.type.photo
                            transfer.transferType = payload.transferType
                            transfer.fromCityId = payload.searchParams.sourceId
                            transfer.toCityId = payload.searchParams.targetId
                            transfer.tourists = payload.type.pax
                            transfer.labelName = payload.labelName
                            transfer.comment = payload.comment
                            transfer.clientPhone = payload.clientPhone
                            transfer.services = payload.services
                        }

                        ctx.commit('updateAddTransferToBasket', transfer)
                        await ctx.dispatch('saveBasket')
                        mainBoxServiceId = transfer.id
                        mainBoxServicePrice = transfer.price
                        break
                    case 'Excursion':
                        let excursion = {
                            id: null,
                            travellers: null,
                            serviceCategory: null,
                            imgUrl: null,
                            name: null,
                            startDate: null,
                            endDate: null,
                            price: null,
                            adults: null,
                            children: null,
                            contentFilters: null,
                            number: null,
                            currency: {
                                currencyId: null,
                                currencyAlias: null,
                            },
                            city: {
                                id: null,
                                name: null,
                            },
                            country: {
                                id: null,
                                name: null,
                            },
                        }
                        excursion.id = payload.serviceKey
                        excursion.travellers = []
                        excursion.serviceCategory = payload.category.id
                        excursion.serviceCategoryName = payload.category.code
                        excursion.imgUrl = payload.imgUrl
                        excursion.name = payload.name
                        excursion.startDate = payload.startDate
                        excursion.endDate = payload.endDate
                        excursion.price = payload.price.value
                        excursion.currency.currencyId = payload.price.currencyId
                        excursion.currency.currencyAlias = payload.price.currencyAlias
                        excursion.adults = payload.adults
                        excursion.children = payload.children
                        excursion.city.id = payload.city.id
                        excursion.city.name = payload.city.name
                        excursion.country.id = payload.country.id
                        excursion.country.name = payload.country.name
                        excursion.contentFilters = payload.contentFilters
                        excursion.number = payload.id
                        ctx.commit('updateAddExcursionToBasket', excursion)
                        await ctx.dispatch('saveBasket')
                        mainBoxServiceId = excursion.id
                        mainBoxServicePrice = excursion.price
                        break

                    case 'Autotour':
                        let autotour = {
                            id: null,
                            travellers: null,
                            serviceCategory: null,
                            imgUrl: null,
                            name: null,
                            startDate: null,
                            endDate: null,
                            price: null,
                            adults: null,
                            children: null,
                            currency: {
                                currencyId: null,
                                currencyAlias: null,
                            },
                            city: {
                                id: null,
                                name: null,
                            },
                            country: {
                                id: null,
                                name: null,
                            },
                        }
                        autotour.id = payload.serviceKey
                        autotour.travellers = []
                        autotour.serviceCategory = payload.category.id
                        autotour.serviceCategoryName = payload.category.code
                        autotour.imgUrl = payload.imgUrl
                        autotour.name = payload.name
                        autotour.date = payload.startDate
                        autotour.price = payload.price.value
                        autotour.currency.currencyId = payload.price.currencyId
                        autotour.currency.currencyAlias = payload.price.currencyAlias
                        autotour.adults = payload.adults
                        autotour.children = payload.children
                        autotour.city.id = payload.city.id
                        autotour.city.name = payload.city.name
                        autotour.country.id = payload.country.id
                        autotour.country.name = payload.country.name
                        ctx.commit('updateAddServiceToBasket', autotour)
                        await ctx.dispatch('saveBasket')
                        mainBoxServiceId = autotour.id
                        mainBoxServicePrice = autotour.price
                        break
                    case 'Aeroexpress':
                        let aeroexpress = {
                            id: null,
                            selectedDate: null,
                            selectedTariff: null,
                            selectedTariffTitle: null,
                            price: null,
                            orderType: null,
                            tripTimeId: null,
                            tripTimeTitle: null,
                            amount: null,
                            email: null,
                            phoneNumber: null,
                            travellers: [
                                // {
                                //     'firstName': null,
                                //     'patronymicName': null,
                                //     'surname': null,
                                //     'documentType': null,
                                //     'documentNumber': null
                                // },
                            ],
                            pointOfSalesSubAgent: {
                                'pointOfSalesSubAgent': 'Fun&Sun',
                                'pointOfSalesSubAgentLocation': 'Сайт'
                            },
                            bookedOrder: {
                                orderId: null,
                                orderCost: null,
                                reservedUntil: null,
                                details: []
                            },
                            paidOrder: {
                                code: null,
                                ticketId: null,
                                ticketGuid: null,
                                ticketUrl: null,
                                webTicketImageAccessToken: null,
                                tripDate: null,
                                arrivalStation: null,
                                departureStation: null,
                                rate: null,
                                coachNumber: null,
                                seat: null,
                                ticketCost: null,
                                payTime: null,
                                tripTime: null,
                            }
                        }
                        aeroexpress.id = `f${(+new Date()).toString(16)}` // генерим уникальный id от времени
                        aeroexpress.selectedDate = payload.selectedDate
                        aeroexpress.selectedTariff = payload.selectedTariff
                        aeroexpress.selectedTariffTitle = payload.selectedTariffTitle
                        aeroexpress.price = payload.selectedTariffPrice
                        aeroexpress.orderType = payload.orderType
                        aeroexpress.tripTimeId = payload.tripTimeId
                        aeroexpress.tripTimeTitle = payload.tripTimeTitle
                        aeroexpress.amount = payload.tickets

                        ctx.commit('updateAddAeroexpressToBasket', aeroexpress)
                        await ctx.dispatch('saveBasket')
                        mainBoxServiceId = aeroexpress.id
                        mainBoxServicePrice = aeroexpress.price
                        break
                    default:
                        let service = {
                            id: null,
                            travellers: [],
                            serviceCategory: null,
                            name: null,
                            date: null,
                            price: null,
                            imgUrl: null,
                            adults: null,
                            children: null,
                            currency: {
                                currencyId: null,
                                currencyAlias: null,
                            },
                            city: {
                                id: null,
                                name: null,
                            },
                            country: {
                                id: null,
                                name: null,
                            },
                        }
                        service.id = payload.serviceKey
                        service.travellers = []
                        service.serviceCategory = payload.category.id
                        service.serviceCategoryName = payload.category.code
                        service.name = payload.name
                        service.date = payload.startDate
                        service.price = payload.price.value
                        service.currency.currencyId = payload.price.currencyId
                        service.currency.currencyAlias = payload.price.currencyAlias
                        service.imgUrl = payload.imgUrl
                        service.adults = payload.adults
                        service.children = payload.children
                        service.city.id = payload.city.id
                        service.city.name = payload.city.name
                        service.country.id = payload.country.id
                        service.country.name = payload.country.name
                        ctx.commit('updateAddServiceToBasket', service)
                        let isSave = await ctx.dispatch('saveBasket')
                        if(isSave) {
                            const url = variables.routeClearDraftId;
                            await axios({
                                url,
                                method: 'POST',
                            }).then(response=>{
                                window.booking_uuid = response.data.booking_uuid
                            });
                        }
                        mainBoxServiceId = service.id
                        mainBoxServicePrice = service.price
                        break
                }
                ctx.dispatch('mainBox/fetchMainboxSetCartItemList', [mainBoxServiceId,mainBoxServicePrice], { root: true })
            } catch (e) {
                console.log('Ошибка в actions fetchAddServiceToBasket', e)
            }
        },
        //Надстройка над методом addTourToBasket
        //Которая позволяет выполнять запросы последовательно
        async serialAddTourToBasket({dispatch, state}, payload){
            let result = null
            let pause = Promise.all(state.tourPromiseContainer)
            await pause
            //Сброс ошибки добавления в корзину
            localStorage.setItem('errorAddToBasket', false)
            localStorage.setItem('addingInBasket', true)
            let addTourPromise = dispatch('addTourToBasket', payload)
            state.tourPromiseContainer.push(addTourPromise)
            result = await addTourPromise
            localStorage.setItem('addingInBasket', false)
            return result
        },
        async addTourToBasket({commit, dispatch, getters, state}, payload) {
            //От таких методов ожидается возвращение булевского значения удалось ли положить
            //товар в корзину. Все просто, не надо идти за тридевять земель...
            let result = false
            let data = null
            console.log('СРАБОТАЛ addTourToBasket');


            try {
                const url = variables.routeTourOptions
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                    validateStatus: (status) => {
                        if (status === 500 || status === 200) {
                            return true
                        }
                    }
                })
                data = await res.data

                if (data.tour !== undefined) {
                    if(!payload.selectedFlightOptionId){
                        payload.selectedFlightOptionId = data.tour.selectedFlightOptionId
                        let flightWithSurcharge = []
                        data.tour.flightOptions.forEach(flight=>{ //Ищем перелеты не с нулевой доплатой
                            if(flight.surcharge !== 0 ){
                                flightWithSurcharge.push(flight)
                            }
                        })
                        if(flightWithSurcharge.length && !payload.isRegularFlight){ //Если есть перелеты не с нулевой доплатой, то берем из них самый дешевый, только для чартеров
                            flightWithSurcharge.sort(function (x, y) {
                                return x.surcharge - y.surcharge
                            });
                            payload.selectedFlightOptionId = flightWithSurcharge[0].key
                        }
                    }
                    let insuranceToBasket = []
                    let servicesToBasket = []
                    let visaOptionsToBasket = []
                    /*data.tour.insurances.forEach(function (item) {
                        if (item.isIncludedInPacket) {
                            let insurance = {
                                id: item.id,
                                name: item.name,
                                options: item.options,
                                type: item.type,
                                price: item.price
                            }
                            insuranceToBasket.push(insurance)
                        }
                    })*/

                    data.tour.insurances.forEach(item => {
                        data.tour.paxes.forEach(pax=>{
                            let index = pax.selectedInsurances.findIndex(selected => selected.id === item.id)
                            if (index !== -1 && (item.isIncludedInPacket || getters.getUserRole ==='agent')) {
                                let insurance = {
                                    id: item.id,
                                    travellerId: pax.id,
                                    name: item.name,
                                    options: [],
                                    type: item.type,
                                    price: item.price
                                }
                                //Изначально прописываем только те опции, которые необходимы
                                item.options.forEach(option => {
                                    if (option.isRequired) {
                                        insurance.options.push(option)
                                    }
                                })
                                insuranceToBasket.push(insurance)
                            }
                        })
                    })

                    data.tour.services.forEach(item => {
                        let index = data.tour.paxes[0].selectedServiceIds.findIndex(selected => selected === item.id)
                        //if (index !== -1 && item.isRequired) {
                        //Мальдивский хардкод
                        if (index !== -1 && (item.isRequired || (item.isIncludedInPacket && data.tour.hotel.country.id === 275410) || item.type.id === 1)) {
                            let services = {
                                id: item.id,
                                name: item.name,
                                options: item.options,
                                type: item.type,
                                price: item.price
                            }
                            servicesToBasket.push(services)
                        }
                    })
                    //Проверяем на обязательный трансфер
                    let isRequired = data.groups.find(item=>item.description === "Трансфер")
                    if(isRequired){
                        isRequired = isRequired.required
                    }
                    //есть ли он уже в корзине
                    let isTransferInBasket = servicesToBasket.find(service=>service.type.id === 1)
                    if(isRequired && !isTransferInBasket){ //есди нужен, но нет в корзине
                        let transfer = data.tour.services.find(service=>service.type.id === 1)
                        if(transfer){
                            let services = {
                                id: transfer.id,
                                name: transfer.name,
                                options: transfer.options,
                                type: transfer.type,
                                price: transfer.price
                            }
                            servicesToBasket.push(services)
                        }
                    }


                    data.tour.visaOptions.forEach(function (item) {
                        data.tour.paxes.forEach(pax=>{
                            if (item.isIncludedInPacket) {
                                let visa = {
                                    id: item.id,
                                    name: item.name,
                                    travellerId: pax.id,
                                    options: item.options,
                                    type: item.type,
                                    price: item.price
                                }
                                visaOptionsToBasket.push(visa)
                            }
                        })
                    })
                    //Для Индии и кипра виза обязательна, но нет пометки что она требуется, в следствии чего делаем следующее
                    if([18772, 18028].includes(data.tour.hotel.country.id)  && !visaOptionsToBasket.length){
                        let item = data.tour.visaOptions.find(visa=>visa.type.name.includes('Своя виза'))
                        if(!item){
                          item = data.tour.visaOptions[0]
                        }
                        data.tour.paxes.forEach(pax=>{
                            let visa = {
                                id: item.id,
                                name: item.name,
                                travellerId: pax.id,
                                options: item.options,
                                type: item.type,
                                price: item.price
                            }
                            visaOptionsToBasket.push(visa)
                        })
                    }

                    //пересчитываем туристов из ajast
                    let paxes = {
                        adultsCount: 0,
                        childrenCount: 0,
                        infantsCount: 0
                    }
                    data.tour.paxes.forEach(function (item) {
                        if (item.person.ageGroup === 1) {
                            paxes.adultsCount += 1
                        }
                        if (item.person.ageGroup === 2) {
                            paxes.childrenCount += 1
                        }
                        if (item.person.ageGroup === 3) {
                            paxes.infantsCount += 1
                        }
                    })

                    payload.insurances = insuranceToBasket
                    payload.services = servicesToBasket
                    payload.visaOptions = visaOptionsToBasket
                    payload.flightSeats=[]
                    payload.tourPartner = data.tour.tourPartner
                    payload.paxes = paxes
                    payload.paxesOptions = data.paxesOptions
                    payload.isVisaRequired = false
                    if(data.tour.visaOptions.length>0){
                        payload.isVisaRequired = true
                    }
                    payload.checkinDate = data.tour.hotel.checkinDate
                    payload.checkoutDate = data.tour.hotel.checkoutDate
                    state.basket.tours = []
                    let isSave = await dispatch('saveBasket')

                    commit('updateAddTourToBasket', payload)
                    commit('updateTourRecountErrors', null)

                    isSave = await dispatch('saveBasket')
                    if(isSave) {
                        const url = variables.routeClearDraftId;
                        await axios({
                            url,
                            method: 'POST',
                        }).then(response=>{
                            window.booking_uuid = response.data.booking_uuid
                        });
                        result = true;
                    }

                    // dispatch('mainBox/fetchMainboxSetCartItemList', [data.tour.id,data.price.amount], { root: true })
                }
            } catch(e){
                console.log('Ошибка в actions addTourToBasket', e)
            }

            if(data.errors){
                commit('updateTourRecountErrors', data.errors)
                getters.setPopupStatus('basketTourErrorsPopup')
            }

            return result
        },
        async addTourToBasketByOffer({commit, dispatch, getters, state}, offer){
          let result = false
          //Прверяем ключ на ТУИ
          let tui = 'TUI::'
          let key = offer.tourKey
          if (!key.includes(tui)) {
            key = tui + key
          }
          //Добавляем тур в корзину
          let tour = {
            id: key,
            hotelId: offer.hotelId,
            countryFromId: 0, //Заполнить
            departureCityId: offer.departureCityId ? offer.departureCityId : 1,
            departureCityName: localStorage.getItem('departureCityName'),
            offerTourId: offer.tourId,
            countryId: getters.getHotelCountry.ebgNodeId ? getters.getHotelCountry.ebgNodeId : 0,
            countryName: getters.getHotelCountry.localizedName ? getters.getHotelCountry.localizedName: '-',
            cityId: 0,
            cityName: 'Заполнить',
            roomId: offer.roomId,
            mealId: offer.mealId,
            mealName: 'Заполнить',
            travellers: [],
            paxes: offer.paxes,
            name: offer.hotelName,
            htplace: offer.accomodation.name,
            startDate: moment(offer.tourStartDate).format('YYYY-MM-DD'),
            endDate: moment(offer.tourEndDate).format('YYYY-MM-DD'),
            checkinDate: moment(offer.checkinDate).format('YYYY-MM-DD'),
            checkoutDate: moment(offer.checkoutDate).format('YYYY-MM-DD'),
            daysCount: offer.daysCount,
            nightsCount: offer.nightsCount,
            price: offer.price.value,
            basePrice: offer.price.oldValue,
            tourTypeId: offer.tourType.id ? offer.tourType.id : 0,
            currency: {
              currencyId: offer.price.currencyId,
              currencyAlias: offer.price.currencyAlias,
            },
            imgUrl: null,
            insurances: [],
            services: [],
            visaOptions: [],
            selectedFlightOptionId: null,
            tourPartner: null,
            tourId: null,
            isRegularFlight: offer.isRegularFlight,
            availablePromos: typeof offer.availablePromos !== 'undefined' ? offer.availablePromos : [],
            packetType: offer.departureCityId !== 1 ? 'tour' : 'hotel',
            programTypeName: null,
            flightSeats:[],
            // programType: {
            //   id: null,
            //   name: null,
            // }
          }
          if(offer.selectedFlightOptionId){
            tour.selectedFlightOptionId = offer.selectedFlightOptionId
          }
          if (offer.programType) {
            tour.programTypeName = offer.programType.name
            // tour.programType.id = offer.programType.key
            // tour.programType.name = offer.programType.name
            }

          commit('updateAddTourToBasket', tour)

          //Сохраняем корзину
          let isSave = await dispatch('saveBasket')
          if(isSave) {
            const url = variables.routeClearDraftId;
            await axios({
              url,
              method: 'POST',
            }).then(response=>{
              window.booking_uuid = response.data.booking_uuid
            });
            result = true
            }

          return result
        },
        async fillTourWithPacket({commit, dispatch, getters, state}, packetData){
          console.log('fillTourWithPacket', packetData)

          let tour = getters.getBasketTours[0]
          let claimDocument = null
          if(!packetData.errors){
            claimDocument = packetData.claim.claimDocument
          }

          //Заполняем недостающие данные
          let countryFromId = 0
          if (tour.departureCityId !== 1) {
            let countryFrom = ''
            countryFrom = await dispatch('fetchCountryByCityIdReturnObject', tour.departureCityId)
            countryFromId = countryFrom.ebgNodeId
          }

          tour.countryFromId = countryFromId
          if(claimDocument){
            tour.countryId = claimDocument.hotels[0].stateKey
            tour.countryName = claimDocument.hotels[0].state
            tour.cityId = claimDocument.hotels[0].townKey
            tour.cityName = claimDocument.hotels[0].town
            let meal = getters.getMealTypesNaming.find(item=>item.nameEN === claimDocument.hotels[0].meal)
            tour.mealName = meal ? meal.nameRU : claimDocument.hotels[0].meal
            tour.name = claimDocument.hotels[0].name
            //Добавляем страховки
            let insuranceToBasket = []
            let insurances = claimDocument.services.filter(service=>service.type === 1 && !service.parentUid)
            insurances.forEach(item=>{
              item.clients.forEach(client=>{
                let insurance = {
                  id: item.key,
                  travellerId: client.peopleKey,
                  name: item.name.replace(':', ''),
                  options: [],
                  type: {
                    id: 1,
                    name: "Медицинская",
                  },
                  price: {
                    amount: item.fsPriceConverted,
                    currencyAlias: tour.currency.currencyAlias,
                    currencyId: tour.currency.currencyId,
                  }
                }
                let options = claimDocument.services.filter(service=>item.uid === service.parentUid)
                options.forEach(option=>{
                  let optionToBasket = {
                    id: option.key,
                    isRequired: option.packet,
                    name: option.name,
                    price: {
                      amount: option.fsPriceConverted,
                      currencyAlias: tour.currency.currencyAlias,
                      currencyId: tour.currency.currencyId,
                    }
                  }
                  insurance.options.push(optionToBasket)
                })
                insuranceToBasket.push(insurance)
              })
            })
            tour.insurances = insuranceToBasket
            //Добавляем визы
            let visaToBasket = []
            let visas = claimDocument.services.filter(service => service.type === 2)
            visas.forEach(item=>{
              claimDocument.peoples.forEach(people=>{
                let visa = {
                  id: item.key,
                  name: item.name,
                  travellerId: people.key,
                  type: {
                    id: item.key,
                    name: item.name,
                  },
                  price: {
                    amount: item.fsPriceConverted,
                    currencyAlias: tour.currency.currencyAlias,
                    currencyId: tour.currency.currencyId,
                  }
                }
                visaToBasket.push(visa)
              })
            })
            tour.visaOptions = visaToBasket
            //Добавляем остальные услуги
            let servicesToBasket = []
            let services = claimDocument.services.filter(service => ![1, 2].includes(service.type))
            services.forEach(item=>{
              let service = {
                id: item.key,
                name: item.name,
                type: {
                  id: item.serviceType ? item.serviceType: '',
                  name: item.serviceCategoryName ? item.serviceCategoryName: '',
                },
                price: {
                  amount: item.fsPriceConverted,
                  currencyAlias: tour.currency.currencyAlias,
                  currencyId: tour.currency.currencyId,
                }
              }
              servicesToBasket.push(service)
            })
            tour.services = servicesToBasket

            // Добавление комиссии

            if(getters.getPacketData) {
              tour.comissionPercent = packetData?.claim?.claimDocument?.comissionPercent
            }
            console.log('tourFill ', tour)
          }

        },
        async deleteTourFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteTourFromBasketById', payload)
            await ctx.dispatch('saveBasket')
        },
        async fetchTourOptions({ commit, getters }, payload) {
            console.log('СРАБОТАЛ fetchTourOptions');

            // Метод получения опций по туру  МНОГО всего!!!!!!
            commit('updateIsRecountWorking', true)
            const url = variables.routeTourOptions
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                    validateStatus: (status) => {
                        if (status === 500 || status === 200) {
                            return true
                        }
                    }
                })
                //Тестовая история
                // return {
                //     errors: [{
                //         userMessage: 'Ошибка метода ' + url
                //     }]
                // }
                //Тестовая история

                const data = await res.data
                if(data.errors){
                    commit('updateTourRecountErrors', data.errors)
                    getters.setPopupStatus('basketTourErrorsPopup')
                }
                commit('updateIsRecountWorking', false)
                return data
            } catch(e) {
                commit('updateIsRecountWorking', false)
                return {
                    errors: [{
                        userMessage: 'Ошибка метода ' + url
                    }]
                }
            }

        },

        async fetchTourRecount({commit, getters}, payload) {
            // Метод пересчета Тура
            commit('updateIsRecountWorking', true)
            const url = variables.routeTourRecount
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                    validateStatus: (status) => {
                        if (status === 500 || status === 200) {
                            return true
                        }
                    }
                })
                const data = await res.data
                if(data.errors){
                    commit('updateTourRecountErrors', data.errors)
                    getters.setPopupStatus('basketTourErrorsPopup')
                }
                commit('updateIsRecountWorking', false)
                return data
             } catch {
                commit('updateIsRecountWorking', false)
                 return {
                     errors: [{
                         userMessage: 'Ошибка метода ' + url
                     }]
                 }
             }

        },
        async fetchTourGetBoarding(ctx, payload) {
            // Метод получения посадочных мест
            const url = variables.routeTourBoarding
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                const data = await res.data
                return data
            } catch(e) {
                return {
                    errors: [{
                        userMessage: 'Ошибка метода ' + url
                    }]
                }
            }
        },
        async fetchTourGetFares(ctx, payload) {
            // Метод получения посадочных мест
            const url = variables.routeTourGetFares
            //try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                    validateStatus: (status) => {
                        if (status === 500 || status === 200) {
                            return true
                        }
                    }
                })
                const data = await res.data
                return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
        async fetchTourSetFares(ctx, payload) {
            // Метод получения посадочных мест
            const url = variables.routeTourSetFares
            //try {
            const res = await axios({
                url,
                method: 'POST',
                data: payload,
                validateStatus: (status) => {
                    if (status === 500 || status === 200) {
                        return true
                    }
                }
            })
            const data = await res.data
            return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
        async addTourInsuranceToBasket(ctx, payload) {
            ctx.commit('updateAddTourInsuranceToBasket', payload)
            //await ctx.dispatch('saveBasket')
        },
        async deleteTourInsuranceFromBasket(ctx, payload) {
            ctx.commit('updateDeleteTourInsuranceFromBasket', payload)
            //await ctx.dispatch('saveBasket')
        },
        async deleteAllTourInsurancesFromBasket(ctx, basketIndex) {
            ctx.commit('updateDeleteAllTourInsuranceFromBasket', basketIndex)
            await ctx.dispatch('saveBasket')
        },
        async addTourServiceToBasket(ctx, payload) {
            ctx.commit('updateAddTourServiceToBasket', payload)
            //await ctx.dispatch('saveBasket')
        },
        async deleteTourServiceFromBasket(ctx, payload) {
            ctx.commit('updateDeleteTourServiceFromBasket', payload)
           // await ctx.dispatch('saveBasket')
        },
        async addTourVisaToBasket(ctx, payload) {
            ctx.commit('updateAddTourVisaToBasket', payload)
            //await ctx.dispatch('saveBasket')
        },
        async deleteTourVisaFromBasket(ctx, payload) {
            ctx.commit('updateDeleteTourVisaFromBasket', payload)
           // await ctx.dispatch('saveBasket')
        },
        async deleteAllTourVisaFromBasket(ctx, payload){
            ctx.commit('updateDeleteAllTourVisaFromBasket', payload)
        },
        async setTourFlightKey(ctx, payload) {
            ctx.commit('updateSetTourFlightKey', payload)
            await ctx.dispatch('saveBasket')
        },
        async setTourFlightOptions(ctx, payload) {
            ctx.commit('updateSetTourFlightOptions', payload)
            if(!payload.noSave) {
                await ctx.dispatch('saveBasket')
            }
        },
        async setTourFlightSeats(ctx, payload) {
            ctx.commit('updateSetTourFlightSeats', payload)
            await ctx.dispatch('saveBasket')
        },
        async setTourPrice(ctx, payload) {
            ctx.commit('updateSetTourPrice', payload)
            await ctx.dispatch('saveBasket')
        },
        setTourDates(ctx, payload){
            ctx.commit('updateSetTourDates', payload)
        },
        async setTourPromoCode(ctx, payload) {
            ctx.commit('updateSetTourPromoCode', payload)
            await ctx.dispatch('saveBasket')
        },
        async setTourUserId(ctx, payload) {
            ctx.commit('updateSetTourUserId', payload)
            await ctx.dispatch('saveBasket')
        },
        //Надстройка над методом addHotelToBasket
        //Которая позволяет выполнять запросы последовательно
        async serialAddHotelToBasket({dispatch, state}, payload){
            let result = null
            let pause = Promise.all(state.hotelPromiseContainer)
            await pause
            //Сброс ошибки добавления в корзину
            localStorage.setItem('errorAddToBasket', false)
            localStorage.setItem('addingInBasket', true)
            let addHotelPromise = dispatch('addHotelToBasket', payload)
            state.hotelPromiseContainer.push(addHotelPromise)
            result = await addHotelPromise
            localStorage.setItem('addingInBasket', false)
            return result
        },
        async addHotelToBasket({commit, dispatch, getters}, payload) {
            //От таких методов ожидается возвращение булевского значения удалось ли положить
            //товар в корзину. Все просто, не надо идти за тридевять земель...
            let result = false
            let data = null
            console.log('СРАБОТАЛ addHotelToBasket');

            try {
                const url = variables.routeTourOptions
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                    validateStatus: (status) => {
                        if (status === 500 || status === 200) {
                            return true
                        }
                    }
                })
                data = await res.data

                if (data.tour !== undefined) {
                    let insuranceToBasket = []
                    let servicesToBasket = []
                    let visaOptionsToBasket = []
                    data.tour.insurances.forEach(item => {
                        data.tour.paxes.forEach(pax => {
                            let index = pax.selectedInsurances.findIndex(selected => selected.id === item.id)
                            if (index !== -1 && (item.isIncludedInPacket || getters.getUserRole === 'agent')) {
                                let insurance = {
                                    id: item.id,
                                    travellerId: pax.id,
                                    name: item.name,
                                    options: [],
                                    type: item.type,
                                    price: item.price
                                }
                                //Изначально прописываем только те опции, которые необходимы
                                item.options.forEach(option => {
                                    if (option.isRequired) {
                                        insurance.options.push(option)
                                    }
                                })
                                insuranceToBasket.push(insurance)
                            }
                        })
                    })

                    data.tour.services.forEach(item => {
                        let index = data.tour.paxes[0].selectedServiceIds.findIndex(selected => selected === item.id)
                        if (index !== -1) { //&& item.isRequired
                            let services = {
                                id: item.id,
                                name: item.name,
                                options: item.options,
                                type: item.type,
                                price: item.price
                            }
                            servicesToBasket.push(services)
                        }
                    })


                    data.tour.visaOptions.forEach(function (item) {
                        data.tour.paxes.forEach(pax => {
                            if (item.isIncludedInPacket) {
                                let visa = {
                                    id: item.id,
                                    name: item.name,
                                    travellerId: pax.id,
                                    options: item.options,
                                    type: item.type,
                                    price: item.price
                                }
                                visaOptionsToBasket.push(visa)
                            }
                        })
                    })
                    //пересчитываем туристов из ajast
                    let paxes = {
                        adultsCount: 0,
                        childrenCount: 0,
                        infantsCount: 0
                    }
                    data.tour.paxes.forEach(function (item) {
                        if (item.person.ageGroup === 1) {
                            paxes.adultsCount += 1
                        }
                        if (item.person.ageGroup === 2) {
                            paxes.childrenCount += 1
                        }
                        if (item.person.ageGroup === 3) {
                            paxes.infantsCount += 1
                        }
                    })
                    payload.insurances = insuranceToBasket
                    payload.services = servicesToBasket
                    payload.visaOptions = visaOptionsToBasket
                    payload.tourPartner = data.tour.tourPartner
                    payload.paxes = paxes
                    payload.paxesOptions = data.paxesOptions
                    payload.isVisaRequired = false
                    if (data.tour.visaOptions.length > 0) {
                        payload.isVisaRequired = true
                    }
                    commit('updateAddHotelToBasket', payload)
                    commit('updateTourRecountErrors', null)

                    let isSave = await dispatch('saveBasket')
                    if(isSave) result = true

                    dispatch('mainBox/fetchMainboxSetCartItemList', [data.tour.id, data.price.amount], { root: true })
                }
            } catch(e) {
                    console.log('Ошибка в actions addHotelToBasket', e)
            }
            if(data.errors){
                commit('updateTourRecountErrors', data.errors)
                getters.setPopupStatus('basketTourErrorsPopup')
            }

            return result

        },
        async deleteHotelFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteHotelFromBasketById', payload)
            await ctx.dispatch('saveBasket')
        },
        async setHotelPrice(ctx, payload) {
            ctx.commit('updateSetHotelPrice', payload)
            await ctx.dispatch('saveBasket')
        },
        async addFlightToBasket(ctx, payload) {
            ctx.commit('updateAddFlightToBasket', payload)
            await ctx.dispatch('saveBasket')
            ctx.dispatch('mainBox/fetchMainboxSetCartItemList', [payload.id,payload.price], { root: true })
        },
        async addFlightServiceToBasket(ctx, payload) {
            ctx.commit('updateAddFlightServiceToBasket', payload)
            await ctx.dispatch('saveBasket')
        },
        async deleteFlightServiceFromBasket(ctx, payload) {
            ctx.commit('updateDeleteFlightServiceFromBasket', payload)
            await ctx.dispatch('saveBasket')
        },
        async updateFlightAfterTariffChange(ctx, payload) {
            ctx.commit('updateFlightAfterTafiffChangeInBasket', payload)
            await ctx.dispatch('saveBasket')
        },
        async deleteFlightFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteFlightFromBasketById', payload)
            await ctx.dispatch('saveBasket')
        },
        async fetchAddInsuranceToBasket(ctx, payload) {
            ctx.commit('updateAddInsuranceToBasket', payload)
            await ctx.dispatch('saveBasket')
        },
        async deleteInsuranceFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteInsuranceFromBasketById', payload)
            await ctx.dispatch('saveBasket')
        },
        async changeTourInsuranceOptionToBasket(ctx, payload){
            ctx.commit('updateChangeTourInsuranceOptionToBasket', payload)
            await ctx.dispatch('saveBasket')
        },
        // addTransferToBasket(ctx, payload) {
        //     ctx.commit('updateAddTransferToBasket', payload)
        //     ctx.dispatch('saveBasket')
        // },
        async deleteTransferFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteTransferFromBasketById', payload)
            await ctx.dispatch('saveBasket')
        },
        async deletePackageTransfer(ctx, data) {
            //По факту пакетный трансфер не удаляется, ему просто ставится флаг isDeleted = true
            // let data = {
            //     basketId: null,
            //     id: null,
            // }
            ctx.commit('updateDeletePackageTransfer', data)
            await ctx.dispatch('saveBasket')
        },
        async deletePackageInsurance(ctx, data) {
            //По факту пакетная страховка не удаляется, ей просто ставится флаг isDeleted = true
            // let data = {
            //     basketId: null,
            //     id: null,
            // }
            ctx.commit('updateDeletePackageInsurance', data)
            await ctx.dispatch('saveBasket')
        },
        // addServiceToBasket(ctx, payload) {
        //     ctx.commit('updateAddServiceToBasket', payload)
        //     ctx.dispatch('saveBasket')
        // },
        async deleteServiceFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteServiceFromBasketById', payload)
            await ctx.dispatch('saveBasket')
        },
        async addExcursionToBasket(ctx, payload) {
            ctx.commit('updateAddExcursionToBasket', payload)
            await ctx.dispatch('saveBasket')
        },
        async updateAddAeroexpressToBasket(ctx, payload) {
            ctx.commit('updateAddAeroexpressToBasket', payload)
            await ctx.dispatch('saveBasket')
        },
        async deleteExcursionFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteExcursionFromBasketById', payload)
            await ctx.dispatch('saveBasket')
        },
        async deleteAeroexpressFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteAeroexpressFromBasketById', payload)
            await ctx.dispatch('saveBasket')
        },
        async fetchUpdateBasketTravellers(ctx, payload) {
            ctx.commit('updateBasketTravellers', payload)
            await ctx.dispatch('saveBasket')
        },
        async fetchUpdateBasketAeroexpressTravellers(ctx, payload) {
            ctx.commit('updateBasketAeroexpressTravellers', payload)
            await ctx.dispatch('saveBasket')
        },
        async fetchUpdateBasketCustomerInfo(ctx, payload) {
            ctx.commit('updateBasketCustomerInfo', payload)
            await ctx.dispatch('saveBasket')
        },
        async fetchUpdateBasketAeroexpressCustomerInfo(ctx, payload) {
            ctx.commit('updateBasketAeroexpressCustomerInfo', payload)
            await ctx.dispatch('saveBasket')
        },
        async addProductToBasket(ctx, payload) {
            ctx.commit('updateAddProductToBasket', payload);
            await ctx.dispatch('saveBasket')
        },
        async increaseProductCount(ctx, productId){
            ctx.commit('updateIncreaseProductCount', productId);
            await ctx.dispatch('saveBasket')
        },
        async decreaseProductCount(ctx, productId){
            ctx.commit('updateDecreaseProductCount', productId);
            await ctx.dispatch('saveBasket')
        },
        async deleteProductFromBasketById(ctx, payload) {
            ctx.commit('updateDeleteProductToBasket', payload);
            await ctx.dispatch('saveBasket')
        },
        async fetchBasketNationalities() {
            let url = variables.routeBasketNationalities;
            //try {
            const status = await axios({
                url,
                method: 'GET'
            });
            let data = await status.data
            return data
        },
        async fetchBasketRegisterUser(ctx, data) {
            let url = variables.routeBasketRegisterUser;
            try {
                const result = await axios({
                    url,
                    method: 'POST',
                    data
                });
               await ctx.commit('updateLoginData', result.data)
               await ctx.dispatch('fetchUser')
               ctx.dispatch('loadBasket')
              eventBus.$emit('loginSuccess')
              return result.data;
            } catch (e) {
                console.log('Ошибка в fetchBasketRegisterUser', e)
            }
        },
        async fetchUpdateSetTourTourId(ctx, payload){
            ctx.commit('updateSetTourTourId', payload);
            await ctx.dispatch('saveBasket')
        },
        async setLoyaltyInfo(ctx, payload){
            ctx.commit('updateLoyaltyInfo', payload);
            await ctx.dispatch('saveBasket')
        },
        async checkTourAvailability(ctx, payload){ //Метод проверки доступности тура
            let url = variables.routeBasketCheckTourAvailability;
            try {
                const result = await axios({
                    url,
                    method: 'POST',
                    data: payload
                });
                return result.data.status;
            } catch (e) {
                console.log('Ошибка в checkTourAvailability', e)
            }
        },
        async fetchGoToBasket(ctx, payload){ //доп параметры
            try{
                await ctx.dispatch('loadBasket')
            } catch {
                //
            }
            let result = true //Если есть услуги, то редиректим на старую корзину
            let params = ''
            let typeOpen = '_self'
            if(payload && payload.params){
                params = payload.params
            }
            if(payload && payload.typeOpen){
                typeOpen = payload.typeOpen
            }
            if(ctx.state.basket.flights.length){
                result = false
            }
            if(ctx.state.basket.insurance.length){
                result = false
            }
            if(ctx.state.basket.transfers.length){
                result = false
            }
            // if(ctx.state.basket.services.length){
            //     result = false
            // }
            // if(ctx.state.basket.excursions.length){
            //     result = false
            // }
            if(ctx.state.basket.aeroexpress.length){
                result = false
            }

            let redirectUrl = '';

            if(result){
                redirectUrl = window.langUrlPrefix + "/booking/" + window.booking_uuid + params
            } else {
                redirectUrl = window.langUrlPrefix + "/basket"
            }
            window.open(redirectUrl, typeOpen);
        },
        async sendCrmCreateOrder(ctx){
            let url = variables.routeSendCrmCreateOrder;
            try {
                const result = await axios({
                    url,
                    method: 'POST',
                    data: payload
                });
                return result.data.status;
            } catch (e) {
                console.log('Ошибка в sendCrmCreateOrder', e)
            }
        },
        async fetchTourGetPacket(ctx, payload) {
            payload.currencyId = 1
            // Метод получения пакета
            const url = variables.routeTourGetPacket
            //try {
            const res = await axios({
                url,
                method: 'POST',
                data: payload,
                validateStatus: (status) => {
                    if (status === 500 || status === 200) {
                        return true
                    }
                }
            })
            const data = await res.data
            ctx.commit('updatePacketData', data)
            ctx.commit('updatePacketDataClaimDocument', data)
            return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
        async fetchTourGetExternalFreights(ctx, payload) {
            // Метод получения передетов GDS
            //const url = variables.routeTourGetExternalFreights
            // временный хардкод для решения 500 из-за пустого claim.note
            if (Array.isArray(payload.claim.notes) && payload.claim.notes.length === 0)
                payload.claim.notes = {}
            payload.clientId = 'b2c:cm'

            //try {
            const res = await axios({
                url: `${process.env.MIX_API_TOUR_RESERVATION_SERVICE}/GetExternalFreights`,
                method: 'POST',
                data: payload,
                validateStatus: (status) => {
                    if (status === 500 || status === 200) {
                        return true
                    }
                }
            })
            const data = await res.data
            return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
        async fetchTourGetReservationPrice(ctx, payload) {
            // Метод уточнения цены
            const url = variables.routeTourGetReservationPrice
            //try {
            const res = await axios({
                url,
                method: 'POST',
                data: payload,
                validateStatus: (status) => {
                    if (status === 500 || status === 200) {
                        return true
                    }
                }
            })
            const data = await res.data
            return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
        async fetchTourActualizePacket(ctx, payload) {
            payload.currencyId = 1
            // Метод уточнения цены
            //const url = variables.routeTourActualizePacket
            // временный хардкод для решения 500 из-за пустого claim.note
            if (Array.isArray(payload.claim.notes) && payload.claim.notes.length === 0)
                payload.claim.notes = {}
            payload.clientId = 'b2c:cm'

            payload.userAgent = "FUNSAN"
            //try {
            const res = await axios({
                url: `${process.env.MIX_API_TOUR_RESERVATION_SERVICE}/ActualizePacket`,
                method: 'POST',
                data: payload,
                validateStatus: (status) => {
                    if (status === 500 || status === 200) {
                        return true
                    }
                }
            })
            const data = await res.data
            ctx.commit('updatePacketData', data);
            ctx.commit('updatePacketDataClaimDocument', data);
            return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
        async fetchGetSupplements(ctx, payload){
            // Метод получения доплат чартеров
            //const url = variables.routeTourGetSupplements
            // временный хардкод для решения 500 из-за пустого claim.note
            if (Array.isArray(payload.claim.notes) && payload.claim.notes.length === 0)
                payload.claim.notes = {}
            payload.clientId = 'b2c:cm'

            //try {
            const res = await axios({
                url: `${process.env.MIX_API_TOUR_RESERVATION_SERVICE}/GetSupplements`,
                method: 'POST',
                data: payload,
                validateStatus: (status) => {
                    if (status === 500 || status === 200) {
                        return true
                    }
                }
            })
            const data = await res.data.supplements
            return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
        async fetchGetPacketFares(ctx, payload) {
            payload.currencyId = 1
            // Метод получения тарифов для регулярок
            // const url = variables.routeTourGetPacketFares
            // временный хардкод для решения 500 из-за пустого claim.note
            if (Array.isArray(payload.claim.notes) && payload.claim.notes.length === 0)
                payload.claim.notes = {}
            payload.clientId = 'b2c:cm'

            //try {
            const res = await axios({
                url: `${process.env.MIX_API_TOUR_RESERVATION_SERVICE}/GetPacketFares`,
                method: 'POST',
                data: payload,
                validateStatus: (status) => {
                    if (status === 500 || status === 200) {
                        return true
                    }
                }
            })
            const data = await res.data.fares
            return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
        async fetchSetPacketFare(ctx, payload) {
            payload.currencyId = 1
            // Метод установления тарифов для регулярок
            const url = variables.routeTourGetPacketFares
            //try {
            const res = await axios({
                url,
                method: 'POST',
                data: payload,
                validateStatus: (status) => {
                    if (status === 500 || status === 200) {
                        return true
                    }
                }
            })
            const data = await res.data
            return data
            // } catch(e) {
            //     return {
            //         errors: [{
            //             userMessage: 'Ошибка метода ' + url
            //         }]
            //     }
            // }
        },
    },
    mutations: {
        updatePacketData(state, payload){
            state.packetData = payload
        },
        updatePacketDataClaimDocument(state, payload) {
          if (state?.basket?.tours.length >= 0 && payload?.claim?.claimDocument) {
            state.basket.tours[0].claimDocumentJson = payload.claim.claimDocument
          }
        },
        updateBasketData(state, payload) {
            try {
                //Берем либо драфт, либо из реддиса
                let basket = null
                if (payload.draftBody !== undefined) {
                    basket = payload.draftBody
                } else {
                    if(payload) {
                        basket = JSON.parse(payload)
                    }
                }
                //Переписывание transfer в transfers чтобы не менять на беке
                if (basket !== null) {
                    let transfers = basket.transfers
                    delete basket.transfer
                    basket.transfers = transfers
                    state.basket = basket
                }

            } catch (e) {
                console.log('Ошибка в мутации updateBasketData', e)
            }
        },
        updateAddTourToBasket(state, payload) {
            //Максимум 1 тур, новый тур заменяет предыдущий
            if (state.basket.tours.length > 0) {
                state.basket.tours = []
            }
            state.basket.tours.push(payload)
        },
        updateDeleteTourFromBasketById(state, payload) {
            const index = state.basket.tours.findIndex(tours => tours.id === payload);
            if (index !== -1) {
                state.basket.tours.splice(index, 1);
            }
        },
        updateAddTourInsuranceToBasket(state, payload) {
            const index = state.basket[payload.basketPart][payload.basketIndex].insurances.findIndex(insurance => insurance.id === payload.id && insurance.travellerId === payload.travellerId)
            if (index === -1) {
                let insurance = {
                    id: payload.id,
                    travellerId: payload.travellerId,
                    name: payload.name,
                    options: payload.options,
                    price: payload.pricePerPerson ? payload.pricePerPerson: payload.price,
                    type: payload.type,
                }
                state.basket[payload.basketPart][payload.basketIndex].insurances.push(insurance)
                //state.basket.tours[payload.basketIndex].price = state.basket.tours[payload.basketIndex].price + payload.price.amount
            } else{
                state.basket[payload.basketPart][payload.basketIndex].insurances[index].options = payload.options
            }

        },
        updateDeleteTourInsuranceFromBasket(state, payload) {
            const index = state.basket[payload.basketPart][payload.basketIndex].insurances.findIndex(insurance => insurance.id === payload.id && insurance.travellerId === payload.travellerId)
            if (index !== -1) {
                state.basket[payload.basketPart][payload.basketIndex].insurances.splice(index, 1);
                //state.basket.tours[payload.basketIndex].price = state.basket.tours[payload.basketIndex].price - payload.price.amount
            }

        },
        updateChangeTourInsuranceOptionToBasket(state, payload){
            const insurance = state.basket[payload.basketPart][payload.basketIndex].insurances.find(insurance => insurance.id === payload.insurance.id && insurance.travellerId === payload.travellerId)
            if(insurance){
                let optionIndex = insurance.options.findIndex(option=>option.id === payload.option.id)
                if(optionIndex !==-1){
                    insurance.options.splice(optionIndex,1);
                } else{
                    insurance.options.push(payload.option)
                }
            }
        },
        updateDeleteAllTourInsuranceFromBasket(state, basketIndex) {
            state.basket.tours[basketIndex].insurances = []
        },
        updateAddTourServiceToBasket(state, payload) {
            if(!payload.travellerId){
                payload.travellerId = 0
            }
            const index = state.basket[payload.basketPart][payload.basketIndex].services.findIndex(service => service.id === payload.id && service.travellerId === payload.travellerId)
            if (index === -1) {
                let service = {
                    id: payload.id,
                    name: payload.name,
                    price: payload.pricePerPerson ? payload.pricePerPerson: payload.price,
                    type: payload.type,
                    travellerId: payload.travellerId,
                }
                state.basket[payload.basketPart][payload.basketIndex].services.push(service)
                //state.basket.tours[payload.basketIndex].price = state.basket.tours[payload.basketIndex].price + payload.price.amount
            }
        },
        updateDeleteTourServiceFromBasket(state, payload) {
            if(!payload.travellerId){
                payload.travellerId = 0
            }
            const index = state.basket[payload.basketPart][payload.basketIndex].services.findIndex(service => service.id === payload.id && service.travellerId === payload.travellerId)
            if (index !== -1) {
                state.basket[payload.basketPart][payload.basketIndex].services.splice(index, 1);
                //state.basket.tours[payload.basketIndex].price = state.basket.tours[payload.basketIndex].price - payload.price.amount
            }
        },
        updateAddTourVisaToBasket(state, payload) {
            const index = state.basket[payload.basketPart][payload.basketIndex].visaOptions.findIndex(visa => visa.travellerId === payload.travellerId)
            let visaItem = {
                id: payload.id,
                travellerId: payload.travellerId,
                name: payload.name,
                price: payload.price,
                type: payload.type,
            }
            if (index === -1) {
                state.basket[payload.basketPart][payload.basketIndex].visaOptions.push(visaItem)
            } else {
                //Если виза уже была выбранна для данноготуриста, то мы её заменим
                state.basket[payload.basketPart][payload.basketIndex].visaOptions.splice(index, 1)
                state.basket[payload.basketPart][payload.basketIndex].visaOptions.push(visaItem)
            }
        },
        updateDeleteTourVisaFromBasket(state, payload) {
            const index = state.basket[payload.basketPart][payload.basketIndex].visaOptions.findIndex(visa => visa.id === payload.id && visa.travellerId === payload.travellerId)
            if (index !== -1) {
                state.basket[payload.basketPart][payload.basketIndex].visaOptions.splice(index, 1);
            }
        },
        updateDeleteAllTourVisaFromBasket(state, payload){
            state.basket['tours'][payload].visaOptions = []
        },
        updateSetTourFlightKey(state, payload) {
            state.basket.tours[payload.basketIndex].selectedFlightOptionId = payload.selectedFlightOptionId
        },
        updateSetTourFlightOptions(state, payload) {
            if(state.basket.tours && state.basket.tours[payload.basketIndex]) {
                //state.basket.tours[payload.basketIndex].flightOption = payload.currentFlight;
            }
        },
        updateSetTourFlightSeats(state, payload){
            if(state.basket.tours[payload.basketIndex].flightSeats){
                state.basket.tours[payload.basketIndex].flightSeats = payload.flightSeats
            }
        },
        updateSetTourPrice(state, payload) {
            state.basket.tours[payload.basketIndex].price = payload.price
        },
        updateSetTourDates(state, payload){
            state.basket.tours[payload.basketIndex].checkinDate = payload.checkinDate
            state.basket.tours[payload.basketIndex].checkoutDate = payload.checkoutDate
            state.basket.tours[payload.basketIndex].nightsCount = payload.nightsCount
        },
        updateSetTourPromoCode(state, payload) {
            state.basket.tours[payload.basketIndex].promoCode = payload.promoCode
        },
        updateSetTourUserId(state, payload) {
            state.basket.userId = payload.userId;
            state.basket.email = payload.email;
            state.basket.phone = payload.phone;
        },
        updateSetTourTourId(state, payload) {
            if (state.basket.tours[payload.basketIndex].tourId === null) {
                state.basket.tours[payload.basketIndex].tourId = payload.tourId
            }
        },
        updateSetSelectedHotels(state, payload) {
            if (state.basket.tours[payload.basketIndex].selectedHotels === null) {
                state.basket.tours[payload.basketIndex].selectedHotels = payload.selectedHotels
            }
        },
        updateSetTourServices(state, payload) {
            let servicesToBasket = []
            payload.tour.services.forEach(item => {
                let index = payload.tour.paxes[0].selectedServiceIds.findIndex(selected => selected === item.id)
                if (index !== -1) {
                    let services = {
                        id: item.id,
                        name: item.name,
                        options: item.options,
                        type: item.type,
                        price: item.price
                    }
                    servicesToBasket.push(services)
                }
            })
            state.basket.tours[payload.basketIndex].services = servicesToBasket
        },
        updateAddHotelToBasket(state, payload) {
            state.basket.hotels.push(payload)
        },
        updateAddProductToBasket(state, payload) {
            let product = {
                id: payload.id,
                amount: payload.amount,
                brand: {
                    id: payload.brand.id,
                    name: payload.brand.name
                },
                capacity: payload.capacity,
                capacityMeasureId: payload.capacityMeasureId,
                careDescription: payload.careDescription,
                category: null,//{
                //     id: 0,
                //     name: "string"
                // },
                code: payload.code,
                colours: null,//[
                    // {
                    //     id: 0,
                    //     nameRus: "string",
                    //     nameEng: "string"
                    // }
                //],
                commodityCategoryId: payload.commodityCategoryId,
                commodityCategoryName: payload.commodityCategoryName,
                commoditySize: payload.commoditySize,
                commoditySizeId: payload.commoditySizeId,
                commodityStatusId: payload.commodityStatusId,
                currency: null,//{
                //     id: 0,
                //     name: "string",
                //     currencyId: 0,
                //     currencyAlias: "string"
                // },
                currencyId: payload.currencyId,
                description: payload.description,
                discountPercent: payload.discountPercent,
                discountPrice: payload.discountPrice,
                externalAgentId: payload.externalAgentId,
                formatId: payload.formatId,
                genres: null,//[
                // {
                //     id: 0,
                //     name: "string"
                // }
                //],
                height: payload.height,
                images: null,//[
                // {
                //     id: 0,
                //     productId: 0,
                //     name: "string",
                //     extension: "string",
                //     img: "string",
                //     sortNumber: 0
                // }
                //],
                isAvailable: payload.isAvailable,
                manufactureCountryName: payload.manufactureCountryName,
                manufacturedDate: payload.manufacturedDate,
                marketCountryId: payload.marketCountryId,
                materials: null, //[
                // {
                //     id: 0,
                //     name: "string"
                // }
                //],
                name: payload.name,
                partnerProfileId: payload.partnerProfileId,
                price: payload.price,
                usageLimit: payload.usageLimit,
                usageLimitMeasure: payload.usageLimitMeasure,
                usageLimitMeasureId: payload.usageLimitMeasureId,
                warrantyLimit: payload.warrantyLimit,
                warrantyLimitMeasure: payload.warrantyLimitMeasure,
                warrantyLimitMeasureId: payload.warrantyLimitMeasureId,
                weight: payload.weight,
                width: payload.width,
                capacityMeasure: payload.capacityMeasure
            }
            let images = []
            if(payload.images.length>0){
                payload.images.forEach(image=>{
                    images.push({
                        id: image.id,
                        productId: product.id,
                        name: image.name,
                        extension: image.extension,
                        img: image.img,
                        sortNumber: image.sortNumber
                    })
                })
            }
            product.images = images
            state.basket.products.push(product);
        },
        updateIncreaseProductCount(state, productId){
            let product = state.basket.products.find(item=> item.id === productId)
            product.amount+=1
        },
        updateDecreaseProductCount(state, productId){
            let product = state.basket.products.find(item=> item.id === productId)
            if(product.amount > 1){
                product.amount-=1
            }
        },
        updateDeleteHotelFromBasketById(state, payload) {
            const index = state.basket.hotels.findIndex(hotel => hotel.id === payload);
            if (index !== -1) {
                state.basket.hotels.splice(index, 1);
            }
        },
        updateSetHotelPrice(state, payload) {
            state.basket.hotels[payload.basketIndex].price = payload.price
        },
        updateAddFlightToBasket(state, payload) {
            state.basket.flights.push(payload)
            state.basket.flightDetails = payload.flightDetails
        },
        updateDeleteFlightFromBasketById(state, payload) {
            const index = state.basket.flights.findIndex(flight => flight.id === payload);
            if (index !== -1) {
                state.basket.flights.splice(index, 1);
            }
        },
        updateAddFlightServiceToBasket(state, payload) {
            const index = state.basket.flights[payload.basketIndex].services.findIndex(service => service.name === payload.name && service.travellerId === payload.travellerId)
            if (index === -1) {
                let service = {
                    name: payload.name,
                    type: payload.type,
                    travellerId: payload.travellerId,
                    offer: {
                        total: payload.offer.total,
                        key: payload.offer.key,
                    }
                }
                state.basket.flights[payload.basketIndex].services.push(service)
                state.basket.flights[payload.basketIndex].price = state.basket.flights[payload.basketIndex].price + payload.offer.total
            }

        },
        updateDeleteFlightServiceFromBasket(state, payload) {
            const index = state.basket.flights[payload.basketIndex].services.findIndex(service => service.name === payload.name && service.travellerId === payload.travellerId)

            if (index !== -1) {
                state.basket.flights[payload.basketIndex].services.splice(index, 1);
                state.basket.flights[payload.basketIndex].price = state.basket.flights[payload.basketIndex].price - payload.offer.total
            }
        },
        updateFlightAfterTafiffChangeInBasket(state, payload) {
            state.basket.flights[payload.basketIndex].price = payload.price
            state.basket.flights[payload.basketIndex].services = []
        },
        updateAddInsuranceToBasket(state, payload) {
            state.basket.insurance.push(payload)
        },
        updateDeleteInsuranceFromBasketById(state, payload) {
            const index = state.basket.insurance.findIndex(insurance => insurance.id === payload);
            if (index !== -1) {
                state.basket.insurance.splice(index, 1);
            }
        },
        updateAddTransferToBasket(state, payload) {
            try {
                state.basket.transfers.push(payload)
                eventBus.$emit('updateBasket')
            } catch (e) {
                console.log('Ошибка в мутации updateAddTransferToBasket', e)
            }
        },
        updateDeleteTransferFromBasketById(state, payload) {
            const index = state.basket.transfers.findIndex(transfer => transfer.id === payload);
            if (index !== -1) {
                state.basket.transfers.splice(index, 1);
            }
        },
        updateDeletePackageTransfer(state, data) {
            try {
                let tour = state.basket.tours.find(item => item.id === data.basketId)
                let transfer = tour.services.find(item => item.id === data.id)
                if (transfer && typeof (transfer) === 'object') {
                    transfer.isDeleted = true
                }
            } catch (e) {
                console.log('Ошибка в мутации updateDeletePackageTransfer', e)
            }
        },
        updateDeletePackageInsurance(state, data) {
            try {
                let tour = state.basket.tours.find(item => item.id === data.basketId)
                let insurance = tour.insurances.find(item => item.id === data.id)
                if (insurance && typeof (insurance) === 'object') {
                    insurance.isDeleted = true
                }
            } catch (e) {
                console.log('Ошибка в мутации updateDeletePackageTransfer', e)
            }
        },
        updateAddServiceToBasket(state, payload) {
          if (state.basket.services.length > 0) {
            state.basket.services = []
          }
            state.basket.services.push(payload)
        },
        updateDeleteServiceFromBasketById(state, payload) {
            const index = state.basket.services.findIndex(service => service.id === payload);
            if (index !== -1) {
                state.basket.services.splice(index, 1);
            }
        },
        updateAddExcursionToBasket(state, payload) {
            //Максимум 1 экскурсия, новая экскурсия заменяет предыдущую
            if (state.basket.excursions.length > 0) {
                state.basket.excursions = []
            }
            state.basket.excursions.push(payload)
        },
        updateAddAeroexpressToBasket(state, payload) {
            state.basket.aeroexpress.push(payload)
        },
        updateDeleteExcursionFromBasketById(state, payload) {
            const index = state.basket.excursions.findIndex(excursion => excursion.id === payload);
            if (index !== -1) {
                state.basket.excursions.splice(index, 1);
            }
        },
        updateDeleteAeroexpressFromBasketById(state, payload) {
            const index = state.basket.aeroexpress.findIndex(order => order.id === payload);
            if (index !== -1) {
                state.basket.aeroexpress.splice(index, 1);
            }
        },
        updateBasketTravellers(state, payload) {
            let basketPart = payload.basketPart
            let basketIndex = payload.basketIndex
            state.basket[basketPart][basketIndex].travellers = payload.travellers
        },
        updateBasketAeroexpressTravellers(state, payload) {
            let basketPart = payload.basketPart
            let basketIndex = payload.basketIndex
            state.basket[basketPart][basketIndex].travellers = payload.travellers
        },
        updateBasketCustomerInfo(state, payload) {
            if (Object.keys(payload).length == 0) {
                state.basket.customerInfo = null
            } else {
                state.basket.customerInfo = payload
            }
        },
        updateBasketAeroexpressCustomerInfo(state, payload) {
            if (Object.keys(payload).length > 0) {
                let idx = payload.basketIndex
                state.basket.aeroexpress[idx].email = payload.email
                state.basket.aeroexpress[idx].phones = payload.phones
                // state.basket.customerInfo.email = payload.email
            }
        },
        updateDeleteProductToBasket(state, payload) {
            const index = state.basket.products.findIndex(product => product.id === payload);
            if (index !== -1) {
                state.basket.products.splice(index, 1);
            }
        },
        updateBasketStatus(state, payload) {
            state.basketStatus = payload
        },
        updateIsBasketBookLoading(state, payload) {
            state.isBasketBookLoading = payload
        },
        updateIsRecountWorking(state, payload) {
            state.isRecountWorking = payload
        },
        updateTourRecountErrors(state, payload){
            state.tourRecountErrors = payload
        },
        updateCheckIsOneClickBooking(state, getters){
            //Проверка на возможность короткого бронирования
            let result = true
            //Туры с регуляркой
            state.basket.tours.forEach(tour => {
                if(tour.isRegularFlight){
                    result = false
                }
                //Проверка туров по стране
                if(tour.countryId === 20613){ //ОАЭ
                    result = false
                }
                //Для ТВД нет одного клика
                if(tour.tourTypeId === 44){
                    result = false
                }

            })
            state.basket.hotels.forEach(tour => {
                if(tour.countryId === 20613){ //ОАЭ
                    result = false
                }
            })
            //Все авиа
            if(state.basket.flights.length > 0 ){
                result = false
            }
            //Страховки от сравни
            if(state.basket.insurance.length > 0 ){
                result = false
            }
            //Трансферы кроме самотуровских
            state.basket.transfers.forEach(transfer => {
                if(transfer.type.provider !== 'samotour'){
                    result = false
                }
            })
            if(getters.getUserRole === 'agent'){
                result = false
            }
            if(getters.getLocalization !== 'ru'){
                result = false
            }
            //С визой позде 21 дня, без визы 14 дней
            let redLineDate
            state.basket.tours.forEach(tour => {
                if(tour.isVisaRequired){
                    redLineDate =  moment().add(21, 'days').format('YYYY-MM-DD')
                }
                if(!tour.isVisaRequired){
                    redLineDate =  moment().add(14, 'days').format('YYYY-MM-DD')
                }
                if(moment(tour.startDate).isBefore(moment(redLineDate))){
                    result = false
                }
            })
            state.basket.hotels.forEach(hotel => {
                if(hotel.isVisaRequired){
                    redLineDate =  moment().add(21, 'days').format('YYYY-MM-DD')
                }
                if(!hotel.isVisaRequired){
                    redLineDate =  moment().add(14, 'days').format('YYYY-MM-DD')
                }
                if(moment(hotel.startDate).isBefore(moment(redLineDate))){
                    result = false
                }
            })
            //Скрыто бронирование в 1 клик 01/11/2021
            result = false
            state.basket.isOneClickBooking = result
        },
        updateLoyaltyInfo(state, payload){
            state.basket.loyality = payload
        },
        updateDisableSaveBasket(state){
            state.disableSave = true;
        },
        updateBasketDraftId(state, payload) {
            state.basketDraftId = payload;
        },
    },
    state: {
        basketDraftId: null,
        basket: {
            tours: [],
            hotels: [],
            flights: [],
            insurance: [],
            transfers: [],
            services: [],
            excursions: [],
            customerInfo: null,
            partnerComment: null,
            products: [],
            aeroexpress: [],
            draftType: window.location.hostname.includes('tinkoff') ? 'TinkoffFS' : 'FunSun',
            loyality: null,
            flightDetails: {},
        },
        basketPositions: ['tours', 'hotels', 'flights', 'insurance', 'transfers', 'services', 'excursions', 'products', 'aeroexpress'],
        basketStatus: null,
        isBasketBookLoading: false,
        isRecountWorking: false,
        isCreatedBasket: false,
        basketValidationMessage: "",
        tourRecountErrors: null,
        isOneClickBooking: true,
        //если отравлено несколько запросов на добавление в корзину, они должны выполняться последовательно
        //для этого нужны промисс контейнеры
        tourPromiseContainer: [],
        hotelPromiseContainer: [],
        disableSave: false,
        packetData: null,
    },
    getters: {
        getBasket(state) {
            return state.basket
        },
        getBasketTours(state) {
            return state.basket.tours
        },
        getBasketDraftId(state) {
            return state.basketDraftId
        },
        getBasketHotels(state) {
            return state.basket.hotels
        },
        getBasketFlights(state) {
            return state.basket.flights
        },
        getBasketFlightDetails(state) {
            return state.basket.flightDetails
        },

        getBasketInsurance(state) {
            return state.basket.insurance
        },
        getBasketTransfer(state) {
            return state.basket.transfers
        },
        getBasketServices(state) {
            return state.basket.services
        },
        getBasketExcursions(state) {
            return state.basket.excursions
        },
        getBasketAeroexpress(state) {
            return state.basket.aeroexpress
        },
        getBasketStatus(state) {
            return state.basketStatus
        },
        getIsBasketBookLoading(state) {
            return state.isBasketBookLoading
        },
        getBasketCountItems(state) {
            let result = 0;
            for (let key in state.basket) {
                if (state.basketPositions.indexOf(key) !== -1) {
                    result += state.basket[key].length
                }
            }
            return result
        },
        getBasketTotalPrice(state) {
            let result = 0;
            for (let key in state.basket) {
                if (state.basketPositions.indexOf(key) !== -1) {
                    state.basket[key].forEach(item => {
                        //если есть количество то умножаем цену на количество
                        if (!item.amount) {
                            result += item.price
                        } else {
                            result += item.price * item.amount
                        }
                    })
                }

            }
            return result
        },
        getBasketPositionPrice: state => position => {
            let result = 0;
            state.basket[position].forEach(item => {
                //если есть количество то умножаем цену на количество
                if (!item.amount) {
                    result += item.price
                } else {
                    result += item.price * item.amount
                }

            })
            return result
        },
        getIsIdInBasket: state => id => {
            let result = false
            for (let key in state.basket) {
                if (state.basketPositions.indexOf(key) !== -1) {
                    state.basket[key].forEach(item => {
                        if (item.id === id) {
                            result = true
                        }
                    })
                }

            }
            return result
        },
        getIsHotelInBasket: state => (hotelId) => {
            let hotels = state.basket.hotels
            let result = hotels.some(item => {
                if (
                    item.hotelId === hotelId
                ) {
                    return true
                }
                return false
            })
            return result
        },
        getIsTransferInBasket: state => (transferId, date) => {
            //возвращает false или идентификатор трансфера в корзине
            let result = false
            let transfers = state.basket.transfers
            let res = transfers.find(item => {
                if (item.transferId === transferId && item.date === date) {
                    return true
                }
            })
            if (res) {
                result = res.id
            }
            return result
        },
        getBasketPartTravellers: state => payload => {
            let basketPart = payload.basketPart
            let basketIndex = payload.basketIndex
            let result = state.basket[basketPart][basketIndex] ? state.basket[basketPart][basketIndex].travellers : []
            result = result.sort((a, b) => a.id > b.id ? 1 : -1)
            return result
        },
        getBasketCustomerInfo(state) {
            if (state.basket.customerInfo === null) {
                return {}
            } else {
                return state.basket.customerInfo
            }
        },
        getTourInsurancesByIndex: state => index => {
            return state.basket.tours[index] ? state.basket.tours[index].insurances : []
        },
        getTourInsurancesByIndexAndTravellerId: state => payload => {
            let result = []
            state.basket[payload.basketPart][payload.basketIndex].insurances.forEach(function (item) {
                if (item.travellerId === payload.travellerId) {
                    result.push(item)
                }
            })
            return result
        },
        getTourVisaOptionsByIndex: state => index => {
            return state.basket.tours[index] ? state.basket.tours[index].visaOptions : []
        },
        getTourFlightSeatsByIndex: state => index => {
            if (state.basket.tours[index] && state.basket.tours[index].flightSeats) {
                return state.basket.tours[index].flightSeats
            } else {
                return []
            }
        },
        getTourVisaOptionsByIndexAndTravellerId: state => payload => {
            let result = []
            state.basket[payload.basketPart][payload.basketIndex].visaOptions.forEach(function (item) {
                if (item.travellerId === payload.travellerId) {
                    result.push(item)
                }
            })
            return result
        },
        getIsTourTravellerFilled: state => index => {
            let travellerLengths = state.basket.tours[index].travellers.length
            let tourTourists = state.basket.tours[index].paxes.adultsCount + state.basket.tours[index].paxes.childrenCount + state.basket.tours[index].paxes.infantsCount
            return travellerLengths === tourTourists
        },
        getIsTourInsurancesFilled: state => index => {
            return state.basket.tours[index] && state.basket.tours[index].insurances && state.basket.tours[index].insurances.length > 0;
        },
        getTourServicesByIndex: state => index => {
            if (state.basket.tours[index] && state.basket.tours[index].services) {
                return state.basket.tours[index].services
            } else {
                return []
            }
        },
        getIsTourServicesFilled: state => index => {
            return state.basket.tours[index] && state.basket.tours[index].services && state.basket.tours[index].services.length > 0;
        },
        getIsTourVisaOptionsFilled: state => index => {
            return state.basket.tours[index] && state.basket.tours[index].visaOptions.length > 0;
        },
        getIsTourFlightOption: state => index => {
            if (state.basket.tours[index] && state.basket.tours[index].flightOption) {
                return state.basket.tours[index].flightOption;
            } else {
                return []
            }
        },
        getIsHotelTravellerFilled: state => index => {
            let travellerLengths = state.basket.hotels[index].travellers.length
            let tourTourists = state.basket.hotels[index].paxes.adultsCount + state.basket.hotels[index].paxes.childrenCount + state.basket.hotels[index].paxes.infantsCount
            return travellerLengths === tourTourists;
        },
        getIsHotelInsurancesFilled: state => index => {
            return state.basket.hotels[index].insurances.length > 0;
        },
        getHotelInsurancesByIndex: state => index => {
            return state.basket.hotels[index].insurances
        },
        getHotelServicesByIndex: state => index => {
            return state.basket.hotels[index].services
        },
        getIsHotelServicesFilled: state => index => {
            return state.basket.hotels[index].services.length > 0;
        },
        getIsHotelVisaOptionsFilled: state => index => {
            return state.basket.hotels[index].visaOptions.length > 0;
        },
        getHotelVisaOptionsByIndex: state => index => {
            return state.basket.hotels[index].visaOptions
        },
        getIsExcursionTravellerFilled: state => index => {
            let travellerLengths = state.basket.excursions[index].travellers.length
            let tourTourists = state.basket.excursions[index].adults + state.basket.excursions[index].children
            return travellerLengths === tourTourists;
        },
        getIsAeroexpressTravellerFilled: state => index => {
            let travellerLengths = state.basket.aeroexpress[index].travellers.length
            let tickets = state.basket.aeroexpress[index].tickets
            return travellerLengths === tickets;
        },
        getIsServiceTravellerFilled: state => index => {
            let travellerLengths = state.basket.services[index].travellers.length
            let tourTourists = state.basket.services[index].adults + state.basket.services[index].children
            return travellerLengths === tourTourists;
        },
        getIsTransferTravellerFilled: state => index => {
            let travellerLengths = state.basket.transfers[index].travellers.length
            let tourTourists = state.basket.transfers[index].passengers.adults + state.basket.transfers[index].passengers.children + state.basket.transfers[index].passengers.infants
            return travellerLengths === tourTourists;
        },
        getIsInsuranceTravellerFilled: state => index => {
            let travellerLengths = state.basket.insurance[index].travellers.length
            let tourTourists = state.basket.insurance[index].adults
            return travellerLengths === tourTourists;
        },
        getIsFlightTravellerFilled: state => index => {
            let travellerLengths = state.basket.flights[index].travellers.length
            let tourTourists = state.basket.flights[index].passengers.adults + state.basket.flights[index].passengers.children
            return travellerLengths === tourTourists;
        },
        getAviaMealsByIndex: state => index => {
            let result = []
            state.basket.flights[index].services.forEach(function (item) {
                if (item.type === "Meal") {
                    result.push(item)
                }
            })
            return result
        },
        getAviaMealsByIndexAndTravellerId: state => payload => {
            let result = []
            state.basket.flights[payload.basketIndex].services.forEach(function (item) {
                if (item.type === "Meal" && item.travellerId === payload.travellerId) {
                    result.push(item)
                }
            })
            return result
        },
        getIsFlightMealsFilled: state => index => {
            let result = false
            state.basket.flights[index].services.forEach(function (item) {
                if (item.type === "Meal") {
                    result = true
                }
            });
            return result
        },
        getAviaLuggageByIndex: state => index => {
            let result = []
            state.basket.flights[index].services.forEach(function (item) {
                if (item.type === "Luggage") {
                    result.push(item)
                }
            })
            return result
        },
        getAviaLuggageByIndexAndTravellerId: state => payload => {
            let result = []
            state.basket.flights[payload.basketIndex].services.forEach(function (item) {
                if (item.type === "Luggage" && item.travellerId === payload.travellerId) {
                    result.push(item)
                }
            })
            return result
        },
        getIsFlightLuggageFilled: state => index => {
            let result = false
            state.basket.flights[index].services.forEach(function (item) {
                if (item.type === "Luggage") {
                    result = true
                }
            });
            return result
        },
        getBasketProducts(state) {
            return state.basket.products
        },
        getIsCreatedBasket(state) {
            return state.isCreatedBasket
        },
        getBasketValidationMessage(state) {
            return state.basketValidationMessage
        },
        getUniqueBasketId(state) {
            //Получение идентификатора
            let id = 1
            let ids = []
            let elems = [].concat(...Object.values(state.basket))
            elems.forEach(item => {
                if (item) {
                    if (item.id) ids.push(item.id)
                }
            })
            while (ids.some(item => item === id)) {
                id = id + 1
            }
            return id
        },
        getBasketTourRecountErrors(state) {
            return state.tourRecountErrors
        },
        getIsRecountWorking(state) {
            return state.isRecountWorking
        },
        getPacketData(state) {
            return state.packetData
        },
        getCurrencySign(state) {
            let result = ""

            let type = 'tours'
            if (state.basket.flights.length) {
                type = 'flights'
            }
            if (state.basket.insurance.length) {
                type = 'insurance'
            }
            if (state.basket.transfers.length) {
                type = 'transfers'
            }
            if (state.basket.services.length) {
                type = 'services'
            }
            if (state.basket.excursions.length) {
                type = 'excursions'
            }
            let value = state.basket[type][0].currency.currencyAlias.toLowerCase()


            switch (value) {
                case 'rub':
                    result = '₽'
                    break
                case 'usd':
                    result = '$'
                    break
                case 'eur':
                    result = '€'
                    break
                case 'uah':
                    result = '₴'
                    break
                case 'byn':
                    result = 'BYN'
                    break
                case 'kzt':
                    result = '₸'
                    break
            }
            return result
        },
    }
}
