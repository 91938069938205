// import variables from "../../plugins/variables";
// import axios from 'axios';


export default {
    actions: {

    },
    state: {
        activeTooltip: {
            // name: "calendar-fs",
            // contactPoint: {
            //     x: 10,
            //     y: 20
            // },
            // orientation: "top"
        }
    },
    mutations: {
        setActiveTooltip(state, initObj){
            // let initObj = {
            //     name: "test",
            //     contactPoint: {
            //         x: 0,
            //         y: 0
            //     },
            // }
            state.activeTooltip = initObj
        },
        removeActiveTooltip(state){
            state.activeTooltip.name = null
        }
    },
    getters: {
        getActiveTooltip(state){
            return state.activeTooltip
        }
    },
}
