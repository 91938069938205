/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const config = process.env.MIX_CONFIG ? '-' + process.env.MIX_CONFIG : ''
console.log( '%c' + process.env.BUILD_DATE, "color: cyan")
import(`../sass/app.scss`)
// Подключение Vue
import Vue from "vue";

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";



window.Vue = Vue

window.nopopup = false

let url = new URL(location.href)
if (url.searchParams.has('nopopup')){
    window.nopopup = true
}
Vue.prototype.$nopopup = window.nopopup

import router from "./router"
import store from "./plugins/store";
import VueCookies from 'vue-cookies'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta'
// кнопка поделиться
import VueTheMask from 'vue-the-mask'
import _ from "lodash";

const clickOutside = require('./plugins/clickOutSide');

Vue.use(clickOutside)
const VueScrollTo = require('vue-scrollto')

import device from "./plugins/device";
Vue.use(device)
Vue.use(VueMeta)
Vue.use(VueTheMask)
Vue.use(VueScrollTo)
Vue.use(VueCookies)


window.customLazyEvent = new Event('custom-lazy-event',{bubbles: true, cancelable: true, composed: true});
document.addEventListener('custom-lazy-event', function() {});
//что бы вызвать событие принудительно - document.dispatchEvent(window.customLazyEvent)
Vue.use(VueLazyload, {
    preLoad: 1.1,
    error: '/storage/images/actionHotel.png',
    loading: '/storage/images/blurry.svg',
    attempt: 1,
    dispatchEvent:true,
    // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
    listenEvents: ['scroll','custom-lazy-event'],
    lazyComponent: true
})


Vue.prototype.$langUrlPrefix = window.langUrlPrefix;
Vue.prototype.$geoCdnLink = window.geo_cdn_link;
import {vTrslt} from "./directives/v-trslt";
Vue.directive('trslt', vTrslt)

window._trans = (key) => {
    let trans = _.get(window.trans_all, key, key)
    return trans ? trans : ''
}
Vue.prototype.$trans = (key) => {
    return _trans(key)
};

//подключение локализаций библиотек//
//-vue2-datepicker
import 'vue2-datepicker/locale/ru';//русский
import 'vue2-datepicker/locale/uk';//украинский
import 'vue2-datepicker/locale/et';//естонский
import 'vue2-datepicker/locale/kk';//казахский
import 'vue2-datepicker/locale/lt';//литва
import 'vue2-datepicker/locale/lv';//латвия
//-moment
require('moment/locale/ru');//русский
require('moment/locale/uk');//украинский
require('moment/locale/et');//естонский
require('moment/locale/kk');//казахский
require('moment/locale/lt');//литва
require('moment/locale/lv');//латвия
////////////////////////////////////

//Глобальная шина событий
window.eventBus = new Vue();

//конфиг для круизов
window.mconfig_start = function () {
    return {
        API_KEY: 'NTQyMzg=',
        SEC_KEY: '7ed7a3c977b7eb51da4f4200b25df264',
        MARKER: '1687'
    }
}


window.gmapConfig ={
    load: {
        key: process.env.MIX_GMAP_KEY ? process.env.MIX_GMAP_KEY : 'AIzaSyDPI0EMXpki7WqAtzev3cOjiB4Og6Ng5WM', // AIzaSyAVy-Xi2KRfBlcBqC2W5btAWsZ3enpcf20 AIzaSyDPI0EMXpki7WqAtzev3cOjiB4Og6Ng5WM
        libraries: 'places',
    }
}

//TODO можно регуляркой, но чет не пашет
let ignoreTrans = ['trans']
for (let i = 0;i<20;i++){
    ignoreTrans.push('trans'+i)
}
Vue.config.ignoredElements = ignoreTrans

import asyncComponents from "./asyncComponents";


import mainAppComponent from './components/App'

Vue.component('App', mainAppComponent)

// if (process.env.MIX_SENTRY_LARAVEL_DSN){
//     if(['fstravel.com'].includes(location.hostname)){
//         Sentry.init({
//             Vue,
//             dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
//             integrations: [
//                 new BrowserTracing({
//                     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//                     tracingOrigins: ["funsun.local"],
//                 }),
//             ],
//             sampleRate: process.env.MIX_SENTRY_TRACES_SAMPLE_RATE,
//             // Set tracesSampleRate to 1.0 to capture 100%
//             // of transactions for performance monitoring.
//             // We recommend adjusting this value in production
//             tracesSampleRate: process.env.MIX_SENTRY_TRACES_SAMPLE_RATE,
//         });
//     }
//
//     //sentry для теста
//     if(
//         ['127.0.0.1', 'localhost'].includes(location.hostname)
//         &&
//         process.env.MIX_SENTRY_USE_LOCAL
//     ){
//         Sentry.init({
//             Vue,
//             dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
//             integrations: [
//                 new BrowserTracing({
//                     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//                     tracingOrigins: ["localhost", "127.0.0.1"],
//                 }),
//             ],
//             sampleRate: process.env.MIX_SENTRY_TRACES_SAMPLE_RATE,
//             tracesSampleRate: process.env.MIX_SENTRY_TRACES_SAMPLE_RATE,
//         });
//     }
// }

window.fsapp = new Vue({
    components:asyncComponents,
    comments:false,
    store,
    el: '#app',
    router,
});

