
export default {
    actions: {
        //подписка
        fetchMainboxRegister(ctx, params){

            if (process.env.MIX_CONFIG === 'aeroflot'){
                return false
            }

            try{
                mindbox("async", {
                    operation: "SubscriptionOnRegistrationForm",
                    data: {
                        "customer": {
                            "mobilePhone": params.phone,
                            "email": params.email,
                            "customFields": {
                                "subscriberTypeFS": params.clientId,
                                // "subscribeSourceFS": VueCookies.get('mindboxDeviceUUID'),
                            },
                            area: {
                                ids: {
                                    externalId: localStorage.selectedCityFromId
                                }
                            },

                            "subscriptions": [
                                {
                                    "brand": "FUNSUN",
                                    "pointOfContact": "Email"
                                }
                            ]
                        }
                    }
                });
            }catch(e){
                console.log('Ошибка в actions fetchMainboxRegister', e)
            }
        },
        fetchMainboxSubscriptionOnMainPages(ctx, params){

            try{
                mindbox("async", {
                    operation: "SubscriptionOnMainPages",
                    data:{
                        "customer": {
                            "email": params.email,
                            "customFields": {
                                "subscriberTypeFS": params.clientId,
                                // "subscribeSourceFS": VueCookies.get('mindboxDeviceUUID'),
                                "SectionOfSiteFS": params.path
                            },
                            area: {
                                ids: {
                                    externalId: localStorage.selectedCityFromId
                                }
                            },
                            "subscriptions": [
                                {
                                    "brand": "FUNSUN",
                                    "pointOfContact": "Email"
                                }
                            ]
                        }
                    }
                });
            }catch(e){
                console.log('Ошибка в actions fetchMainboxSubscriptionOnMainPages', e)
            }
        },
        fetchMainboxSubscriptionOnInternalPages(ctx, params){

            try{
                mindbox("async", {
                    operation: "SubscriptionOnInternalPages",
                    data:{
                        "customer": {
                            "email": params.email,
                            "customFields": {
                                "subscriberTypeFS": params.clientId,
                                // "subscribeSourceFS": VueCookies.get('mindboxDeviceUUID'),
                                "SectionOfSiteFS": params.path
                            },
                            area: {
                                ids: {
                                    externalId: localStorage.selectedCityFromId
                                }
                            },
                            "subscriptions": [
                                {
                                    "brand": "FUNSUN",
                                    "pointOfContact": "Email"
                                }
                            ]
                        }
                    }
                });
            }catch(e){
                console.log('Ошибка в actions fetchMainboxSubscriptionOnInternalPages', e)
            }
        },

        //действия пользователей
        fetchMainboxAuth(ctx, params){

            try{
                mindbox("async", {
                    operation: "AuthenticatedOnWebsite.JS",
                    data: {
                        "customer": {
                            "email": params.email,
                        }
                    }
                });
            }catch(e){
                console.log('Ошибка в actions fetchMainboxAuth', e)
            }
        },

        async fetchMainboxSiteSearch(ctx,searchData){

            let params = searchData
            let path = location.pathname.toLowerCase()
            if (path.length > 1 && path[path.length - 1]==='/'){
                path = path.slice(0,-1)
            }
            switch (path){
                case '/': params.type = 'country'
                    break
                case '/searchtour': params.type = 'country'
                    break
                case '/searchhotel': params.type = 'hotel'
                    break
                case '/avia': params.type = 'avia'
                    break
                case '/searchservices': params.type = 'otherservice'
                    break
                case '/searchexcursions': params.type = 'otherservice'
                    break
            }
               let data = {
                   sectionOfSite: ctx.state.sectionOfSite.hasOwnProperty(location.pathname.toLowerCase()) ? ctx.state.sectionOfSite[location.pathname.toLowerCase()]:'Other',
                   directionFrom: "",
                   directionTo: "",
                   dateFrom: "",
                   dateDelta: "",
                   nightsFrom: "",
                   nightsTo: "",
                   adults: "",
                   newbies: "",
                   page: location.href,
               }

            if (params.type === 'country'|| params.type === 'hotel'){
                if (params.departureCityId !== 1){
                    let departureCity = await ctx.dispatch('fetchGeoObjectByEbgNodeId',params.departureCityId)
                    data.directionFrom = departureCity.localizedName
                }

                if (params.arrivalCityIds.length === 1){
                    let arrivalCity = await ctx.dispatch('fetchGeoObjectByEbgNodeId',params.arrivalCityIds[0])
                    data.directionTo = arrivalCity.localizedName
                }else if(params.arrivalCityIds.length > 1) {
                    let arrivalCity = await ctx.dispatch('fetchGeoObjectByEbgNodeId',params.arrivalCityIds)
                    data.directionTo = arrivalCity.localizedName
                }else {
                    let arrivalCity = await ctx.dispatch('fetchGeoObjectByEbgNodeId',params.arrivalCountryId)
                    data.directionTo = arrivalCity.localizedName
                }

                data.dateFrom= params.minStartDate
                data.dateDelta= params.maxStartDate
                data.nightsFrom= params.minNightsCount
                data.nightsTo= params.maxNightsCount
                data.adults= params.adults
                if(params.children.length){
                    data.children = params.children.length
                    // for (let i = 1 ; i <= params.children.length ; i++){
                    //     data['childrenAges'+i] = params.children[i-1]
                    // }
                }
            }
            else if(params.type === 'avia'){
                let departureCity = await ctx.dispatch('fetchGeoObjectByEbgNodeId',params.ebgFrom)
                let arrivalCity = await ctx.dispatch('fetchGeoObjectByEbgNodeId',params.ebgTo)
                data.directionFrom= departureCity.localizedName
                data.directionTo= arrivalCity.localizedName

                data.dateFrom= params.date1
                data.dateDelta= params.date2
                data.adults= params.adults
                if(params.children){
                    data.children = params.children
                }
                if(params.infants){
                    data.children = data.children ? data.children : 0
                    data.children += params.infants
                }
            }
            else if(params.type === 'otherservice'){
                let departureCity = await ctx.dispatch('fetchGeoObjectByEbgNodeId',params.departureCityId)
                data.directionFrom= departureCity.localizedName

                data.dateFrom= params.startDate
                data.dateDelta= params.endDate
                data.adults= params.adults
                if(params.childrens.length){
                    data.children = params.childrens.length
                    // for (let i = 1 ; i <= params.children.length ; i++){
                    //     data['childrenAges'+i] = params.children[i-1]
                    // }
                }
            }
            try{
                mindbox("async", {
                        operation: "SiteSearch",
                        data: {
                            customerAction: {
                                customFields: data
                            }
                        }
                    }
                );
            }catch(e){
                console.log('Ошибка в actions fetchMainboxSiteSearch', e)
            }
        },

        fetchMainboxSetSearchList(ctx,payload){
            let type = ''
            let products = ''

            let path = location.pathname.toLowerCase()
            // if (path.length > 1 && path[path.length - 1]==='/'){
            //     path = path.slice(0,-1)
            // }
            if (path !== '/') {
                path = '/' + path.split('/')[1]
            }

            switch (path){
                case '/':
                    type = 'country'
                    products = payload
                    break
                case '/weekendtour':
                    type = 'country'
                    products = payload
                    break
                case '/searchhottours':
                    type = 'country'
                    products = payload
                    break
                case '/searchtour':
                    type = 'country'
                    products = payload
                    break
                case '/searchhotel':
                    type = 'hotel'
                    products = payload
                    break
                case '/avia':
                    type = 'avia'
                    products = payload.data
                    break
                case '/searchservices':
                    type = 'otherservice'
                    products = payload
                    break
                case '/searchexcursions':
                    type = 'otherservice'
                    products = payload
                    break
            }
            let productList = []
            let newProducts =[]

            if (type === 'country' || type === 'hotel'){
                products.forEach((el,i)=>{
                        if (newProducts.findIndex(x => x.id === el.id) === -1){
                            newProducts.push(el)
                        }
                })
            }else {
                newProducts = products
            }


            newProducts.slice(0,5).forEach((el,i)=>{
                if (type === 'country' || type === 'hotel'){
                    productList.push({
                        product: {
                            ids: {
                                website: el.id
                            }
                        },
                        count: 1,
                        price: el.currentOffer.price.value.toFixed()
                    })
                }else if(type === 'avia'){
                    let respId = payload.responseId
                    productList.push({
                        product: {
                            ids: {
                                website: i + respId
                            }
                        },
                        count: 1,
                        price: el.prices[0].toFixed()
                    })
                }else if (type === 'otherservice'){
                    productList.push({
                        product: {
                            ids: {
                                website: el.id
                            }
                        },
                        count: 1,
                        price: el.price.value.toFixed()
                    })
                }

            })
            try{
                mindbox("async", {
                    operation: "SetSearchList",
                    data: {
                        productList: productList
                    }
                });

            }catch(e){
                console.log('Ошибка в actions fetchMainboxSetSearchList', e)
            }
        },

        fetchMainboxViewSectionOfSite(ctx,searchData) {
            let data = {
                customerAction: {
                    customFields: {
                        sectionOfSite: ctx.state.sectionOfSite.hasOwnProperty(location.pathname.toLowerCase()) ? ctx.state.sectionOfSite[location.pathname.toLowerCase()]:'Other',
                    }
                }
            }
            // console.log(data)
            try {
                mindbox("async", {
                        operation: "ViewSectionOfSite",
                        data: data
                    }
                );

            } catch(e) {
                console.log('Ошибка в actions fetchMainboxViewSectionOfSite', e)
            }
        },
        fetchMainboxViewProduct(ctx,productId){
            try{
                mindbox("async", {
                    operation: "ViewProduct",
                    data: {
                        viewProduct: {
                            product: {
                                ids: {
                                    website: productId
                                }
                            }
                        }
                    }
                });
            }catch(e){
                console.log('Ошибка в actions fetchMainboxViewSectionOfSite', e)
            }
        },
        fetchMainboxChangingClientZoneOnSite(ctx, payload){
            if (payload && payload.hasOwnProperty('defaultCityEbgId')){
                let externalId = payload.defaultCityEbgId
            }else {
                let externalId = localStorage.selectedCityFromId
            }
            try{
                mindbox("async", {
                    operation: "ChangingClientZoneOnSite",
                    data: {
                        customer: {
                            area: {
                                ids: {
                                    externalId: localStorage.selectedCityFromId
                                }
                            }
                        }
                    },
                });
            }catch(e){
                console.log('Ошибка в actions fetchMainboxViewSectionOfSite', e)
            }
        },

        fetchMainboxSetCartItemList(ctx, [idProduct,price]){
            // console.log('MainboxSetCartItemList',idProduct,price)
            let data = {
                addProductToList: {
                    product: {
                        ids: {
                            website: idProduct
                        },
                    },
                    pricePerItem: price
                }
            }
            try{
                mindbox("async", {
                    operation: "SetCartItemList",
                    data: data
                });
            }catch(e){
                console.log('Ошибка в actions fetchMainboxViewSectionOfSite', e)
            }
        },




    },
    state: {
        sectionOfSite : {
            '/':'Tours',
            '/searchtour':'Tours',
            '/avia':'Flights',
            '/searchcruises':'Cruises',
            '/searchhotel':'Hotels',
            '/searchconstructor':'Сonstructor',
            '/searchexcursions':'Excursions',
            '/marketplace':'Marketplace',
            '/searchservices':'Services',
            '/oteli-s-koncepciyami':'Holiday.concepts',
            '/searchhottours':'Hot.tours',
            '/countries':'Country',
            '/market':'Marketplace',
            '/searchautotour':'Autotour',
            '/weekendtour':'Weekends',
            '/acrossrussia':'Acrossrussia',
            '/actions':'Actions',
            '/offices':'Sales.offices',
            '/statuscheck':'StatusCheck',
            '/cabinet':'Cabinet',
            '/companynews':'News',
            '/obshhie-voprosy-nacala-raboty':'For.Agency',
            '/wishlist':'wishlist',

            //нет таких страниц
            'Order.status':'Order.status',
            'Holiday.concepts':'Holiday.concepts',
            'Hot.tours':'Hot.tours',
            'Sales.offices':'Sales.offices',
            'Country':'Country',
            'Early.booking':'Early.booking',
        }
    },
    mutations: {


    },
    getters: {

    }
}
