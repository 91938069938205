import variables from "../../../plugins/variables";
import helpers from "../../../plugins/helpers";
import axios from "axios";

export default {
    actions: {
        // Старый метод использовался когда могли быть не указаны категории и тогда получали страны из
        // всех возможных категорий. Можно удалять после теста нового метода (ниже)
        // async fetchServicesCountriesTo(ctx, categoryId = null) {
        //     await ctx.dispatch('fetchServicesCategories')
        //     const url = variables.routeTourServices
        //     try {
        //         //Формирование массива ссылок для обхода
        //         let urls = []
        //         switch (categoryId) {
        //             case 1:
        //                 urls.push(url + '/1/CountriesTo')
        //                 break
        //             case 2:
        //                 urls.push(url + '/1/CountriesTo')
        //                 break
        //             default:
        //                 let categories = [...ctx.state.categoryServices]
        //                 //Удаление не нужных категорий
        //                 let transfer = categories.find(item => {
        //                     return item.code == "Transfer"
        //                 })
        //                 let excursion = categories.find(item => {
        //                     return item.code == "Excursion"
        //                 })
        //                 categories.splice(categories.indexOf(transfer), 1)
        //                 categories.splice(categories.indexOf(excursion), 1)
        //                 categories.forEach(item => {
        //                     urls.push(url + '/' + item.id + '/CountriesTo')
        //                 })
        //         }
        //         // Получение стран
        //         let payload = []
        //         for (let key in urls) {
        //             let res = await axios({
        //                 url: urls[key],
        //                 method: 'POST'
        //             })
        //             let countries = await res.data
        //             countries.forEach(item => {
        //                 let isInArray = payload.find(elem => {
        //                     return elem.id == item.id
        //                 })
        //                 if (!isInArray) payload.push(item)
        //             })
        //         }
        //
        //         ctx.commit('updateServicesCountriesTo', payload)
        //     } catch (e) {
        //         console.log('Ошибка в actions fetchServicesCountryTo', e)
        //     }
        // },
        //Параметр categoryId теперь обязательный
        async fetchServicesCountriesTo(ctx, categoryId) {
            const url = variables.routeTourServices + '/' + categoryId + '/CountriesTo'
            console.log(url)
            try {
                let res = await axios({
                    url: url,
                    method: 'POST'
                })
                // Получение стран
                let countries = await res.data
                ctx.commit('updateServicesCountriesTo', countries)
                return countries
            } catch (e) {
                console.log('Ошибка в actions fetchServicesCountryTo', e)
            }
        },
        //Старый метод использовался для поиска городов по множеству категорий. Можно удалялять после проверки нового
        // async fetchServicesCitiesTo(ctx, params) {
        //     // let params = {
        //     //     countryId: null,
        //     //     categoryId: null
        //     // }
        //     if (!params.countryId) return
        //     //let url = variables.routeTourServices + '/1/CitiesTo/' + countryId
        //     await ctx.dispatch('fetchServicesCategories')
        //     let url = variables.routeTourServices
        //     try {
        //         //Формирование массива ссылок для обхода
        //         let urls = []
        //         switch (params.categoryId) {
        //             case 1:
        //                 urls.push(url + '/1/CitiesTo/' + params.countryId)
        //                 break
        //             case 2:
        //                 urls.push(url + '/2/CitiesTo/' + params.countryId)
        //                 break
        //             default:
        //                 let categories = [...ctx.state.categoryServices]
        //                 //Удаление не нужных категорий
        //                 let transfer = categories.find(item => {
        //                     return item.code == "Transfer"
        //                 })
        //                 let excursion = categories.find(item => {
        //                     return item.code == "Excursion"
        //                 })
        //                 categories.splice(categories.indexOf(transfer), 1)
        //                 categories.splice(categories.indexOf(excursion), 1)
        //                 categories.forEach(item => {
        //                     urls.push(url + '/' + item.id + '/CitiesTo/' + params.countryId)
        //                 })
        //         }
        //         //Получение городов
        //         let payload = []
        //         for (let key in urls) {
        //             let res = await axios({
        //                 url: urls[key],
        //                 method: 'POST'
        //             })
        //             let cities = await res.data
        //             cities.forEach(item => {
        //                 let isInArray = payload.find(elem => {
        //                     return elem.id == item.id
        //                 })
        //                 if (!isInArray) {
        //                     payload.push(item)
        //                 }
        //             })
        //         }
        //         ctx.commit('updateServicesCities', payload)
        //
        //     } catch (e) {
        //         console.log('Ошибка в actions fetchServicesCitiesTo', e)
        //     }
        // },
        async fetchServicesCitiesTo(ctx, params) {
            // let params = {
            //     countryId: null,
            //     categoryId: null
            // }
            let result = []
            if (!params.countryId || !params.categoryId) return
            //let url = variables.routeTourServices + '/1/CitiesTo/' + countryId
            let url = variables.routeTourServices + '/' + params.categoryId + '/CitiesTo/' + params.countryId
            try {
                let res = await axios({
                    url,
                    method: 'POST'
                })

                if(Array.isArray(await res.data)){
                    result = await res.data
                }

                ctx.commit('updateServicesCities', result)
                return result

            } catch (e) {
                console.log('Ошибка в actions fetchServicesCitiesTo', e)
            }
        },
        async fetchServicesSources(ctx, countryId) {
            if (!countryId) return
            let url = variables.routeTourServices + '/transfer/sources/' + countryId
            try {
                let res = await axios({
                    url,
                    method: 'POST'
                })
                ctx.commit('updateServicesSources', await res.data)
            } catch (e) {
                console.log('Ошибка в actions fetchTransferSources', e)
            }
        },
        async fetchTransferTargets(ctx, payload) {
            // let payload = {
            //     countryId: null,
            //     sourceId: null,
            // }
            if (!payload.countryId) return
            let url = variables.routeTourServices + '/transfer/targets/' + payload.countryId + '/' + payload.sourceId
            try {
                let res = await axios({
                    url,
                    method: 'POST'
                })
                ctx.commit('updateTransferTargets', await res.data)
            } catch (e) {
                console.log('Ошибка в actions fetchTransferTargets', e)
            }
        },
        async fetchTransferSearch(ctx, params) {
            let url = variables.routeTourServices + '/search'

            try {
                let res = await axios({
                    url,
                    method: 'POST',
                    data: params
                })

                let transfers = []

                let data = await res.data
                if (Array.isArray(data)) {
                    data.forEach(item => {
                        transfers.push(item)
                    })
                }

                let travellerPayload = {
                    adults: params.adults,
                    children: params.childrens.length
                }
                ctx.commit('updateServicesTravellers', travellerPayload)

                ctx.commit('updateTransferServicesLoad', true)
                return transfers
            } catch (e) {
                console.log('Ошибка в actions fetchTransferSearch', e)
            }
        },
        //на определенную дату без записи в state
        async fetchTransferSearchWithDate(ctx, params) {
            let url = variables.routeTourServices + '/search'
            await ctx.dispatch('fetchServicesCategories')
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                    data: params
                })

                let payload = []
                let categories = [...ctx.state.categoryServices]
                let transfer = await res.data
                transfer.forEach(item => {
                    let category = categories.find(elem => {
                        return elem.id == 1
                    })
                    item.category = category
                    item.searchParamsSamo = params
                    payload.push(item)
                })
                return payload
            } catch (e) {
                console.log('Ошибка в actions fetchTransferSearch', e)
            }
        },
        async fetchExcursionSearch(ctx, params) {
            ctx.state.preloader = true;
            let url = variables.routeTourServices + '/search'
            await ctx.dispatch('fetchServicesCategories')
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                    data: params
                })
                let payload = []
                let categories = [...ctx.state.categoryServices]
                let excursion = await res.data
                if (Array.isArray(excursion)) {
                    excursion.forEach(item => {
                        let category = categories.find(elem => {
                            return elem.id == 2
                        })
                        item.category = category
                        payload.push(item)
                    })
                }
                //console.log(params)
                let travellerPayload = {
                    adults: params.adults,
                    children: params.childrens.length
                }
                ctx.commit('updateServicesTravellers', travellerPayload)
                ctx.commit('updateExcursionServices', payload)
                ctx.commit('updateExcursionServicesLoad', true)
                ctx.state.preloader = false
                return payload
            } catch (e) {
                console.log('Ошибка в actions fetchExcursionSearch', e)
            }
        },
        async fetchOtherServices(ctx, params) {
            console.log('start fetch other services')
            // console.log('params', params)
            let result = []
            let url = variables.routeTourServices + '/search'
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                    params,
                })

                let categories = await ctx.dispatch('fetchServicesCategories')

                let category = categories.find(item => item.id === params.categoryCode)

                let countries = await ctx.dispatch('fetchServicesCountriesTo', category.id)
                let country = countries.find(item=>item.id===params.countryId)

                if(Array.isArray(await res.data)){
                    result = await res.data
                    result.forEach(item => {
                        item.category = category
                        item.country = country
                    })
                }

                let travellerPayload = {
                    adults: params.adults,
                    children: params.childrens.length
                }

                ctx.commit('updateServicesTravellers', travellerPayload)
                ctx.commit('updateOtherServices', result)
                ctx.commit('updateOtherServicesLoad', true)
                return result
            } catch (e) {
                console.log('Ошибка в actions fetchOtherServices', e)
            }
        },
        async fetchServicesCategories(ctx) {
            let url = variables.routeTourServices + '/categories'
            let result = []
            try {
                let res = await axios({
                    url,
                    method: 'POST'
                })

                if(Array.isArray(await res.data)){
                    ctx.commit('updateCategoryServices', await res.data)
                    result = await res.data
                }
                return result
            } catch (e) {
                console.log('Ошибка в actions fetchServicesCategories', e)
            }
        },
        async fetchGetCustomServices(ctx) {
            let url = variables.routeTourServices + '/get-custom-services'
            //try {
                let res = await axios({
                    url,
                    method: 'POST',
                })
                return await res.data
            // } catch (e) {
            //     console.log('Ошибка в actions fetchGetCustomServices', e)
            // }
        },
    },
    state: {
        servicesCountriesTo: [],
        servicesSources: [],
        servicesTargets: [],
        servicesCities: [],
        transferServices: [],
        transferServicesLoad: false,
        excursionServices: [],
        excursionServicesLoad: false,
        otherServices: [],
        otherServicesLoad: false,
        categoryServices: [],
        transferSearchParams: {
            countryName: null,
            sourceName: null,
            sourceId: null,
            targetId: null,
            targetName: null,
            startDate: null,
            endDate: null,
            attribute: null,
            addressFrom: null,
            addressTo: null,
        },
        adults: 0,
        children: 0,
        preloader: false,
    },
    mutations: {
        updateCategoryServices(state, payload) {
            let result = []
            try {
                payload.forEach(item => {
                    let category = {
                        id: null,
                        code: null,
                        name: null,
                    }
                    category.id = item.id
                    category.name = item.name
                    category.code = item.code
                    result.push(category)
                })
                state.categoryServices = result
            } catch (e) {
                console.log('Ошибка в мутации updateCategoryServices', e)
            }
        },
        updateServicesCountriesTo(state, payload) {
            let transferCountryTo = []
            try {
                payload.forEach(item => {
                    let country = {
                        id: null,
                        name: null,
                        stateISO: null,
                    }
                    country.id = item.id
                    country.name = item.name
                    country.stateISO = item.stateISO
                    transferCountryTo.push(country)
                })
                //сортируем по алфавиту
                transferCountryTo.sort(function (a, b) {
                    let nameA = a.name.toLowerCase()
                    let nameB = b.name.toLowerCase()
                    if (nameA < nameB)
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0
                })
                state.servicesCountriesTo = transferCountryTo
            } catch (e) {
                console.log('Ошибка в мутации updateServicesCountryTo', e)
            }
        },
        updateServicesCities(state, payload) {
            let result = []
            try {
                payload.forEach(item => {
                    let city = {
                        id: null,
                        name: null,
                    }
                    city.id = item.id
                    city.name = item.name
                    // if(item.id > 0) result.push(city)
                    result.push(city)
                })
                //сортируем по алфавиту
                result.sort(function (a, b) {
                    let nameA = a.name.toLowerCase()
                    let nameB = b.name.toLowerCase()
                    if (nameA < nameB)
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0
                })
                //Помещаем "все" на первое место
                let all = result.findIndex(function (item) {
                    return item.name === 'Все'
                })
                let allItem = result.splice(all, 1)
                result.unshift(allItem[0])

                state.servicesCities = result
            } catch (e) {
                console.log('Ошибка в мутации updateServicesCities', e)
            }
        },
        updateServicesSources(state, payload) {
            let result = []
            try {
                payload.forEach(item => {
                    let source = {
                        id: null,
                        name: null,
                    }
                    source.id = item.id
                    source.name = item.name
                    result.push(source)
                })
                state.servicesSources = result
            } catch (e) {
                console.log('Ошибка в мутации updateServicesSources', e)
            }
        },
        updateTransferTargets(state, payload) {
            let result = []
            try {
                payload.forEach(item => {
                    let target = {
                        id: null,
                        name: null,
                    }
                    target.id = item.id
                    target.name = item.name
                    result.push(target)
                })
                state.servicesTargets = result
            } catch (e) {
                console.log('Ошибка в мутации updateServicesTargets', e)
            }
        },
        updateTransferServices(state, payload) {
            let result = []
            try {
                payload.forEach(item => {
                    let service = {
                        id: null,
                        name: null,
                        photoUrls: null,
                        shortDescription: null,
                        city: {
                            id: null,
                            name: null,
                            englishName: null
                        },
                        paxes: {
                            maxPeopleCount: null,
                            minPeopleCount: null,
                        },
                        category: {
                            id: null,
                            code: null,
                            name: null,
                        },
                        price: {
                            currencyAlias: null,
                            currencyId: null,
                            value: null,
                        },
                        searchParamsSamo: null,
                        serviceKey: null,
                        startDate: null,
                        endDate: null,
                        transferType: 'samotour',
                    }
                    service.id = item.id
                    service.name = item.name
                    service.photoUrls = item.photoUrls
                    service.shortDescription = helpers.clearHtml(item.shortDescription)
                    service.city.id = item.city.id
                    service.city.name = item.city.name
                    service.city.englishName = item.city.englishName
                    // service.paxes.maxPeopleCount = item.paxes.maxPeopleCount
                    service.paxes.maxPeopleCount = 2
                    service.paxes.minPeopleCount = item.paxes.minPeopleCount
                    service.price.currencyAlias = item.price.currencyAlias
                    service.price.currencyId = item.price.currencyId
                    service.price.value = item.price.value
                    service.serviceKey = item.serviceKey
                    service.startDate = item.startDate
                    service.endDate = item.endDate
                    service.category.id = item.category.id
                    service.category.code = item.category.code
                    service.category.name = item.category.name
                    service.searchParamsSamo = item.searchParamsSamo
                    result.push(service)
                })
                state.transferServices = result
            } catch (e) {
                console.log('Ошибка в мутации updateTransferServices', e)
            }
        },
        updateExcursionServices(state, payload) {
            let result = []
            try {
                payload.forEach(item => {
                    let service = {
                        id: null,
                        name: null,
                        photoUrls: null,
                        shortDescription: null,
                        city: {
                            id: null,
                            name: null,
                            englishName: null
                        },
                        country: {
                            id: null,
                            name: null,
                            englishName: null
                        },
                        category: {
                            id: null,
                            code: null,
                            name: null,
                        },
                        paxes: {
                            maxPeopleCount: 4,
                            minPeopleCount: 1,
                        },
                        price: {
                            currencyAlias: null,
                            currencyId: null,
                            value: null,
                        },
                        serviceKey: null,
                        startDate: null,
                        endDate: null,
                    }
                    service.id = item.id
                    service.name = item.name
                    service.photoUrls = item.photoUrls
                    service.shortDescription = helpers.clearHtml(item.shortDescription)
                    service.city.id = item.city.id
                    service.city.name = item.city.name
                    service.city.englishName = item.city.englishName
                    service.country.id = item.country.id
                    service.country.name = item.country.name
                    service.country.englishName = item.country.englishName
                    service.paxes.maxPeopleCount = item.paxes.maxPeopleCount
                    service.paxes.minPeopleCount = item.paxes.minPeopleCount
                    service.price.currencyAlias = item.price.currencyAlias
                    service.price.currencyId = item.price.currencyId
                    service.price.value = item.price.value
                    service.serviceKey = item.serviceKey
                    service.startDate = item.startDate
                    service.endDate = item.endDate
                    service.category.id = item.category.id
                    service.category.code = item.category.code
                    service.category.name = item.category.name
                    result.push(service)
                })
                state.excursionServices = result
            } catch (e) {
                console.log('Ошибка в мутации updateTransferServices', e)
            }
        },
        updateOtherServices(state, payload) {
            let result = []
            try {
                payload.forEach(item => {
                    let service = {
                        id: null,
                        name: null,
                        photoUrls: null,
                        shortDescription: null,
                        category: {
                            id: null,
                            code: null,
                            name: null,
                        },
                        country: {
                            id: null,
                            code: null,
                            name: null,
                        },
                        city: {
                            id: null,
                            name: null,
                            englishName: null
                        },
                        paxes: {
                            maxPeopleCount: 4,
                            minPeopleCount: 1,
                        },
                        price: {
                            currencyAlias: null,
                            currencyId: null,
                            value: null,
                        },
                        serviceKey: null,
                        startDate: null,
                        endDate: null,
                    }
                    service.category.id = item.category.id
                    service.category.code = item.category.code
                    service.category.name = item.category.name
                    service.id = item.id
                    service.name = item.name
                    service.photoUrls = item.photoUrls
                    service.shortDescription = helpers.clearHtml(item.shortDescription)
                    service.country.id = item.country.id
                    service.country.name = item.country.name
                    service.country.code = item.country.code
                    service.city.id = item.city.id
                    service.city.name = item.city.name
                    service.city.englishName = item.city.englishName
                    service.paxes.maxPeopleCount = item.paxes.maxPeopleCount
                    service.paxes.minPeopleCount = item.paxes.minPeopleCount
                    service.price.currencyAlias = item.price.currencyAlias
                    service.price.currencyId = item.price.currencyId
                    service.price.value = item.price.value
                    service.serviceKey = item.serviceKey
                    service.startDate = item.startDate
                    service.endDate = item.endDate
                    result.push(service)
                })
                state.otherServices = result
            } catch (e) {
                console.log('Ошибка в мутации updateOtherServices', e)
            }
        },
        updateExcursionServicesLoad(state, payload) {
            state.excursionServicesLoad = payload
        },
        updateOtherServicesLoad(state, payload) {
            state.otherServicesLoad = payload
        },
        updateTransferServicesLoad(state, payload) {
            state.transferServicesLoad = payload
        },
        updateServicesTravellers(state, payload) {
            state.adults = payload.adults
            state.children = payload.children
        },
    },
    getters: {
        getServicesCountriesTo: state => {
            return state.servicesCountriesTo
        },
        getServicesCities: state => {
            return state.servicesCities
        },
        getServicesSources: state => {
            return state.servicesSources
        },
        getServicesTargets: state => {
            return state.servicesTargets
        },
        getTransferServices: state => {
            return state.transferServices
        },
        getExcursionServices: state => {
            return state.excursionServices
        },
        getOtherServices: state => {
            return state.otherServices
        },
        setTransferSearchParams: state => params => {
            state.transferSearchParams = params
        },
        getTransferSearchParams: state => {
            return state.transferSearchParams
        },
        getExcursionServicesLoad: state => {
            return state.excursionServicesLoad
        },
        getOtherServicesLoad: state => {
            return state.otherServicesLoad
        },
        getTransferServicesLoad: state => {
            return state.transferServicesLoad
        },
        getServicesAdults: state => {
            return state.adults
        },
        getServicesChildren: state => {
            return state.children
        },
        getServicesPreloaderStatus: state => {
            return state.preloader
        },
    }
}
