var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("header"),
      _vm._v(" "),
      _vm.show ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      _vm._t("footer"),
      _vm._v(" "),
      !_vm.$nopopup
        ? _c("android-app-popup", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.androidPopupShow,
                expression: "androidPopupShow",
              },
            ],
            on: {
              open: function ($event) {
                _vm.androidPopupShow = true
              },
              close: function ($event) {
                _vm.androidPopupShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ScalableVectorGraphics"),
      _vm._v(" "),
      !_vm.$nopopup ? _c("cookie-notification") : _vm._e(),
      _vm._v(" "),
      _vm.finishLoad ? _c("div", { attrs: { id: "finishload" } }) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }