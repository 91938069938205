import variables from "../../plugins/variables";
import axios from 'axios';
export default {
    actions: {
        async fetchAddItemFavorite(ctx, object){
            const url = variables.routeAddWishList;
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: object,
                    validateStatus: function (status) {
                        return status < 500;
                    }
                });

                if (await res.status === 200) {
                    ctx.commit('updateAddItemFavorite', object)
                    return res.status;
                } else {
                    console.log(await res.data.validationErrors);
                }
            }catch(e){
                console.log('Ошибка в actions fetchAddItemFavorite', e)
            }
        },
        async fetchRemoveItemFavorite(ctx, object){
            const url = variables.routeRemoveWishList+'/'+object.objectKey;
            try{
                let res = await axios({
                    url,
                    method: 'DELETE',
                    data: object,
                    validateStatus: function (status) {
                        return status < 500;
                    }
                });

                if (await res.status === 200) {
                    ctx.commit('updateDeleteItemFavorite', object)
                    return res.status;
                } else {
                    console.log(await res.data.validationErrors);
                }
            }catch(e){
                console.log('Ошибка в actions fetchRemoveItemFavorite', e)
            }
        },
        async fetchIndexHotelFavorite(ctx){
            const url = variables.routeIndexWishList;
            try{
                let res = await axios({
                    url,
                    method: 'GET'
                });
                const list = await res.data;
                ctx.commit('updateWishList', list);
                return list;
            }catch(e){
                console.log('Ошибка в actions fetchIndexHotelFavorite', e)
            }
        },
    },
    mutations: {
        updateWishList(state, date) {
            state.wishList = date;
        },
        updateAddItemFavorite(state, object){
            state.wishList.push(object)
        },
        updateDeleteItemFavorite(state, object){
            let index = state.wishList.findIndex(favorite => favorite.objectTypeId === object.objectTypeId && favorite.objectKey === String(object.objectKey))
            if (index !== -1) {
                state.wishList.splice(index, 1)
            }
        }
    },
    state: {
        wishList: [],
    },
    getters: {
        getWishList(state) {
            let arrWishlist = []
            state.wishList.forEach(item => {
                if(item.objectTypeId !== 3) {
                    arrWishlist.push(item)
                }
            })
            return arrWishlist
        },
        isInFavorite:state=>key=>{
            let result = false
            if (state.wishList.length){
                let index = state.wishList.findIndex(favorite=> favorite.objectKey === String(key))
                if (index !== -1){
                    result = true
                }
            }
            return result
        }
    }
}
