export default {
    'Skeleton':()=>import(/* webpackChunkName: "Skeleton" */'./components/elements/skeletonStaticBlocks'),

    'vueHeader': ()=>import(/* webpackChunkName: "vueHeader" */'./components/elements/topMenu'),
    'vueHeaderNew': ()=>import(/* webpackChunkName: "vueHeader" */'./components/elements/topMenu/indexNew'),

    'oldVueHeader': ()=>import(/* webpackChunkName: "oldVueHeader" */'./components/elements/OldTopMenu'),

    'vueFooter': ()=>import(/* webpackChunkName: "vueFooter" */'./components/elements/footer/footerNew'),

    // 'oldVueFooter': ()=>import(/* webpackChunkName: "oldVueFooter" */'./components/elements/oldFooter'),

    'topBasket': ()=>import(/* webpackChunkName: "topBasket" */'./components/elements/topMenu/topBasket'),

    'topAcount': ()=>import(/* webpackChunkName: "topAcount" */'./components/elements/auth/topAccount'),

    'tourOrderPopup': ()=>import(/* webpackChunkName: "tourOrderPopup" */'./components/elements/topMenu/tourOrderPopup'),

    'searchMenu': ()=>import(/* webpackChunkName: "searchMenu" */'./components/elements/search/searchMenu'),
    'searchMenuNew': ()=>import(/* webpackChunkName: "searchMenu" */'./components/elements/search/searchMenuNew'),
    'staticMainBannerMobile': ()=>import(/* webpackChunkName: "staticMainBannerMobile" */'./components/elements/staticBlocks/bannersBlocks/staticMainBannerMobile'),
    'searchTour': ()=>import(/* webpackChunkName: "searchTour" */'./components/pages/search'),
    'searchTourNew': ()=>import(/* webpackChunkName: "searchTourNew" */'./components/pages/searchNew'),
    //searchTour2 - новая поисковая выдача подключается через этот компонент
    'searchTour2': ()=>import(/* webpackChunkName: "searchTour2" */'./components/pages/searchNew2'),
    'searchHotel2': ()=>import(/* webpackChunkName: "searchHotel2" */'./components/pages/hotelNew2'),
    'searchTourRussia': ()=>import(/* webpackChunkName: "searchTourRussia" */'./components/pages/searchTourRussia'),

    'searchWeekend': ()=>import(/* webpackChunkName: "searchWeekend" */'./components/pages/searchWeekend'),
    //TODO подвешивает пересборку из за плагина
    'searchExcursionTours': ()=>import(/* webpackChunkName: "searchExcursionTours" */'./components/pages/searchExcursionTours'),

    'searchHotTours': ()=>import(/* webpackChunkName: "searchHotTours" */'./components/pages/searchHotTours'),
    'searchAutoTour': ()=>import(/* webpackChunkName: "searchAutoTour" */'./components/pages/searchAutoTour'),

    'searchHotel': ()=>import(/* webpackChunkName: "searchHotel" */'./components/pages/searchHotel'),

    'searchHotelNew': ()=>import(/* webpackChunkName: "searchHotel" */'./components/pages/searchHotelNew'),

    'searchFlights': ()=>import(/* webpackChunkName: "searchFlights" */'./components/pages/searchFlights'),

    'searchConstructor': ()=>import(/* webpackChunkName: "searchConstructor" */'./components/pages/constructor'),

    'searchInsurance': ()=>import(/* webpackChunkName: "searchInsurance" */'./components/pages/searchInsurances'),

    'searchTransfers': ()=>import(/* webpackChunkName: "searchTransfers" */'./components/pages/searchTransfers'),

    'searchServices': ()=>import(/* webpackChunkName: "searchServices" */'./components/pages/searchServices'),

    'onBoardServices': () => import(/* webpackChunkName: "onBoardServices" */ "./components/pages/onBoardServices"),

    'weddingCeremonies': () => import(/* webpackChunkName: "weddingCeremonies" */ "./components/pages/weddingCeremonies"),

    'certificates': () => import(/* webpackChunkName: "certificates" */ "./components/pages/certificates"),

    'aeroexpress': ()=>import(/* webpackChunkName: "aeroexpress" */'./components/pages/aeroexpress'),

    'searchExcursions': ()=>import(/* webpackChunkName: "searchExcursions" */'./components/pages/searchExcursions'),

    'tuiPremium': ()=>import(/* webpackChunkName: "tuiPremium" */'./components/pages/tuiPremium'),

    'searchCruises': ()=>import(/* webpackChunkName: "searchCruises" */'./components/pages/searchCruises'),

    'customer':()=>import(/* webpackChunkName: "customer" */'./components/pages/cabinet/customer'),

    'agent': ()=>import(/* webpackChunkName: "agent" */'./components/pages/cabinet/agent'),

    'agentEducation': ()=>import(/* webpackChunkName: "agentEducation" */'./components/pages/agentEducation'),

    'partner': ()=>import(/* webpackChunkName: "partner" */'./components/pages/cabinet/partner'),

    'moderator': ()=>import(/* webpackChunkName: "moderator" */'./components/pages/cabinet/moderator'),

     'basket': ()=>import(/* webpackChunkName: "basket" */'./components/pages/basket'),

     'wishlist': ()=>import(/* webpackChunkName: "wishlist" */'./components/elements/cabinet/customer/tabs/wishListTab'),

     'basketPayment': ()=>import(/* webpackChunkName: "basketPayment" */'./components/pages/basketPayment'),

     'bookingPayment': ()=>import(/* webpackChunkName: "bookingPayment" */'./components/pages/bookingPayment'),

     'hotel': ()=>import(/* webpackChunkName: "hotel" */'./components/pages/hotel'),

     'excursion': ()=>import(/* webpackChunkName: "excursion" */'./components/pages/excursion'),

     'touristsUpdate': ()=>import(/* webpackChunkName: "touristsUpdate" */'./components/pages/touristsUpdate'),

     'abandonedBasket': ()=>import(/* webpackChunkName: "abandonedBasket" */'./components/pages/abandonedBasket'),

     // 'staticBlockPage': ()=>import(/* webpackChunkName: "staticBlockPage" */'./components/elements/staticBlocks/staticBlocksPage'),

    'staticH1': ()=>import(/* webpackChunkName: "staticH1" */ "./components/elements/staticBlocks/textBlocks/staticH1"),

    'staticSpoiler': ()=>import(/* webpackChunkName: "staticSpoiler" */ "./components/elements/staticBlocks/textBlocks/staticSpoiler"),

    'staticText': ()=>import(/* webpackChunkName: "staticText" */ "./components/elements/staticBlocks/textBlocks/staticText"),

    'staticTextShadow': ()=>import(/* webpackChunkName: "staticTextShadow" */ "./components/elements/staticBlocks/textBlocks/staticTextShadow"),

    'staticImg3': ()=>import(/* webpackChunkName: "staticImg3" */ "./components/elements/staticBlocks/imageBlocks/staticImg3"),

    'staticImg4': ()=>import(/* webpackChunkName: "staticImg4" */ "./components/elements/staticBlocks/imageBlocks/staticImg4"),

    'staticImgFrom1To4': ()=>import(/* webpackChunkName: "staticImgFrom1To4" */ "./components/elements/staticBlocks/imageBlocks/imageFromOneToFour/staticImgFrom1To4"),

    'staticImgFrom1To4New': ()=>import(/* webpackChunkName: "staticImgFrom1To4New" */ "./components/elements/staticBlocks/imageBlocks/imageFromOneToFour/staticImgFrom1To4New"),

    //TODO подвешивает пересборку из за плагина vue-concise-slider
    'staticPhotoTiles': ()=>import(/* webpackChunkName: "staticPhotoTiles" */ "./components/elements/staticBlocks/imageBlocks/staticPhotoTiles/staticPhotoTiles"),

    'generationListsTables': ()=>import(/* webpackChunkName: "generationListsTables" */ "./components/elements/staticBlocks/generationListsTables/generationListsTables"),

    'staticPromoCode': ()=>import(/* webpackChunkName: "staticPromoCode" */ "./components/elements/staticBlocks/staticPromoCode"),

    'staticHotelListCol1': ()=>import(/* webpackChunkName: "staticHotelListCol1" */ "./components/elements/staticBlocks/hotelBlocks/staticHotelListCol1"),

    'staticHotelListCol2': ()=>import(/* webpackChunkName: "staticHotelListCol2" */ "./components/elements/staticBlocks/hotelBlocks/staticHotelListCol2"),

    'staticHotelListCol3': ()=>import(/* webpackChunkName: "staticHotelListCol3" */ "./components/elements/staticBlocks/hotelBlocks/staticHotelListCol3"),

    'staticHotelCheckList': ()=>import(/* webpackChunkName: "staticHotelCheckList" */ "./components/elements/staticBlocks/hotelBlocks/staticHotelCheckList"),

    'staticHotelMap': ()=>import(/* webpackChunkName: "staticHotelMap" */ "./components/elements/staticBlocks/hotelBlocks/staticHotelMap"),

    'staticCountryMap': ()=>import(/* webpackChunkName: "staticCountryMap" */ "./components/elements/staticBlocks/staticCountryMap"),

    'staticTable': ()=>import(/* webpackChunkName: "staticTable" */ "./components/elements/staticBlocks/tableBlocks/staticTable"),

    'staticTableSort': ()=>import(/* webpackChunkName: "staticTableSort" */ "./components/elements/staticBlocks/tableBlocks/staticTableSort"),

    'staticButton': ()=>import(/* webpackChunkName: "staticButton" */ "./components/elements/staticBlocks/staticButton"),

    'staticConceptions': ()=>import(/* webpackChunkName: "staticConceptions" */ "./components/elements/staticBlocks/conception/staticConceptions"),

    'staticMarket': ()=>import(/* webpackChunkName: "staticMarket" */ "./components/elements/search/main/tourSearchMainMarket"),

    'aboutBlock': ()=>import(/* webpackChunkName: "aboutBlock" */ "./components/elements/staticBlocks/others/aboutBlock"),

    'directions': ()=>import(/* webpackChunkName: "directions" */ "./components/elements/staticBlocks/directions"),

    'directionsNotBanner': ()=>import(/* webpackChunkName: "directionsNotBanner" */ "./components/elements/staticBlocks/directionsNotBanner"),

    'leisureFormat': ()=>import(/* webpackChunkName: "leisureFormat" */ "./components/elements/staticBlocks/leisureFormat"),

    'helpInSelection': ()=>import(/* webpackChunkName: "helpInSelection" */ "./components/elements/staticBlocks/helpInSelection"),

    'hotelWithConcept': ()=>import(/* webpackChunkName: "hotelWithConcept" */ "./components/elements/staticBlocks/hotelWithConcept"),

    'diceWithOffers': ()=>import(/* webpackChunkName: "diceWithOffers" */ "./components/elements/staticBlocks/diceWithOffers"),

    'selectionOfTours': ()=>import(/* webpackChunkName: "selectionOfTours" */ "./components/elements/staticBlocks/selectionOfTours"),
    'selectionOfHotels': ()=>import(/* webpackChunkName: "selectionOfHotels" */ "./components/elements/staticBlocks/selectionOfHotels"),


    'selectionOfExcursionTours': ()=>import(/* webpackChunkName: "selectionOfExcursionTours" */ "./components/elements/staticBlocks/selectionOfExcursionTours"),
    'excursionInPopularPlaces': ()=>import(/* webpackChunkName: "excursionInPopularPlaces" */ "./components/elements/staticBlocks/excursionInPopularPlaces"),
    'excursionByTags': ()=>import(/* webpackChunkName: "excursionByTags" */ "./components/elements/staticBlocks/excursionByTags"),
    'excursionPopular': ()=>import(/* webpackChunkName: "excursionPopular" */ "./components/elements/staticBlocks/excursionPopular"),
    'excursionBanner': ()=>import(/* webpackChunkName: "excursionBanner" */ "./components/elements/staticBlocks/excursionBanner"),

    'simpleSelectionOfTours': ()=>import(/* webpackChunkName: "simpleSelectionOfTours" */ "./components/elements/staticBlocks/simpleSelectionOfTours"),
    'hotTourDay': ()=>import(/* webpackChunkName: "hotTourDay" */ "./components/elements/staticBlocks/hotTourDay"),
    'hotelOfTheDay': ()=>import(/* webpackChunkName: "hotelOfTheDay" */ "./components/elements/staticBlocks/hotelOfTheDay"),

    'hotelsInDifferentCountries': ()=>import(/* webpackChunkName: "hotelsInDifferentCountries" */ "./components/elements/staticBlocks/hotelsInDifferentCountries"),
    'hotelsInPopularPlaces': ()=>import(/* webpackChunkName: "hotelsInPopularPlaces" */ "./components/elements/staticBlocks/hotelsInPopularPlaces"),
    'hotelsInRussianCities': ()=>import(/* webpackChunkName: "hotelsInRussianCities" */ "./components/elements/staticBlocks/hotelsInRussianCities"),
    'hotelsPopularAccommodation': ()=>import(/* webpackChunkName: "hotelsPopularAccommodation" */ "./components/elements/staticBlocks/hotelsPopularAccommodation"),
    'hotelsWithConcepts': ()=>import(/* webpackChunkName: "hotelsWithConcepts" */ "./components/elements/staticBlocks/hotelsWithConcepts"),

    'exclusiveOffer': ()=>import(/* webpackChunkName: "exclusiveOffer" */ "./components/elements/staticBlocks/exclusiveOffer"),

    'popularCountries': ()=>import(/* webpackChunkName: "popularCountries" */ "./components/elements/staticBlocks/popularCountries"),

    'aboutHotTours': ()=>import(/* webpackChunkName: "aboutHotTours" */ "./components/elements/staticBlocks/aboutHotTours"),

    'advantages': ()=>import(/* webpackChunkName: "advantages" */ "./components/elements/staticBlocks/advantages"),

    'advantagesOnlineNew': ()=>import(/* webpackChunkName: "advantagesOnlineNew" */ "./components/elements/staticBlocks/advantagesOnlineNew"),

    'openedDestinations': ()=>import(/* webpackChunkName: "openedDestinations" */ "./components/elements/staticBlocks/openedDestinations"),

    'middleBanner': ()=>import(/* webpackChunkName: "middleBanner" */ "./components/elements/staticBlocks/middleBanner"),

    'staticBannerWall': ()=>import(/* webpackChunkName: "staticBannerWall" */ "./components/elements/staticBlocks/bannersBlocks/staticBannerWall"),

    'staticBannerCard': ()=>import(/* webpackChunkName: "staticBannerCard" */ "./components/elements/staticBlocks/bannersBlocks/staticBannerCard"),

    'staticBannerHorFull': ()=>import(/* webpackChunkName: "staticBannerHorFull" */ "./components/elements/staticBlocks/bannersBlocks/staticBannerHorFull"),

    'staticFrame': ()=>import(/* webpackChunkName: "staticFrame" */ "./components/elements/staticBlocks/staticFrame"),

    'readyGuides': ()=>import(/* webpackChunkName: "readyGuides" */ "./components/elements/staticBlocks/readyGuides"),

    'tripRecipe': ()=>import(/* webpackChunkName: "tripRecipe" */ "./components/elements/staticBlocks/tripRecipe"),

    'popularDestinationGuide': ()=>import(/* webpackChunkName: "popularDestinationGuide" */ "./components/elements/staticBlocks/popularDestinationGuide"),

    'counters': ()=>import(/* webpackChunkName: "counters" */ "./components/elements/staticBlocks/counters"),

    'Marketplace': ()=>import(/* webpackChunkName: "Marketplace" */ "./components/pages/marketplace/marketplace"),

    'MarketplaceProduct': ()=>import(/* webpackChunkName: "MarketplaceProduct" */ "./components/pages/marketplace/product/marketplaceProduct"),

    'MarketplaceSellers': ()=>import(/* webpackChunkName: "MarketplaceSellers" */ "./components/pages/marketplace/marketplaceSellers"),

    'marketplaceSearchLine': ()=>import(/* webpackChunkName: "marketplaceSearchLine" */ "./components/elements/marketplace/marketplaceSearchLine"),

    'MarketplaceCategory': ()=>import(/* webpackChunkName: "MarketplaceCategory" */ "./components/pages/marketplace/marketplaceCategory"),

    'MarketplaceSearch': ()=>import(/* webpackChunkName: "MarketplaceSearch" */ "./components/pages/marketplace/marketplaceSearch"),

    'Error500': ()=>import(/* webpackChunkName: "Error500" */ "./components/pages/errors/error500"),

    'Error404': ()=>import(/* webpackChunkName: "Error404" */ "./components/pages/errors/error404"),

    'oldError404': ()=>import(/* webpackChunkName: "Error404" */ "./components/pages/errors/old/oldError404"),

    'Error403': ()=>import(/* webpackChunkName: "Error403" */ "./components/pages/errors/error403"),

    'tehnicalPreview': ()=>import(/* webpackChunkName: "tehnicalPreview" */ "./components/pages/errors/tehnicalPreview"),

    'payStatus': ()=>import(/* webpackChunkName: "payStatus" */ "./components/pages/payStatus"),

    'landingPage': ()=>import(/* webpackChunkName: "landingPage" */ "./components/elements/landing/landingPage"),

    'landingHotelListMap': ()=>import(/* webpackChunkName: "landingHotelListMap" */ "./components/elements/landing/landingHotelListMap"),

    'landingResortList': ()=>import(/* webpackChunkName: "landingResortList" */ "./components/elements/landing/landingResortList"),

    'landingCarousel': ()=>import(/* webpackChunkName: "landingCarousel" */ "./components/elements/landing/landingCarousel"),

    'landingHotelRecomendedHotels': ()=>import(/* webpackChunkName: "landingHotelRecomendedHotels" */ "./components/elements/landing/landingRecomendedHotels"),

    'news': ()=>import(/* webpackChunkName: "news" */ "./components/pages/newsActions/news"),

    'oneNews': ()=>import(/* webpackChunkName: "oneNews" */ "./components/pages/newsActions/one-news"),

    'actions': ()=>import(/* webpackChunkName: "actions" */ "./components/pages/newsActions/actions"),

    'innovations': ()=>import(/* webpackChunkName: "innovations" */ "./components/pages/innovations"),

    'partneram': ()=>import(/* webpackChunkName: "partneram" */ "./components/pages/partneram"),

    'loyalityLanding': ()=>import(/* webpackChunkName: "loyalityLanding" */ "./components/pages/loyalityLanding"),

    'touragentAppLanding': ()=>import(/* webpackChunkName: "touragentAppLanding" */ "./components/pages/touragentAppLanding"),

    'expertNewsSlider': ()=>import(/* webpackChunkName: "expertNewsSlider" */ "./components/elements/expert/newsSlider"),

    'newsTheme': ()=>import(/* webpackChunkName: "newsTheme" */ "./components/pages/newsActions/news-theme"),

    'oneAction': ()=>import(/* webpackChunkName: "oneAction" */ "./components/pages/newsActions/one-actions-or-company"),

    'offices': () => import(/* webpackChunkName: "offices" */ "./components/pages/offices/offices"),

    'oneOffice': () => import(/* webpackChunkName: "oneOffice" */ "./components/pages/offices/one-office"),

    'oneNewsCompany': ()=>import(/* webpackChunkName: "oneNewsCompany" */ "./components/pages/newsActions/one-actions-or-company"),

    'NewsCompany': ()=>import(/* webpackChunkName: "NewsCompany" */ "./components/pages/newsActions/news-company"),

    'surveyPage': () => import(/* webpackChunkName: "surveyPage" */ "./components/pages/survey"),

    'addReview': () => import(/* webpackChunkName: "addReview" */ "./components/pages/addReview"),

    'legalFeedback': () => import(/* webpackChunkName: "legalFeedback" */ "./components/pages/legalFeedback"),

    'securityFeedback': () => import(/* webpackChunkName: "securityFeedback" */ "./components/pages/securityFeedback"),

    'sliderMainBanner':()=>import(/* webpackChunkName: "SliderMainBanner" */"./components/elements/staticBlocks/bannersBlocks/sliderMainBanner"),

    'orderStatusCheck': () => import(/* webpackChunkName: "orderStatusCheck" */ "./components/pages/orderStatusCheck"),

    'weekendRegions': () => import(/* webpackChunkName: "weekendRegions" */ "./components/elements/search/data/weekendTour/weekendRegions"),

    'directionsPage': () => import(/* webpackChunkName: "directionsPage" */ "./components/pages/directions/directions"),

    'directionsVisa': () => import(/* webpackChunkName: "directionsVisa" */ "./components/pages/directions/directions-visa"),

    'directionsVisaCountry': () => import(/* webpackChunkName: "directionsVisaCountry" */ "./components/pages/directions/directions-visa-country"),

    'directionsVisaNoCountry': () => import(/* webpackChunkName: "directionsVisaNoCountry" */ "./components/pages/directions/directions-visano-country"),

    'autotour': () => import(/* webpackChunkName: "autotour" */ "./components/pages/autotour"),

    'adventCalendar': () => import(/* webpackChunkName: "adventCalendar" */ "./components/pages/adventCalendar"),

    'academy': () => import(/* webpackChunkName: "academy" */ "./components/pages/academy"),


    'academyWebinars': () => import(/* webpackChunkName: "academyWebinars" */ "./components/elements/academy/pages/academyWebinars"),

    'academyNews': () => import(/* webpackChunkName: "academyNews" */ "./components/elements/academy/pages/academyNews"),
    'methodicalManuals': () => import(/* webpackChunkName: "methodicalManuals" */ "./components/elements/academy/pages/methodicalManuals"),
    'photoReports': () => import(/* webpackChunkName: "photoReports" */ "./components/elements/academy/pages/photoReports"),
    'photoReportsPage': () => import(/* webpackChunkName: "photoReportsPage" */ "./components/elements/academy/pages/photoReportsPage"),
    'workshopOffice': () => import(/* webpackChunkName: "workshopOffice" */ "./components/elements/academy/pages/workshopOffice"),
    'businessBreakfast': () => import(/* webpackChunkName: "businessBreakfast" */ "./components/elements/academy/pages/businessBreakfast"),
    'thematicEvents': () => import(/* webpackChunkName: "thematicEvents" */ "./components/elements/academy/pages/thematicEvents"),
    'trainings': () => import(/* webpackChunkName: "trainings" */ "./components/elements/academy/pages/trainings"),
    'informationTours': () => import(/* webpackChunkName: "informationTours" */ "./components/elements/academy/pages/informationTours"),
    'recentWebinars': () => import(/* webpackChunkName: "recentWebinars" */ "./components/elements/academy/pages/recentWebinars"),

    'holidayTours': () => import(/* webpackChunkName: "holidayTours" */ "./components/elements/staticBlocks/holidayTours"),

    'emptyLines': () => import(/* webpackChunkName: "emptyLines" */ "./components/elements/staticBlocks/others/emptyLines"),

    'tilesPhotoText': () => import(/* webpackChunkName: "tilesPhotoText" */ "./components/elements/staticBlocks/tilesPhotoText"),

    'videoYoutube': () => import(/* webpackChunkName: "videoYoutube" */ "./components/elements/staticBlocks/videoYoutube"),

    'hotelSelectionBig': () => import(/* webpackChunkName: "hotelSelectionBig" */ "./components/elements/staticBlocks/hotelSelection/hotelSelectionBig"),

    'leadGeneration': () => import(/* webpackChunkName: "leadGeneration" */ "./components/pages/leadGeneration/leadGeneration"),

    'agentStatusCheck': () => import(/* webpackChunkName: "agentStatusCheck" */ "./components/pages/agentStatusCheck"),

    'funsunExpert': () => import(/* webpackChunkName: "funsunExpert" */ "./components/pages/funsunExpert"),

    'exchangeRates': () => import(/* webpackChunkName: "exchangeRates" */ "./components/pages/exchangeRates"),

    'hotelSelection': () => import(/* webpackChunkName: "hotelSelection" */ "./components/elements/staticBlocks/hotelSelection/hotelSelection"),

    'textBlock': () => import(/* webpackChunkName: "textBlock" */ "./components/elements/staticBlocks/textBlocks/textBlock"),

    'bannerFullWidth': () => import(/* webpackChunkName: "bannerFullWidth" */ "./components/elements/staticBlocks/bannersBlocks/bannerFullWidth"),

    'iconListPopup': () => import(/* webpackChunkName: "iconListPopup" */ "./components/elements/staticBlocks/iconListPopup"),

    'tabsSmallSixCards': () => import(/* webpackChunkName: "tabsSmallSixCards" */ "./components/elements/staticBlocks/tabsSmallSixCards"),

    'whyChoose': () => import(/* webpackChunkName: "whyChoose" */ "./components/elements/staticBlocks/whyChoose"),

    'feedbackForm': () => import(/* webpackChunkName: "feedbackForm" */ "./components/elements/staticBlocks/feedbackForm"),

    'subsVk': () => import(/* webpackChunkName: "subsVk" */ "./components/elements/staticBlocks/subsVk"),

    'searchConcepts': () => import(/* webpackChunkName: "searchConcepts" */'./components/pages/searchConcepts'),

    'mobileAppBanner': () => import(/* webpackChunkName: "mobileAppBanner" */'./components/elements/staticBlocks/mobileAppBanner'),

    'tilesPhotoTextBadge': () => import(/* webpackChunkName: "tilesPhotoTextBadge" */'./components/elements/staticBlocks/tilesPhotoTextBadge'),

    'tilesIcons': () => import(/* webpackChunkName: "tilesIcons" */'./components/elements/staticBlocks/tilesIcons'),

    'newsExpert': () => import(/* webpackChunkName: "newsExpert" */'./components/elements/staticBlocks/newsExpert'),

    'minPriceCountry': () => import(/* webpackChunkName: "minPriceCountry" */'./components/elements/staticBlocks/minPriceCountry'),

    'queryHotelTourSelection': () => import(/* webpackChunkName: "queryHotelTourSelection" */ "./components/elements/staticBlocks/queryHotelTourSelection"),

    'resortsSliderWithPrice': () => import(/* webpackChunkName: "resortsSliderWithPrice" */ "./components/elements/staticBlocks/resortsSliderWithPrice"),

    'blockWithButtons': () => import(/* webpackChunkName: "blockWithButtons" */ "./components/elements/staticBlocks/blockWithButtons"),

    'booking': () => import(/* webpackChunkName: "booking" */ "./components/pages/booking"),

    'bookingTTE': () => import(/* webpackChunkName: "bookingTTE" */ "./components/pages/bookingTTE"),

    'aeroexpressNew': () => import(/* webpackChunkName: "aeroexpressNew" */ "./components/pages/aeroexpressNew"),

    'hotelNew': () => import(/* webpackChunkName: "aeroexpressNew" */ "./components/pages/hotelNew"),

    'tilesPromoCodes': () => import(/* webpackChunkName: "tilesPromoCodes" */ "./components/elements/staticBlocks/tilesPromoCodes"),

    'blockDirections': () => import(/* webpackChunkName: "blockDirections" */ "./components/elements/staticBlocks/blockDirections/blockDirections"),

    'textWithBackground': () => import(/* webpackChunkName: "textWithBackground" */ "./components/elements/staticBlocks/textBlocks/textWithBackground"),

    'blockWithListLinks': () => import(/* webpackChunkName: "blockWithListLinks" */ "./components/elements/staticBlocks/textBlocks/blockWithListLinks"),

    'bigBannerWithWeather': () => import(/* webpackChunkName: "bigBannerWithWeather" */ "./components/elements/staticBlocks/bannersBlocks/bigBannerWithWeather"),

    'popupBanner': () => import(/* webpackChunkName: "popupBanner" */ "./components/elements/staticBlocks/bannersBlocks/popupBanner"),

    'blockWithActions': () => import(/* webpackChunkName: "blockWithActions" */ "./components/elements/staticBlocks/blockWithActions"),

    'blockDownloadFiles': () => import(/* webpackChunkName: "blockDownloadFiles" */ "./components/elements/staticBlocks/textBlocks/blockDownloadFiles"),

    'blockWithSurcharges': () => import(/* webpackChunkName: "blockWithSurcharges" */ "./components/elements/staticBlocks/blockWithSurcharges"),

    'staticH1Print': () => import(/* webpackChunkName: "staticH1Print" */ "./components/elements/staticBlocks/textBlocks/staticH1Print"),

    'sliderTwoImg': () => import(/* webpackChunkName: "sliderTwoImg" */ "./components/elements/staticBlocks/bannersBlocks/sliderTwoImg"),

    'sanatorii': () => import(/* webpackChunkName: "sanatorii" */ "./components/pages/sanatorii"),
    'sanatoriiNew': () => import(/* webpackChunkName: "sanatoriiNew" */ "./components/pages/sanatoriiNew"),

    'attentionText': () => import(/* webpackChunkName: "attentionText" */ "./components/elements/staticBlocks/attentionText"),

    'textBackgroundImage':()=>import(/* webpackChunkName: "textBackgroundImage" */ "./components/elements/staticBlocks/textBackgroundImage"),

    'androidAppPopup': () =>import(/* webpackChunkName: "androidAppPopup" */ "./components/elements/androidAppPopup"),

    'comparePage': ()=>import(/* webpackChunkName: "comparePage" */'./components/pages/comparePage'),

    'collectionContent': ()=>import(/* webpackChunkName: "collectionContent" */'./components/elements/collection/collectionContent'),

    'salesAccents': () => import(/* webpackChunkName: "salesAccents" */ "./components/pages/salesAccents"),

    'blockSalesAccents': () => import(/* webpackChunkName: "salesAccents" */ "./components/elements/staticBlocks/blockSalesAccents"),
    'collectionsPage': ()=>import(/* webpackChunkName: "collectionsPage" */'./components/pages/collectionsPage'),


    'landingEgyptMain': ()=>import(/* webpackChunkName: "landingEgyptMain" */ "./components/elements/landingEgypt/landingEgyptMain"),

    'landingSki': ()=>import(/* webpackChunkName: "landingSki" */ "./components/elements/landingSki/landingSki"),

    'day-theme':()=>import(/*day-theme*/ './components/elements/staticBlocks/dayTheme'),

    'selection-expert-news':()=>import(/*selection-expert-news*/ './components/elements/staticBlocks/selectionExpertNews')
}
