import variables from "../../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchGetAgentMessages(ctx, claimId){
            const url = variables.routeAgentMessages + "?claimId=" + claimId
            const payload = {
                claimId,
                messages: null,
            }
            try {
                const res = await axios({
                    url,
                    method: 'GET',
                })
                payload.messages = await res.data
                ctx.commit("updateAgentMessages", payload)
            } catch {
                console.log('Ошибка получения ответа от ' + url)
            }
        },
        async fetchGetAgentMessagesDepartments(ctx){
            const url = variables.routeReference + '/departments'
            try {
                const res = await axios({
                    url,
                    method: 'POST'
                })
                ctx.commit("updateAgentMessageDepartments", await res.data)
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url + e)
            }
        },
        async fetchGetAgentMessagesTypes(ctx, departmentID){
            ctx.commit("updateAgentMessageTypes", null)
            const url = variables.routeAgentMessages + '/types' + '/' + departmentID
            try {
                const res = await axios({
                    url,
                    method: 'POST'
                })
                ctx.commit("updateAgentMessageTypes", await res.data)
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url + e)
            }
        },
        async fetchSendAgentMessage(ctx, formData){
            let result = null
            const url = variables.routeAgentMessages
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: formData,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },
                })
                result = res.status === 200;
            } catch (e){
                console.log('Ошибка получения ответа от ' + url, e)
            }
            return result
        },
        async fetchAgentMessagesMarkRead(ctx, messageIds){
            const url = variables.routeAgentMessages + '/markRead'
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        messageIds
                    }
                })
                if(res.status === 200){
                    ctx.commit('updateAgentMessagesMarkRead', await res.data)
                }
            } catch {
                console.log('Ошибка получения ответа от ' + url)
            }
        },
        async fetchGetAgentMessagesFilesId(ctx, messageId){
            const url = `${variables.routeAgentMessages}/${messageId}/files`
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                if(await res.status == 200){
                    ctx.commit('updateAgentMessagesFiles', await res.data)
                } else {
                    console.log('Ошибка в статусе при получении списка файлов')
                }
            } catch {
                console.log('Ошибка получения ответа от ' + url)
            }
        }
    },
    state: {
        agentMessages: {},
        agentMessageDepartments: [],
        agentMessageTypes: [],
        agentMessagesId: [], //Содержит идентификаторы полученных сообщений
        agentMessagesFiles: {},
        agentMessagesFilesId: [], //Содержит идентификаторы полученных файлов
    },
    mutations: {
        updateAgentMessages(state, payload){
            try {
                //копирование объекта для срабатывания изменений
                const messages = Object.assign({}, state.agentMessages)
                const claimId = payload.claimId
                //индикатор изменения объекта
                let flag = false
                const result = {
                    isMessage: false,
                    messages: []
                }
                if(messages[claimId])
                    result.messages = messages[claimId].messages;
                payload.messages.forEach(item => {
                    result.isMessage = true
                    //проверка на отсутствие индификатора сообщения в массиве
                    //формирование объекта сообщения и помещение в массив сообщений
                    if(state.agentMessagesId.indexOf(item.messageId) === -1){
                        flag = true
                        state.agentMessagesId.push(item.messageId)
                        let message = {
                            id: null,
                            author: null,
                            body: null,
                            claimId: null,
                            createDate: null,
                            editDate: null,
                            filesCount: null,
                            isIncoming: null,
                            isOpen: null,
                            isRead: null,
                            type: null,
                            parentMessageId: null
                        }
                        message.id = item.messageId
                        message.author = item.author
                        message.body = item.body
                        message.claimId = claimId
                        message.createDate = item.createDate
                        message.editDate = item.editDate
                        message.filesCount = item.filesCount
                        message.isIncoming = item.isIncoming
                        message.isOpen = false
                        message.isRead = item.isRead
                        message.type = item.type
                        message.parentMessageId = item.parentMessageId ? item.parentMessageId : null
                        result.messages.push(message)
                    }
                })
                if(flag){
                    messages[claimId] = result
                }
                state.agentMessages = messages
            } catch (e) {
                console.log('Ошибка в мутации updateAgentMessages', e)
            }
        },
        updateAgentMessageDepartments(state, payload){
            state.agentMessageDepartments = payload
        },
        updateAgentMessageTypes(state, payload){
            state.agentMessageTypes = payload
        },
        updateAgentMessagesMarkRead(state, payload){
            try {
                const messageIds = payload
                //Перебор полученных от Api идентификаторов
                //и отметка их прочитанными в state
                messageIds.forEach(messageId => {
                    let res = state.agentMessages
                    for(let key in res){
                        res[key].messages.forEach(message => {
                            if(message.id == messageId){
                                message.isRead = true
                            }
                        })
                    }
                })
            } catch (e){
                console.log('Ошибка в мутации updateAgentMessagesMarkRead', e)
            }
        },
        updateAgentMessagesFiles(state, payload){
            try {
                const result = Object.assign({}, state.agentMessagesFiles)
                payload.forEach(item => {
                    //Проверка загружалась-ли в state ранее информация о файле
                    if(state.agentMessagesFilesId.indexOf(item.fileId) == -1){
                        const file = {
                            id: null,
                            messageId: null,
                            name: null,
                            note: null,
                        }
                        state.agentMessagesFilesId.push(item.fileId)
                        file.id = item.fileId
                        file.messageId = item.messageId
                        file.name = item.fileName
                        file.note = item.note
                        //Добавление объекта в массив
                        //Создание массива если он отсутствует
                        try {
                            result[item.messageId].push(file)
                        } catch {
                            result[item.messageId] = []
                            result[item.messageId].push(file)
                        }
                    }
                    state.agentMessagesFiles = result
                })
            } catch (e){
                console.log('Ошибка в мутации updateAgentMessagesFiles', e)
            }
        }
    },
    getters: {
        getAgentMessages: state => {
            return state.agentMessages
        },
        getAgentMessagesByClaimId: state => claimId => {
            let result = []
            try {
                result = state.agentMessages[claimId].messages
            } catch {}
            return result
        },
        // получение сообщений верхнего уровня для определенной заявки
        getAgentFirstLevelMessagesByClaimId: (state, getters) => claimId => {
            let result = []
            try {
                const messages = getters.getAgentMessagesByClaimId(claimId)
                messages.map(item => {
                    if(!item.parentMessageId){
                        result.push(item)
                    }
                })
            } catch {}
            return result
        },
        getAgentMessagesByParrentId: state => parentId => {
            let result = []
            let intermediate = []
            try {
                const res = state.agentMessages
                for(let key in res){
                    intermediate = [...intermediate, ...res[key].messages]
                }
                intermediate.map(item => {
                    if(item.parentMessageId === parentId){
                        result.push(item)
                    }
                })
            } catch {}
            return result
        },
        getAgentMessagesLoadStatusById: (state, getters) => claimId => {
           let result = false
           try {
               let res = getters.getAgentMessagesByClaimId(claimId)
               if(res[0].id || res[0].isMessage == false){
                   result = true
               } else {
                   result = false
               }
           } catch {}
           return result
        },
        getAgentMessageTypes: (state) => {
            let result = []
            try {
                let res = state.agentMessageTypes
                res.forEach(item => {
                    let type = {
                        value: null,
                        name: null,
                    }
                    type.value = item.id
                    type.name = item.title
                    result.push(type)
                })
            } catch {}
            return result
        },
        getAgentMessageDepartments: (state) => {
            let result = []
            try {
                let res = state.agentMessageDepartments
                let validDepartmentIds = [3, 4, 6, 7, 8, 14]
                let validDepartments = res.filter( el => {
                    if (validDepartmentIds.includes(el.id)) {
                        return el
                    }
                });

                validDepartments.forEach(item => {
                    let type = {
                        value: null,
                        name: null,
                    }
                    type.value = item.id
                    type.name = item.name
                    result.push(type)
                })
            } catch {}
            return result
        },
        getAgentMessageFilesByMessageId: state => messageId => {
            let result = null
            try {
                result = state.agentMessagesFiles[messageId]
            } catch {}
            return result
        }
    }
}
