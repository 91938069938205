import variables from "../../../plugins/variables";
import axios from 'axios';
import moment from 'moment';


export default {
    actions: {
        async fetchOrders(ctx) {
            let fetchStatus = ctx.state.fetchStatus
            if (!fetchStatus) {
                ctx.commit('setProcessing', true)
                const res = await axios({
                    url: variables.routeOrders,
                    method: 'POST'
                })
                const orders = await res.data.data
                console.log('orders', orders)
                ctx.commit('updateOrders', orders)
                ctx.commit('setProcessing', false)
                ctx.commit('updateFetchStatus', true)
            }
        },
        async fetchOrderById(ctx, orderId) {
            const res = await axios({
                url: variables.routeOrders + '/' + orderId,
                method: 'GET'
            })
            //console.log('res ', res)
            const order = await res.data.data
            ctx.commit('updateOrder', order)
        },
        async fetchOrderByIdReturnData(ctx, orderId) {
            const res = await axios({
                url: variables.routeOrders + '/' + orderId,
                method: 'GET'
            })            
            const order = await res.data.data
            return order
        },
        async fetchOrderPaid(ctx, orderId) {
            const url = variables.routeOrders + '/' + orderId
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                let order = await res.data.data
                ctx.commit('updateOrder', order)
                return true
            } catch (e){
                console.log('Ошибка в actions fetchOrderPaid', e)
            }
        },
        async fetchAviaOrder(ctx, payload) {
            let orderId = payload.orderId
            const url = variables.routeGetFlightOrder + '/' + payload.TTEOrderId
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })

                ctx.commit('updateAviaOrder', {order: res.data, orderId: orderId})
                return true
            } catch {
                console.log('Ошибка в actions fetchAviaOrder')
            }
        },
        // async fetchAviaTicketSubjects(ctx) {
        //     const url = variables.routeGetAviaTicketSubjects
        //     try {
        //         const res = await axios({
        //             url,
        //             method: 'GET'
        //         })
        //         // console.log('res', res)
        //         let order = await res.data
        //         ctx.commit('updateTicketSubjects', order)
        //         return true
        //     } catch {
        //         console.log('Ошибка в actions fetchAviaTicketSubjects')
        //     }
        // },
        async fetchAviaTicketStatuses(ctx) {
            const url = variables.routeGetAviaTicketStatuses
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                console.log('res', res)
                let order = await res.data
                ctx.commit('updateTicketStatuses', order)
                return true
            } catch {
                console.log('Ошибка в actions fetchAviaTicketStatuses')
            }
        },
        async fetchCreateTTETicket(ctx, payload) {
            const url = variables.routeAddAviaTTETicket
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                return res.data
            } catch {
                console.log('Ошибка в actions fetchCreateTTETicket')
            }
        },
        async fetchCustomerAppeals(ctx, payload) {
            const url = variables.routeExternalCustomerTickets + `/${payload.customerId}`
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                eventBus.$emit('passTickets', res.data.tickets)
                ctx.commit('updateTickets', res.data)
            } catch {
                console.log('Ошибка в actions fetchCustomerAppeals')
            }
        },
        async fetchAppealDetails(ctx, id) {
            const url = variables.routeGetTicketDetails + `/${id}`
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                // console.log('fetchAppealDetails', res)
                return res.data
                // ctx.commit('updateAppealDetails', res.data)
            } catch {
                console.log('Ошибка в actions fetchAppealDetails')
            }
        },
        async fetchCreateMessage(ctx, payload) {
            const url = variables.routeAddTicketMessage
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                return res.data
            } catch {
                console.log('Ошибка в actions fetchCreateTTETicket')
            }
        },
        async fetchApplyCalculation(ctx, payload) {
            const url = variables.routeApplyCalculation
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                return res.data
            } catch {
                console.log('Ошибка в actions fetchApplyCalculation')
            }
        },
        async fetchDeclineCalculation(ctx, payload) {
            const url = variables.routeDeclineCalculation
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                return res.data
            } catch {
                console.log('Ошибка в actions fetchDeclineCalculation')
            }
        },
    },
    state: {
        orders: [],
        tickets: [],
        processing: false,
        fetchStatus: false,
        ticketSubjects: [],
        ticketStatuses: [],
        appealDetails: {},
    },
    mutations: {
        updateOrders(state, orders) {
            let result = []
            //try {
            if (Array.isArray(orders)) {
                orders.forEach(item => {
                    const order = {
                        id: null,
                        accountId: null,
                        aviaOrderId: null,
                        checkinDate: null,
                        checkoutDate: null,
                        createdDate: null,
                        nightsCount: null,
                        status: null,
                        bookingStatus: null,
                        customerInfo: {},
                        townFrom: {},
                        arrivalCountry: {},
                        hotels: [],
                        freights: [],
                        flights: [],
                        insurances: [],
                        services: [],
                        visas: [],
                        tourists: [],
                        prices: [],
                        payments: [],
                        paymentTerms: {},
                        enablePayOrderByTourist: null,
                        documents: [],
                        clientId: null,
                    }
                    order.id = item.orderId
                    order.checkinDate = item.startDate
                    order.checkoutDate = item.checkoutDate
                    order.createdDate = item.createdDate
                    order.nightsCount = item.nightsCount
                    order.status = item.status
                    order.bookingStatus = item.bookingStatus
                    order.customerInfo = item.customerInfo
                    order.townFrom = item.townFrom
                    order.arrivalCountry = item.arrivalCountry
                    order.touristsCount = item.touristsCount
                    order.clientId = item.clientId

                    if (!item.hotels || item.hotels.length===0) {
                        order.hotels.push({
                            country: null,
                            city: null,
                            room: null,
                            name: null,
                        })
                    } else {
                        item.hotels.forEach(item => {
                            order.hotels.push(item)
                        })
                    }
                    // item.freights.forEach(item => {
                    //     order.freights.push(item)
                    // })
                    // if (item.freights.length > 0 ) {
                    //     item.freights.forEach((el, idx) => {
                    //         // order.freights[idx].push(JSON.parse(el.freight.name))
                    //         //order.freights[idx].details = el.freight.name
                    //         order.flights.push(item.freights[0].freight.name)
                    //     })
                    //     //order.aviaOrderId = order.freights[0].details.flights[0].orderId || null
                    // }
                    // item.insurances.forEach(item => {
                    //     order.insurances.push(item)
                    // })
                    // item.services.forEach(item => {
                    //     order.services.push(item)
                    // })
                    // item.visas.forEach(item => {
                    //     order.visas.push(item)
                    // })
                    // item.tourists.forEach(item => {
                    //     order.tourists.push(item)
                    // })
                    order.paymentTerms = item.paymentTerms
                    order.enablePayOrderByTourist = item.enablePayOrderByTourist
                    if(Array.isArray(item.prices)){
                        item.prices.forEach(item => {
                            order.prices.push(item)
                        })
                        result.push(order)
                    }
                    if (typeof item.payments !== 'undefined') {
                        item.payments.forEach(item => {
                            order.payments.push(item);
                        })
                    }
                    // item.documents.forEach(item => {
                    //     order.documents.push(item)
                    // })

                })
            }
            state.orders = result
            //} catch(err) {
            //   console.log('Ошибка в мутации updateOrders', err)
            //}
        },
        updateOrder(state, order) {
            let res = state.orders.find(item => {
                return item.id === order.id
            })
            if(res && order) {
                //console.log('order ', order)
                res.status = order.status
                res.bookingStatus = order.bookingStatus
                res.prices = order.prices
                res.payments = order.payments
                res.paymentTerms = order.paymentTerms
                res.arrivalCountry = order.arrivalCountry
                res.aviaOrderId = order.aviaOrderId
                res.checkinDate = order.checkinDate
                res.checkoutDate = order.checkoutDate
                res.createdDate = order.createdDate
                res.customerInfo = order.customerInfo
                res.documents = order.documents
                res.enablePayOrderByTourist = order.enablePayOrderByTourist
                res.flights = order.flights
                res.freights = order.freights
                if (order.hotels.length > 0) {
                    res.hotels = order.hotels
                }
                res.insurances = order.insurances
                res.services = order.services
                res.tourists = order.tourists
                res.townFrom = order.townFrom
                res.visas = order.visas
                res.accountId = order.accountId
            }
        },
        updateTickets(state, data) {
            state.tickets = data.tickets
        },
        updateAppealDetails(state, data) {
            state.appealDetails = data
            console.log('appealDetails', state.appealDetails)
        },
        setProcessing(state, payload) {
            state.processing = payload
        },
        updateFetchStatus(state, payload) {
            state.fetchStatus = payload
        },
        updateAviaOrder(state, payload) {
            state.orders.find(order => order.id === payload.orderId).flightDetails = payload.order
        },
        updateTicketSubjects(state, payload) {
            let subjects = []
            payload.forEach( (item, idx) => {
                let option = {
                    id: null,
                    name: null,
                }
                option.id = item.code
                option.name = item.description
                subjects.push(option)
            })
            state.ticketSubjects = subjects
        },
        updateTicketStatuses(state, payload) {
            state.ticketStatuses = payload
        },
    },
    getters: {
        getOrders(state) {
            return state.orders
        },
        getProcessingOrders(state) {
            return state.processing
        },
        getOrderById: state => id => {
            let result = null
            try {
                result = state.orders.find(item => {
                    return item.id == id
                })
            } catch {
            }
            return result
        },
        getHotelById: (state, getters) => id => {
            let result = null
            try {
                result = getters.getOrderById(id).hotels[0]
            } catch {
            }
            return result
        },
        getHotelStarById: (state, getters) => id => {
            let result = null
            let hotel = null
            try {
                hotel = getters.getOrderById(id).hotels[0]
                result = Number(hotel.star.slice(0, -1))
            } catch {
            }
            return result
        },
        getCountTouristsById: (state, getters) => id => {
            let result = null
            let tourists = null
            try {
                tourists = getters.getOrderById(id).tourists
                result = tourists.length
            } catch {
            }
            return result
        },
        getRubPriceById: (state, getters) => id => {
            let result = {
                catalog: null,
                amount: null,
                currency: {
                    key: null,
                    alias: null
                },
                paid: null,
                rate: null
            }
            try {
                let res = getters.getOrderById(id).prices
                let rub = res.find(item => {
                    return item.currency.alias === getters.getDefaultCurrencyAlias
                })
                if(!rub){
                    rub = res.find(item => {
                        return item.currency.alias === 'RUB'
                    })
                }
                if (rub !== undefined) {
                    result = rub
                }
            } catch {
            }
            return result
        },
        getPriceByClientId:(state, getters) => payload=>{
            let result = {
                catalog: null,
                amount: null,
                currency: {
                    key: null,
                    alias: null
                },
                paid: null,
                rate: null
            }
            try {
                let res = getters.getOrderById(payload.id).prices
                let alias
                if(payload.clientId === 'b2c:ua'){
                    alias = 'UAH'
                }
                if(payload.clientId === 'b2c:ru'){
                    alias = 'RUB'
                }
                if(payload.clientId === 'b2c:by'){
                    alias = 'BYN'
                }
                if(payload.clientId === 'b2c:cm'){
                    alias = 'RUB'
                }
                result = res.find(item => {
                    return item.currency.alias === alias
                })
            } catch {
            }
            return result
        },
        getCustomerFlightById: (state, getters) => id => {
            let result = null
            try {
                result = getters.getOrderById(id).flights[0].flights[0]
            } catch {
            }
            return result
        },
        getFlightWayToDetailsById: state => id => {
            const order = state.orders.find(order => order.id === id)
            return order.flightDetails.orderPositions[0].segmentGroups[0]
        },
        getFlightWayBackDetailsById: state => id => {
            const order = state.orders.find(order => order.id === id)
            return order.flightDetails.orderPositions[0].segmentGroups[1]
        },
        getFlightDuration: state => id => {
            const order = state.orders.find(order => order.id === id)
            let startDate = order.freights[0].details.flights[0].dateStart1
            let start = `${startDate.split('T')[0]}T${order.freights[0].details.flights[0].timeStart1}`
            let finishDate = order.freights[0].details.flights[0].dateFinish1
            let finish = `${finishDate.split('T')[0]}T${order.freights[0].details.flights[0].timeFinish1}`
            return moment(finish).diff(moment(start), 'minutes')
        },
        getTicketSubjects(state) {
            return state.ticketSubjects
        },
        getCustomerTickets(state) {
            return state.tickets
        },
        getTicketDetails(state) {
            return state.appealDetails
        },

    }
}
