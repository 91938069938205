import Vue from 'vue'
import VueRouter from 'vue-router'

// const Home = () => import(/* webpackChunkName: "home" */'./components/pages/home');
// const Customer = () => import(/* webpackChunkName: "customer" */'./components/pages/cabinet/customer');
// const Agent = () => import(/* webpackChunkName: "agent" */'./components/pages/cabinet/agent');
// const Partner = () => import(/* webpackChunkName: "partner" */'./components/pages/cabinet/partner');
// const Moderator = () => import(/* webpackChunkName: "moderator" */'./components/pages/cabinet/moderator');
// const Search = () => import(/* webpackChunkName: "search" */'./components/pages/search');
// const SearchHotel = () => import(/* webpackChunkName: "search" */'./components/pages/searchHotel');
// const SearchFlights = () => import(/* webpackChunkName: "search" */'./components/pages/searchFlights');
// const Constructor = () => import(/* webpackChunkName: "search" */'./components/pages/constructor');
// const SearchInsurances = () => import(/* webpackChunkName: "search" */'./components/pages/searchInsurances');
// const SearchTransfers = () => import(/* webpackChunkName: "search" */'./components/pages/searchTransfers');
// const SearchServices = () => import(/* webpackChunkName: "search" */'./components/pages/searchServices');
// const SearchExcursions = () => import(/* webpackChunkName: "search" */'./components/pages/searchExcursions');
// const SearchCruises = () => import(/* webpackChunkName: "search" */'./components/pages/searchCruises');
// const Hotel = () => import(/* webpackChunkName: "search" */'./components/pages/hotel');
// const Avia = () => import(/* webpackChunkName: "avia" */'./components/pages/avia');
// const Basket = () => import(/* webpackChunkName: "basket" */'./components/pages/basket');
// const BasketPayment = () => import(/* webpackChunkName: "basketPayment" */'./components/pages/basketPayment');
// const Test = () => import(/* webpackChunkName: "test" */'./components/pages/test');
// const Marketplace = () => import('./components/pages/marketplace/marketplace');
// const MarketplaceProduct = () => import('./components/pages/marketplace/product/marketplaceProduct');

// const ResetPassword = () => import(/* webpackChunkName: "home" */'./components/pages/resetPassword');
// const MarketplaceSellers = () => import('./components/pages/marketplace/marketplaceSellers');
// const MarketplaceCategory = () => import('./components/pages/marketplace/marketplaceCategory');
// const MarketplaceSearch = () => import('./components/pages/marketplace/marketplaceSearch');
// const Actions = () => import('./components/pages/newsActions/actions');
// const News = () => import('./components/pages/newsActions/news');


const router = new VueRouter({
    mode: 'history',
    routes: [
        // { path: '/', component: Search },
        // { path: '/searchHotel', component: SearchHotel },
        // { path: '/searchFlights', component: SearchFlights },
        // { path: '/constructor', component: Constructor },
        // { path: '/searchInsurances', component: SearchInsurances },
        // { path: '/searchTransfers', component: SearchTransfers },
        // { path: '/searchServices', component: SearchServices },
        // { path: '/searchExcursions', component: SearchExcursions },
        // { path: '/searchCruises', component: SearchCruises },
        // { path: '/cabinet/customer', component: Customer },
        // { path: '/cabinet/agent', component: Agent },
        // { path: '/cabinet/partner', component: Partner },
        // { path: '/search', component: Search },
        // { path: '/hotel/:hotelId', component: Hotel, props: true },
        // { path: '/avia', component: Avia },
        // { path: '/basket', component: Basket },
        // { path: '/payment/:orderId', component: BasketPayment, props: true },
        // { path: '/marketplace', component: Marketplace },
        // { path: '/marketplace/product/:id', component: MarketplaceProduct, props: true },
        // { path: '/marketplace/category/:id', component: MarketplaceCategory, props: true },
        // { path: '/marketplace/sellers', component: MarketplaceSellers},
        // { path: '/marketplace/search', component: MarketplaceSearch},
        // { path: '/resetpassword', component: ResetPassword },
        // { path: '/news', component: News },
    ]
})

Vue.use(VueRouter)

export default router
