
export default {
    actions: {

    },
    state: {
        popupStatus: {
            captchaPopup: false,
            agentEditPopup: false,
            agentAddPopup:false,
            customPopup: false,
            loginPopup:false,
            loginPopupNew: false,
            registerAgencyPopup: false,
            registerPartnerPopup: false,
            registerPopup: false,
            registerPopupNew: false,
            academyPopup: false,
            academySendingPopup: false,
            academyPopupSliderPhotos: false,
            forgotPasswordPopupNew: false,
            tableRowEditPopup: false,
            deletePhonePopup:false,
            claimPaymentInfoPopup:false,
            claimPaymentPopup:false,
            claimSendPaymentOnEmailPopup:false,
            claimMessageSendPopup:false,
            constructorAddPopup:false,
            tourOrderPopup:false,
            tourOrderPopupNew:false,
            conceptInfoPopup: false,
            mealInfoPopup: false,
            regionInfoPopup: false,
            regionPopup: false,
            regionMobilePopup: false,
            connectionPopup: false,
            connectionMobilePopup: false,
            flyInfoPopup: false,
            bestInfoPopup: false,
            tourSearchConceptPopup: false,
            hotelImagePopup: false,
            hotelImageInfoPopup: false,
            landingImagePopup: false,
            hotelVideoPopup: false,
            hotelQuestionPopup: false,
            basketTouristsTourPopup: false,
            basketTouristsHotelPopup: false,
            basketTouristsFlightPopup: false,
            basketTouristsExcursionsPopup: false,
            basketTouristsAeroexpressPopup: false,
            basketTouristsServicesPopup: false,
            basketTouristsTransferPopup: false,
            basketTouristsInsurancePopup: false,
            basketFlightInsurancePopup: false,
            basketFlightFoodPopup: false,
            basketFlightLuggagePopup: false,
            basketFlightTariffPopup: false,
            bookingTTETariffDetailsPopup: false,
            bookingTTEServicesDetailsPopup: false,
            bookingTTEInsurancesDetailsPopup: false,
            basketFlightAviaPopup: false,
            basketTourInsurancesPopup: false,
            basketHotelInsurancesPopup: false,
            basketTourServicesPopup: false,
            basketHotelServicesPopup: false,
            basketTourVisaPopup: false,
            basketHotelVisaPopup: false,
            marketplaceTableSettingsPopup: false,
            marketplaceTableDeletePopup: false,
            marketplaceOrderProductPopup: false,
            tourSearchImportantSettingsPopup: false,
            uploadUserAvatarPopup:false,
            hotelAgentPriceRangePopup: false,
            hotelAgentRoomsPopup: false,
            hotelAgentFlightsPopup: false,
            basketTourFlightPopup: false,
            basketTourBoardingPopup: false,
            basketTourErrorsPopup: false,
            bookingErrorPopup: false,
            bookingHaveBookPopup: false,
            bookingPriceChangePopup: false,
            bookingNewYearMealPopup: false,
            basketChooseServicePopup: false,
            basketAddInsurancePopup: false,
            basketAddExcursionPopup: false,
            basketAddTransferPopup: false,
            basketAddServicePopup: false,
            basketAddAeroexpressPopup: false,
            basketChangeTourPopup: false,
            FITClaimCommentsPopup: false,
            claimPayCardPopup:false,
            claimPaymentReceiptPopup:false,
            loyaltyRegistrationPopupStep1: false,
            loyaltyRegistrationPopupStep2: false,
            loyaltyRegistrationPopupStep3: false,
            loyaltyAgentRegistrationPopupStep1: false,
            loyaltyAgentRegistrationPopupStep2: false,
            loyaltyAgentRegistrationPopupStep3: false,
            privetMirPopup: false,
            loyaltyEditDataPopup: false,
            partnerProductCategoriesSelectPopup: false,
            travelSlider: false,
            partnerProductNewCategoriesPopup: false,
            moderatorReferencesPropertyPopup: false,
            moderatorReferencesPropertyDeletePopup: false,
            moderatorReferencesImagePopup: false,
            partnerProductNewParameterPopup: false,
            moderatorReferencesPropertyValuePopup: false,
            cancelClaimPopup: false,
            marketCityPopup: false,
            appealCreatePopup: false,
            appealCalculationPopup: false,
            appealConsentPopup: false,
            appealCalculationAcceptedPopup: false,
            appealCalculationRejectedPopup: false,
            appealConfirmCalculationRejectionPopup: false,
            basketProductAddressPopup: false,
            marketplaceProductRatePopup: false,
            marketplacePartnerRatePopup: false,
            basketTourTariffPopup: false,
            bookingMapPopup: false,
            requestSentPopup: false,
            yachtServicePopup: false,
            footerSubscriptionPopup: false,
            tourMobileFilter: false,
            sortMobilePopup: false,
            tourMobileSearch: false,
            comparePopup: false,
            comparePopupForHeader: false,
            comparePopupForNewDelivery: false,
            compareNoOffersPopup: false,
            compareUpdateSettings: false,
            landingTouragentAppModal: false,
            bookingPromoPopup: false,
            hotelSliderPopup: false,
            hotelAboutPopup: false,
            hotelMapPopup: false,
            collectionPopup: false,
            successSendingPopup:false,
        },
        popupId: 0,
    },
    mutations: {

    },
    getters: {
        //Используется, если есть несколько одинаковых модальных окон
        getPopupStatusByNameAndId: state => (popupName, id) =>{
            let result = false
            for (let popup in state.popupStatus) {
                if(state.popupStatus[popup] && popup === popupName && state.popupId === id){
                    result = true
                }
            }
            return result
        },
        //Используется, если есть несколько одинаковых модальных окон
        setPopupStatusByNameAndId: state => (popupName, id) =>{
            for (let popup in state.popupStatus){
                state.popupStatus[popup] = false;
            }
            state.popupStatus[popupName] = true;
            state.popupId = id
        },
        getPopupStatus: state => {
            return state.popupStatus
        },
        setPopupStatusByName: state => popupName => {
            state.popupStatus[popupName] = true;
        },
        closePopupByName: state => popupName => {
            state.popupStatus[popupName] = false;
        },
        setPopupStatus: state => popupName => {
            for (let popup in state.popupStatus){
                state.popupStatus[popup] = false;
            }
            state.popupStatus[popupName] = true;
        },
        setCloseAllPopups: state => event =>{
            for (let popup in state.popupStatus){
                state.popupStatus[popup] = false;
            }
        }
    }
}
