import axios from 'axios';
import variables from "../../../plugins/variables";
import moment from "moment";

moment.locale('ru');
//TODO можно сделать фильтром
import helpers from "../../../plugins/helpers";

export default {
    actions: {
        async fetchClaimsCount(ctx, config) {
            let url = variables.routeAgentClaims + '/count'
            try {
                ctx.state.loadClaimsStatus = false
                const res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        "claimStatus": config.status,
                        "claimId": config.claimId,
                    }
                })
                if (await res.status == 200) {
                    ctx.commit("updateClaimsCount", await res.data)
                    //console.log('Данные по заявкам: ', await res.data)
                } else {
                    console.log('Ошибка статуса при обращении к api/claims', res)
                }
                ctx.state.loadClaimsStatus = true
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url, e)
            }
        },
        async fetchClaims(ctx, config) {
            let url = variables.routeAgentClaims
            try {
                ctx.state.loadClaimsStatus = false
                const res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        "claimStatus": config.status,
                        "PageSize":ctx.state.PageSize,
                        "Page":ctx.state.Page,
                        "claimId": config.claimId,
                    }
                })
                if (await res.status == 200) {
                    ctx.commit("updateClaims", await res.data)
                    //console.log('Данные по заявкам: ', await res.data)
                } else {
                    console.log('Ошибка статуса при обращении к api/claims', res)
                }
                ctx.state.loadClaimsStatus = true
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url, e)
            }
        },
        async fetchClaimReservationReturnData(ctx, claimId){
            let url = variables.routeAgentClaims
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        claimStatus: "-1",
                        claimId: claimId,
                    }
                })
                if (await res.status == 200) {
                    return await res.data[0]
                } else {
                    console.log('Ошибка статуса при обращении к api/claims', res)
                }
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url, e)
            }
        },
        async fetchClaim(ctx, id) {
            let payload = {
                id,
                claim: null,
            }
            let url = variables.routeAgentClaims + '/' + id
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                })
                payload.claim = await res.data
                ctx.commit('updateClaimById', payload)
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url, e)
            }
        },
        async fetchClaimReturnData(ctx, id) {
            let url = variables.routeAgentClaims + '/' + id
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                })
                let data = await res.data
                return data
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url, e)
            }
        },
        async fetchClaimDocuments(ctx, claimId) {
            let payload = {
                claimId,
                documents: null,
            }
            let url = variables.routeAgentClaims + '/' + claimId + '/documents'
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                })
                payload.documents = await res.data
                ctx.commit('updateClaimDocumentsById', payload)
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url, e)
            }
        },
        async fetchReferenceClaimStatus(ctx) {
            const url = variables.routeReference + "/statuses"
            try {
                const res = await axios({
                    url,
                    method: "POST",
                })
                ctx.commit('updateClaimsStatus', await res.data)
            } catch (e) {
                console.log('Ошибка в actions при получении ответа от ' + url, e)
            }
        },
        async cancelClaim(ctx, payload) {
            const url = variables.routeAgentClaims + '/cancel'
            // const url = 'api/agent/claims/cancel'
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                console.log('result cancel', res)
            } catch (e) {
                console.log('Ошибка получения ответа от ' + url, e)
            }
        },
        async fetchSingleClaim(ctx, payload) {
            const url = variables.routeAgentClaims
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        "claimStatus": payload.status,
                        "claimId": payload.claimId,
                    }
                })
                // console.log(res)
                return res.data
            } catch {
                console.log('Ошибка в actions fetchSingleClaim')
            }
        },

    },
    state: {
        claimsCount:0,
        Page:1,
        PageSize:100,
        claims: [],
        claimsId: [],
        currentClaimId: null,//используется попапами. Переключается кнопками открытия попапов.
        loadClaimsStatus: true,//статус загрузки всех заявок
        loadClaimsCount:false,//загрузка кол-ва заявок, false ставится после смены статуса в фильтре
        filterClaimsStatus: true, //отмечается в true если идет фильтрация заявок
        claimsStatus: [],
        claimsFilter: {
            status: null,
            paymentStatus: null,
            claimId: null,
            name: null,
            startDate: null,
            endDate: null,
            reservationDate: null,
            country: null,
            managerName: null,
            operatorName: null,
        },
        documents: [], // массив объектов документов {claimId,documents}
    },
    mutations: {
        updateClaimsCount(ctx, payload) {
            ctx.claimsCount = payload;
            ctx.loadClaimsCount = true;
        },
        updateCurrentPages(ctx, num){
            ctx.page = num
        },
        async updateClaims(state, claims) {
            let result = []
            let claimsId = []
            try {
                //Перебор полученного массива объектов и получение идентификаторов заявок
                claims.forEach(item => {
                    claimsId.push(item.claimId)
                })
                //Удаление дублей идентификаторов
                let seen = {}
                claimsId = claimsId.filter(item => {
                    return seen.hasOwnProperty(item) ? false : (seen[item] = true)
                })
                //Запись в state
                state.claimsId = claimsId
                //Перебор идентификаторов и первичное формирование внутренних объектов claim
                //В объекте создается поле claimId и reservations.
                //В reservation помещаются объекты из общего запроса к заявкам относящиеся к этому идентификатору
                //Помещение внутренник объектов claim в массив claims
                claimsId.forEach(id => {
                    let claim = {
                        claimId: id,
                        isProgress: true,
                        reservations: []
                    }
                    claims.forEach(item => {
                        if (item.claimId === id) {
                            delete item.claimId
                            claim.reservations.push(item)
                        }
                    })
                    result.push(claim)
                })
                state.claims = result
            } catch (e) {
                console.log('Ошибка в store. Mutations updateClaims', e)
            }
        },
        async updateClaimById(state, payload) {
            try {
                const id = payload.id
                const claim = await payload.claim
                state.claims.forEach(item => {
                    if (item.claimId === payload.id) {
                        item.isProgress = false
                        Object.assign(item, claim)
                    }
                })
                //Создание нового массива, чтобы vuex учитывал изменения
                state.claims = Object.assign([], state.claims)
            } catch (e) {
                console.log('Ошибка в store. Mutations updateClaimById', e)
            }
        },
        async updateClaimDocumentsById(state, payload) {
            try {
                let foundDocuments = state.documents.find(item => item.claimId === payload.claimId)
                if (foundDocuments) {
                    state.documents.splice(state.documents.indexOf(foundDocuments), 1)
                }

                state.documents.push(payload)
                state.documents.forEach(e=>{
                        e.inprintable = false
                })
                //Создание нового массива, чтобы vuex учитывал изменения
                // state.claims = Object.assign([], state.claims)
            } catch (e) {
                console.log('Ошибка в store. Mutations updateClaimDocumentsById', e)
            }
        },
        async updateClaimsStatus(state, payload) {
            let result = []
            try {
                payload.forEach(item => {
                    const option = {
                        name: null,
                        value: null
                    }
                    option.name = item.name
                    option.value = item.id
                    result.push(option)
                })
                state.claimsStatus = result
            } catch (e) {
                console.log('Ошибка в store. Mutations updateClaimsStatus', e)
            }

        },
    },
    getters: {
        getClaims(state) {
            return state.claims
        },
        getClaimsId(state) {
            return state.claimsId
        },
        getClaimProgressById: (state, getters) => id => {
            let result = true
            try {
                let res = getters.getClaimById(id).isProgress
                if (res !== null) {
                    result = res
                }
            } catch (e) {
            }
            return result
        },
        getClaimById: state => id => {
            let result = null
            try {
                result = state.claims.find(item => {
                    return item.claimId === id
                })
            } catch (e) {
            }
            return result
        },
        getClaimDocumentsById: state => id => {
            let result = null
            try {
                let found = state.documents.find(item => {
                    return item.claimId === id
                })
                if (found)
                    result = found.documents;
            } catch (e) {
            }
            return result
        },
        //Геттеры получающие данные из общего запроса к заявкам.
        //Информация берется из заявки первого участника (кроме имени)
        getClaimReservationDateById: (state, getters) => id => {
            let result = null
            let date = null
            try {
                date = getters.getClaimById(id).reservations[0].reservationDate
                result = moment(date).format('DD.MM.YYYY')
            } catch (e) {
            }
            return result
        },
        getClaimStartDateById: (state, getters) => id => {
            let result = null
            let date = null
            try {
                date = getters.getClaimById(id).reservations[0].dateBeg
                result = moment(date).format('DD.MM.YYYY')
            } catch (e) {
            }
            return result
        },
        getClaimEndDateById: (state, getters) => id => {
            let result = null
            let date = null
            try {
                date = getters.getClaimById(id).reservations[0].dateEnd
                result = moment(date).format('DD.MM.YYYY')
            } catch (e) {
            }
            return result
        },
        getClaimPaymentStatusById: (state, getters) => id => {
            let result = {
                id: null,
                title: null
            }
            try {
                let res = getters.getClaimById(id).reservations[0].paymentStatus
                if (res.id || res.title) {
                    result = res
                }
            } catch (e) {
            }
            return result
        },
        getClaimPaymentDateById: (state, getters) => id => {
            let result = null
            let date = null
            try {
                date = getters.getClaimById(id).reservations[0].paymentDate
                result = moment(date).format('DD.MM.YYYY')
            } catch (e) {
            }
            return result
        },
        getClaimCityById: (state, getters) => id => {
            let result = {
                id: null,
                title: null,
            }
            let town = null
            try {
                town = getters.getClaimById(id).reservations[0].town
                result.id = town.id
                result.title = town.title
            } catch (e) {
            }
            return result
        },
        getClaimCountryById: (state, getters) => id => {
            let result = {
                id: null,
                title: null,
            }
            let country = null
            try {
                country = getters.getClaimById(id).reservations[0].country
                result.id = country.id
                result.title = country.title
            } catch (e) {
            }
            return result
        },
        //Геттеры получающие данные из общего запроса к заявкам. Без оговорок
        getClaimPeoplesById: (state, getters) => id => {
            let result = []
            let reservations = null
            try {
                let people = {
                    name: null,
                    latinName: null
                }
                reservations = getters.getClaimById(id).reservations
                reservations.forEach(item => {
                    people.name = item.clientName
                    people.latinName = item.clientLatinName
                    result.push(people)
                })
            } catch (e) {
            }
            return result
        },
        getClaimHotelsById: (state, getters) => id => {
            let result = []
            try {
                let res = getters.getClaimById(id).hotels
                res.forEach(item => {
                    let hotel = {
                        id: null,
                        name: null,
                        startDate: null,
                        endDate: null,
                        count: null,
                        status: null,
                        townId: null
                    }
                    hotel.id = item.orderID
                    hotel.name = item.name
                    hotel.startDate = moment(item.dateBeg).format('DD.MM.YYYY')
                    hotel.endDate = moment(item.dateEnd).format('DD.MM.YYYY')
                    hotel.count = item.count
                    hotel.status = item.status.title
                    hotel.townId = item.townID
                    result.push(hotel)
                });
            } catch (e) {
            }
            return result
        },
        getClaimFlightsById: (state, getters) => id => {
            let result = []
            try {
                let res = getters.getClaimById(id).flights
                res.forEach(item => {
                    let flight = {
                        id: null,
                        name: null,
                        startDate: null,
                        endDate: null,
                        className: null,
                        peopleCount: null,
                        cost: null,
                        costCurrency: null,
                        fromTown: null,
                        toTown: null,
                        fromTime: null,
                        toTime: null,
                        fromPortCode: null,
                        toPortCode: null,
                        duration: null,
                        count: null,
                        status: null,
                        timeLimit: null,
                    }
                    flight.id = item.orderID
                    flight.name = item.name
                    flight.startDate = moment(item.dateBeg).format('DD.MM.YYYY')
                    flight.endDate = moment(item.dateEnd).format('DD.MM.YYYY')
                    flight.className = item.className
                    flight.peopleCount = item.peopleCount
                    flight.cost = item.cost
                    flight.costCurrency = item.costCurrency
                    //TODO towns
                    flight.fromTownId = item.fromTownID
                    flight.toTownId = item.toTownID
                    flight.fromTime = item.fromTime
                    flight.toTime = item.toTime
                    //TODO ports
                    flight.fromPortCode = item.fromPortIATA
                    flight.toPortCode = item.toPortIATA
                    flight.duration = helpers.getTimeFromMins(item.durationMin)
                    flight.count = item.count
                    flight.status = item.status.title
                    if(item.timeLimit){
                        flight.timeLimit = item.timeLimit
                    }
                    result.push(flight)
                })
            } catch (e) {
            }
            return result
        },
        getClaimServicesById: (state, getters) => id => {
            let result = []
            try {
                let res = getters.getClaimById(id).services
                res.forEach(item => {
                    let service = {
                        id: null,
                        startDate: null,
                        endDate: null,
                        name: null,
                        fromTown: null,
                        toTown: null,
                        count: null,
                        status: null
                    }
                    service.id = item.orderID
                    service.startDate = moment(item.dateBeg).format('DD.MM.YYYY')
                    service.endDate = moment(item.dateEnd).format('DD.MM.YYYY')
                    service.name = item.serviceName
                    //TODO towns
                    service.fromTown = "*" + item.fromTownID + "*"
                    service.toTown = "*" + item.toTownID + "*"
                    service.count = item.count
                    service.status = item.status.title
                    result.push(service)
                })
            } catch (e) {
            }
            return result
        },
        getClaimPeoplesDetailsById: (state, getters) => id => {
            let result = []
            try {
                let res = getters.getClaimById(id).people
                res.forEach(item => {
                    let phone = item.phones
                    if(phone[0] === "7")
                        phone = "+7" + phone.slice(1,)
                    let people = {
                        id: item.id,
                        human: item.human,
                        name: item.name,
                        latinName: item.latinName,
                        bornDate: moment(item.bornDate).format('DD.MM.YYYY'),
                        passportSeries: item.passportSerie + ' ' + item.passportNumber + ' до ' + moment(item.passportDate).format('DD.MM.YYYY'),
                        passportNumber: item.passportNumber,
                        passportDate: moment(item.passportDate).format('DD.MM.YYYY'),
                        firstNameLatin: item.firstNameLatin,
                        lastNameLatin: item.lastNameLatin,
                        email: item.email,
                        phones: phone,
                    }
                    result.push(people)
                })
            } catch (e) {
            }
            return result
        },
        getClaimАmountPrepaymentById: (state, getters) => id => {
            let result = null
            try {
                // TODO пока нет данных
                result = '-----------'
            } catch (e) {
            }
            return result
        },
        getClaimInvoiceById: (state, getters) => id => {
            let result = null
            try {
                // TODO пока нет данных
                result = '-----------'
            } catch (e) {
            }
            return result
        },
        setClaimsFilter: state => claimsFilter => {
            state.claimsFilter = claimsFilter
        },
        getClaimsFilter: state => {
            return state.claimsFilter
        },
        getClaimsManagers: state => {
            let result = []
            let managers = []//массив для исключения не уникальных имен
            try {
                state.claims.forEach(item => {
                    const manager = {
                        name: null,
                        value: null
                    }
                    if (managers.indexOf(item.reservations[0].managerName) == -1 && item.reservations[0].managerName !== "") {
                        manager.name = item.reservations[0].managerName
                        manager.value = item.reservations[0].managerName
                        managers.push(item.reservations[0].managerName)
                        result.push(manager)
                    }
                })

            } catch (e) {
            }
            return result
        },
        getClaimsOperators: state => {
            let result = []
            let operators = []//массив для исключения не уникальных имен
            try {
                state.claims.forEach(item => {
                    const operator = {
                        name: null,
                        value: null
                    }
                    if (operators.indexOf(item.reservations[0].operatorName) == -1 && item.reservations[0].operatorName !== "") {
                        operator.name = item.reservations[0].operatorName
                        operator.value = item.reservations[0].operatorName
                        operators.push(item.reservations[0].operatorName)
                        result.push(operator)
                    }
                })
            } catch (e) {
            }
            return result
        },
        getClaimsStatuses: state => {
            let result = []
            let addStatuses = [
                {
                    name: 'Все',
                    value: '-1',
                },
                {
                    name: 'Актуальные',
                    value: '0',
                },
            ]
            try {
                result = state.claimsStatus
            } catch (e) {
            }
            result = [...addStatuses, ...result]
            return result
        },
        getClaimsPaymentStatuses: state => {
            let result = []
            try {
                result = state.claimsStatus
            } catch (e) {
            }
            return result
        },
        getClaimsLoadStatus: state => {
            let result = null
            try {
                result = state.loadClaimsStatus
            } catch (e) {
            }
            return result
        },
        getClaimsFilterStatus: state => {
            let result = null
            try {
                result = state.filterClaimsStatus
            } catch (e) {
            }
            return result
        },
        setClaimsFilterStatus: state => status => {
            if (typeof status === "boolean") {
                state.filterClaimsStatus = status
            }
        },
        getClaimsReservationsByID: (state, getters) => id => {
            let result = null
            try {
                result = getters.getClaimById(id).reservations[0]
            } catch (e) {
            }
            return result
        },
        getClaimsFirstClientEmailByID: (state, getters) => id => {
            let result = null
            try {
                let email = getters.getClaimById(id).people[0].email;
                if (email) {
                    result = email;
                } else {
                    result = "Почта не указана";
                }
            } catch (e) {
            }
            return result
        },
        getClaimsFirstClientPhoneByID: (state, getters) => id => {
            let result = null
            try {
                let phone = getters.getClaimById(id).people[0].phones;
                if (phone) {
                    result = phone;
                } else {
                    result = "Телефон не указан";
                }
            } catch (e) {
            }
            return result
        },
        getCurrentClaimId: state => {
            return state.currentClaimId
        },
        setCurrentClaimId: state => claimId => {
            state.currentClaimId = claimId
        },
        getClaimsCount:state=>{
            return state.claimsCount
        },
        getLoadClaimsCount: state => {
            return state.loadClaimsCount
        },
        setLoadClaimsCount: state => {
            state.loadClaimsCount = false
        },
        getNumPages: state => {
            return Math.ceil(state.claimsCount / state.PageSize)
        },

    }
}
