import variables from "../../plugins/variables";
import axios from 'axios';


export default {
    actions: {
        async fetchHeaderMenu(ctx){
            const url = variables.routeHeaderMenu
            const res = await axios({
                url,
                method: 'POST'
            })
            const menu_json = await res.data
            ctx.commit('updateHeaderMenu', menu_json)
        },
        async fetchFooterMenu(ctx){
            const url = variables.routeFooterMenu
            const res = await axios({
                url,
                method: 'POST'
            })
            const menu_json = await res.data
            ctx.commit('updateFooterMenu', menu_json)
        }
    },
    mutations: {
        updateHeaderMenu(state, menu_json){
            state.headerMenu = menu_json
        },
        updateFooterMenu(state, menu_json){
            state.footerMenu = menu_json
        }
    },
    state: {
        headerMenu: [],
        footerMenu: [],
    },
    getters: {
        getHeaderMenu(state){
            return state.headerMenu
        },
        getFooterMenu(state){
            return state.footerMenu
        }
    },
}

