import variables from "../../../plugins/variables";
import axios from "axios";


export default {
    actions: {
        async fetchAviaAirportSearch(ctx, iataCode) {
            const url = variables.routeFetchAirport + iataCode
            try {
                const res = await axios({
                    url,
                    method: 'GET',
                })
                return await res.data.data
            } catch {
                console.log('Ошибка получения ответа от ' + url)
            }
        },
        async fetchAviaCitySearch(ctx, cityStr){
            const url = variables.routeAvia + '/Cities/search/' + cityStr
            try{
                let res = await axios({
                    url,
                })
                let search = await res.data.data
                // console.log('search', search)
                if(Array.isArray(search)){
                    return search
                } else{
                    return []
                }
            }catch(e){
                console.log('Ошибка в actions fetchAviaCitySearch', e)
            }
        },
        async fetchAviaGetAirportsByCityName(ctx, city){
            // console.log('city', city)
            const url = variables.routeAvia + '/Elastic/search/' + city
            let result = []
            try{
                let res = await axios({
                    url,
                })
                //result = await res.data.data.cities[0].airports
                let resCity = await res.data.data.cities[0]
                if(resCity){
                    result = resCity.airports
                }
            }catch(e){
                console.log('Ошибка в actions fetchAviaElastic', e)
            }
            return result
        },
        async fetchAviaFlightsSearch(ctx, service) {
            const url = variables.routeFlightSearch
            const params = service.searchParams
            let result = []
            let responseId = null
            try {

                const res = await axios({
                    url,
                    method: 'GET',
                    params,
                })
                if(Array.isArray(await res.data.data)){
                    result = res.data.data
                    responseId = res.data.responseId
                }
                result.forEach(item => {
                    item.responseId = responseId
                })
                service.response = result
                //мутация только для получения данных для фильтра
                //полученные перелеты в мутации не обрабатываются
                ctx.commit('updateAviaFlights', service)
                return result
            } catch(e) {
                console.log('Ошибка в actions fetchAviaFlightsSearch', e)
            }
        },
    },
    state: {
        aviaFlights: [],
        constructorFlightMinPrice: null,
        constructorFlightMaxPrice: null,
    },
    mutations: {
        updateAviaFlights(state, service){
            //Данные для отрисовки фильтра. Изменяются только здесь
            let filterData = {
                airports: [
                    // {
                        // iata: 'SVO',
                        // name: 'Шереметьево'
                        // cityName: 'Москва',
                        // country: 'Россия',
                        // terminal: 'B'
                    // }
                ],
                dateTimesTo: [],//Двумерный массив. Содержит массивы с двумя значениями (дата и время вылета и прилета)
                dateTimesFrom: [],//Двумерный массив. Содержит массивы с двумя значениями (дата и время вылета и прилета)
                priceMin: 0,
                priceMax: 0,
                numStopsTo: [],//Пересадки, массив с Numbers
                numStopsFrom: [],//Пересадки, массив с Numbers
                stopDurationsTo: [],//Длительность пересадок, массив с Numbers
                stopDurationsFrom: [],//Длительность пересадок, массив с Numbers
                flightDurationsTo: [],//Время в пути, массив с Numbers
                flightDurationsFrom: [],//Время в пути, массив с Numbers
                baggages: [],
                aviaCompanyNames: [],
            }

            //Параметры фильтра
            let filterParams = {
                priceMin: 0,
                priceMax: 0,
                numStopsTo: [],
                numStopsFrom: [],
                baggages: [],
                dateTimesTo: [],
                dateTimesFrom: [],
                airports: [],
                aviaCompanyNames: [],
                stopDurationsTo: [],
                stopDurationsFrom: [],
                flightDurationsTo: [],
                flightDurationsFrom: [],
            }


            let iataAirports = new Set()
            service.response.forEach(item => {
                const way = item.groups[0].flights[0]
                const countSegments = way.segments.length

                let fromDateTime = way.segments[0].fromDate + 'T' + way.segments[0].fromTime
                let toDateTime = way.segments[countSegments - 1].toDate + 'T' + way.segments[countSegments - 1].toTime
                let price = item.prices[0]

                //Получение всех аэропортов
                for(let i=0;i<countSegments;i++){
                    let from = way.segments[i].from
                    let to = way.segments[i].to
                    let airport = {
                        id: null,
                        iata: null,
                        name: null,
                        cityName: null,
                        countryName: null,
                        terminal: null
                    }
                    let airportFrom = Object.assign({}, airport)
                    let airportTo = Object.assign({}, airport)
                    //Получение первого аэропорта сегмента
                    airportFrom.id = from.code
                    airportFrom.iata = from.code
                    airportFrom.name = from.airport
                    airportFrom.cityName = from.city
                    airportFrom.countryName = from.country
                    airportFrom.terminal = from.terminal
                    //Получение второго аэропорта сегмента
                    airportTo.id = to.code
                    airportTo.iata = to.code
                    airportTo.name = to.airport
                    airportTo.cityName = to.city
                    airportTo.countryName = to.country
                    airportTo.terminal = to.terminal

                    if(!iataAirports.has(airportFrom.iata)) filterData.airports.push(airportFrom)
                    if(!iataAirports.has(airportTo.iata)) filterData.airports.push(airportTo)
                    iataAirports.add(airportFrom.iata)
                    iataAirports.add(airportTo.iata)
                }

                filterData.airports.unshift({
                    id: "ALL",
                    iata: "all",
                    name: 'all',
                    cityName: null,
                    countryName: null,
                    terminal: null
                })
                //Получение всех авиакомпаний
                let aviaCompanyName = {
                    id: item.airlinesCode.split(',')[0].trim(),
                    name: item.airlines.replace(',','').trim()
                }
                //Иногда прилетает string, баг со стороны бека
                if (aviaCompanyName.name !== 'string') {
                    filterData.aviaCompanyNames.push(aviaCompanyName)
                }
                filterData.aviaCompanyNames.unshift({
                    id: 'ALL',
                    'name': 'all',
                })

                // let aviaCompanyNames = item.airlines.split(',')
                // aviaCompanyNames.forEach(name => {
                //     filterData.aviaCompanyNames.push(name.trim())
                // })
                //Иногда прилетает string, баг со стороны бека
                // filterData.aviaCompanyNames = filterData.aviaCompanyNames.filter(aviaCompany => aviaCompany!=='string')
                // filterData.aviaCompanyNames.unshift('all')

                //Получение длительности пересадок
                let stopDuration = way.stops.reduce((acc, current) => {
                    return acc + current.duration
                }, 0)
                if(stopDuration) filterData.stopDurationsTo.push(stopDuration)
                //Получение времени в пути. Сумма времени в воздухе и времени на пересадки
                let flightDuration = 0
                if(Array.isArray(way.segments)){
                    flightDuration = way.segments.reduce((acc, current) => {
                        return acc + current.duration
                    }, 0)
                }
                if(Array.isArray(way.stops)){
                    flightDuration = flightDuration + way.stops.reduce((acc, current) => {
                        return acc + current.duration
                    }, 0)
                }

                filterData.flightDurationsTo.push(flightDuration)
                filterData.dateTimesTo.push([fromDateTime, toDateTime])
                //Количество пересадок
                filterData.numStopsTo.push(countSegments - 1)

                //Установка минимальной и максимальной цены в рублях
                //Проверка бывает такое что прилетает 0 или отрицательное значение
                //Такие перелеты нам не нужны
                if(price > 0){
                    if(!filterData.priceMin || filterData.priceMin > price) filterData.priceMin = price
                    if(!filterData.priceMax || filterData.priceMax < price) filterData.priceMax = price
                }
            })

            //Доп. обработка полученных значений
            ////Формируется массив с уникальным сочетанием аэропорта и терминала
            let intermediate = []
            filterData.airports = filterData.airports.filter(item => {
                let result = false
                let isIata = false
                let isTerminal = false
                if(intermediate.length === 0){
                    intermediate.push(item)
                    result = true
                } else{
                    isIata = intermediate.some(elem => {
                        return elem.iata === item.iata
                    })
                    isTerminal = intermediate.some(elem => elem.terminal === item.terminal)
                    intermediate.push(item)
                    if(!isIata || !isTerminal) result = true
                }
                return result
            })

            //Условие чтобы минимальная цена не была равна 0
            if(filterData.priceMin > 200){
                filterData.priceMin = Math.floor(filterData.priceMin / 100) * 100 - 100
            } else {
                filterData.priceMin = Math.floor(filterData.priceMin)
            }

            filterData.priceMax = Math.floor(filterData.priceMax / 100) * 100 + 100
            filterData.priceStep = 100
            filterData.aviaCompanyNames = filterData.aviaCompanyNames.filter((name, index, self) =>
                index === self.findIndex((t) => (
                    t.name === name.name
                ))
            )
            // filterData.aviaCompanyNames = [... new Set(filterData.aviaCompanyNames)]
            filterData.stopDurationsTo.push(0)
            filterData.stopDurationsTo = [... new Set(filterData.stopDurationsTo)]
            filterData.flightDurationsTo = [... new Set(filterData.flightDurationsTo)]
            filterData.numStopsTo = [... new Set(filterData.numStopsTo)]
            //Добавление варианта 'Все' для пересадок
            filterData.numStopsTo.unshift(-1)
            //Сортировка по возрастанию
            filterData.numStopsTo = filterData.numStopsTo.sort((a,b)=>a-b)
            filterData.stopDurationsTo = filterData.stopDurationsTo.sort((a,b)=>a-b)
            filterData.flightDurationsTo = filterData.flightDurationsTo.sort((a,b)=>a-b)
            //TODO
            filterData.baggages = [
                'Багаж и ручная кладь',
                'Без багажа',
            ]
            //Настройки фильтра по умолчанию
            filterParams.priceMin = filterData.priceMin
            filterParams.priceMax = filterData.priceMax
            filterParams.numStopsTo = [...filterData.numStopsTo]
            filterParams.baggages = [...filterData.baggages]
            filterParams.dateTimesTo = [...filterData.dateTimesTo]
            filterParams.airports = [...filterData.airports]
            filterParams.aviaCompanyNames = [...filterData.aviaCompanyNames]
            filterParams.stopDurationsTo = [...filterData.stopDurationsTo]
            filterParams.flightDurationsTo = [...filterData.flightDurationsTo]

            service.filterData = filterData
            service.filterParams = filterParams
        },
        updateAviaFlightsOld(state, service){
            // console.log('service', service)
            // let result = []
            // try{
            //     service.response.forEach(item => {
                    // let flight = {
                    //     index: null,
                    //     airlines: null,
                    //     airlinesCode: null,
                    //     features: {},
                    //     prices: [],
                    //     serviceClass: null,
                    //     flight: null,
                    //     filters: {
                    //         'baggage': {
                    //             'title': 'Неизвестный багаж',
                    //             'weight': null,
                    //             'type': 'Все'
                    //         },
                    //     },
                    // }
                    // flight.index = item.index
                    // flight.airlines = item.airlines
                    // flight.airlinesCode = item.airlinesCode
                    // flight.features = item.features
                    // flight.prices = item.prices
                    // flight.serviceClass = item.serviceClass
                    // item.groups[0].flights.forEach(elem => {
                    //     flight.flight = elem
                    //     result.push(Object.assign({}, flight))
                    // })
                    //собираем данные для фильтров по каждому рейсу
                    // flight.filters.price = item.prices[0]
                    // flight.filters.airline = item.airlines

                    // item.features.forEach(feature => {
                    //     if (feature.type === 'Baggage') {
                    //         flight.filters.baggage.title = feature.content
                    //         flight.filters.baggage.weight = feature.value
                    //         if (Number.isInteger(feature.value) && feature.value > 0) {
                    //             flight.filters.baggage.type = 'Багаж и ручная кладь'
                    //         } else {
                    //             flight.filters.baggage.type = 'Без багажа'
                    //         }
                    //     }
                    // })

                    // прокидываем кол-во пересадок на каждом маршруте и флаг noStops = true|false
                    // flight.filters.stopsTo = item.groups[0].stopsCount
                    // flight.filters.noStops = item.groups[0].stopsCount === 0
                    // flight.filters.singleStop = item.groups[0].stopsCount === 1
                    // flight.filters.doubleStop = item.groups[0].stopsCount === 2
                    // if (flight.filters.noStops) {
                    //     flight.filters.stopsType = 'Без пересадок'
                    // } else if (flight.filters.singleStop) {
                    //     flight.filters.stopsType = '1 пересадка'
                    // } else if (flight.filters.doubleStop) {
                    //     flight.filters.stopsType = '2 пересадки'
                    // } else {
                    //     flight.filters.stopsType = 'Все'
                    // }

                    // инфа о времени вылета-прилета, продолжительности полета и остановок
                    //const way = item.groups[0].flights[0]

                    // продолжительность всех остановок на маршруте
                    // flight.filters.stopsTime = way.stops.reduce((acc, current) => {
                    //     return acc + current.duration
                    // }, 0)

                    // поле для фильтрации (НЕ удалять)
                    //flight.filters.maxStopsDuration = flight.filters.stopsTime
                    // продолжительность рейса/ов (все сегменты, если несколько)
                    // flight.filters.flightDuration = way.segments.reduce((acc, current) => {
                    //     return acc + current.duration
                    // }, 0)
                    // общая продолжительность - полет + остановки
                    //flight.filters.totalDuration = flight.filters.flightDuration + flight.filters.stopsTime
                    // дата, время и аэропорт начала маршрута
                    // flight.filters.fromTime    = way.segments[0].fromTime
                    // flight.filters.fromDate    = way.segments[0].fromDate
                    // flight.filters.startFrom   = flight.filters.fromDate + 'T' + flight.filters.fromTime
                    // по дизайну такой формат вывода в фильтре .format('H[ч] mm[мин], D MMM')
                    // flight.filters.airportFrom = way.segments[0].from.airport
                    // flight.filters.cityFrom    = way.segments[0].from.city
                    // дата, время и аэропорт окончания маршрута
                    // если рейс прямой
                    // if (way.segments.length === 1) {
                    //     flight.filters.toTime    = way.segments[0].toTime
                    //     flight.filters.toDate    = way.segments[0].toDate
                    //     flight.filters.finishTo  = flight.filters.toDate + 'T' + flight.filters.toTime
                    //     flight.filters.airportTo = way.segments[0].to.airport
                    //     flight.filters.cityTo    = way.segments[0].to.city
                    // } else { // если есть пересадки
                    //     flight.filters.toTime    = way.segments[way.segments.length - 1].toTime
                    //     flight.filters.toDate    = way.segments[way.segments.length - 1].toDate
                    //     flight.filters.finishTo  = flight.filters.toDate + 'T' + flight.filters.toTime
                    //     flight.filters.airportTo = way.segments[way.segments.length - 1].to.airport
                    //     flight.filters.cityTo    = way.segments[way.segments.length - 1].to.city
                    // }
            //
            //     })
            // }catch(e){
            //     console.log('Ошибка в мутации updateAviaFlight', e)
            // }
            // service.flights = result
            //
            // // ВРЕМЕННО
            // let portsFrom = service.flights.map(el => el.filters.airportFrom)
            // let portsTo = service.flights.map(el => el.filters.airportTo)
            // let airlines = service.flights.map(el => el.filters.airline)
            // let minPrice = service.flights[0].prices[0]
            // service.flights.forEach(flight => {
            //     if (flight.prices[0] < minPrice) {
            //         minPrice = flight.prices[0]
            //     }
            // })
            // let maxPrice = service.flights[0].prices[0]
            // service.flights.forEach(flight => {
            //     if (flight.prices[0] > maxPrice) {
            //         maxPrice = flight.prices[0]
            //     }
            // })
            //
            // service.filterParams = {
            //     'cityFrom': {
            //         'title': service.flights[0].filters.cityFrom,
            //         'airports': [...new Set(portsFrom)]
            //     },
            //     'cityTo': {
            //         'title': service.flights[0].filters.cityTo,
            //         'airports': [...new Set(portsTo)]
            //     },
            //     'fromDate': service.flights[0].filters.fromDate,
            //     'toDate': service.flights[0].filters.toDate,
            //     'priceMin': Math.floor(minPrice / 100) * 100,
            //     'priceMax': Math.floor(maxPrice / 100) * 100,
            //     'priceMinDiapason': Math.floor(minPrice / 100) * 100,
            //     'priceMaxDiapason': Math.floor(maxPrice / 100) * 100,
            //     'priceStep': 100,  // временно hardcode
            //     'stops': [
            //         'Без пересадок',
            //         '1 пересадка',
            //         '2 пересадки',
            //     ],
            //     'baggage': [
            //         'Багаж и ручная кладь',
            //         'Без багажа',
            //     ],
            //     'airlinesAll': [...new Set(airlines)],
            //     'airlinesFiltered': [...new Set(airlines)]
            //}
            // ВРЕМЕННО

            //console.log('service', service)

        },


    },
    getters: {

    }
}
