//Конвертер объектов удобен потому-что
//1. Выполняет функцию документации, в конверторе объявлены переменные по типу objectModel - благодаря которым
//   сразу видно структуру объекта и типы данных в нем
//2. Позволяет облегчить изменение и написание компонентов, беря на себя логику видоизменения объектов
//3. Позволяет уйти от вида объектов которые мы получаем с бека. Объекты содержит только поля необходимые для
//   реализации компонентов (какой-то бизнес логики), и не содержат ничего лишнего (что приводит к путанице или
//   более долгому анализу чужого/своего кода)
//4. При возникновении ошибок или изменении бизнес логики, объекты проще изменять в одном месте
//5. Такая организация изменения объектов в коде зачастую позволяет избавиться от лишних геттеров и т.д. которые только
//   все усложняют
//6. Не надо прыгать по файлам, все в одном месте
//7. Проверку данных можно производить в одном месте
//В конвертерах не используются какие-либо запросы или обращения за данными в другой источник.

import {capitalize, getHotelImages, getTourImages, getTourKey} from "./helpers";
import {getOfferRoom} from "./helpers";
import {getOfferMeal} from "./helpers";
import moment from "moment";

//Метод конвертирует объект samoTour в объект tour
//Необходимый в частности для компонентов поисковой выдачи
//cdn для передачи можно получить в компоненте с помощью this.$geoCdnLink или window.geo_cdn_link
export function convertSamoTourToTour(samoTour, cdn){
    let tourModel = {
        id: null,
        hotelId: null,
        tourName: null,
        hotelName: null,
        hotelFriendlyName: null,
        hotelCity: {
            id: null,
            name: null,
            samoId: null,
        },
        hotelRegion: {
            id: null,
            name: null,
        },
        hotelCountry: {
            id: null,
            name: null
        },
        spo: null,
        topHotels: null,
        images: [],
        geoPriorities: [],
        meal: {
            groupId: null,
            name: null,
        },
        room: {
            id: null,
            name: null,
        },
        price: {
            value: null,
            oldValue: null,
            currencyAlias: null,
            currencyId: null
        },
        priceId: null,
        startDate: null,
        isRegularFlight: null,
        tourTypeId: null,
        geoFilters: [],
        flightStartTime: null,
        flightAvailability: null,
        hotelAvailability: null,
        nightsCount: null,
        attributes: [],
        stars: null,
        adults: null,
        children: [],
        infants: null,
        rating: null,
        stopSale: null,
        best: null,
        hotelServices: [],
        specialServices: [],
        isInstantConfirmation: null, //Мгновенное подтверждение
        isCashBack: null, //Кэшбек
        lat: null, //Координаты
        lng: null, //Координаты
    }
    tourModel.id = samoTour.id
    tourModel.hotelId = samoTour.hotel.id
    tourModel.tourName = samoTour.name
    tourModel.hotelName = samoTour.hotel.name
    tourModel.hotelCity.id = samoTour.hotel.cityId
    tourModel.hotelCity.name = samoTour.hotel.cityName
    tourModel.hotelCity.samoId = samoTour.hotel.samoCityId
    tourModel.hotelRegion.id = samoTour._region.id
    tourModel.hotelRegion.name = samoTour._region.name
    tourModel.hotelCountry.id = samoTour._country.id
    tourModel.hotelCountry.name = samoTour._country.name
    tourModel.spo = samoTour.hotel.spo
    tourModel.topHotels = samoTour.hotel.topHotels
    tourModel.images = getTourImages(samoTour, cdn, ['/storage/images/actionHotel.png'], 5)
    tourModel.meal.groupId = samoTour.accommodationPriceOffer[0].mealTypeGroupId
    tourModel.meal.name = samoTour.accommodationPriceOffer[0].mealTypeName
    tourModel.room.id = samoTour.accommodationPriceOffer[0].roomId
    tourModel.room.name = samoTour.accommodationPriceOffer[0].room
    tourModel.roomName = samoTour.accommodationPriceOffer[0].room
    tourModel.price = samoTour.accommodationPriceOffer[0].price
    tourModel.isRegularFlight = samoTour.accommodationPriceOffer[0].isRegularFlight
    tourModel.tourTypeId = samoTour.currentOffer.tourTypeId
    tourModel.priceId = samoTour.priceId
    tourModel.startDate = samoTour.startDate
    tourModel.endDate = samoTour.checkOutDate
    let flightStartTime = samoTour.accommodationPriceOffer[0].departureTimeOptions || '00:00:00'
    tourModel.flightStartTime = flightStartTime.split(' ')[0]
    tourModel.flightAvailability = samoTour.accommodationPriceOffer[0].flightAvailability
    tourModel.hotelAvailability = samoTour.hotel.hotelAvailability
    tourModel.nightsCount = samoTour.hotel.nightsCount
    tourModel.attributes = samoTour.hotel.attributes
    tourModel.stars = samoTour.hotel.stars
    tourModel.rating = samoTour.hotel.rating
    tourModel.hotelFriendlyName = samoTour.hotel.urlFriendlyName
    tourModel.stopSale = samoTour.accommodationPriceOffer[0].stopSale
    tourModel.best = samoTour.accommodationPriceOffer[0].best
    tourModel.isInstantConfirmation = samoTour.isInstantConfirmation
    tourModel.isCashBack = samoTour.accommodationPriceOffer.filter((offer) => {
        return typeof offer.availablePromos !== 'undefined' && offer.availablePromos.some(item => item === "CashBack");
    }).length > 0
    tourModel.hotelServices = samoTour.hotel.hotelServices
    tourModel.specialServices = samoTour.hotel.specialServices
    tourModel.lat = samoTour.hotel.lat
    tourModel.lng = samoTour.hotel.lng
    if(Array.isArray(samoTour.hotel.geoPriorities)) tourModel.geoPriorities = samoTour.hotel.geoPriorities

    //Все айдишники фильтрации и гео цмс кладутся в одно место
    if (Array.isArray(samoTour.hotel.descriptionBlockFilters)) {
        let filters = samoTour.hotel.descriptionBlockFilters

        filters.forEach(parentBlock => {
            parentBlock.filters.forEach(id => {
                tourModel.geoFilters.push(id)
            })
        })
    }

    return tourModel
}

export function convertCrmOfferToTour(objToConverter, cdn){
    // console.log(objToConverter, 'objToConverter')
    let tourModel = {
        id: null,
        hotelId: null,
        tourName: null,
        hotelName: null,
        hotelFriendlyName: null,
        hotelCity: {
            id: null,
            name: null,
            samoId: null,
        },
        hotelRegion: {
            id: null,
            name: null,
        },
        hotelCountry: {
            id: null,
            name: null
        },
        spo: null,
        topHotels: null,
        images: [],
        geoPriorities: [],
        meal: {
            groupId: null,
            name: null,
        },
        room: {
            id: null,
            name: null,
        },
        price: {
            value: null,
            oldValue: null,
            currencyAlias: null,
            currencyId: null
        },
        priceId: null,
        startDate: null,
        isRegularFlight: null,
        tourTypeId: null,
        geoFilters: [],
        flightStartTime: null,
        flightAvailability: null,
        hotelAvailability: null,
        nightsCount: null,
        attributes: [],
        stars: null,
        adults: null,
        children: [],
        infants: null,
        rating: null,
        stopSale: null,
        best: null,
        hotelServices: [],
        specialServices: [],
        isInstantConfirmation: null, //Мгновенное подтверждение
        isCashBack: null, //Кэшбек
        lat: null, //Координаты
        lng: null, //Координаты
    }

    tourModel.id = objToConverter.crmOfferTour.id //Тур кей
    tourModel.hotelId = objToConverter.crmOfferTour.hotelId //EbgId
    tourModel.tourName = objToConverter.crmOfferTour.name
    tourModel.hotelName = objToConverter.extendedHotelInfo.localizedName
    tourModel.hotelFriendlyName = objToConverter.extendedHotelInfo.urlName
    tourModel.hotelCity.id = objToConverter.crmOfferTour.cityId
    tourModel.hotelCity.name = objToConverter.crmOfferTour.cityName
    // tourModel.hotelCity.samoId = objToConverter.crmOfferTour.cityId
    tourModel.hotelRegion.id = objToConverter.regionInfo.ebgNodeId
    tourModel.hotelRegion.name = objToConverter.regionInfo.localizedName
    tourModel.hotelCountry.id = objToConverter.crmOfferTour.countryId
    tourModel.hotelCountry.name = objToConverter.crmOfferTour.countryName
    // tourModel.spo = objToConverter.hotel.spo
    tourModel.topHotels = objToConverter.extendedHotelInfo.topHotels
    tourModel.images = getHotelImages(objToConverter.extendedHotelInfo, cdn, ['/storage/images/actionHotel.png'], 5)
    tourModel.meal.groupId = objToConverter.crmOfferTour.mealId
    // tourModel.meal.name = objToConverter.mealId
    tourModel.room.id = objToConverter.crmOfferTour.roomId
    // tourModel.room.name = objToConverter.dataFromAjast.tour.hotel.room.name
    // tourModel.roomName = objToConverter.dataFromAjast.tour.hotel.room.name
    tourModel.price.currencyAlias = objToConverter.crmOfferTour.currency.currencyAlias
    tourModel.price.currencyId = objToConverter.crmOfferTour.currency.currencyId
    tourModel.price.value = objToConverter.crmOfferTour.price
    tourModel.startDate = objToConverter.crmOfferTour.startDate
    tourModel.isRegularFlight = objToConverter.crmOfferTour.isRegularFlight
    tourModel.tourTypeId = objToConverter.crmOfferTour.tourTypeId

    tourModel.flightStartTime = objToConverter.crmOfferTour.flightOption.forwardFlight.startDateTime
    // tourModel.flightAvailability = objToConverter.geoFilters
    // tourModel.hotelAvailability = objToConverter.geoFilters
    tourModel.nightsCount = objToConverter.crmOfferTour.nightsCount
    // tourModel.attributes = objToConverter.geoFilters
    tourModel.stars = objToConverter.extendedHotelInfo.hotelCategory
    tourModel.adults = objToConverter.crmOfferTour.paxes.adultsCount
    // tourModel.children = objToConverter.crmOfferTour.paxes.childrenCount

    let childrenCount = objToConverter.crmOfferTour.paxes.childrenCount

    for (let i = 0; i < childrenCount; i++){
        tourModel.children.push(10)
    }

    tourModel.infants = objToConverter.crmOfferTour.paxes.infantsCount
    // tourModel.rating = objToConverter.geoFilters
    // tourModel.stopSale = objToConverter.geoFilters
    // tourModel.best = objToConverter.geoFilters
    tourModel.hotelServices = objToConverter.extendedHotelInfo.recommendations

    // tourModel.specialServices = objToConverter.services

    // tourModel.isInstantConfirmation = objToConverter.geoFilters
    // tourModel.isCashBack = objToConverter.geoFilters
    tourModel.lat = parseFloat(objToConverter.extendedHotelInfo.latitude.replace(',', '.'))
    tourModel.lng = parseFloat(objToConverter.extendedHotelInfo.longitude.replace(',', '.'))

    //Все айдишники фильтрации и гео цмс кладутся в одно место
    // if (Array.isArray(objToConverter.extendedHotelInfo.descriptionBlocks)) {
    //     let filters = objToConverter.extendedHotelInfo.descriptionBlocks
    //
    //     filters.forEach(parentBlock => {
    //         parentBlock.filters.forEach(id => {
    //             tourModel.geoFilters.push(id)
    //         })
    //     })
    // }

    // console.log(tourModel,'tourModel')

    return tourModel
}

//samoOffersResponse нужен для получения питания и комнат
//tour нужен для обогащения
export function convertSamoOfferToOffer(samoOffersResponse, samoOffer, tour){
    let offerModal = {
        id: null, //Необязательный параметр. Устанавливается и используется по Vue
        hotelId: null,
        tourName: null,
        hotelName: null,
        hotelFriendlyName: null,
        hotelCountry: {
            id: null,
            name: null
        },
        hotelCity: {
            id: null,
            name: null,
            samoId: null,
        },
        meal: {
            groupId: null,
            name: null,
        },
        room: {
            id: null,
            name: null,
        },
        startDate: null,
        endDate: null,
        checkinDate: null,
        checkoutDate: null,
        daysCount: null,
        nightsCount: null,
        stars: null,
        fligthStartTime: '00:00:00', //Ждем Само
        attributes: null, //Пока дублирует атрибуты тура, вопрос в необходимости этого поля
        spo: null, //Пока дублирует атрибуты тура, вопрос в необходимости этого поля
        flightAvailability: null,
        hotelAvailability: null,
        price: {
            value: null,
            oldValue: null,
            currencyAlias: null,
            currencyId: null
        },
        tourKey: null,
        stopSale: null,
        best: null,
        isInstantConfirmation: null,
    }
    offerModal.hotelId = samoOffer.hotelId
    offerModal.tourName = tour.tourName
    offerModal.hotelName = samoOffer.hotelName
    offerModal.hotelFriendlyName = tour.hotelFriendlyName
    offerModal.hotelCountry = tour.hotelCountry
    offerModal.hotelCity = tour.hotelCity
    offerModal.meal = getOfferMeal(samoOffersResponse, samoOffer)
    offerModal.room = getOfferRoom(samoOffersResponse, samoOffer)
    offerModal.startDate = samoOffer.tourStartDate
    offerModal.endDate = samoOffer.tourEndDate
    offerModal.checkinDate = samoOffer.checkinDate
    offerModal.checkoutDate = samoOffer.checkoutDate
    offerModal.daysCount = samoOffer.daysCount
    offerModal.nightsCount = samoOffer.nightsCount
    offerModal.attributes = tour.attributes
    offerModal.spo = tour.spo
    offerModal.stars = tour.stars
    offerModal.flightAvailability = samoOffer.freights
    offerModal.hotelAvailability = tour.hotelAvailability
    offerModal.price = samoOffer.price
    offerModal.tourKey = samoOffer.tourKey
    offerModal.stopSale = samoOffer.stopSale
    offerModal.best = samoOffer.best
    offerModal.isInstantConfirmation = samoOffer.isInstantConfirmation

    return offerModal
}

//Передаем массив дней, получаем объект DynamicObject
//Возможно имеет смысл данный метод разместить внутри компонента dynamicChart.vue
//Если есть предложения в рамках одного дня, выбирается самое дешевое
export function convertDynamicDaysToDynamicObject(dynamicDays){
    let dynamicObjModel = {
        months: [],
    }
    let dynamicMonth = {
        name: '',
        days: []
    }
    // let dynamicDayModel = {
    //     id: null, //Необязательный параметр, устанавливается и используется во Vue (для связки с оффером)
    //     isoDate: null, //'2022-03-07'
    //     price: {
    //         value: null, //5000
    //         currencyAlias: null, //'RUB'
    //     }
    //     isActive: false,
    // }
    let days = [] //Здесь будет лежать не более одного предложения в день
    //Выбор самого дешевого варианта в рамках одного дня, остальные откидываются
    let daysSet = new Set()
    dynamicDays.forEach(day => {
        daysSet.add(moment(day.isoDate).format('YYYY-MM-DD'))
    })

    daysSet.forEach(elem => {
        //Получаем все предложения определенного дня
        let oneDayOffers = dynamicDays.filter(day => moment(day.isoDate).format('YYYY-MM-DD') === elem)
        //Перебираем предложения для каждого дня, и определяем лучшую цену
        // console.log('oneDayOffers', oneDayOffers)
        let bestPrice = null
        oneDayOffers.forEach(day => {
            let price = day.price.value
            if(!bestPrice || bestPrice === price) bestPrice = price
        })
        //Получаем одно предложение
        let oneDay = oneDayOffers.find(day => day.price.value === bestPrice)
        days.push(oneDay)
    })

    //Получаем месяца
    let monthsSet = new Set()
    let months = [] //В результате здесь будут отсортированные месяца
    days.forEach(day => {
        monthsSet.add(moment(day.isoDate).format('YYYY-MM'))
    })

    //Получаем месяца отсортированные по возрастанию
    months = [...monthsSet].sort((prev, next)=>{
        let result = 0
        if(moment(prev, 'YYYY-MM').isAfter(next, 'YYYY-MM')){
            result = 1
        }
        if(moment(prev, 'YYYY-MM').isBefore(next, 'YYYY-MM')){
            result = -1
        }
        return result
    })

    months.forEach(elem => {
        let month = Object.assign({}, dynamicMonth)
        month.name = capitalize(moment(elem, 'YYYY-MM').format('MMMM'))

        let daysInThisMonth = days.filter(day => {
            return moment(day.isoDate).format('YYYY-MM') === elem
        })

        //Установка дат в объект месяца, отсортированных по возрастанию
        month.days = daysInThisMonth.sort((prev, next) => {
            let result = 0
            if(moment(prev.isoDate).isAfter(next.isoDate)){
                result = 1
            }
            if(moment(prev.isoDate).isBefore(next.isoDate)){
                result = -1
            }
            return result
        })

        dynamicObjModel.months.push(month)
    })

    return dynamicObjModel
}

export function convertTourToPaymentObject(tour, countryFromId){
    let tourModel = {
        id: null,
        hotelId: null,
        tourName: null,
        hotelName: null,
        hotelFriendlyName: null,
        hotelCity: {
            id: null,
            name: null,
            samoId: null,
        },
        hotelCountry: {
            id: null,
            name: null
        },
        spo: null,
        topHotels: null,
        images: [],
        meal: {
            groupId: null,
            name: null,
        },
        room: {
            id: null,
            name: null,
        },
        price: {
            value: null,
            oldValue: null,
            currencyAlias: null,
            currencyId: null
        },
        priceId: null,
        startDate: null,
        flightStartTime: null,
        nightsCount: null,
        attributes: [],
        stars: [],
    }
    let paymentObject = {
        id: null,
        hotelId: null,
        countryFromId: null,
        countryId: null,
        countryName: null,
        cityId: null,
        cityName: null,
        roomId: null,
        mealId: null,
        mealName: null,
        travellers: [],
        paxes: null,
        name: null,
        htplace: null,
        startDate: null,
        endDate: null,
        checkinDate: null,
        checkoutDate: null,
        daysCount: null,
        nightsCount: null,
        price: {
            currency: null,
            currencyId: null,
            currencyAlias: null,
        },
        insurances: [],
        services: [],
        visaOptions: [],
        selectedFlightOptionId: null,
        tourPartner: null,
    }

    try {
        paymentObject.id = getTourKey(tour.priceId)
        paymentObject.hotelId = tour.hotelId
        paymentObject.countryFromId = countryFromId
        paymentObject.countryId = tour.hotelCountry.id
        paymentObject.countryName = tour.hotelCountry.name
        paymentObject.cityId = tour.hotelCity.id
        paymentObject.cityName = tour.hotelCity.name
        paymentObject.roomId = tour.room.id
        paymentObject.mealId = tour.meal.groupId
        paymentObject.mealName = tour.meal.name
        paymentObject.name = tour.hotelName + ' (' + tour.room.name + ')'
        paymentObject.htplace = tour.room.name
        paymentObject.startDate = moment(tour.startDate).format('YYYY-MM-DD')
        paymentObject.endDate = moment(tour.endDate).add(tour.nightsCount + 1, 'days').format('YYYY-MM-DD')
        paymentObject.checkinDate = moment(tour.startDate).format('YYYY-MM-DD')
        paymentObject.checkoutDate = moment(tour.endDate).add(tour.nightsCount + 1, 'days').format('YYYY-MM-DD')
        paymentObject.daysCount = tour.nightsCount + 1
        paymentObject.nightsCount = tour.nightsCount
        paymentObject.price = tour.price.value
        paymentObject.currency = {
            currencyId: parseInt(tour.price.currencyId),
            currencyAlias: tour.price.currencyAlias
        }
    } catch(e){
        console.log('Ошибка в convertTourToPaymentObject', e)
    }

    return paymentObject
}
export function convertOfferToPriceObject(offer, countryFromId){
    let offerModal = {
        hotelId: null,
        tourName: null,
        hotelName: null,
        hotelCountry: {
            id: null,
            name: null
        },
        hotelCity: {
            id: null,
            name: null,
            samoId: null,
        },
        meal: {
            groupId: null,
            name: null,
        },
        room: {
            id: null,
            name: null,
        },
        startDate: null,
        endDate: null,
        checkinDate: null,
        checkoutDate: null,
        daysCount: null,
        nightsCount: null,
        price: {
            value: null,
            oldValue: null,
            currencyAlias: null,
            currencyId: null
        },
        tourKey: null,
    }

    let paymentObject = {
        id: null,
        hotelId: null,
        countryFromId: null,
        countryId: null,
        countryName: null,
        cityId: null,
        cityName: null,
        roomId: null,
        mealId: null,
        mealName: null,
        travellers: [],
        paxes: null,
        name: null,
        htplace: null,
        startDate: null,
        endDate: null,
        checkinDate: null,
        checkoutDate: null,
        daysCount: null,
        nightsCount: null,
        price: {
            currency: null,
            currencyId: null,
            currencyAlias: null,
        },
        insurances: [],
        services: [],
        visaOptions: [],
        selectedFlightOptionId: null,
        tourPartner: null,
    }

    try {
        paymentObject.id = getTourKey(offer.tourKey)
        paymentObject.hotelId = offer.hotelId
        paymentObject.countryFromId = countryFromId
        paymentObject.countryId = offer.hotelCountry.id
        paymentObject.countryName = offer.hotelCountry.name
        paymentObject.cityId = offer.hotelCity.id
        paymentObject.cityName = offer.hotelCity.name
        paymentObject.roomId = offer.room.id
        paymentObject.mealId = offer.meal.groupId
        paymentObject.mealName = offer.meal.name
        paymentObject.name = offer.hotelName + ' (' + offer.room.name + ')'
        paymentObject.htplace = offer.room.name
        paymentObject.startDate = moment(offer.startDate).format('YYYY-MM-DD')
        paymentObject.endDate = moment(offer.endDate).format('YYYY-MM-DD')
        paymentObject.checkinDate = moment(offer.checkinDate).format('YYYY-MM-DD')
        paymentObject.checkoutDate = moment(offer.checkoutDate).format('YYYY-MM-DD')
        paymentObject.daysCount = offer.daysCount
        paymentObject.nightsCount = offer.nightsCount
        paymentObject.price = offer.price.value
        paymentObject.currency = {
            currencyId: parseInt(offer.price.currencyId),
            currencyAlias: offer.price.currencyAlias
        }
    } catch(e){
        console.log('Ошибка в convertOfferToPriceObject', e)
    }

    return paymentObject
}

//Из response аджаста получаем массив страховок
export function convertAjastResponseToInsurances(ajastResponse){
    let result = []
    if(!ajastResponse.tour.insurances) return result
    let insuranceModal = {
        id: null,
        startDate: null,
        endDate: null,
        type: {
            id: null,
            name: null,
        },
        price: {
            value: null,
            currencyAlias: null,
            currencyId: null
        },
        url: null,
        options: [],
        name: null,
    }

    let ajastInsurances = ajastResponse.tour.insurances

    result = ajastInsurances.map(ajastInsurance => {
        let insurance = Object.assign({}, insuranceModal)
        insurance.id = ajastInsurance.id
        insurance.startDate = ajastInsurance.startDate
        insurance.endDate = ajastInsurance.endDate
        insurance.type = ajastInsurance.type
        insurance.price.value = ajastInsurance.price.amount
        insurance.price.currencyAlias = ajastInsurance.price.currencyAlias
        insurance.price.currencyId = ajastInsurance.price.currencyId
        insurance.url = ajastInsurance.url
        insurance.options = ajastInsurance.options
        insurance.name = ajastInsurance.name


        return insurance
    })

    return result
}

//Из response аджаста получаем массив трансферов
export function convertAjastResponseToTransfers(ajastResponse){
    let result = []
    if(!ajastResponse.tour.services) return result
    let transferModal = {
        id: null,
        startDate: null,
        endDate: null,
        type: {
            id: null,
            name: null,
        },
        price: {
            value: null,
            currencyAlias: null,
            currencyId: null
        },
        name: null,
    }

    let ajastTransfers = ajastResponse.tour.services.filter(service => service.category.id === 1)

    result = ajastTransfers.map(ajastTransfer => {
        let transfer = Object.assign({}, transferModal)

        transfer.id = ajastTransfer.id
        transfer.startDate = ajastTransfer.startDate
        transfer.endDate = ajastTransfer.endDate
        transfer.type = ajastTransfer.type
        transfer.price.value = ajastTransfer.price.amount
        transfer.price.currencyAlias = ajastTransfer.price.currencyAlias
        transfer.price.currencyId = ajastTransfer.price.currencyId
        transfer.name = ajastTransfer.name

        return transfer
    })

    return result
}

//Из response аджаста получаем массив экскурсий
export function convertAjastResponseToExcursions(ajastResponse){
    let result = []
    if(!ajastResponse.tour.services) return result
    let excursionModal = {
        id: null,
        startDate: null,
        endDate: null,
        type: {
            id: null,
            name: null,
        },
        price: {
            value: null,
            currencyAlias: null,
            currencyId: null
        },
        name: null,
    }

    let ajastExcursions = ajastResponse.tour.services.filter(service => service.category.id === 2)

    result = ajastExcursions.map(ajastExcursion => {
        let excursion = Object.assign({}, excursionModal)

        excursion.id = ajastExcursion.id
        excursion.startDate = ajastExcursion.startDate
        excursion.endDate = ajastExcursion.endDate
        excursion.type = ajastExcursion.type
        excursion.price.value = ajastExcursion.price.amount
        excursion.price.currencyAlias = ajastExcursion.price.currencyAlias
        excursion.price.currencyId = ajastExcursion.price.currencyId
        excursion.name = ajastExcursion.name

        return excursion
    })

    return result
}

//Из response аджаста получаем доп. услуги
//Вторым параметром передается массив с id категорий услуг
//которые исключаются
//например [1,2]
export function convertAjastResponseToServices(ajastResponse, exceptionIds){
    //category.id: 1 - трансфер, 2 - Экскурсия
    let result = []
    if(!ajastResponse.tour.services) return result
    let serviceModal = {
        id: null,
        startDate: null,
        endDate: null,
        type: {
            id: null,
            name: null,
        },
        price: {
            value: null,
            currencyAlias: null,
            currencyId: null
        },
        name: null,
    }

    let ajastServices = ajastResponse.tour.services.filter(service => !exceptionIds.some(id => id === service.category.id))

    result = ajastServices.map(ajastService => {
        let service = Object.assign({}, serviceModal)

        service.id = ajastService.id
        service.startDate = ajastService.startDate
        service.endDate = ajastService.endDate
        service.type = ajastService.type
        service.price.value = ajastService.price.amount
        service.price.currencyAlias = ajastService.price.currencyAlias
        service.price.currencyId = ajastService.price.currencyId
        service.name = ajastService.name

        return service
    })

    return result
}
//Из response аджаста (2-го запроса) получаем перелеты
export function convertAjastResponseToFlights(ajastResponse){
    let result = []
    if(!ajastResponse.tour || !ajastResponse.tour.flightOptions) return result
    let modalFlights = {
        forwardFlight: [],
        backwardFlight: [],
    }
    let modalSegment = {
        startDateTime: null,
        endDateTime: null,
        flightNumber: null,
        aviacompany: {
            id: null,
            name: null,
        },
        class: {
            id: null,
            name: null,
        },
        startWayPoint: {
            country: {
                id: null,
                name: null,
                alias: null,
            },
            city: {
                id: null,
                name: null,
                alias: null,
            },
            port: {
                id: null,
                name: null,
                alias: null,
            }
        },
        endWayPoint: {
            country: {
                id: null,
                name: null,
                alias: null,
            },
            city: {
                id: null,
                name: null,
                alias: null,
            },
            port: {
                id: null,
                name: null,
                alias: null,
            }
        },
    }

    let flightOption = Array.isArray(ajastResponse.tour.flightOptions) ? ajastResponse.tour.flightOptions[0] : []

    //ForwardSegments
    if(flightOption.forwardFlight && Array.isArray(flightOption.forwardFlight.segments)){
        let segments = flightOption.forwardFlight.segments
        segments.forEach(elem => {
            let segment = Object.assign({}, modalSegment)
            segment.startDateTime = elem.startDateTime
            segment.endDateTime = elem.endDateTime
            segment.flightNumber = elem.flightNumber
            segment.aviacompany = elem.aviacompany
            segment.class = elem.class
            segment.startWayPoint = elem.startWayPoint
            segment.endWayPoint = elem.endWayPoint
            modalFlights.forwardFlight.push(segment)
        })
    }

    //backwardFlight
    if(flightOption.backwardFlight && Array.isArray(flightOption.backwardFlight.segments)){
        let segments = flightOption.backwardFlight.segments
        segments.forEach(elem => {
            let segment = Object.assign({}, modalSegment)
            segment.startDateTime = elem.startDateTime
            segment.endDateTime = elem.endDateTime
            segment.flightNumber = elem.flightNumber
            segment.aviacompany = elem.aviacompany
            segment.class = elem.class
            segment.startWayPoint = elem.startWayPoint
            segment.endWayPoint = elem.endWayPoint
            modalFlights.backwardFlight.push(segment)
        })
    }


    return modalFlights
}

//Из response аджаста получаем отель
export function convertAjastResponseToHotel(ajastResponse){
    let result = null
    if(!ajastResponse.tour || !ajastResponse.tour.hotel) return result
    let hotelModal = {
        name: null,
        startDate: null,
        endDate: null,
        room: {
            id: null,
            name: null,
        }
    }

    let ajastHotel = ajastResponse.tour.hotel
    result = Object.assign({}, hotelModal)
    result.name = ajastHotel.name
    result.startDate = ajastHotel.checkinDate
    result.endDate = ajastHotel.checkoutDate
    result.room = ajastHotel.room

    return result
}
