import variables from "../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchOptionsFilterList({commit},params) {

            return await axios({
                method: 'GET',
                params: params,
                url:variables.routeOptionsFilterList
            })
                .then((data_options_list) => {
                    commit('mutOptionsFilterList', data_options_list.data);
                    return data_options_list;
                }).catch((error) => {
                    console.log(error);
                    return error;
                });
        },

        async fetchFullOfficesList({commit},params) {
            return await axios({
                method: 'GET',
                params: params,
                url:variables.routeFullOfficesList
            }).then((data_full_offices_list) => {
                    commit('mutFullOfficesList', data_full_offices_list.data);
                    return data_full_offices_list;
                }).catch((error) => {
                    console.log(error);
                    return error;
                });
        },
    },

    state: {
        fullOfficesList:[],
        filterOfficeList:[],
        optionsFilterList: [],
        officeFilterTown: '',
        officeFilterSubway: '',
        officeFilterService: '',
        officeFilterWithoutWeekends: false,
    },

    getters: {
        getFullOfficesList(state) {
           return state.fullOfficesList
        },
        getFilterOfficeList(state) {
            return state.filterOfficeList
        },
        getOptionsFilterList(state) {
            return state.optionsFilterList
        },
        getOfficeFilterTown(state){
            return state.officeFilterTown
        },
        getOfficeFilterSubway(state){
            return state.officeFilterSubway
        },
        getOfficeFilterService(state){
            return state.officeFilterService
        },
        getOfficeFilterWithoutWeekends(state){
            return state.officeFilterWithoutWeekends
        },
    },

    mutations: {
        mutFullOfficesList(state, payload) {
            state.fullOfficesList = payload
        },
        mutFilterOfficeList(state, payload) {
            state.filterOfficeList = payload
        },
        mutOptionsFilterList(state, payload) {
            state.optionsFilterList = payload
        },
        mutOfficeFilterTown(state, town){
            state.officeFilterTown = town
        },
        mutOfficeFilterSubway(state, subway){
            state.officeFilterSubway = subway
        },
        mutOfficeFilterService(state, service){
            state.officeFilterService = service
        },
        mutOfficeFilterWithoutWeekends(state, payload){
            state.officeFilterWithoutWeekends = payload
        },
    }
}
