import variables from "../../../plugins/variables";
import moment from "moment";
import axios from "axios";
export default {
    actions: {
        async fetchLoyaltyCreateUser(ctx, person){
            const url = variables.routeLoyalty + '/create-user'
            person.RegistrationDate = moment().format('YYYY-MM-DD HH:mm:ss')
            console.log('person', person)
            try{
                let res = await axios({
                    url,
                    method: "POST",
                    data: person
                })
                let data = await res.data
                console.log('data', data)
                let result = false
                ctx.commit("updateLoyaltyUserId", data)
                if(data.value){
                    result = true
                }
                return result
            }catch(e){
                console.log('Ошибка в actions fetchLoyaltyCreateUser', e)
            }
        },
        async fetchLoyaltyUpdateUser(ctx, person){
            const url = variables.routeLoyalty + '/update-user'
            console.log('person', person)
            try{
                let res = await axios({
                    url,
                    method: "POST",
                    data: person
                })
                let data = await res.data
                console.log('updateData', data)
            }catch(e){
                console.log('Ошибка в actions fetchLoyaltyUpdateUser', e)
            }
        },
        async fetchBindExtendedAttribute(ctx, payload){
            const url = variables.routeLoyalty + '/bind-extended-attribute'
            try{
                let res = await axios({
                    url,
                    method: "POST",
                    data: payload
                })
                console.log('bindPayload', payload)
            }catch(e){
                console.log('Ошибка в actions fetchBindExtendedAttribute', e)
            }
        },
        async fetchLoyaltyGetUserByContacts(ctx, contacts){
            // let contacts = {
            //     MobilePhone: null,
            //     EmailAddress: null,
            // }
            if(ctx.getters.getIsLogin) {
                const url = variables.routeLoyalty + "/get-user-by-contacts"
                // console.log('contacts', contacts)
                try {
                    let res = await axios({
                        url,
                        method: "POST",
                        data: contacts,
                    })
                    let result = false
                    let data = await res.data
                    if (Array.isArray(data.value) && data.value.length > 0) {
                        console.log('data value', data.value)
                        ctx.commit("updateLoyaltyUser", data.value[0])
                        result = true
                    } else {
                        ctx.commit("clearLoyaltyUser")
                    }
                    //Привязываем карту, если не привязана
                    if (!data.value[0]?.VirtualCardNumber) {
                        console.log('не привязана карта, привязываем')
                        let payload = {
                            contactId: data.value[0].Id,
                            contacts: contacts
                        }
                        ctx.dispatch('fetchBindVirtualCard', payload)
                    }
                    return result
                } catch (e) {
                    console.log('Error in actions - fetchLoyaltyGetUserByContacts', e)
                }
            } else {
                return false
            }
        },
        async fetchBindVirtualCard(ctx, payload){
            //console.log('bindPaload:', payload)
            const url = variables.routeLoyalty + "/bind-virtual-card"
            let res = await axios({
                url,
                method: "POST",
                data: {
                    contactId: payload.contactId
                },
            })
            let data = await res.data
            //console.log('binRes.data', data)
            ctx.dispatch('fetchLoyaltyGetUserByContacts', payload.contacts)
        },
        async fetchLoyaltyGetChecksByContact(ctx, contactId){
            const url = variables.routeLoyalty + "/get-checks-by-contact"
            try{
                let res = await axios({
                    url,
                    method: "POST",
                    data: contactId,
                })
                let data = await res.data
                let result = false

                if(Array.isArray(data.value)){
                    //console.log('собираем чеки ухахах')
                    //console.log(contactId)
                    //console.log(data)
                    ctx.commit("updateLoyaltyChecks", data.value)
                    result = true
                }

                return result
            }catch(e){
                console.log('Error in actions - fetchLoyaltyGetChecksByConstact', e)
            }
        },
        async fetchLoyaltyGetUser(ctx){
            const url = variables.routeLoyalty + '/get-user-by-id'
            let id = ctx.state.userId
            console.log('id', id)
            if(!id) return false
            try{
                let res = await axios({
                    url,
                    method: "POST",
                    data: {
                        id
                    }
                })

                let result = false
                let data = await res.data

                console.log('=resid', data.Id)
                console.log('=res', data)

                if(data.Id){
                    ctx.commit("updateLoyaltyUser", data)
                    result = true
                } else {
                    ctx.commit("clearLoyaltyUser")
                }
                return result
            }catch(e){
                console.log('Error in action fetchLoyaltyGetUser', e)
            }
        },
        async fetchLoyaltyConfirmPhone(ctx, phone){
            const url = variables.routeLoyalty + '/send-phone-confirm'
            if(!phone.includes('+')){
                phone = '+' + phone
            }
            let res = await axios({
                url,
                method: "POST",
                data: {
                    phone: phone
                }
            })
            let data = await res.data
            ctx.commit("updateSmsId", data)
        },
        async fetchLoyaltyCheckSms(ctx, code){
            const url = variables.routeLoyalty + '/phone-confirm'
            let data = null
            let res = await axios({
                url,
                method: "POST",
                data: {
                    token: code,
                    id: ctx.getters.getSmsId
                }
            }).then(response => {
                data = response.data // если код корректный (TRUE)
            }).catch(err => {
                data = false // истёк срок действия кода или же некорректный код
            })
            return data
        },
        async fetchGetAllContactExtendedAttributes(ctx){
            const url = variables.routeLoyalty + '/get-all-contact-extended-attributes'
            let id = ctx.state.userId
            let res = await axios({
                url,
                method: "POST",
                data: {
                    id
                }
            })
            let data = await res.data
            console.log('externalData: ', data)
            ctx.commit('updateContactExtendedAttribute', data.value)
        }
    },
    state: {
        userId: null,
        loyaltyUser: {
            Id: null,
            LastName: null,
            Login: null,
            FirstName: null,
            MiddleName: null,
            FullName: null,
            GenderCode: null,
            BirthDate: null,
            FamilyStatusCode: null,
            HasChildrenCode: null,
            EmailAddress: null,
            MobilePhone: null,
            AllowEmail: null,
            AllowSms: null,
            Balance: null,
            ActiveBalance: null,
            Debet: null,
            Credit: null,
            Summ: null,
            SummDiscounted: null,
            DiscountSumm: null,
            Quantity: null,
            RegistrationDate: null,
            PartnerId: null,
            PartnerName: null,
            OrgUnitId: null,
            OrgUnitName: null,
            PreferredOrgUnitId: null,
            PreferredOrgUnitName: null,
            MobilePhoneVerified: null,
            EmailVerified: null,
            CommunicationMethod: 1,
            AllowNotification: null,
            ContactType: null,
            StatusBalance: null,
            StatusActiveBalance: null,
            StatusDebet: null,
            StatusCredit: null,
            LevelName: null,
            VirtualCardNumber: null,
        },
        contactExtendedAttribute: [],
        checks: [],
        smsId: null,
    },
    mutations: {
        updateLoyaltyUserId(state, payload){
            if(payload.value){
                state.userId = payload.value
            }
        },
        updateLoyaltyUser(state, data){
            state.userId = data.Id
            state.loyaltyUser.Id = data.Id
            state.loyaltyUser.LastName = data.LastName
            state.loyaltyUser.Login = data.Login
            state.loyaltyUser.FirstName = data.FirstName
            state.loyaltyUser.MiddleName = data.MiddleName
            state.loyaltyUser.FullName = data.FullName
            state.loyaltyUser.GenderCode = data.GenderCode
            state.loyaltyUser.BirthDate = data.BirthDate
            state.loyaltyUser.FamilyStatusCode = data.FamilyStatusCode
            state.loyaltyUser.HasChildrenCode = data.HasChildrenCode
            state.loyaltyUser.EmailAddress = data.EmailAddress
            state.loyaltyUser.MobilePhone = data.MobilePhone
            state.loyaltyUser.AllowEmail = data.AllowEmail
            state.loyaltyUser.AllowSms = data.AllowSms
            state.loyaltyUser.Balance = data.Balance
            state.loyaltyUser.ActiveBalance = data.ActiveBalance
            state.loyaltyUser.Debet = data.Debet
            state.loyaltyUser.Credit = data.Credit
            state.loyaltyUser.Summ = data.Summ
            state.loyaltyUser.SummDiscounted = data.SummDiscounted
            state.loyaltyUser.DiscountSumm = data.DiscountSumm
            state.loyaltyUser.Quantity = data.Quantity
            state.loyaltyUser.RegistrationDate = data.RegistrationDate
            state.loyaltyUser.PartnerId = data.PartnerId
            state.loyaltyUser.PartnerName = data.PartnerName
            state.loyaltyUser.OrgUnitId = data.OrgUnitId
            state.loyaltyUser.OrgUnitName = data.OrgUnitName
            state.loyaltyUser.PreferredOrgUnitId = data.PreferredOrgUnitId
            state.loyaltyUser.PreferredOrgUnitName = data.PreferredOrgUnitName
            state.loyaltyUser.MobilePhoneVerified = data.MobilePhoneVerified
            state.loyaltyUser.EmailVerified = data.EmailVerified
            state.loyaltyUser.CommunicationMet = data.CommunicationMet
            state.loyaltyUser.AllowNotification = data.AllowNotification
            state.loyaltyUser.ContactType = data.ContactType
            state.loyaltyUser.StatusBalance = data.StatusBalance
            state.loyaltyUser.StatusActiveBalance = data.StatusActiveBalance
            state.loyaltyUser.StatusDebet = data.StatusDebet
            state.loyaltyUser.StatusCredit = data.StatusCredit
            state.loyaltyUser.LevelName = data.LevelName
            state.loyaltyUser.VirtualCardNumber = data.VirtualCardNumber
        },
        clearLoyaltyUser(state){
            state.userId = null
            state.loyaltyUser =  {}
        },
        updateLoyaltyChecks(state, checks){
            if(Array.isArray(checks)){
                checks.forEach(check=>{
                    check.Summ = parseFloat(check.Summ)
                    check.Bonus = parseFloat(check.Bonus)
                    check.PaidByBonus = parseFloat(check.PaidByBonus)
                })
                state.checks = checks
            }
        },
        updateContactExtendedAttribute(state, payload){
            state.contactExtendedAttribute = payload
        },
        updateSmsId(state, payload){
            state.smsId = payload
        }
    },
    getters: {
        isLoyaltyUserId: state => {
            return !!state.userId
        },
        getLoyaltyUserId: state => {
            return state.userId
        },
        getLoyaltyUser: state => {
            return state.loyaltyUser
        },
        getLoyaltyChecks: state => {
            return state.checks
        },
        getLoyaltyContactExtendedAttributeByKey: state => key => {
            let result = state.contactExtendedAttribute.find(item => item.Key === key)
            if(result){
                return result
            } else{
                return null
            }

        },
        getLoyaltyContactExtendedAttributes: state =>{
            return state.contactExtendedAttribute
        },
        getSmsId: state =>{
            return state.smsId
        }
    }

}
