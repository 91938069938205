import axios from "axios"
import variables from "../../../plugins/variables"
import moment from "moment"

export default {
    actions: {
        async fetchAgentPayments(ctx, payload){
            const url = variables.routeAgentPayments
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                ctx.commit('updateAgentPayments', await res.data)
                // console.log('Вот это откопали: ', res.data)
            } catch(e) {
                console.log('Ошибка в action fetchAgentPayments ' + e)
            }
        },
        async fetchAgentPayment(ctx, payload){
            const url = variables.routeAgentPayment
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                if (res.status === 200){
                    window.open(res.data,'_blank')
                }
                // ctx.commit('updateAgentPayments', await res.data)
            } catch(e) {
                console.log('Ошибка в action fetchAgentPayments ' + e)
            }
        },
        async fetchAgentPaymentsActs(ctx){
            const url = variables.routeAgentPayments + '/acts'
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateAgentPaymentsActs', await res.data)
            } catch(e){
                console.log('Ошибка в action fetchAgentPaymentsActs ' + e)
            }
        }
    },
    state: {
        agentPayments: [],
        agentPaymentsActs: [],
        statusLoadAgentPayments: false,
        statusLoadAgentPaymentsActs: false
    },
    mutations: {
        updateAgentPayments(state, payload){
            const result = []
            state.statusLoadAgentPayments = true
            payload.forEach(item => {
                const payment = {
                    claimId: null,
                    startDate: null,
                    status: {
                        id: null,
                        title: null
                    },
                    // isPartPayment: null,
                    // isConfirmed: null,
                    // confirmedDate: null,
                    // amount: null,
                    // debt: null,
                    // cost: null,
                    commiss: null,
                    // invoiceAmount: null,
                    // invoiceDebt: null,
                    // invoiceCost: null,
                    // invoiceCommiss: null,
                    currencyAlias: null,
                    invoiceCurrencyAlias: null,
                    // rateDate: null,
                    prepaidPercent: null,
                    // isAccessPay: null,
                    // isEarlyCommission: null,
                    prePaymentDate: null,
                    nextPaymentDate: null,
                    nextPaymentSum: null,
                    paymentDetail: null,
                    // statusFull: {
                    //     id: null,
                    //     title: null,
                    // },
                    // isPayTimeLimit: null,
                }
                payment.claimId = item.claimId
                payment.startDate = item.dateBeg
                payment.status = item.status
                payment.prepaidPercent = item.prepaidPercent

                if(payment.prepaidPercent === 0 && item.deadlineClaims.length>0){
                    payment.prepaidPercent = item.deadlineClaims.reduce((prev, curr) => prev.percent < curr.percent ? prev.percent : curr.percent)
                }
                payment.prePaymentDate = item.prePaymentDate
                payment.commiss = item.commiss
                payment.amount = item.amount
                payment.debt = item.debt
                payment.cost = item.cost
                payment.invoiceCommiss = item.invoiceCommiss
                payment.invoiceAmount = item.invoiceAmount
                payment.invoiceDebt = item.invoiceDebt
                payment.invoiceCost = item.invoiceCost
                payment.paymentDetail = item.paymentDetail
                payment.currencyAlias = item.currencyAlias
                payment.invoiceCurrencyAlias = item.invoiceCurrencyAlias
                payment.nextPaymentDate = item.nextPaymentDate
                payment.nextPaymentSum = item.nextPaymentSum
                payment.paymentDate = item.paymentDate
                result.push(payment)
            })
            state.agentPayments.push(...result);
            console.log('updateAgentPayments', state.agentPayments)
        },
        updateAgentPaymentsActs(state, payload){
            let result = []
            state.statusLoadAgentPaymentsActs = true
            try {
                payload.forEach(item => {
                    let act = {
                        actId: null,
                        agentId: null,
                        amount: null,
                        creationDate: null,
                        currency: null,
                        documentTypeId: null,
                        documentTypeName: null,
                        haveCopy: null,
                        haveDocument: null,
                        id: null,
                        number: null,
                        purpose: null,
                    }
                    act.actId = item.actId
                    act.agentId = item.agentId
                    act.amount = item.amount
                    act.creationDate = moment(item.creationDate).format('DD.MM.YYYY')
                    act.currency = item.currency
                    act.documentTypeId = item.documentTypeId
                    act.documentTypeName = item.documentTypeName
                    act.haveCopy = item.haveCopy ? 'Да' : 'Нет'
                    act.haveDocument = item.haveDocument ? 'Да' : 'Нет'
                    act.id = item.id
                    act.number = item.number
                    act.purpose = item.purpose
                    result.push(act)
                })
                state.agentPaymentsActs = result
            } catch(e){
                console.log('Ошибка в мутации updateAgentPaymentsActs ' + e)
            }
        }
    },
    getters: {
        getAgentPayments: state => {
            return state.agentPayments
        },
        getAgentPaymentByClaimId: state => claimId => {
            let result
            try {
                const res = state.agentPayments
                result = res.find(item => {
                    return item.claimId === claimId
                })
            } catch(e) {
                console.log('Ошибка в геттере getAgentPaymentByClaimId ' + e)
            }
            return result
        },
        getAgentPaymentsStatusLoad: state => {
            return state.statusLoadAgentPayments
        },
        getAgentPaymentsActsStatusLoad: state => {
            return state.statusLoadAgentPaymentsActs
        },
        getAgentPaymentsActs: state => {
            return state.agentPaymentsActs
        }
    }
}
