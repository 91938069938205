export default {
    imgPath: "/storage/images",
    academy: "/storage/images/academy",
    academySpeakers: "/storage/images/academy/bg-and-speakers",
    videoPath: "/storage/video",
    documentsPath: "/storage/documents",
    routeAgentMessages: "/api/agent/messages",
    routeApplyCountry: "/api/loyalty/apply-country",
    routeAirport: "/api/getairport",
    routeAvia: "/api/avia",
    routeFlightSearch: "/api/avia/FlightSearch",
    routeFetchAirport: "/api/avia/Airports/search/",
    routeGetFlightOptions: "/api/avia/AirCheckAvailability",
    routeGetFlightInsurances: "/api/avia/AirCheckAvailability/insurances",
    routeGetFlightAdditionalServices:
        "/api/avia/AirCheckAvailability/additional-services",
    routeAviaOrderCreate: "/api/avia/airbooking/order/create",
    routeGetFlightOrder: "/api/aviaService/Order/get",
    routeGetAviaTicketSubjects: "/api/aviaService/Ticket/ticket-subjects",
    routeGetAviaTicketStatuses: "/api/aviaService/Ticket/ticket-statuses",
    routeAddAviaTTETicket: "/api/aviaService/Ticket/add-ticket",
    routeExternalCustomerTickets:
        "/api/aviaService/Ticket/get-external-customer-tickets",
    routeGetTicketDetails: "/api/aviaService/Ticket/get-ticket",
    routeAddTicketMessage: "/api/aviaService/Ticket/add-message",
    routeApplyCalculation: "/api/aviaService/Ticket/apply-calculation",
    routeDeclineCalculation: "/api/aviaService/Ticket/decline-calculation",
    routeAgentBonuses: "/api/agent/sales/total-bonuses",
    routeAgentBonusDetails: "/api/agent/sales/bonus-details",
    routeAgentContracts: "/api/agent/documents",
    routeAgentReports: "/api/agent/documents/get-agent-reports",
    routeAgentReportsParameters: "/api/agent/documents/get-report-parameters",
    routeAgentReportsJob: "/api/agent/documents/create-report-job",
    routeAgentPrint: "/api/agent/documents/get-print-job-result",

    routeGeoAeroflotTours: "/api/search/aeroflot-tours",
    routeGeoAeroflotDirections: "/api/search/aeroflot-directions",

    routeCity: "/api/geo/city-object",
    //Методы Гео
    routeGeoObjectByEbgNodeId: "/api/geo/geo-object/",
    routeCommon: "/api/common",
    routeGetCaptcha: "/captcha/api/mini",
    routeCheckoutCaptcha: "api/checkout-captcha",
    routeConstructor: "/api/constructor",
    routeCountries: "/api/geo/country",
    routeFlightSurchargesCities: "/api/flight-surcharges-cities",
    routeFlightSurcharges: "/api/flight-surcharges",
    routeCustomerPaxes: "/api/customer/paxes",
    routeDocument: "/api/customer/orders",
    routeGeo: "/api/geo",
    routeInsurance: "/api/insurance",
    routeKiwiTransfer: "/api/kiwi",
    routeLoyalty: "/api/loyalty",
    routeLoyaltyCountries: "/api/loyalty/countries",
    routeMemberStatus: "/api/loyalty/member/status",
    routeNationality: "/api/references/nationality",
    routePlanFact: "/api/agent/plan-fact",
    routeHeaderMenu: "/api/get-header-menu",
    routeFooterMenu: "/api/get-footer-menu",
    routeOrders: "/api/customer/orders",
    routePolicy: "/privacy-policy",
    routeAgentInfo: "/api/agent/userinfo",
    routeAgentSales: "/api/agent/sales/salePlan",
    routeAgentPayments: "/api/agent/payments",
    routeAgentPayment: "/api/agent/payment",
    routeReference: "/api/agent/reference",
    routeAgentClaims: "/api/agent/claims",
    routeReferenceInsurance: "/api/insurance/reference",
    routeObjectByUrl: "/api/geo/object-by-url",
    routeGeoAmenityList: '/api/geo/amenity-list',
    //Пути кабинета клиента
    routeUserProfile: "/api/customer/profile",
    routeUser: "/api/customer/user/get",
    routeUserAvatar: "/api/customer/avatar",
    routeUserPayment: "/api/customer/payment",
    routeUserUpdate: "/api/customer/user/put",
    //
    routeAgency: "/api/agent/agency",
    routeAgent: "/api/agent/agent",
    routeAgentCheckEmail: "/api/agent/check-partpass-existence",
    //#Агентские интеграция с ТТЕ
    routeAgencyImport: "/api/agent/import",
    //Рекомендации для customer
    routeCustomerRecommendations: "/api/customer/recommendation",

    routeCustomerMarketOrder: "/api/customer/marketplace-orders/order",
    routeCustomerMarketOrders: "/api/customer/marketplace-orders/orders",

    routeGeoAreas: "/api/partner/catalogs/geo-areas",
    routeShippingVariants: "/api/partner/catalogs/shipping-variants",
    routeShippingTemplate: "/api/partner/shiping/template",
    routeShippingTemplateDefault: "/api/partner/shiping/template/default",
    routeShippingTemplates: "/api/partner/shiping/templates",

    //Партнерка-профиль
    routePartnerProfile: "/api/partner/profile",
    routeSearchPartners: "/api/partner/profiles-filtered",
    //Партнерка-документы
    routePartnerInfo: "/api/partner/info",

    routePartnerProfileStore: "/api/partner/profile/store",
    routePartnerTaxStatuses: "/api/partner/catalogs/tax-statuses",
    routePartnerStatuses: "/api/partner/catalogs/partner-statuses",
    routePartnerDocuments: "/api/partner/documents",
    routePartnerProducts: "/api/partner/products",
    routePartnerProductsMany: "/api/partner/products-many",
    routePartnerProperties: "/api/partner/properties",
    routeSearchPartnerProducts: "/api/partner/searchProduct",
    routeSearchPartnerProductsForPeriod: "/api/partner/productSearchForPeriod",
    routePartnerMarketCountries: "/api/partner/catalogs/market-countries",
    routePartnerCurrencies: "/api/partner/catalogs/currencies",
    routePartnerProductCategories: "/api/partner/catalogs/categories",
    routePartnerProductCategory: "/api/partner/catalogs/category",
    routePartnerCapacityMeasures: "/api/partner/catalogs/capacity-measures",
    routePartnerCommoditySizes: "/api/partner/catalogs/sizes",
    routePartnerFormats: "/api/partner/catalogs/formats",
    routePartnerTimeMeasures: "/api/partner/catalogs/time-measures",
    routePartnerColours: "/api/partner/catalogs/colours",
    routePartnerMaterials: "/api/partner/catalogs/materials",
    routePartnerGenres: "/api/partner/catalogs/genres",
    routePartnerCategoriesTree: "/api/partner/catalogs/categories/tree",
    routePartnerProductStatuses: "/api/partner/catalogs/product-statuses",
    routePartnerProductTags: "/api/partner/catalogs/product-tags",
    routePartnerProductTagSet: "/api/partner/product-tags/set",
    routePartnerProductTagUnset: "/api/partner/product-tags/unset",
    routePartnerTables: "/api/partner/partner-tables",
    routePartnerOrdersAll: "/api/partner/orders/ordersall",
    routePartnerOrders: "/api/partner/orders/orders",
    routePartnerProductOrder: "/api/partner/orders/productorder",

    routeCategoryFilters: "/api/partner/catalogs/filters",
    routeCategoryFilter: "/api/partner/catalogs/filter",
    routeCategoryFiltersSet: "/api/partner/catalogs/categories-paged-filtered",

    routeCategoryPage: "/api/partner/catalogs/categories-paged-filtered",
    routeCategoryMap: "/api/partner/catalogs/categories-map",
    routeFilesStorage: "/api/filesstorage/local",
    routeFilesStorageAdd: "/api/filesstorage/local-add",

    routeSigIn: "/api/login/signin",
    routeSigOut: "/api/login/signout",
    routeSignUp: "/api/login/signup",
    routeCheckIsLogin: "/api/login/auth-status",
    routeRegistrationConfirmPhone: "/api/login/confirm-phone",
    routeRestorePassword: "/api/login/forgot-password",
    routeResetPassword: "/api/login/reset-password",
    /*Пути методов поиска туров*/
    routeSearchTour: "/api/search",
    routeSearchTourHot: "/api/search-hot",
    routeMosaic: "/api/filters",
    routeDepartureCities: "/api/filters/DepartureCities",
    routeTourSearchCitiesOfLocation: "/api/filters/DepartureCities",
    routeGetAviaArrivalCountries: "/api/filters/GetArrivalCountries/",
    routeGetAviaArrivalCities: "/api/filters/GetArrivalCities",
    routeGetAviaArrivalCountriesFromEs:
        "/api/filters/getArrivalCountriesFromEs/",
    routeGetAviaArrivalCountriesFromEsCache:
        "/api/filters/getArrivalCountriesFromEsCache/",
    routeFilterGetCheckins: "/api/filters/getCheckins",
    routeFilterGetAvailableCheckins: "/api/filters/getAvailableCheckins",
    routeFilterWeekendsMinPrices: "/api/filters/getWeekendMinPrices",
    routeFilterWeekendsArrivals: "/api/filters/getWeekendArrivals",
    routeFilterGetMeals: "/api/filters/meals",
    // routeTourSearchCitiesOfLocation: '/api/geo/city',
    // routeTourSearchCountries: '/api/geo/cities-all',
    routeTourSearchCitiesByCountry: "/api/geo/city/byCountryId",
    routeTourSearchRegionsByCountry: "/api/geo/resort/byCountryId",
    routeTourSearchCountries: "/api/geo/country/all",
    routeTourSearchExcursions: "/api/services/search-excursion",
    routeTourSearchCountryById: "/api/geo/country-object-by-city/",
    routeParents: "/api/geo/parents/",
    routeHotelByUrl: "/api/geo/hotel-by-url",
    routeHotelByUrlWithDescr: "/api/hotel-with-descr-block-match",
    routeTourServices: "/api/services",
    routeTourOptions: "/api/search/tourOptions",
    routeTourRecount: "/api/search/tourRecount",
    routeTourBoarding: "/api/search/getBoarding",
    routeTourGetFares: "/api/search/getFares",
    routeTourSetFares: "/api/search/setFares",
    //Новые методы бронирования
    routeTourGetPacket: '/api/search/getPacket',
    routeTourGetExternalFreights: '/api/search/getExternalFreights',
    routeTourGetReservationPrice: '/api/search/getReservationPrice',
    routeTourActualizePacket: '/api/search/getActualizePacket',
    routeTourGetSupplements: '/api/search/getSupplements',
    routeTourGetPacketFares: '/api/search/getPacketFares',
    routeTourSetPacketFare: '/api/search/setPacketFare',
    // АЭРОЭКСПРЕСС
    routeSalesMenu: "/api/aeroexpress/infos/salesmenu",
    routeFreeSeats: "/api/aeroexpress/infos/freeseats",
    routeStationsWithTimetable: "/api/aeroexpress/infos/stationswithtimetable",
    routeDocumentTypes: "/api/aeroexpress/infos/personinfodocumenttypes",
    routeTrainTimetable: "/api/aeroexpress/timetable/trains",
    routeStationTimetable: "/api/aeroexpress/timetable/stations",
    routeCreateOrder: "/api/aeroexpress/orders/create",
    routePayOrder: "/api/aeroexpress/orders/pay",
    routeMinPricesCountry: "/api/search/minPricesCountry",
    //Мин.цены по турам на 30 дней
    routeToursMinPrices30Days: "/api/search/minPrices",
    //Доступность количества ночей в поиске
    routeAvailableNights: "/api/filters/nights",
    routeAvailableNightsCached: "/api/filters/nightsCached",
    routeAvailableNightsByFrightType: "/api/filters/nightsByFrightType",
    //Мин.цены по турам на 60 дней
    routeToursMinPrices: "/api/tourDetails/minPrices",
    routeToursMinPricesWithCache: "/api/search/minPricesWithCache",
    //Дополнительные данные по турам
    routeTourPartnerDetails: "/api/tourDetails",
    /*Пути методов локализации*/
    routeLocalization: "/api/locale",
    routeLog: "/api/log",
    routeChangeLocalization: "/api/change-locale/",
    routeChangeDefaultCity: "/api/change-default-city",
    routeGetMainLocalization: "/api/localize/main",
    routeGetTourLocalization: "/api/localize/tour-search",
    routeGetHotelLocalization: "/api/localize/hotel-offer",
    /*Пути методов отеля*/
    routeHotelObject: "/api/geo/hotel-object/",
    routeHotelSaveFilter: "/api/hotel/savefilter",
    routeHotelGetFilter: "/api/hotel/getfilter",
    routeHotelGetOffers: "/api/hotel/offers",
    routeHotelGetHotelsByExcursionProgramId:
        "/api/hotel/hotelsByExcursionProgramId",
    routeHotelGetExcursionDescriptionByEbgId:
        "/api/hotel/excursionDescriptionByEbgId",
    routeHotelMinPrices: "/api/hotel/getMinPrice",
    routeHotelGetCityByParentId: "/api/geo/city-object-by-hotel/",
    routeHotelImgUrl:
        "https://api.selcdn.ru/v1/SEL_50469/tui-geocontent-test/Hotel/",
    routeHotelSearch: "/api/search/country",
    routeAddWishList: "/api/search/wishList",
    routeRemoveWishList: "/api/search/wishList",
    routeIndexWishList: "/api/search/wishList",
    routeClearDraftId: "/api/clear-draft-id",
    /*Пути методов Корзины*/
    routeBasket: "/api/cart",
    routeBasketBook: "/api/cart/reserve",
    routeBasketStatus: "/api/cart/status",
    routeBasketOrders: "/api/cart/orders",
    routeBasketOrder: "/api/cart/order",
    routeBasketSafeLink: "/api/cart/safelink",
    routeBasketSafeLinkClear: "/api/cart/safelink-clear",
    routeAbandonedBasketById: "/api/cart/abandonedBasketById",
    routeBasketOrderTravellers: "/api/cart/travellers",
    routeBasketNationalities: "/api/cart/nationalities",
    routeBasketRegisterUser: "/api/cart/registerUser",
    routeBasketConfirmPayment: "/api/cart/confirmPayment",
    routeBasketCheckTourAvailability: "/api/cart/check-tour-availability",
    routeSaveOfferToIntegration: "/api/integration/save-offer",
    routeGetOffersToIntegration: "/api/integration/get-offers",
    /*Пути методов статического контента*/
    routeStaticContent: "/api/static",
    routeStaticFavorites: "/api/static/favorite-list",

    routeMarketplaceProducts: "/api/marketplace/products-filtered",
    routeMarketplaceProductsImport: "/api/marketplace/import",
    roureMarketplaceProductData: "/marketplace/productData/",
    routeMarketplaceSendOrderMail: "/marketplace/send-order-mail",
    routeMarketplaceDocumentOffer: "/market/document/offer",
    routeMarketplaceDocumentImport: "/market/document/import",
    routeMarketplaceGeoObjects: "/market/marketGeoObjects",
    routeMarketplaceGeoObject: "/market/marketGeoObject",
    routeMarketplaceIpLocation: "/market/marketIpLocation",
    routeCatalogProducts: "/market/products-catalog-filtered",
    routeViewedProducts: "/market/products-viewed",
    routeCatalogSearch: "/market/search",

    routeProductRatingCreate: "/marketplace-rating/product-create",
    routeProductRatingUpdate: "/marketplace-rating/product-update",
    routeProductRatingModerate: "/marketplace-rating/product-moderate",
    routeProductRatingList: "/marketplace-rating/product-rating-list",
    routeProductRatingReport: "/marketplace-rating/product-report",
    routePartnerRatingCreate: "/marketplace-rating/partner-create",
    routePartnerRatingUpdate: "/marketplace-rating/partner-update",
    routePartnerRatingModerate: "/marketplace-rating/partner-moderate",
    routePartnerRatingList: "/marketplace-rating/partner-rating-list",
    routePartnerReport: "/marketplace-rating/partner-report",

    routeAgentIssue: "/api/agent/issues/fit",
    routeAgentIssueComments: "/api/agent/issues/fit/comments",

    routeAgentLmsEmails: "/api/agent/lmsemails",

    routeCurrencyList: "/api/services/currencies",

    routeOptionsFilterList: "/api/offices/options",
    routeFullOfficesList: "/api/offices/list",

    routSendLead: "/api/send-lead",

    routeSendCrmCreateOrder: "/api/send-crm-create-order",

    routCounters: "/api/counters",

    routSurvey: "/survey-send",

    routSender: "/api/sender",

    routOrderStatusCheck: "/statuscheck",

    routWeekendTour: "/api/weekendtour",

    routRestTypesData: "/api/rest-types-data",

    routeCountriesCards: "/api/get-countries-cards",

    routeGeoIp: "/api/geoip",

    routTrslt: "/trslt",

    routAgentStatusChek: "/agent-statuscheck",

    phonePrefixes: [
        {
            value: "+7",
            countryISO: "RU",
        },
        {
            value: "+370",
            countryISO: "LT",
        },
        {
            value: "+371",
            countryISO: "LV",
        },
        {
            value: "+372",
            countryISO: "EE",
        },
        {
            value: "+375",
            countryISO: "BY",
        },
        {
            value: "+380",
            countryISO: "UA",
        },
    ],

    weightCities: [
        "Москва",
        "Санкт-Петербург",
        "Париж",
        "Вена",
        "Анталья",
        "Лондон",
    ],

    dev: true,
};
