import variables from "../../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchKiwiCountries(ctx){
            let url = variables.routeKiwiTransfer + '/reference/countries'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateKiwiCountries', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchKiwiCountries', e)
            }
        },
        async fetchKiwiCurrencies(ctx){
            let url = variables.routeKiwiTransfer + '/reference/currencies'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateKiwiCurrencies', await res.data.data.results)
            }catch(e){
                console.log('Ошибка в actions fetchKiwiCurrencies', e)
            }
        },
        //Поиск страны по одному из значений name, iata, id
        async fetchKiwiSearchCountries(ctx, data){
            let datas = {
                name: 'Турция',
                iata: null,
                id: null
            }
            let url = variables.routeKiwiTransfer + '/search/countries'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: datas
                })
                ctx.commit('updateKiwiSearchCountries', await res.data.data.results)
            }catch(e){
                console.log('Ошибка в actions fetchKiwiSearchCountries', e)
            }
        },
        //TODO
        async fetchKiwiSearchTransfer(ctx, data){
            let result = []
            let datas = {
                placeFromName: "Анталья",
                placeToName: "Аланья",
                maxPerPage: 500,
            }
            let url = variables.routeKiwiTransfer + '/search/transfer'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: data
                })
                result.push.apply(result, await res.data.data.results)
                let currentPage = 1
                let lastPage = await res.data.data.lastPage
                //Добор результатов со всех страниц
                if(lastPage > 1){
                    while(currentPage < lastPage){
                        currentPage++
                        datas.page = currentPage
                        let addRes = await axios({
                            url,
                            method: 'POST',
                            data: datas
                        })
                        result.push.apply(result, await addRes.data.data.results)
                    }
                }
                ctx.commit('updateKiwiSearchTransfersResult', result)
            }catch(e){
                console.log('Ошибка в actions fetchKiwiSearchTransfer', e)
            }
        },
        async fetchKiwiSearchTransferByPlaces(ctx, data){
            let url = variables.routeKiwiTransfer + '/search/transfer'
            // let data = {
            //     countryName: options.countryName,
            //     placeFromId: options.placesFrom[options.positionFrom],
            //     placeToId: options.placesTo[options.positionTo],
            //     maxPerPage: options.countElements,
            //     page: options.currentPage
            // }
            data.maxPerPage = 500
            data.page = 1

            let res = await axios({
                url,
                method: 'POST',
                data: data
            })

            let transfers = []

            if(res.data.data && Array.isArray(res.data.data.results)){
                transfers = await res.data.data.results
            }

            //Добавление 20% коммиссии
            if(Array.isArray(transfers)){
                transfers.forEach(transfer => {
                    for(let key in transfer.price){
                        transfer.price[key].netPrice = transfer.price[key].cost
                        transfer.price[key].cost = transfer.price[key].cost + transfer.price[key].cost / 5
                    }
                })
            }

            return transfers
        },
        //Actions осуществляет порционный поиск
        //Параметры поиска храняться в state kiwiSearchTransferOptions
        // async fetchKiwiSearchTransferByPlaces(ctx){
        //     let url = variables.routeKiwiTransfer + '/search/transfer'
        //     let options = ctx.getters.getKiwiSearchTransferOptions
        //     options.isLoading = true
        //
        //     if(!options.placesFrom.length || !options.placesTo.length){
        //         ctx.commit('updateKiwiSearchTransfersResult', [])
        //         options.isLoading = false
        //         return
        //     }
        //
        //     let result = options.transfers
        //
        //     while(result.length < options.countElements){
        //         let data = {
        //             countryName: options.countryName,
        //             placeFromId: options.placesFrom[options.positionFrom],
        //             placeToId: options.placesTo[options.positionTo],
        //             maxPerPage: options.countElements,
        //             page: options.currentPage
        //         }
        //
        //         let res = await axios({
        //             url,
        //             method: 'POST',
        //             data: data
        //         })
        //
        //         let transfers = await res.data.data.results
        //         result.push.apply(result, transfers)
        //         if(await res.data.data.lastPage > options.currentPage){
        //             options.currentPage = options.currentPage + 1
        //         } else {
        //             options.currentPage = 1
        //             options.positionTo = options.positionTo + 1
        //             if(options.positionTo >= options.placesTo.length){
        //                 options.positionFrom = options.positionFrom + 1
        //                 options.positionTo = 0
        //                 if(options.positionFrom >= options.placesFrom.length){
        //                     options.positionFrom = 0
        //                     options.positionTo = 0
        //                     options.isCompleted = true
        //                     break
        //                 }
        //             }
        //         }
        //     }
        //
        //     ctx.commit('updateKiwiSearchTransfersResult', result.splice(0, options.countElements))
        //     options.isLoading = false
        // },
        async fetchKiwiSearchCurrentTransfer(ctx, data){
            let url = variables.routeKiwiTransfer + '/search/transfer'
            let result = null

            // let data = {
            //     id: null,
            //     dateStart: null,
            // }

            let res = await axios({
                url,
                method: 'POST',
                data: data
            })

            if(res.data.data && Array.isArray(res.data.data.results)){
                result = await res.data.data.results[0]
            }

            //Добавление 20% коммиссии
            if(result){
                for(let key in result.price){
                    result.price[key].netPrice = result.price[key].cost
                    result.price[key].cost = result.price[key].cost + result.price[key].cost / 5
                }
            }

            return result

        },
        // поиск маршрутной точки по одному или нескольким параметрам
        async fetchKiwiSearchPlaces(ctx, data){
            let result = []
            // let datas = {
            //     iata: null,
            //     name: null,
            //     typeId: null,
            //     regionId: null,
            //     regionName: null,
            //     countryId: 31,
            //     countryIata: null,
            //     countryName: null,
            //     id: null
            // }
            let url = variables.routeKiwiTransfer + '/search/places'

            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: data,
                })
                let places = await res.data.data
                if(Array.isArray(places.results)){
                    places.results.forEach(item => {
                        result.push(item.id)
                    })
                }
                ctx.commit('updateKiwiSearchPlacesResult', places)
                return result
            }catch(e){
                console.log('Ошибка в actions fetchKiwiSearchPlaces', e)
            }
        },
        // поиск маршрутной точки по countryId. С проверкой отправлялся ли уже запрос
        async fetchKiwiSearchPlacesByCountryId(ctx, countryId){
            if(ctx.state.kiwiUsedCountryId.has(countryId)) return
            ctx.state.kiwiUsedCountryId.add(countryId)

            let url = variables.routeKiwiTransfer + '/search/places'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        countryId: countryId
                    },
                })

                ctx.commit('updateKiwiSearchPlacesResult', await res.data.data)
            }catch(e){
                console.log('Ошибка в actions fetchKiwiSearchPlacesByCountryId', e)
            }
        },
        // async fetchKiwiSearchRegion(ctx, data){
        //     let datas = {
        //         id: null,
        //         name: "Аланья",
        //         countryId: null,
        //         countryIata: null,
        //         countryName: "Турция"
        //     }
        //     let url = variables.routeKiwiTransfer + '/search/regions'
        //     try{
        //         let res = await axios({
        //             url,
        //             method: 'POST',
        //             data: datas,
        //         })
        //
        //     }catch(e){
        //         console.log('Ошибка в actions fetchKiwiSearchRegion', e)
        //     }
        // },
        // async fetchKiwiSearchRoutes(ctx, data){
        //     let datas = {
        //         id: null,
        //         countryId: 31,
        //         countryIata: null,
        //         countryName: null,
        //         placeFromId: null,
        //         placeFromIata: null,
        //         placeFromName: null,
        //         placeToId: null,
        //         placeToIata: null,
        //         placeToName: null
        //     }
        //     let datas2 = {
        //         "placeFromId": 48
        //     }
        //     let url = variables.routeKiwiTransfer + '/search/routes'
        //     try{
        //         let res = await axios({
        //             url,
        //             method: 'POST',
        //             data: datas2
        //         })
        //         console.log('kiwi search-routes', await res.data.data)
        //     }catch(e){
        //         console.log('Ошибка в actions fetchKiwiSearchRoutes', e)
        //     }
        // },
        // async fetchKiwiSearchPlaceTypes(ctx){
        //     const url = variables.routeKiwiTransfer + '/reference/placeTypes'
        //     try{
        //         let res = await axios({
        //             url,
        //             method: 'POST'
        //         })
        //         console.log('types', await res.data)
        //     }catch(e){
        //         console.log('Ошибка в actions fetchKiwiSearchPlaceTypes', e)
        //     }
        // },
        async fetchKiwiGetMeeting(ctx, id){
            const url = variables.routeKiwiTransfer + '/transfer/meetingData/' + id
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                return await res.data.data
            }catch(e){
                console.log('Ошибка в actions fetchKiwiGetMeeting', e)
            }
        }
    },
    state: {
        kiwiCountries: [],
        kiwiCurrencies: [],
        kiwiSearchCountriesResult: [],
        kiwiSearchPlacesResult: [],
        kiwiSearchRegionResult: [],
        kiwiSearchTransfersResult: [],
        kiwiUsedCountryId: new Set(),
        //Используется для порционального поиска трансферов kiwi
        kiwiSearchTransferOptions: {
            placesFrom: [],
            placesTo: [],
            transfers: [],
            countryName: null,
            countElements: 20,
            positionFrom: 0,
            positionTo: 0,
            currentPage: 1,
            isCompleted: false,
            isLoading: false,
        }
    },
    mutations: {
        updateKiwiCountries(state, payload){
            let countries = []
            try{
                payload.forEach(item => {
                    let country = {
                        id: null,
                        name: null,
                        search: [],
                    }
                    country.id = item.id
                    country.name = item.name.ru
                    Object.values(item.name).forEach(countryName => {
                        if(countryName !== "") {
                            country.search.push(countryName)
                        }
                    })
                    countries.push(country)
                })
            }catch(e){
                console.log('Ошибка в мутации updateKiwiCountries', e)
            }
            state.kiwiCountries = countries
        },
        updateKiwiCurrencies(state, payload){
            let currencies = []
            try{
                payload.forEach(item => {
                    let currency = {
                        code: null,
                        iso: null,
                        id: null,
                        modifiedAt: null,
                    }

                    currency.code = item.code
                    currency.iso = item.iso
                    currency.id = item.id
                    currency.modifiedAt = item.modifiedAt

                    currencies.push(currency)
                })
            }catch(e){
                console.log('Ошибка в мутации updateKiwiCurrencies', e)
            }
            state.kiwiCurrencies = currencies
        },
        updateKiwiSearchCountries(state, payload){
            let result = []
            try{
                payload.forEach(item => {
                    let country = {
                        currency: {
                            code: null,
                            iso: null,
                            id: null,
                            modifiedAt: null,
                        },
                        iata: null,
                        id: null,
                        modifiedAt: null,
                        name: {
                            en: null,
                            de: null,
                            es: null,
                            fr: null,
                            ru: null,
                        },
                        published: true,
                    }
                    country.currency.code = item.currency.code
                    country.currency.iso = item.currency.iso
                    country.currency.id = item.currency.id
                    country.currency.modifiedAt = item.currency.modifiedAt
                    country.iata = item.iata
                    country.id = item.id
                    country.modifiedAt = item.modifiedAt
                    country.name.en = item.name.en
                    country.name.de = item.name.de
                    country.name.es = item.name.es
                    country.name.fr = item.name.fr
                    country.name.ru = item.name.ru

                    result.push(country)
                })
            }catch(e){
                console.log('Ошибка в мутации updateKiwiSearchCountries', e)
            }
            state.kiwiSearchCountriesResult = result
        },
        updateKiwiSearchPlacesResult(state, payload){
            let result = []
            try{
                payload.results.forEach(item => {
                    let place = {
                        country: {},
                        iata: null,
                        id: null,
                        modifiedAt: null,
                        name: {},
                        region: {},
                        timeZone: {},
                        type: {},
                    }
                    place.country = item.country
                    place.iata = item.iata
                    place.id = item.id
                    place.modifiedAt = item.modifiedAt
                    place.name = item.name
                    place.region = item.region
                    place.timeZone = item.timeZone
                    place.type = item.type
                    result.push(place)
                })
            }catch(e){
                console.log('Ошибка в мутации updateKiwiSearchPlacesResult', e)
            }
            state.kiwiSearchPlacesResult = result
        },
        updateKiwiSearchTransfersResult(state, payload){
            let transferIds = new Set()
            state.kiwiSearchTransfersResult.forEach(item => {
                transferIds.add(item.id)
            })

            try{
                payload.forEach(item => {
                    let transfer = {
                        id: null,
                        route: {},
                        type: {},
                        price: {},
                        optionalServicesPrice: {},
                        restructions: {},
                        modifiedAt: null,
                        transferType: 'kiwi',
                    }
                    transfer.id = item.id
                    transfer.route = item.route
                    transfer.type = item.type
                    transfer.price = item.price
                    transfer.optionalServicesPrice = item.optionalServicesPrice
                    transfer.restructions = item.restructions
                    transfer.modifiedAt = item.modifiedAt
                    if(!transferIds.has(transfer.id)){
                        state.kiwiSearchTransfersResult.push(transfer)
                    }
                })
            }catch(e){
                console.log('Ошибка в мутации updateKiwiSearchTransfersResult', e)
            }
        },
        updateKiwiSearchTransferResultClear(state){
            state.kiwiSearchTransfersResult = []
        },
        updateKiwiSearchTransferOptions(state, payload){
            state.kiwiSearchTransferOptions = payload
        }
    },
    getters: {
        getKiwiCountries: state => {
            return state.kiwiCountries
        },
        getKiwiSearchCountriesResult: state => {
            return state.kiwiSearchCountriesResult
        },
        getKiwiCurencies: state => {
            return state.kiwiCurencies
        },
        getKiwiSearchPlacesResult: state => {
            return state.kiwiSearchPlacesResult
        },
        getKiwiSearchTransferResult: state => {
            return state.kiwiSearchTransfersResult
        },
        getKiwiSearchTransferOptions: state => {
            return state.kiwiSearchTransferOptions
        }
    }
}
