import Vue from "vue";

/**
 * @description У девайса есть тип: (type = 'mobile', 'table', 'desktop'), и есть ориентация экрана: (orientation = 'landscape', 'portrait')
 * @typedef {(mobile|table|desktop)} DeviceTypes
 * @typedef {(portrait|landscape)} OrientationTypes
 * @typedef {{type: DeviceTypes, orientation: OrientationTypes, userAgent: string}} Device
 * @returns {Device}
 */
let device = {};
Vue.prototype.$device = function () {

    let userAgent = window.navigator.userAgent.toLowerCase();
    let width=document.documentElement.clientWidth
    let height=window.innerHeight
    if(width<=992&&width>=768){
        device.type='table'
    }else if(width<768){
        device.type='mobile'
    }else{
        device.type='desktop'
    }
    if((height/width)>1){
        device.orientation='portrait'
    }else if((height/width)<1){
        device.orientation='landscape'
    }
    device.userAgent=userAgent
    return device
};
export default device

