import axios from "axios";
import variables from "../../../plugins/variables";
import moment from "moment";

export default {
    actions: {
        async fetchAgentBonuses(ctx){
            const url = variables.routeAgentBonuses

            if(ctx.statusLoadAgentBonuses) return

            try {
                const res = await axios({
                    url,
                    method: 'POST'
                })
                ctx.commit('updateAgentBonuses', await res.data)
            } catch {
                console.log('Ошибка в actions fetchAgentBonuses')
            }
        },
        async fetchAgentBonusDetails(ctx){
            const url = variables.routeAgentBonusDetails

            if(ctx.statusLoadAgentBonusDetails) return

            try {
                const res = await axios({
                    url,
                    method: 'POST'
                })
                ctx.commit('updateAgentBonusDetails', await res.data)
            } catch {
                console.log('Ошибка в actions fetchAgentBonusDetails')
            }
        }
    },
    state: {
        agentBonuses: [],
        agentBonusDetails: [],
        statusLoadAgentBonuses: false,
        statusLoadAgentBonusDetails: false,
    },
    mutations: {
        updateAgentBonuses(state, payload){
            let result = []
            try {
                state.statusLoadAgentBonuses = true
                payload.forEach(item => {
                    const bonus = {
                        id: null,
                        name: null,
                        currency: null,
                        accrued: null,
                        used: null,
                        burned: null,
                        total: null,
                        available: null,
                        notAvailable: null,
                    }
                    bonus.id = item.id
                    bonus.name = item.fullName
                    bonus.currency = item.currencyName
                    bonus.accrued = item.accruedBonuses
                    bonus.used = item.usedBonuses
                    bonus.burned = item.burnedBonuses
                    bonus.total = item.totalBonuses
                    bonus.available = item.availableBonuses
                    bonus.notAvailable = item.notAvailableBonuses
                    result.push(bonus)
                })
            } catch {
                console.log('Ошибка в мутации updateAgentBonuses')
            }
            state.agentBonuses = result
        },
        updateAgentBonusDetails(state, payload){
            const result = []
            try {
                state.statusLoadAgentBonusDetails = true
                payload.forEach(item => {
                    const bonusDetail = {
                        id: null,
                        issueDate: null,
                        claimId: null,
                        bonuses: null,
                        // currencyId: null,
                        currencyName: null,
                        // fullName: null,
                        // targetBonuses: null,
                        // targetCurrencyId: null,
                        // targetCurrencyName: null,
                        // isExpensed: null,
                        isCompleted: null,
                        // isClaimExist: null,
                        isValid: null,
                        // isCanceled: null,
                        validTill: null,
                        type: null,
                    }
                    bonusDetail.id = item.id
                    bonusDetail.issueDate = moment(item.issueDate).format('DD.MM.YYYY HH:mm')
                    bonusDetail.claimId = item.claimId
                    bonusDetail.bonuses = item.bonuses + ' ' + item.currencyName
                    bonusDetail.isValid = item.isValid
                    bonusDetail.validTill = item.validTill
                    bonusDetail.type = item.isExpensed ? 'Приход' : 'Расход'
                    bonusDetail.isCompleted = item.isCompleted ? 'Доступен' : 'Недействителен'
                    result.push(bonusDetail)
                })
                //TODO заглушка для примера
                result.push({
                    id: '201757026',
                    issueDate: '20.05.2019 16:53',
                    claimId: '220017',
                    bonuses: '200 RUR',
                    isValid: false,
                    isCompleted: 'Доступен',
                    validTill: '20.05.2019 16:53',
                    type: 'Приход'
                })
                state.agentBonusDetails = result
            } catch {
                console.log('Ошибка в мутации updateAgentBonusDetails')
            }
        }
    },
    getters: {
        getAgentBonuses: state => {
            return state.agentBonuses
        },
        getAgentBonusDetails: state => {
            return state.agentBonusDetails
        },
        getAgentBonusDetailsById: state => (id, showValid = false) => {
            let result = []
            const intermediate = []
            try {
                const res = state.agentBonusDetails
                res.forEach(item => {
                    if(item.id === id){
                        intermediate.push(item)
                    }
                })
                //Проверка на showValid
                if(showValid){
                    intermediate.forEach(item => {
                        if(item.isValid === true){
                            result.push(item)
                        }
                    })
                } else {
                    result = intermediate
                }
            } catch {}
            return result
        }
    }
}
