export default {
    actions: {

    },
    state: {
        statusTabs: {
            customerData: false,
            customerOrders: false,
            customerProducts: false,
            customerCountries: false,
            customerWishList: false,
            customerHistory: false,
            customerMessage: false,
            customerAppeal: false,
            customerAction: false,
            customerLoyalty: false,
            agentData: false,
            agentClaims: true,
            agentPayment: false,
            agentBonuses: false,
            agentContracts: false,
            agentPaymentAct: false,
            agentCommission: false,
            agentReports: false,
            agentFITClaims: false,
            agentEducation: false,
            agentAddToTTE: false,
            agentCurators: false,
            partnerProfile: false,
            partnerContract: false,
            partnerStatistics: false,
            partnerGoods: false,
            partnerComment: false,
            partnerOrders: false,
            partnerModeration: false,
            partnerFAQ: false,
            partnerNotifications: false,
            partnerAppeals: false,
            partnerDelivery: false,
            partnerReturns: false,
            partnerNews: false,
            moderatorPartners: false,
            moderatorProducts: false,
            moderatorOrders: false,
            moderatorDelivery: false,
            moderatorAppeals: false,
            moderatorReturns: false,
            moderatorComment: false,
            moderatorContacts: false,
            moderatorTypesTable: false,
            moderatorReferences: false,
            moderatorNews: false,
            historyLoyalty: false,
            questionsLoyalty: false,
            specificationLoyalty: false,
            aboutLoyalty: false,
            megaDiscountsPartnersTab:false,
        },
    },
    mutations: {

    },
    getters: {
        getStatusTab: state => {
            return state.statusTabs
        },
        setStatusTab: state => tabName => {
            for(let key in state.statusTabs){
                state.statusTabs[key] = false
            }
            if(typeof state.statusTabs[tabName] !== 'undefined'){
                state.statusTabs[tabName] = true
            } else {
                console.log('Ошибка установки активного таба. Передано неверное имя')
            }
        },
        getActiveTab: state => {
            return Object.keys(state.statusTabs).find(key => state.statusTabs[key] === true)
        },
    }
}
