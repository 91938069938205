export default {
    actions: {
        async fetchHotAdditionalInfo(ctx){
            //Установка названия страны вылета
            let countryId = ctx.state.params.arrivalCountryId
            if(countryId){
                //Проверка, возможно данные в state уже установлены
                if(ctx.state.arrivalCountry.id === countryId) return
                let country = await ctx.dispatch('fetchGeoObjectByEbgNodeId', countryId)
                ctx.commit('updateHotArrivalCountry', country)
            }
        },
    },
    state: {
        params: {
            departureCityName: 'Москва',
            departureCityId: 274286,
            arrivalCountryId: 18803,
            arrivalRegionIds: [],
            arrivalCityIds: [],
            hotelIds: [],
            minStartDate: null,
            maxStartDate: null,
            minNightsCount: 7,
            maxNightsCount: 14,
            adults: 1,
            children: 0,
        },
        promiseContainer: [],
        arrivalCountry: {
            id: null,
            name: null,
        }
    },
    mutations: {
        updateHotToursParams(state){
            state.params = Object.assign({}, state.params)
        },
        updateHotArrivalCountry(state, country){
            try{
                state.arrivalCountry.id = country.ebgNodeId
                state.arrivalCountry.name = country.localizedName
            }catch(e){
                console.log('Ошибка в мутации updateHotArrivalCountry', e)
            }
        }
    },
    getters: {
        getHotToursParams(state){
            return state.params
        },
        getHotToursPromiseContainer(state){
            return state.promiseContainer
        },
        getHotToursArrivalCountry(state){
            return state.arrivalCountry
        },
    }
}
