import variables from "../../plugins/variables";
import axios from 'axios';

export default {
    actions: {
        async fetchWeekendTourCounters(ctx) {
            let url = variables.routCounters+'/weekend-tour';
            try {
                const counters = await axios({
                    url,
                    method: 'POST'
                });
                ctx.commit('updateWeekendCounters', counters.data)
            } catch(e) {
                console.log('Ошибка в actions fetchWeekendTourCounters', e)
            }

        },


    },
    mutations: {
        updateWeekendCounters(state, counters) {
            state.weekendCounters = counters
        },

    },
    state: {
        weekendCounters:[],
    },
    getters: {
        getWeekendCounters(state) {
            return state.weekendCounters
        },

    },
}
