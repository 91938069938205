import variables from "../../../plugins/variables";
import axios from 'axios';

export default {
    actions: {
        async fetchCountries(ctx){
            let res = null
            let countries = null
            res = await axios({
                url: variables.routeLoyaltyCountries,
                method: 'GET'
            })
            ctx.state.loadLoyaltyCountry = true
            ctx.commit('updateCountries', await res.data.countries)
        },
        async fetchMemberStatus(ctx){
            let res = null
            let status = null
            res = await axios({
                url: variables.routeMemberStatus,
                method: 'GET'
            })
            ctx.commit('updateMemberStatus', await res.data)
        },
        async fetchApplyCountry(ctx, country){
            let res = null
            res = await axios({
                url: variables.routeApplyCountry,
                method: 'POST',
                data: country
            })
        }
    },
    state: {
        countries: null,
        memberStatus: {
            status: null,
            expirationDate: null,
            country: null,
            discountPercent: null,
            fullName: null,
            email: null,
            phone: null,
            birthDate: null
        },
        loadLoyaltyCountry: false,

    },
    mutations: {
        updateCountries(state, payload){
            let result = []
            try {
                payload.forEach(item => {
                    let country = {
                        name: null,
                        value: null
                    }
                    country.name = item.name
                    country.value = item.code
                    result.push(country)
                })
            } catch {
                console.log('Ошибка в мутации updateCountries')
            }
            state.countries = result

        },
        updateMemberStatus(state, payload){
            let result = {
                status: null,
                expirationDate: null,
                country: null,
                discountPercent: null,
                fullName: null,
                email: null,
                phone: null,
                birthDate: null
            }
            try {
                result.status = payload.status
                if(payload.country !== undefined){
                    result.country = {
                        name: payload.country.name,
                        value: payload.country.code
                    }
                }
                result.discountPercent = payload.discountPercent
                result.expirationDate = payload.expirationDate
                result.fullName = payload.user.fullName
                result.email = payload.user.email
                result.phone = payload.user.phone
                result.birthDate = payload.user.birthDate

            } catch {
                console.log('Ошибка в мутации udateMemberStatus')
            }
            state.memberStatus = result
        }
    },
    getters: {
        getCountries(state){
            return state.countries
        },
        getMemberStatus(state){
            return state.memberStatus
        },
        getStatusLoadLoyaltyCountry(state){
            return state.loadLoyaltyCountry
        },
        setMemberCountry: state => country => {
            state.memberStatus.country = country
        }
    }
}
