import variables from "../../../plugins/variables";
import axios from 'axios';
import moment from "moment";

export default {
    actions: {
        async fetchWeekendRegionCards(ctx, payload) {
            let url = `${variables.routWeekendTour}/weekend-region-cards`;
            const fetchWeekendRegionCards = await axios({
                url,
                method: 'POST',
            })
            // console.log('fetchWeekendRegionCards', fetchWeekendRegionCards)
            if (fetchWeekendRegionCards.status === 200){
                ctx.commit('updateWeekendRegionCards', fetchWeekendRegionCards.data)
            }
        },
        async fetchPopularRestTypesData(ctx, payload) {

            let url = variables.routRestTypesData;
            const fetchPopularRestTypesData = await axios({
                url,
                method: 'POST',
                data: payload
            })
            if (fetchPopularRestTypesData.status === 200){
                ctx.commit('updatePopularRestTypesData', fetchPopularRestTypesData.data)
            }
        },
        async searchExcursionTours(ctx, payload) {
            let str = '?';
            Object.keys(payload.data).map(function (key) {
                let elem = key + '=' + payload.data[key] + '&'
                if (key === 'minStartDate' || key === 'maxStartDate') {
                    elem = key + '=' + moment(payload.data[key]).format('YYYY-MM-DD') + '&'
                }
                str = str + elem;
            })
            let queryString = str.slice(0, -1);
            //console.log(queryString)

            let url = variables.routeSearchTour + '/' + payload.tab + queryString;
            // url ='http://127.0.0.1:8000/api/search/country?departureCityId=274286&arrivalCountryId=18803&arrivalRegionIds=&arrivalCityIds=&hotelIds=&minStartDate=2021-06-17&maxStartDate=2021-06-29&minNightsCount=7&maxNightsCount=14&adults=2&children=&searchLevel=&isGeoInfoRequired=false'
            // try {
            const fetchHotels = await axios({
                url,
                method: 'GET',
            })
            return fetchHotels.data
        },

    },
    mutations: {
        updateWeekendRegionCards(state, cards) {
            state.weekendRegionCards = cards;
        },
        updatePopularRestTypesData(state, payload) {
            state.popularRestTypesData = payload;
        },

    },
    state: {
        weekendRegionCards: [],
        popularRestTypesData: [],

    },
    getters: {
        getWeekendRegionCards(state) {
            return state.weekendRegionCards;
        },
        getRestTypesData(state) {
            return state.popularRestTypesData;
        },
    }
}
