import variables from "../../../plugins/variables";
import axios from 'axios';

export default {
    actions: {
        async fetchAgents(ctx) {
            if(ctx.state.isLoadAgents) return
            ctx.state.isLoadingAgents = true
            let url = variables.routeAgent
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                const agents = await res.data
                ctx.commit('updateAgents', agents)
            } catch(e) {
                console.log('Ошибка в actions fetchAgentUsers', e)
            }
            ctx.state.isLoadingAgents = false
            ctx.state.isLoadAgents = true
        },
        async fetchAgentUpdate(ctx, agentInfo){
            let url = variables.routeAgent
            ctx.state.isAgentUpdating = true
            try {
                const res = await axios({
                    url,
                    method: 'PATCH',
                    data: agentInfo
                })
                ctx.commit('updateAgent', res.data)
            } catch(e) {
                console.log('Ошибка в действии fetchAgentsUpdate', e)
            }
            ctx.state.isAgentUpdating = false
        },
        async checkAgentEmail(ctx, email){
            let url = variables.routeAgentCheckEmail +'/'+ email
            try {
                const res = await axios({
                    url,
                    method: 'GET',
                })
                console.log('res.data', res.data)
                return res.data
            } catch(e) {
                console.log('Ошибка в действии checkAgentEmail', e)
                return false
            }
        },
        async fetchAgentAdd(ctx, agentInfo){
            let url = variables.routeAgent
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: agentInfo
                })
                console.log(await res.data)
                if(!await res.data.validationErrors){
                    agentInfo.partpassId = await res.data
                } else {
                    return res.data.validationErrors
                }
                ctx.commit('updateAgentsAdd', agentInfo)
            } catch(e) {
                console.log('Ошибка в действии fetchAgentAdd', e)
            }
        },
        async fetchAgentRegistration(ctx, agentInfo){
            //Создание агента при регистрации агенства
            const url = variables.routeSignUp
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        role: 'agent',
                        data: agentInfo
                    }
                })

                if (await res.status === 200){
                    return true
                }

            }catch(e){
                console.log('Ошибка в actions fetchAgentRegistration', e)
            }
        },
        async fetchAgentDeactivate(ctx, partpassId){
            let agentsInfo = Object.assign([], ctx.state.agents)
            let agentInfo = agentsInfo.find(item => {
                return item.partpassId == partpassId
            })
            agentInfo.isArchived = true
            ctx.dispatch('fetchAgentUpdate', agentInfo)
        },
        async fetchAgentActivate(ctx, partpassId){
            let agentsInfo = Object.assign([], ctx.state.agents)
            let agentInfo = agentsInfo.find(item => {
                return item.partpassId == partpassId
            })
            agentInfo.isArchived = false
            ctx.dispatch('fetchAgentUpdate', agentInfo)
        },
    },
    state: {
        agents: [],
        isAgentUpdating: false, //Переключается в true когда происходит запрос на обновление агента
        isLoadingAgents: false, //Переключается в true во время запроса
        isLoadAgents: false, //Статус были ли загружены агенты
        newAgent:{
            fullName: null,
            position: null,
            birthday: null,
            login: null,
            isMale: null,
            email: null,
            password: null,
            fax: '',
            phone: null,
            isAdministrator: true,
        },
    },
    mutations: {
        updateAgent(state, payload){
            try {
                let agentsInfo = Object.assign([], state.agentsInfo)
                if(payload.partpassId){
                    const res = agentsInfo.find(item => {
                        return item.partpassId == payload.partpassId
                    })
                    if(res){
                        res.birthday = payload.birthday
                        res.createdDate = payload.createdDate
                        res.email = payload.email
                        res.fax = payload.fax
                        res.fullName = payload.fullName
                        res.isAdministrator = payload.isAdministrator
                        res.isArchived = payload.isArchived
                        res.isBookKeeper = payload.isBookKeeper
                        res.isMale = payload.isMale
                        res.isOnlineAccess = payload.isOnlineAccess
                        res.login = payload.login
                        res.note = payload.note
                        res.phone = payload.phone
                        res.position = payload.position
                    }
                state.agentsInfo = agentsInfo
                }
            } catch(e) {
                console.log('Ошибка в мутации updateAgent', e)
            }
        },
        updateAgentsAdd(state, payload){
            try {
                let agentsInfo = Object.assign([], state.agents)
                if(payload.partpassId){
                    const newAgent = {}
                    newAgent.fullName = payload.fullName
                    newAgent.login = payload.login
                    newAgent.ocupation = payload.ocupation
                    newAgent.isMale = payload.isMale
                    newAgent.birthday = payload.birthday
                    newAgent.email = payload.email
                    newAgent.fax = payload.fax
                    newAgent.phone = payload.phone
                    newAgent.position = payload.position
                    newAgent.isAdministrator = payload.isAdministrator
                    newAgent.isBookKeeper = payload.isBookKeeper
                    newAgent.isOnlineAccess = payload.onlineAccess
                    agentsInfo.push(newAgent)
                    state.agents = agentsInfo
                }
            } catch(e){
                console.log('Ошибка в mutations updateAgentsAdd', e)
            }
        },
        updateAgents(state, payload) {
            try{
                let result = []
                payload.forEach(item => {
                    let agents = {
                        birthday: null,
                        createdDate: null,
                        email: null,
                        fax: null,
                        fullName: null,
                        isAdministrator: null,
                        isBookKeeper: null,
                        isArchived: null,
                        isMale: null,
                        isOnlineAccess: null,
                        login: null,
                        note: null,
                        partpassId: null,
                        phone: null,
                        position: null,
                    }
                    agents.birthday = item.birthday
                    agents.createdDate = item.createdDate
                    agents.email = item.email
                    agents.fax = item.fax
                    agents.fullName = item.fullName
                    agents.isAdministrator = item.isAdministrator
                    agents.isBookKeeper = item.isBookKeeper
                    agents.isArchived = item.isArchived
                    agents.isMale = item.isMale
                    agents.isOnlineAccess = item.isOnlineAccess
                    agents.login = item.login
                    agents.note = item.note
                    agents.partpassId = item.partpassId
                    agents.phone = item.phone
                    agents.position = item.position
                    result.push(agents)
                })
                state.agents = result
            } catch(e){
                console.log('Ошибка в mutations updateAgentsInfo', e)
            }
            // try {
            //     state.agentUserInfo.fio = agentUserInfo[0].fullName
            //     state.agentUserInfo.login = agentUserInfo[0].login
            //     state.agentUserInfo.tel = agentUserInfo[0].phone
            //     state.agentUserInfo.create = moment(agentUserInfo[0].createdDate).format('YYYY.MM.DD')
            //     state.agentUserInfo.agentPosition = agentUserInfo[0].positionName
            //     // state.agentUserInfo.isAdmin = agentUserInfo[0].isAdministrator
            //     // todo temp
            //     state.agentUserInfo.isAdmin = 'Нет'
            //     state.agentUserInfo.agentEmail = agentUserInfo[0].email
            //     state.agentUserInfo.agentFax = agentUserInfo[0].fax
            //
            //
            //     // Загружается информация об агенте  в changedInfo если она не была загружена ранее
            //     if (state.isLoadUserChanged === false) {
            //         state.changedAgentUserInfo = {...state.agentUserInfo}
            //         state.isLoadUserChanged = true
            //     }
            //     // console.log(state.agentUserInfo)
            // } catch {
            //     console.log('Не удалось записать полученные данные пользователя в state')
            // }
        },
    },
    getters: {
        getAgents: state => {
            return state.agents
        },
        getAgentById: state => partpassId => {
            let result = {
                birthday: null,
                createdDate: null,
                email: null,
                fax: null,
                fullName: null,
                isAdministrator: null,
                isBookKeeper: null,
                isMale: null,
                isOnlineAccess: null,
                login: null,
                note: null,
                partpassId: null,
                phone: null,
                position: null,
            }
            try {
                let res = state.agents.find(item => {
                    return item.partpassId == partpassId
                })
                if(res.partpassId) result = res
            } catch {}
            return result
        },
        getStatusLoadingAgents: state => {
            return state.isLoadingAgents
        },
        getStatusUpdatingAgent: state => {
            return state.isAgentUpdating
        },
        getNewAgent: state => {
            return state.newAgent
        },
    },

}
