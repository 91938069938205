import variables from "../../plugins/variables";
import axios from 'axios';
import moment from "moment";


export default {
    actions: {
        async fetchHotelByEbgId(ctx, id) {
            const url = variables.routeHotelObject + id;
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                });
                const data = await res.data
                ctx.commit('updateHotelData', data);
                return data
            } catch(e) {
                console.log('Не удалось получить отель методом fetchHotelByEbgId')
                return false
            }

        },
        async fetchHotelCityByParentId(ctx, id) {
            const url = variables.routeHotelGetCityByParentId + id
            const res = await axios({
                url,
                method: 'GET'
            })
            const data = await res.data
            ctx.commit('updateHotelCity', data)
        },
        async fetchHotelSaveFilter(ctx, payload) {
            const url = variables.routeHotelSaveFilter
            const res = await axios({
                url,
                method: 'POST',
                data: payload,
            })
            const data = await res.data
            //console.log('fetchHotelSaveFilter')
            //console.log(data)
        },
        async fetchHotelGetFilter(ctx) {
            const url = variables.routeHotelGetFilter
            const res = await axios({
                url,
                method: 'POST'
            })
            const data = await res.data
            ctx.commit('updateHotelFilterData', data)
            return data;
        },
        async fetchHotelGetOffers(ctx, payload) {
            try {
                let payloadData = Object.assign({}, payload)

                // payloadData.departureCityId = payloadData.departureCityId.toString()
                // payloadData.arrivalCountryId = payloadData.arrivalCountryId.toString()
                // payloadData.adults = payloadData.adults.toString()
                // if(payloadData.nightsFrom) payloadData.nightsFrom = payloadData.nightsFrom.toString()
                // if(payloadData.nightsTo) payloadData.nightsTo = payloadData.nightsTo.toString()

                let keys = Object.keys(payloadData)

                for (let key of keys) {
                    if (typeof (payloadData[key]) === "number") {
                        payloadData[key] = payloadData[key].toString()
                    }
                }

                //Обработка количества детей. Может прилетать число детей или массив с их возрастами
                //Если прилетает число, то создается массив с таким же количеством и возврастом 3 года
                if (typeof (payloadData.children) === "number") {
                    let children = []
                    for (let i = 0; i < payloadData.children; i++) {
                        children.push("2")
                    }
                    payloadData.children = children
                }

                if (Array.isArray(payloadData.children)) {
                    if (payloadData.children.length === 0) {
                        payloadData.children = null
                    } else {
                        payloadData.children = payloadData.children.join(',')
                    }
                }

                const url = variables.routeHotelGetOffers
                ctx.commit('updatePreloader', true)
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payloadData
                })
                const data = await res.data

                if (data === null) {
                    let defaultData = {
                        rooms: [],
                        meals: [],
                        offers: [],
                    }
                    ctx.commit('updateHotelFilterOffers', defaultData)
                } else {
                    ctx.commit('updateHotelFilterOffers', data)
                }
                ctx.commit('updatePreloader', false)
                return data
            } catch (e) {
                console.log('Ошибка в actions fetchHotelGetOffers', e)
                ctx.state.isShowClearFilters = true
            }
        },
        async fetchHotelPriceAndDates(ctx, payload) {
            let payloadData = Object.assign({}, payload)
            payloadData.departureCityId = payloadData.departureCityId.toString()
            payloadData.arrivalCountryId = payloadData.arrivalCountryId.toString()
            payloadData.adults = payloadData.adults.toString()
            if (payloadData.children.length === 0) {
                payloadData.children = null
            } else {
                payloadData.children = payloadData.children.join(',')
            }
            payloadData.nightsFrom = payloadData.nightsFrom.toString()
            payloadData.nightsTo = payloadData.nightsTo.toString()

            let payloadData2 = Object.assign({}, payloadData)
            payloadData2.minStartDate = payloadData2.maxStartDate
            payloadData2.maxStartDate = moment(payloadData2.maxStartDate, "YYYY-MM-DD").add(13, 'days').format("YYYY-MM-DD")
            //console.log('PriceAndDates:', payload)

            payloadData2.minStartDate = moment(payloadData2.maxStartDate, "YYYY-MM-DD").subtract(13, 'days').format("YYYY-MM-DD")
            //console.log('PriceAndDates:', payload2)
            const url = variables.routeHotelGetOffers
            const res = await axios({
                url,
                method: 'POST',
                data: payloadData
            })
            const data = await res.data
            //console.log('PriceAndDates', data)

            const res2 = await axios({
                url,
                method: 'POST',
                data: payloadData2
            })
            const data2 = await res2.data
            let offers = data.offers.concat(data2.offers)
            ctx.commit('updatePriceAndDates', offers)
        },
        async fetchHotelPriceAndDatesNew(ctx, payload) {
            let payloadData = Object.assign({}, payload)
            payloadData.departureCityId = payloadData.departureCityId.toString()
            payloadData.arrivalCountryId = payloadData.arrivalCountryId.toString()
            payloadData.adults = payloadData.adults.toString()
            if (payloadData.children.length === 0) {
                payloadData.children = null
            } else {
                payloadData.children = payloadData.children.join(',')
            }
            payloadData.nightsFrom = payloadData.nightsFrom.toString()
            payloadData.nightsTo = payloadData.nightsTo.toString()

            let payloadData2 = Object.assign({}, payloadData)
            payloadData2.minStartDate = payloadData2.maxStartDate
            payloadData2.maxStartDate = moment(payloadData2.maxStartDate, "YYYY-MM-DD").add(2, 'days').format("YYYY-MM-DD")
            //console.log('PriceAndDates:', payload)

            payloadData2.minStartDate = moment(payloadData2.minStartDate, "YYYY-MM-DD").subtract(2, 'days').format("YYYY-MM-DD")
            //console.log('PriceAndDates:', payload2)
            const url = variables.routeHotelGetOffers
            const res = await axios({
                url,
                method: 'POST',
                data: payloadData
            })
            const data = await res.data
            //console.log('PriceAndDates', data)

            const res2 = await axios({
                url,
                method: 'POST',
                data: payloadData2
            })
            const data2 = await res2.data
            let offers = data.offers.concat(data2.offers)
            ctx.commit('updatePriceAndDates', offers)
        },

        async fetchHotelGetOffersForAgent(ctx, payload) {
            const url = variables.routeHotelGetOffers

            ctx.commit('updatePreloader', true)
            const res = await axios({
                url,
                method: 'POST',
                data: payload
            })
            const data = await res.data;
            if (data && data.offers) {
                data.offers.forEach(item => {
                    // Заменяем цены на полные с учётом топливного сбора (делается здесь чтоб не менять в куче мест где используется)
                    if (item.fullPrice && item.fullPrice.value) {
                        item.clearPrice = item.price;
                        item.price = item.fullPrice;
                    }
                });
            }
            ctx.commit('updatePreloader', false)
            return data
        },
        async fetchCountryByCityId(ctx, payload) {
            const url = variables.routeTourSearchCountryById + payload
            // try {
            const country = await axios({
                url: url,
                method: 'POST'
            });

            const data = await country.data;
            ctx.commit('updateHotelCountry', data)
            /* } catch {
                 console.log('Ошибка получения ответа от ' + url)
             }*/
        },
        async fetchCountryByCityIdReturnObject(ctx, payload) {
            const url = variables.routeTourSearchCountryById + payload

            // try {
            const country = await axios({
                url: url,
                method: 'GET'
            });

            const data = await country.data;
            return data
            /* } catch {
                 console.log('Ошибка получения ответа от ' + url)
             }*/
        },
        async fetchHotelByUuid(ctx, hotelUuid) {
            const url = variables.routeHotelObject + hotelUuid
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                })
                return await res.data
            } catch (e) {
                console.log('Ошибка в actions fetchHotelByUuid', e)
            }
        },
        //Получение выдачи. Использует service - объект конструктора
        async fetchHotelToHead(ctx, service) {
            let url = variables.routeSearchTour + '/country';
            let result = []
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                    params: service.searchParams,
                })
                if (Array.isArray(await res.data.tours)) {
                    result = res.data.tours
                }
                service.response = res.data
                //service.hotels = result
                //при пересчете пакетных отелей логика формирования фильтра не мешает
                //в дальнейшем может понадобиться
                ctx.commit('updateFilterHotels', service)
                return result
            } catch (e) {
                console.log('Ошибка в actions fetchHotelToHead', e)
            }
        },
        //Получение выдачи. Легкий метод, без конструкторских объектов и мутаций
        async fetchHotelToHeadLight(ctx, searchParams) {
            // let searchParams = {
            //     departureCityId: 1,
            //     arrivalCountryId: 18803,
            //     arrivalCityIds: 19415,
            //     minStartDate: '2021-01-05',
            //     maxStartDate: '2021-01-20',
            //     minNightsCount: 7,
            //     maxNightsCount: 14,
            //     adults: 2,
            //     children: [],
            //     arrivalRegionIds: null,
            //     hotelIds: null,
            // }
            let url = variables.routeSearchTour + '/country';
            let result = []
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                    params: searchParams,
                })
                if (Array.isArray(await res.data.tours)) {
                    result = res.data.tours
                }
                return result
            } catch (e) {
                console.log('Ошибка в actions fetchHotelToHeadLight', e)
            }
        },
        async fetchHotelsByExcursionProgramId(ctx, excursionProgramId) {
            // console.log('excursionProgramId', excursionProgramId)
            let url = variables.routeHotelGetHotelsByExcursionProgramId + '/' + excursionProgramId;
            //try{
            let res = await axios({
                url,
                method: 'POST',
            })
            let result = await res.data
            return result
            //} catch(e){
            //  console.log('Ошибка в actions fetchHotelsByExcursionProgramId', e)
            //}
        },
        async fetchGetExcursionDescriptionByEbgId(ctx, ebgId) {
            // console.log('ebgId', ebgId)
            let url = variables.routeHotelGetExcursionDescriptionByEbgId + '/' + ebgId;
            let res = await axios({
                url,
                method: 'POST',
            })
            let result = await res.data
            return result
        }
    },
    mutations: {
        updateFilterHotels(state, service) {
            try {

                let tours = []
                if (service.response.tours && service.response.tours.length > 0) {
                    tours = service.response.tours
                } else {
                    return
                }

                service.country.id = service.response.country.id
                service.country.name = service.response.country.name

                //Данные для отрисовки фильтра. Изменяются только здесь
                let filterData = {
                    priceMin: 0,
                    priceMax: 0,
                    tourTypes: [],
                    hotelServices: [],
                    specialServices: [],
                    stars: [],
                    meals: [],
                    countries: [],
                    regions: [],
                    cities: [],
                    hotelName: '',
                }

                //Параметры фильтра
                let filterParams = {
                    priceMin: 0,
                    priceMax: 0,
                    tourTypes: [],
                    hotelServices: [],
                    specialServices: [],
                    stars: [],
                    meals: [],
                    countries: [],
                    regions: [],
                    cities: [],
                    hotelName: '',
                }

                let meals = [] //массив с идентификаторами. В фильтре уже будет массив с объектами
                let tourTypeIds = []

                tours.forEach(hotel => {
                    //Цена берется из первого ценового предложения
                    let price = hotel.accommodationPriceOffer[0].price.value

                    if (filterData.priceMin === 0 || filterData.priceMin > price) filterData.priceMin = price
                    if (filterData.priceMax < price) filterData.priceMax = price

                    filterData.hotelServices.push.apply(filterData.hotelServices, hotel.hotel.hotelServices)
                    filterData.specialServices.push.apply(filterData.specialServices, hotel.hotel.specialServices)
                    filterData.stars.push(hotel.hotel.stars)

                    meals.push(hotel.accommodationPriceOffer[0].mealTypeGroupId)
                    tourTypeIds.push(hotel.accommodationPriceOffer[0].tourTypeId)
                })

                //Регионы
                if (Array.isArray(service.response.regions)) {
                    //С бека один и тот же регион может прилетать несколько раз
                    //поэтому проверка
                    let regionsId = new Set()
                    service.response.regions.forEach(item => {
                        if (!regionsId.has(item.id)) {
                            regionsId.add(item.id)

                            let region = {
                                country: null,
                                id: null,
                                name: null,
                                urlName: null
                            }

                            region.country = item.country
                            region.id = item.id
                            region.name = item.name
                            region.urlName = item.urlName

                            filterData.regions.push(region)
                        }
                    })
                }
                filterData.regions.unshift({
                    country: null,
                    id: -1,
                    name: 'Все',
                    urlName: null
                })

                //Города (могут не использоваться при отрисовке фильтра, но данные используются для фильтрации по регионам)
                if (Array.isArray(service.response.cities)) {
                    service.response.cities.forEach(item => {
                        let city = {
                            country: null,
                            guid: null,
                            id: null,
                            name: null,
                            region: null,
                            urlName: null
                        }

                        city.country = item.city
                        city.guid = item.guid
                        city.id = item.id
                        city.name = item.name
                        city.region = item.region
                        city.urlName = item.urlName

                        filterData.cities.push(city)
                    })
                }

                //Дополнительная обработка полученных значений
                filterData.hotelServices = filterData.hotelServices.reduce((acc, value) => {
                    if (!acc.some(item => item.id === value.id)) {
                        acc.push(value)
                    }
                    return acc
                }, [])
                filterData.hotelServices.unshift({
                    id: -1,
                    name: "Все",
                    order: 0
                })

                filterData.specialServices = filterData.specialServices.reduce((acc, value) => {
                    if (!acc.some(item => item.id === value.id)) {
                        acc.push(value)
                    }
                    return acc
                }, [])
                filterData.specialServices.unshift({
                    id: -1,
                    name: "Все",
                    order: 0
                })

                filterData.stars = [... new Set(filterData.stars)]
                filterData.stars = filterData.stars.sort((a, b) => b - a)
                filterData.stars.unshift(-1)//Все

                //Питание
                meals = [... new Set(meals)]
                meals.forEach(meal => {
                    // let mealType = {
                    //     mealTypeGroupId: null,
                    //     name: null
                    // }
                    if (Array.isArray(service.response.mealTypes)) {
                        let mealType = service.response.mealTypes.find(item => item.mealTypeGroupId === meal)
                        if (mealType.name !== null) filterData.meals.push(mealType)
                    }
                })
                filterData.meals.unshift({
                    mealTypeGroupId: -1,
                    name: 'all'
                })//Все

                //Тип тура
                tourTypeIds = [...new Set(tourTypeIds)]
                tourTypeIds.forEach(tourTypeId => {
                    if (Array.isArray(service.response.tourTypes)) {
                        // let tourType = {
                        //     "id": 7,
                        //     "name": "Наземное обслуживание"
                        // }
                        let tourType = service.response.tourTypes.find(item => item.id === tourTypeId)
                        if (tourType) filterData.tourTypes.push(tourType)
                    }
                })
                filterData.tourTypes.unshift({
                    id: -1,
                    name: 'Все',
                })

                //Настройки фильтра по умолчанию

                filterParams.priceMin = filterData.priceMin
                filterParams.priceMax = filterData.priceMax
                filterParams.tourTypes = [...filterData.tourTypes]
                filterParams.hotelServices = [...filterData.hotelServices]
                filterParams.specialServices = [...filterData.specialServices]
                filterParams.stars = [...filterData.stars]
                filterParams.meals = [...filterData.meals]
                filterParams.regions = [...filterData.regions]
                filterParams.cities = [...filterData.cities]

                service.filterData = filterData
                service.filterParams = filterParams
            } catch (e) {
                console.log('Ошибка в мутации updateFilterHotels', e)
            }
        },
        updateHotelData(state, payload) {
            state.hotelData = payload
        },
        updateHotelCity(state, payload) {
            state.hotelCityData = payload
        },
        updateHotelCountry(state, payload) {
            state.hotelCountry = payload
        },
        updateHotelFilterData(state, payload) {
            state.hotelFilterData = payload
        },
        updateHotelFilterOffers(state, payload) {
            // console.log('Отработал апдейт', payload)
            state.hotelOffers = []
            state.hotelOffers = payload
            state.hotelOffers.meals.forEach(meal => {
                // НОВАЯ логика с проверкой (костыль)
                // если mealTypeGroupId = 0, НО при этом приходит аббревиатура типа питания (хотя по умолчанию "0" это "нет данных" )
                meal.nameRU = state.mealTypesNaming.find(item => item.id === meal.mealTypeGroupId).nameRU
                if (meal.mealTypeGroupId === 0 && meal.mealTypeName) {
                    let mealTypeObj = state.mealTypesNaming.find(el => el.name === meal.mealTypeName)
                    if (mealTypeObj) {
                        meal.mealTypeGroupId = mealTypeObj.id
                        meal.mealTypeGroupName = mealTypeObj.name
                        meal.nameRU = mealTypeObj.nameRU
                    }
                }
            })
            state.hotelProgramTypes = []
            let exKeys = [1, 5, 14, 17, 57, 87, 114, 194] // STD, GDS, тариф стандарт, невозвратный тариф, promo, Промо
            state.hotelOffers.offers.forEach(item => {
                let findType = state.hotelProgramTypes.find(type => type.key === item.programType.key)
                if (!findType && !exKeys.includes(item.programType.key)) {
                    state.hotelProgramTypes.push(item.programType)
                }
                // Заменяем цены на полные с учётом топливного сбора (делается здесь чтоб не менять в куче мест где используется)
                if (item.fullPrice && item.fullPrice.value) {
                    item.clearPrice = item.price;
                    item.price = item.fullPrice;
                }
            })
        },
        updatePreloader(state, payload) {
            state.preloader = payload
        },
        updatePriceAndDates(state, payload) {
            //console.log(payload)
            let dates = []
            let maxPrice = 0
            state.hotelPriceAndDates.maxPrice = 0
            state.hotelPriceAndDates.dates = []
            state.hotelPriceAndDates.offers = []
            state.hotelPriceAndDates.monthStart = ""
            state.hotelPriceAndDates.monthEnd = ""
            if (payload !== undefined) {
                payload.forEach(function (item) {
                    // Заменяем цены на полные с учётом топливного сбора (делается здесь чтоб не менять в куче мест где используется)
                    if (item.fullPrice && item.fullPrice.value) {
                        item.clearPrice = item.price;
                        item.price = item.fullPrice;
                    }
                });
                payload.forEach(function (item) {
                    //Собираем даты
                    item.checkinDate = moment(item.checkinDate).format('YYYY-MM-DD')
                    if (!dates.includes(item.checkinDate)) {
                        dates.push(item.checkinDate)
                    }
                    //Ищем максимальную цену на эти даты
                    if (item.price.value > maxPrice) {
                        maxPrice = item.price.value
                    }
                })
                dates.sort((a, b) => moment(a, 'YYYY-MM-DD') - moment(b, 'YYYY-MM-DD'))
                //Ищем максимальную цену из минимальных по дням
                let maxMinPrice = 0
                dates.forEach(function (date) {
                    let minPrice = maxPrice
                    payload.forEach(function (offer) {
                        if (offer.checkinDate === date && minPrice > offer.price.value) {
                            minPrice = offer.price.value
                        }
                    })
                    if (minPrice > maxMinPrice) {
                        maxMinPrice = minPrice
                    }
                })
                //console.log(maxMinPrice)
                //Собираем результат
                dates.forEach(function (date) {
                    let dateName = moment(date).format('ddd');
                    let dateDay = moment(date).format('DD');
                    let minPrice = maxPrice
                    let currencyAlias = ""
                    payload.forEach(function (offer) {
                        if (offer.checkinDate === date && minPrice > offer.price.value) {
                            minPrice = offer.price.value
                            currencyAlias = offer.price.currencyAlias
                        }
                    })
                    let resultOffer = {
                        dateName: dateName,
                        date: date,
                        dateDay: dateDay,
                        minPrice: minPrice,
                        currencyAlias: currencyAlias,
                        height: minPrice * 100 / maxMinPrice
                    }
                    state.hotelPriceAndDates.offers.push(resultOffer)
                })
                state.hotelPriceAndDates.maxPrice = maxPrice
                state.hotelPriceAndDates.dates = dates
                state.hotelPriceAndDates.monthStart = moment(dates[0], 'YYYY-MM-DD').format('MMMM')
                state.hotelPriceAndDates.monthEnd = moment(dates[dates.length - 1], 'YYYY-MM-DD').format('MMMM')
            }
        },
        updateTourPrice(state, payload) {
            state.tourPrice = payload
        }
    },
    state: {
        hotelData: {
            recommendations: [],
            address: "",
            addressPostIndex: "",
            addressStreet: "",
            addressHouse: "",
            concepts: [],
            descriptions: [],
            services: [],
            hotelCategory: {},
            hotelGroup: null,
            constructionYear: 2000,
            renovationYear: null,
            totalArea: null,
            phone: "",
            fax: "",
            website: "",
            email: "",
            id: "",
            objectType: "",
            standardizedCode: null,
            standardizedName: "",
            searchSynonyms: null,
            tourTypes: "",
            latitude: null,
            longitude: null,
            mapZoomLevel: 0,
            parentId: "",
            ebgNodeId: 0,
            localizedName: "",
            generalDescription: null,
            caseForms: {
                nominativeCase: "",
                genitiveCase: "",
                dativeCase: "",
                accusativeCase: "",
                instrumentalCase: "",
                prepositionalCase: ""
            },
            prepositionForm: null,
            geoSystemCode: null,
            desertAdventuresCode: null,
            profkurortCode: null,
            apiBolCode: null,
            images: [],
            videos: [],
            additionalDescriptions: [],
            createdAt: "",
            createdBy: "",
            updatedAt: "",
            updatedBy: ""
        },
        hotelFilterData: {},
        hotelOffers: {},
        hotelProgramTypes: [],
        hotelCityData: {},
        hotelCountry: {},
        preloader: false,
        hotelPriceAndDates: {
            maxPrice: 0,
            dates: [],
            offers: [],
            monthStart: "",
            monthEnd: "",
        },
        mealTypesNaming: [
            //'Завтрак'
            { id: 10001, name: 'BB', nameRU: _trans('tourSearch/countryData.mealBB'), trslt: 'tourSearch/countryData.mealBB', nameEN: 'Bed & Breakfast' },
            //'Полупансион'
            { id: 10002, name: 'HB', nameRU: _trans('tourSearch/countryData.mealHB'), trslt: 'tourSearch/countryData.mealHB', nameEN: 'Half Board' },
            //'Полный пансион'
            { id: 10003, name: 'FB', nameRU: _trans('tourSearch/countryData.mealFB'), trslt: 'tourSearch/countryData.mealFB', nameEN: 'Full Board' },
            //'Все включено'
            { id: 10004, name: 'AI', nameRU: _trans('tourSearch/countryData.mealAI'), trslt: 'tourSearch/countryData.mealAI', nameEN: 'All Inclusive' },
            //'Без питания'
            { id: 10005, name: 'RO', nameRU: _trans('tourSearch/countryData.mealRO'), trslt: 'tourSearch/countryData.mealRO', nameEN: 'Room Only' },
            //'Ультра все включено'
            { id: 8, name: 'AUI', nameRU: _trans('tourSearch/countryData.mealAUI'), trslt: 'tourSearch/countryData.mealAUI', nameEN: 'Ultra All Inclusive' },
            //'По программе'
            { id: 4, name: 'PRG', nameRU: _trans('tourSearch/countryData.mealPRG'), trslt: 'tourSearch/countryData.mealPRG', nameEN: 'Program' },
            //'Нет данных'
            { id: 0, name: 'N/A', nameRU: _trans('tourSearch/countryData.mealNoData'), trslt: 'tourSearch/countryData.mealNoData', nameEN: 'No data' },
            { id: 10, name: 'N/A', nameRU: _trans('tourSearch/countryData.mealNoData'), trslt: 'tourSearch/countryData.mealNoData', nameEN: 'No data' },
        ],
        tourPrice: 0,
        isShowClearFilters: false,
    },
    getters: {
        getHotelData(state) {
            return state.hotelData
        },
        getHotelCityData(state) {
            return state.hotelCityData
        },
        getHotelCountry(state) {
            return state.hotelCountry
        },
        getHotelFilterData(state) {
            return state.hotelFilterData
        },
        getHotelOffers(state) {
            return state.hotelOffers
        },
        getHotelOffersMinPrice(state) {
            let price = { currencyAlias: 'RUB', value: 0 }
            if (state.hotelOffers.offers && state.hotelOffers.offers.length) {
                price.currencyAlias = state.hotelOffers.offers[0].price.currencyAlias
                price.value = state.hotelOffers.offers[0].price.value
            }
            return price
        },
        getPreloader(state) {
            return state.preloader
        },
        getHotelPriceAndDates(state) {
            return state.hotelPriceAndDates
        },
        getTourPrice(state) {
            return state.tourPrice
        },
        getHotelProgramTypes(state) {
            return state.hotelProgramTypes
        },
        getIsShowClearFilters(state) {
            return state.isShowClearFilters
        },
    },
}
