import axios from 'axios'
import variables from "../../../plugins/variables";
import helpers from "../../../plugins/helpers";
export default {
    actions: {
        async fetchAgentContractsGet(ctx){
            try {
                const url = variables.routeAgentContracts + '/get-agent-contracts'
                let res = await axios({
                    url,
                    method: 'POST',
                })
                let data = await res.data
                if(!data.validationErrors){
                    ctx.commit('updateAgentContracts', data)
                }
            } catch(e) {
                console.log('Ошибка в actions fetchAgentContractsGet', e)
            }
        },
        async fetchAgentContractAdd(ctx, contractInfo){
            try {
                const url = variables.routeAgentContracts + '/add-agent-contracts'
                let res = await axios({
                    url,
                    method: 'POST',
                    data: contractInfo,
                })
                if(await res.data.id){
                    ctx.dispatch('fetchAgentContractsGet')
                }
            } catch(e){
                console.log('Ошибка в actions fetchAgentContractAdd', e)
            }
        },
        async fetchAgentContractPrint(ctx, contractInfo){
            ctx.commit('updateAgentContractsLoadingStatus',true)
            try{
                const url = variables.routeAgentContracts + '/create-contract-job'
                let res = await axios({
                    url,
                    method: 'POST',
                    data: contractInfo,
                })
                return await res.data
            } catch(e){
                console.log('Ошибка в actions fetchAgentContractPrint', e)
            }
        },
        async fetchAgentPrint(ctx, jobId){
            ctx.commit('updateAgentContractsLoadingStatus',true)
            try{
                const url = variables.routeAgentPrint+'?jobId='+ jobId
                let res = await axios({
                    url,
                    method: 'GET',
                    // params: {
                    //     jobId,
                    // },
                })
                if (await res.status!==200){
                    return  res.status
                }else {
                    helpers.downloadFile(url)
                    ctx.commit('updateAgentContractsLoadingStatus',false)
                    return  res.status
                }

            } catch(e){
                console.log('Ошибка в actions fetchAgentPrint', e)
            }
        },
    },
    state: {
        loadingStatus:false,
        agentContracts: [],
    },
    mutations: {
        updateAgentContractsLoadingStatus(state, payload){
            state.loadingStatus = payload
        },
        updateAgentContracts(state, payload){
            // console.log('contacts', payload)
            try {
                let result = []
                payload.forEach(item => {
                    let contract = {
                        bankLName: null,
                        bankName: null,
                        bik: null,
                        contractType: null,
                        contractTypeLName: null,
                        contractTypeName: null,
                        dateBegin: null,
                        dateEnd: null,
                        dogovorNumber: null,
                        haveCopy: null,
                        haveOriginal: null,
                        id: null,
                        inDelta: null,
                        kpp: null,
                        ks: null,
                        owner: null,
                        rs: null,
                        rsv: null,
                    }
                    contract.bankLName = item.bankLName
                    contract.bankName = item.bankName
                    contract.bik = item.bik
                    contract.contractType = item.contractType
                    contract.contractTypeLName = item.contractTypeLName
                    contract.contractTypeName = item.contractTypeName
                    contract.dateBegin = item.dateBegin
                    contract.dateEnd = item.dateEnd
                    contract.dogovorNumber = item.dogovorNumber
                    contract.haveCopy = item.haveCopy
                    contract.haveOriginal = item.haveOriginal
                    contract.id = item.id
                    contract.inDelta = item.inDelta
                    contract.kpp = item.kpp
                    contract.ks = item.ks
                    contract.owner = item.owner
                    contract.rs = item.rs
                    contract.rsv = item.rsv
                    result.push(contract)
                })
                state.agentContracts = result
            } catch(e) {
                console.log('Ошибка в мутации updateAgentContracts', e)
            }
        },
    },
    getters: {
        getAgentContracts: state => {
            return state.agentContracts
        },
        getAgentContractsLoadingStatus:state=>{
            return state.loadingStatus
        }
    }
}
