import variables from "../../plugins/variables"
import axios from 'axios'

export default {
    actions: {
        async fetchAgencyActivities(ctx){
            let url = variables.routeReference + '/activities'
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    headers: {'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')}
                })
                ctx.commit('updateAgencyActivities', await res.data)
            } catch(e) {
                console.log('Ошибка в actions fetchAgencyActivities', e)
            }
        },
        async fetchAgencyTaxation(ctx){
            let url = variables.routeReference + '/taxation'
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    headers: {'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')}
                })
                ctx.commit('updateAgencyTaxation', await res.data)
            } catch(e) {
                console.log('Ошибка в actions fetchAgencyTaxation', e)
            }
        },
        async fetchOwners(ctx){
            let url = variables.routeReference + '/owners'
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                    headers: {'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')}
                })
                ctx.commit('updateOwners', await res.data)
            } catch(e) {
                console.log('Ошибка в actions fetchOwners', e)
            }
        },
        async fetchOwnerships(ctx){
            const url = variables.routeReference + '/ownerships'
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                    headers: {'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')},
                })
                ctx.commit('updateOwnerships', await res.data)
            } catch(e) {
                console.log('Ошибка в action fetchOwnership', e)
            }
        },
        async fetchReferenceDirectorPositions(ctx){
            let url = variables.routeReference + "/directorpositions"
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    headers: {'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')}
                })
                ctx.commit('updatePositions', await res.data)
            } catch(e) {
                console.log('Ошибка в actions fetchReferenceDirectorPositions', e)
            }
        },
        async fetchAgentInfo(ctx){
            let url = variables.routeAgentInfo
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    headers: {'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')},
                })
                return await res.data
            }catch(e){
                console.log('Ошибка в action fetchAgentInfo')
            }
        },
        async fetchGetNationalities(ctx){
            if(ctx.state.natinalities.length > 0) return
            const url = variables.routeNationality
            try{
                let res = await axios({
                   method: 'GET',
                   url,
                })
                ctx.commit('updateNationalities', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchGetNationalities', e)
            }
        },
        async fetchCancelClaimReasons(ctx){
            const url = variables.routeReference + '/reasons'
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateCancelReasons', await res.data)
            } catch(e) {
                console.log('Ошибка в action fetchCancelClaimReasons', e)
            }
        },
    },
    state: {
        owners: [],
        ownerships: [],
        positions: [],
        agencyActivities: [],
        agencyTaxation: [],
        cityHotels: [],
        natinalities: [],
        cancelReasons: [],
    },
    mutations: {
        updateAgencyActivities(state, payload){
            let agencyActivities = []
            try{
                payload.forEach(item => {
                    let activity = {
                        id: null,
                        title: null,
                        titleLatin: null,
                    }
                    activity.id = item.id
                    activity.title = item.title
                    activity.titleLatin = item.titleLatin
                    agencyActivities.push(activity)
                })
                state.agencyActivities = agencyActivities
            }catch(e){
                console.log('Ошибка в мутации updateAgencyActivities', e)
            }
        },
        updateNationalities(state, payload){
            let nationalities = []
            if(Array.isArray(payload)){
                nationalities = payload
            }
            state.natinalities = nationalities
        },
        updateAgencyTaxation(state, payload){
            let agencyTaxation = []
            let forbiddenIds = [101, 102, 103, 104, 105, 106] // временное решение не показывать варианты для Украины
            try{
                payload.forEach(item => {
                    if (!forbiddenIds.includes(item.id)) {
                        let tax = {
                            id: null,
                            title: null,
                            titleLatin: null,
                        }
                        tax.id = item.id
                        tax.title = item.title
                        tax.titleLatin = item.titleLatin
                        agencyTaxation.push(tax)
                    }
                })
                state.agencyTaxation = agencyTaxation
            }catch(e){
                console.log('Ошибка в мутации updateAgencyTaxation', e)
            }
        },
        updateOwners(state, payload){
            let result = []
            try {
                payload.forEach(item => {
                    let owner = {
                        id: null,
                        name: null,
                        countryId: null,
                        countryName: null,
                        contractTypeId: null,
                    }
                    owner.id = item.id
                    owner.name = item.name
                    owner.countryId = item.countryId
                    owner.countryName = item.countryName
                    owner.contractTypeId = item.contractTypeId
                    result.push(owner)
                })
                state.owners = result
            } catch(e){
                console.log('Ошибка в мутации updateOwners', e)
            }
        },
        updateOwnerships(state, payload){
            let ownerships = []
            let forbiddenIds = [101, 104, 114, 115, 116] // временное решение не показывать варианты для Украины
            try{
                payload.forEach(item => {
                    if (!forbiddenIds.includes(item.id)) {
                        let ownership = {
                            id: null,
                            title: null,
                            titleLatin: null,
                        }
                        ownership.id = item.id
                        ownership.title = item.title
                        ownership.titleLatin = item.titleLatin
                        ownerships.push(ownership)
                    }
                })
                state.ownerships = ownerships
            }catch(e){
                console.log('Ошибка в мутации updateOwnership', e)
            }
        },
        updatePositions(state, payload){
            let positions = []
            try{
                payload.forEach(item => {
                    let position = {
                        id: null,
                        title: null,
                        titleLatin: null,
                    }
                    position.id = item.id
                    position.title = item.title
                    position.titleLatin = item.titleLatin
                    positions.push(position)
                })
                state.positions = positions
            }catch(e){
                console.log('Ошибка в мутации updatePositions', e)
            }
        },
        updateCityHotels(state, payload){
            let hotels = []
            try{
                let hotelsArray = Object.values(payload)
                hotelsArray.forEach(item => {
                    let hotel = {
                        ebgNodeId: null,
                        hasChangesToCheck: null,
                        hasPublishedVersion: null,
                        id: null,
                        isDeleted: null,
                        localizedName: null,
                        objectType: null,
                        parentId: null,
                        standardizedCode: null,
                        standardizedName: null,
                    }
                    hotel.ebgNodeId = item.ebgNodeId
                    hotel.hasChangesToCheck = item.hasChangesToCheck
                    hotel.hasPublishedVersion = item.hasPublishedVersion
                    hotel.id = item.id
                    hotel.isDeleted = item.isDeleted
                    hotel.localizedName = item.localizedName
                    hotel.objectType = item.objectType
                    hotel.parentId = item.parentId
                    hotel.standardizedCode = item.standardizedCode
                    hotel.standardizedName = item.standardizedName
                    hotels.push(hotel)
                })
            }catch(e){
                console.log('Ошибка в мутации updateCityHotels', e)
            }
            state.cityHotels = hotels
        },
        updateCancelReasons(state, payload) {
            let reasons = []
            payload.forEach(el => {
                const reason = {
                    id: null,
                    nameRU: null,
                    nameEN: null,
                }
                reason.id     = el.id
                reason.nameRU = el.title
                reason.nameEN = el.titleLatin
                reasons.push(reason)
            })
            state.cancelReasons = reasons
        }
    },
    getters: {
        getAgencyActivities: state => {
            return state.agencyActivities
        },
        getAgencyTaxation: state => {
            return state.agencyTaxation
        },
        getOwners: state => {
            return state.owners
        },
        getOwnerShips: state => {
            return state.ownerships
        },
        getPositions: state => {
            return state.positions
        },
        getCityHotels: state => {
            return state.cityHotels
        },
        getNationalities: state => {
            return state.natinalities
        },
        getCancelReasons(state) {
            return state.cancelReasons
        }
    }
}
