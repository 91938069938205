import variables from "../../../plugins/variables";
import axios from 'axios';
import {convertSamoTourToTour} from "../../../plugins/convertObjects";


export default {
    actions: {
        async searchByCompare(ctx, params = null) {
            ctx.commit('updateSearchCompleted', false)
            ctx.commit('updateIsShowOffersModal', true)

            let storageSearchParams = []
            if (!params) {
                try {
                    let res = JSON.parse(localStorage.getItem('storageSearchParams'))
                    if(Array.isArray(res)) storageSearchParams = res
                } catch(e){}

                if (Array.isArray(storageSearchParams)) ctx.state.storageSearchParams = storageSearchParams
            } else {
                storageSearchParams = params
            }

            let promiseContainer = []
            let allTours = []
            let filteredSearchParams = storageSearchParams.filter(searchParams => {
                let result = false

                //if (searchParams.arrivalRegionIds === ctx.state.compareFilterParams.regionId) result = true
                // if (searchParams.arrivalCityIds === ctx.state.compareFilterParams.cityId) result = true

                result = ctx.state.regions.some(region => {
                    return region.isChecked && searchParams.arrivalRegionIds === region.id
                })

                if (!params) result = true

                return result
            })

            filteredSearchParams.forEach(searchParams => {
                let res = ctx.dispatch('searchToursForBlockCompare', searchParams)
                promiseContainer.push(res)
            })

            for (let key in promiseContainer) {
                let container = await promiseContainer[key]
                if (Array.isArray(container.tours) && container.tours.length) {
                    allTours = [...allTours, convertSamoTourToTour(container.tours[0], window.geo_cdn_link)]
                }
            }

            allTours.forEach(tour => {
                if (!tour.hotelRegion.id && !tour.hotelRegion.name) {
                    let region = storageSearchParams.find(elem => {
                        return tour.hotelId === elem.hotelIds
                    })
                    tour.hotelRegion.id = region.arrivalRegionIds
                    tour.hotelRegion.name = region.arrivalRegionName
                }
            })

            if (!params) ctx.commit('updateCompareDirections', allTours)
            ctx.state.compareActiveTours = allTours
            // Сделано для перерендера характеристик,
            // после обновления параметров на странице
            // сравнения
            eventBus.$emit('searchByCompare')

            ctx.commit('updateIsShowOffersModal', false)
            ctx.commit('updateSearchCompleted', true)
        },
        async researchByCompare(ctx) {
            let storageSearchParams = ctx.state.storageSearchParams
            let compareFilterParams = ctx.state.compareFilterParams

            storageSearchParams.forEach(searchParams => {
                searchParams.adults = compareFilterParams.adults
                searchParams.children = compareFilterParams.children
                searchParams.minNightsCount = compareFilterParams.minNightsCount
                searchParams.maxNightsCount = compareFilterParams.maxNightsCount
                searchParams.minStartDate = compareFilterParams.minStartDate
                searchParams.maxStartDate = compareFilterParams.maxStartDate
            })

            //storageSearchParams = ctx.state.storageSearchParams

            //Закомментировал из-за исчезновения туров,
            //по которым нет предложений,
            //после обновления страницы
            // localStorage.setItem('storageSearchParams', JSON.stringify(storageSearchParams))
            ctx.dispatch('searchByCompare', storageSearchParams)
        },
        //deprecated, замена - fetchHotelDescriptionReferences
        async hotelDescriptionTypes(ctx) {
            let url = variables.routeGeo + '/hotelDescriptiontypes'
            let result = []
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                })

                if (res.data && Array.isArray(res.data)) {
                    result = res.data
                }
            } catch (e) {
                console.log('Ошибка в actions _hotelDescriptiontypes', e)
            }

            //Мутация готовит одноуровневый справочник
            ctx.commit('updateHotelDescriptionReferences', result)

            //Метод возвращает иерархическую структуру
            return result
        },
        async fetchHotelDescriptionReferences(ctx) {
            let url = variables.routeGeo + '/hotelDescriptiontypes'
            let result = []
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                })

                if (res.data && Array.isArray(res.data)) {
                    result = res.data
                }
            } catch (e) {
                console.log('Ошибка в actions _hotelDescriptiontypes', e)
            }

            //Мутация готовит одноуровневый справочник
            ctx.commit('updateHotelDescriptionReferences', result)

            //Метод возвращает иерархическую структуру
            return result
        },
        async searchToursForBlockCompare(ctx, searchParams){
            let url = variables.routeSearchTour + '/country'
            let result = []
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                    params: searchParams
                })
                result = res.data

                if (result && Array.isArray(result.tours)) {
                    let regionModel = {
                        id: null,
                        name: null,
                        urlName: null,
                    }
                    let cityModel = {
                        id: null,
                        name: null,
                        urlName: null,
                    }
                    let country = result.country ? result.country : null
                    let regions = Array.isArray(result.regions) ? result.regions : []
                    let cities = Array.isArray(result.cities) ? result.cities : []

                    result.tours.forEach(tour => {
                        tour._country = country
                        tour._region = Object.assign({}, regionModel)
                        tour._city = Object.assign({}, cityModel)

                        let city = cities.find(item => item.id === tour.hotel.cityId)
                        let region = null
                        if(city) region = regions.find(item => item.id === city.region)

                        if(region) {
                            tour._region.id = region.id
                            tour._region.name = region.name
                            tour._region.urlName = region.urlName
                        }

                        if(city) {
                            tour._city.id = city.id
                            tour._city.name = city.name
                            tour._city.urlName = city.urlName
                        }

                        tour.accommodationPriceOffer.forEach(offer => {
                            // Заменяем цены на полные с учётом топливного сбора (делается здесь чтоб не менять в куче мест где используется)
                            if (offer.fullPrice && offer.fullPrice.value) {
                                offer.clearPrice = offer.price;
                                offer.price = offer.fullPrice;
                            }
                        })

                        tour.currentOffer = {}
                        tour.currentOffer = Object.assign({}, tour.accommodationPriceOffer[0])
                    })
                }
            } catch(e){
                console.log('Ошибка в actions searchToursForBlockCompare', e)
            }
            return result
        },
    },
    state: {
        searchCompleted: false,
        compareActiveTours: [],
        storageSearchParams: [],
        hotelDescriptionReferences: [],
        compareDirections: [],
        countries: [],
        regions: [],
        compareFilterParams: {
            regionId: -1,
            minStartDate: null,
            maxStartDate: null,
            minNightsCount: 7,
            maxNightsCount: 7,
            adults: 2,
            children: '',
        },
        sliderCount: 0,
        isScrollToReviews: false,
        isShowOffersModal: false,
    },
    mutations: {
        addCompareActiveTour(state, compareObj) {
            // let compareObj = {
            //     compareActiveTour: {},
            //     searchParams: {}
            // }
            let compareActiveTour = compareObj.compareActiveTour
            let searchParams = {...compareObj.searchParams}
            searchParams.arrivalRegionIds = compareActiveTour.hotelRegion.id
            searchParams.arrivalRegionName = compareActiveTour.hotelRegion.name
            // searchParams.arrivalCityIds = compareActiveTour.hotelCity.id
            let compareActiveIds = new Set(state.compareActiveTours.map(tour => tour.hotelId))
            if (!compareActiveIds.has(compareActiveTour.hotelId)) {
                // state.compareActiveTours = [...state.compareActiveTours, compareActiveTour]
                state.compareActiveTours = [compareActiveTour, ...state.compareActiveTours]
                searchParams.hotelIds = compareActiveTour.hotelId
                // state.storageSearchParams.push(searchParams)
                state.storageSearchParams.unshift(searchParams)
                localStorage.setItem('storageSearchParams', JSON.stringify(state.storageSearchParams));
            }
        },
        removeCompareActiveTour(state, compareActiveId) {
            let storageSearchParams = JSON.parse(localStorage.getItem('storageSearchParams'))
            state.compareActiveTours = state.compareActiveTours.filter(tour => tour.hotelId !== compareActiveId)
            storageSearchParams = storageSearchParams.filter(searchParams => searchParams.hotelIds !== compareActiveId)
            //this.commit('updateCompareDirections', state.compareActiveTours)
            this.commit('changeCompareDirections')
            localStorage.setItem('storageSearchParams', JSON.stringify(storageSearchParams));
        },
        clearCompareTours(state) {
            state.compareActiveTours = []
            state.storageSearchParams = []
            localStorage.setItem('storageSearchParams', JSON.stringify(state.storageSearchParams));
        },
        rotateNext(state) {
            if (state.compareActiveTours.length) {
                let firstElem = state.compareActiveTours[0]
                state.compareActiveTours = state.compareActiveTours.slice(1)
                state.compareActiveTours.push(firstElem)
            }
        },
        rotatePrev(state) {
            if (state.compareActiveTours.length) {
                let lastElem = state.compareActiveTours[state.compareActiveTours.length - 1]
                state.compareActiveTours = state.compareActiveTours.slice(0, -1)
                state.compareActiveTours.unshift(lastElem)
            }
        },
        changeCompareDirections(state) {
            let regions = []
            let countries = []

            regions = state.regions.filter(region => state.compareActiveTours.some(tour => tour.hotelRegion.id === region.id))
            countries = state.countries.filter(country => state.compareActiveTours.some(tour => tour.hotelCountry.id === country.id))

            state.regions = regions
            state.countries = countries
        },
        updateCompareDirections(state, tours) {
            state.countries = []
            state.regions = []
            // state.compareDirections = [
            //     {
            //         name: 'Все направления',
            //         id: -1,
            //     }
            // ]
            let regionIds = new Set()
            let countryIds = new Set()

            let countryModel = {
                name: null,
                id: null,
                isChecked: true,
                isHidden: true,
                notAllRegionsChecked: false,
            }
            let regionModel = {
                name: null,
                id: null,
                countryId: null,
                isChecked: true,
            }

            tours.forEach(tour => {
                let country = {...countryModel}
                let region = {...regionModel}

                country.name = tour.hotelCountry.name
                country.id = tour.hotelCountry.id
                region.name = tour.hotelRegion.name
                region.id = tour.hotelRegion.id
                region.countryId = tour.hotelCountry.id

                if (!countryIds.has(country.id)) {
                    state.countries.push(country)
                    countryIds.add(country.id)
                }

                if (!regionIds.has(region.id)) {
                    state.regions.push(region)
                    regionIds.add(region.id)
                }
            })
        },
        addSliderCount(state) {
            state.sliderCount++
        },
        removeSliderCount(state) {
            state.sliderCount--
        },
        updateHotelDescriptionReferences(state, types){
            let result = []
            let descriptionModel = {
                id: null,
                parentId: null,
                name: null,
                iconFileName: null,
                sortOrder: null,
            }

            //В массив пушутся оба уровня
            types.forEach(elem => {
                //Первый уровень
                let objFilterFirst = Object.assign({}, descriptionModel)
                objFilterFirst.id = elem.id
                objFilterFirst.parentId = elem.parentId //вероятно всегда undefined
                objFilterFirst.name = elem.name
                objFilterFirst.iconFileName = elem.iconFileName
                objFilterFirst.sortOrder = elem.sortOrder
                result.push(objFilterFirst)

                //Второй уровень
                elem.filters.forEach(filter => {
                    let objFilterSecond = Object.assign({}, descriptionModel)
                    objFilterSecond.id = filter.id
                    objFilterSecond.parentId = elem.id
                    objFilterSecond.name = filter.name
                    objFilterSecond.iconFileName = filter.iconFileName
                    objFilterSecond.sortOrder = null //ждем сортировку
                    result.push(objFilterSecond)
                })
            })
            //В стейт кладется одноуровневый массив
            state.hotelDescriptionReferences = result
        },
        scrollToReviews(state) {
            state.isScrollToReviews = true
        },
        updateSearchCompleted(state, value) {
            state.searchCompleted = value
        },
        updateIsShowOffersModal(state, value) {
            if (value === false) {
                setTimeout(() => {
                    state.isShowOffersModal = value
                }, 0)
            } else {
                state.isShowOffersModal = value
            }
        },
        changeCompareActiveTours(state, tours) {
            state.compareActiveTours = tours
        },
    },
    getters: {
        getCompareActiveIds(state) {
            return state.compareActiveTours.map(tour => tour.hotelId)
        },
        getCompareActiveTours(state) {
            return state.compareActiveTours
        },
        getStorageSearchParams(state) {
            return state.storageSearchParams
        },
        //лучше getHotelDescriptionReferences
        getHotelDescriptionTypes(state) {
            return state.hotelDescriptionReferences
        },
        getHotelDescriptionReferences(state) {
            return state.hotelDescriptionReferences
        },
        getSearchCompleted(state) {
            return state.searchCompleted
        },
        getCompareDirections(state) {
            return state.compareDirections
        },
        getCompareFilterParams(state) {
            return state.compareFilterParams
        },
        getSliderCount(state) {
            return state.sliderCount
        },
        getIsScrollToReviews(state) {
            return state.isScrollToReviews
        },
        getIsShowOffersModal(state) {
            return state.isShowOffersModal
        },
        getCompareCountries(state) {
            return state.countries
        },
        getCompareRegions(state) {
            return state.regions
        },
    },
}
