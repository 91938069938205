import _ from "lodash";
import axios from "axios";
import variables from "../plugins/variables";

(()=>{
    if (!window.trans_all){
        axios.post(laroute.route('localize.all')).then(response=>{
            window.trans_all = response.data
        })
    }

})()
window._trans = (key) => {
    let trans = _.get(window.trans_all, key, key)
    return trans ? trans : ''
}
const _trans = window._trans
export const vTrslt = {

    loadTranslations:()=>{

    },

    // Когда привязанный элемент вставлен в DOM...
    bind: function (el,binding,vnode) {

        if (!Array.isArray(binding.value)){
            binding.value = [binding.value]
        }

            binding.value.forEach((termin,i)=>{
                let noFollow = false

                try {
                if (binding.modifiers.tooltip){
                    /* меняем шаблон $trslt$ в атрибуте 'tooltip' */
                    el.setAttribute("tooltip", el.getAttribute('tooltip').replaceAll(`$trslt${i>0?i:''}$`, _trans(termin)))

                }else if(binding.modifiers.placeholder){
                    /* меняем шаблон $trslt$ в атрибуте 'placeholder' */
                    el.setAttribute("placeholder", _trans(termin))

                }else if(binding.modifiers.name){
                    /* меняем шаблон $trslt$ в атрибуте 'name' */
                    el.setAttribute("name", _trans(termin))

                }else if(binding.modifiers.datagreat){
                    /* меняем шаблон $trslt$ в атрибуте 'data-great' */
                    el.setAttribute("data-great", el.getAttribute('data-great').replaceAll(`$trslt${i>0?i:''}$`, _trans(termin)))


                }else if(binding.modifiers.no){
                    /*
                    *не искать шаблон $trslt$ (для тех элементов в которых нельзя дотянутся до нужного места)
                    * пример компонент input
                    * в нем прийдется передавать директиву v-trslt.no
                    * и в name передавать термин через функцию trans('other/other.termin')
                    *
                    * */
                }else if(binding.modifiers.tag){
                    el.querySelectorAll(`trans${i>0?i:''}`).forEach(e=>{
                        if (e.parentNode === el){
                            e.dataset.termin = termin
                            e.innerHTML = _trans(termin)
                        }
                    })
                }else {
                    el.innerHTML = el.innerHTML.replaceAll(`$trslt${i>0?i:''}$`, _trans(termin))
                }

                }catch (e){
                    noFollow = true
                }

            })


        if (window.interactiveTrsltMode){
            el.classList.add('trslt__edit-mod')
            el.addEventListener('contextmenu',(event)=>{
                if (event.altKey){
                    event.preventDefault()
                    let popup = document.createElement('div')
                    popup.classList.add('trslt__popup-bg')
                    popup.classList.add('active')

                    let inputTermins = ``
                    binding.value.forEach((termin,i)=>{
                        inputTermins +=
                            `<label>
                                        <input id="trslt_value_${i}" type="text" value="${_trans(termin)}">
                                        <div class="label__text">
                                            Введите перевод
                                        </div>
                            </label>`
                    })

                    popup.innerHTML=`<div class="popup active">
                                        <div class="v-modal-close v-icon-close-1 close-popup"></div>
                                            ${inputTermins}
                                    <button id="save_termin">Сохранить</button>
                                </div>`

                    document.querySelector('body').appendChild(popup)


                    popup.querySelector('#save_termin').addEventListener('click',(event)=>{
                        event.preventDefault()
                        let payload = []
                        binding.value.forEach((termin,i)=>{
                            payload.push({
                                termin:termin,
                                value:document.querySelector(`#trslt_value_${i}`).value
                            })
                        })


                        let url = variables.routTrslt+'/update-termin'
                        let res =  axios({
                            url,
                            method: 'POST',
                            data: {termins:payload},
                        },)
                        console.log(el)
                        el.classList.add('edit_ok')
                        popup.remove();
                    })


                    //добавление в буфер обмена//
                    // const textAreaElement = document.createElement('textarea');
                    // textAreaElement.value = value[1]
                    // textAreaElement.setAttribute('readonly', '');
                    // textAreaElement.style.position = 'absolute';
                    // textAreaElement.style.left = '-9999px';
                    // document.body.appendChild(textAreaElement);
                    // textAreaElement.select();
                    // document.execCommand('copy');
                    // document.body.removeChild(textAreaElement);
                    // конец добавление в буфер обмена//

                    let popupBg = document.querySelector('.trslt__popup-bg');
                    let closePopupButton = document.querySelector('.close-popup');


                    closePopupButton.addEventListener('click',() => {
                        popup.remove();
                    });
                    document.addEventListener('click', (e) => {
                        if(e.target === popupBg) {
                            popup.remove();
                        }
                    });

                }

            })
        }



    },
}
