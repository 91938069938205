import md5 from 'js-md5'
import{ getProductType }  from "../../plugins/helpers";


export default {
    actions: {
        //подписка
        fetchAdvCakeOnMainPage(ctx) {
            if (location.pathname.toLowerCase() === '/'){
                try {
                    window.advcake_data = window.advcake_data || [];
                    window.advcake_data.push({
                        pageType: 1,
                        user: {email: ctx.getters.getIsLogin ? md5(ctx.getters.getUser.email) : ''},
                    });
                } catch (e) {
                    console.log('Ошибка в actions fetchAdvCakeOnMainPage', e)
                }
            }


        },

        fetchAdvCakeSetSearchList(ctx, payload) {
            // if (window.advcake_data.length){
            //     return
            // }

            let type = ''
            let products = ''

            let path = location.pathname.toLowerCase()
            // if (path.length > 1 && path[path.length - 1]==='/'){
            //     path = path.slice(0,-1)
            // }
            if (path !== '/') {
                path = '/' + path.split('/')[1]
            }

            switch (path) {
                case '/':
                    type = 'country'
                    products = payload
                    break
                case '/weekendtour':
                    type = 'country'
                    products = payload
                    break
                case '/searchhottours':
                    type = 'country'
                    products = payload
                    break
                case '/searchtour':
                    type = 'country'
                    products = payload
                    break
                case '/searchhotel':
                    type = 'hotel'
                    products = payload
                    break
                case '/avia':
                    type = 'avia'
                    products = payload.data
                    break
                case '/searchservices':
                    type = 'otherservice'
                    products = payload
                    break
                case '/searchexcursions':
                    type = 'otherservice'
                    products = payload
                    break
            }


            let productList = []
            products.slice(0, 25).forEach((el, i) => {
                if (type === 'country' || type === 'hotel') {

                   let product = {
                            id: el.id,
                            price: el.currentOffer.price.value,
                            type: getProductType(true),
                            DepartureCity: localStorage.getItem('selectedCityFromName'),
                            ResortName: el.hotel.cityName,
                            HotelType: 'отель',
                            HotelName: el.hotel.name,
                            HotelCategory: el.hotel.stars+'*'
                    }

                    productList.push(product)
                } else if (type === 'avia') {
                    let respId = payload.responseId
                    productList.push({
                        product: {
                            ids: {
                                website: i + respId
                            }
                        },
                        count: 1,
                        price: el.prices[0]
                    })
                } else if (type === 'otherservice') {
                    productList.push({
                        product: {
                            ids: {
                                website: el.id
                            }
                        },
                        count: 1,
                        price: el.price.value + ' ' + el.price.currencyAlias
                    })
                }

            })

            try {
                window.advcake_data = window.advcake_data || [];
                window.advcake_data.push({
                    pageType: 3,
                    user: {email: ctx.getters.getIsLogin ? md5(ctx.getters.getUser.email) : ''},
                    currentCategory: {
                        id: localStorage.getItem('arrivalId'),
                        name: localStorage.getItem('arrivalName')},
                    products: productList,
                });

            } catch (e) {
                console.log('Ошибка в actions fetchAdvCakeSetSearchList', e)
            }
        },

        async fetchAdvCakeViewProduct(ctx, departureCityId) {

            try {
                let data = {
                    pageType: 2,
                    user: {email: ctx.getters.getIsLogin ? md5(ctx.getters.getUser.email) : ''},
                    currentCategory: {
                        id: ctx.getters.getHotelCountry.ebgNodeId,
                        name: ctx.getters.getHotelCountry.localizedName
                    },
                    currentProduct: {
                        id: ctx.getters.getHotelData.ebgNodeId,
                        price: ctx.getters.getTourPrice.toString(),
                        type: getProductType(true),
                        DepartureCity: localStorage.getItem('selectedCityFromName'),
                        ResortName: ctx.getters.getHotelCityData.localizedName,
                        HotelType: 'отель',
                        HotelName: ctx.getters.getHotelData.localizedName,
                        HotelCategory: ctx.getters.getHotelData.hotelCategory.name
                    },
                }
                window.advcake_data = window.advcake_data || [];
                // console.log('fetchAdvCakeViewProduct data', data)
                window.advcake_data.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchAdvCakeViewProduct', e)
            }
        },

        async fetchAdvCakeCartPage(ctx) {

            let products = []
            try {
                let cart = ctx.getters.getBasket

                for (const tour of cart.tours) {
                    let hotel = await ctx.dispatch('fetchGeoObjectByEbgNodeIdAndType', {
                        id: tour.hotelId,
                        objectType: 'hotel'
                    })

                    products.push({
                        id: tour.id,
                        price: tour.price.toString(),
                        quantity: 1,
                        child: tour.paxes.childrenCount,
                        categoryId: tour.countryId,
                        categoryName: tour.countryName,
                        type: getProductType(true),
                        DepartureCity: localStorage.getItem('selectedCityFromName'),
                        ResortName: tour.cityName,
                        HotelType: 'отель',
                        HotelName: hotel.localizedName,
                        HotelCategory: hotel.hotelCategory ? hotel.hotelCategory.name : '',
                    })
                }
                for (const tour of cart.hotels) {
                    let hotel = await ctx.dispatch('fetchGeoObjectByEbgNodeIdAndType', {
                        id: tour.hotelId,
                        objectType: 'hotel'
                    })

                    products.push({
                        id: tour.id,
                        price: tour.price.toString(),
                        quantity: 1,
                        child: tour.paxes.childrenCount,
                        categoryId: tour.countryId,
                        categoryName: tour.countryName,
                        type: getProductType(true),
                        DepartureCity: localStorage.getItem('selectedCityFromName'),
                        ResortName: tour.cityName,
                        HotelType: 'отель',
                        HotelName: hotel.localizedName,
                        HotelCategory: hotel.hotelCategory ? hotel.hotelCategory.name : '',
                    })
                }
                let data = {
                    pageType: 4,
                    user: {email: ctx.getters.getIsLogin ? md5(ctx.getters.getUser.email) : ''},
                    basketProducts: products
                }
                console.log('fetchAdvCakeCartPage', data)
                window.advcake_data = window.advcake_data || [];

                window.advcake_data.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchAdvCakeCartPage', e)
            }
        },
        async fetchAdvCakePaySaccess(ctx, payload) {

            let products = []
            try {
                let cart = payload.draft.draftBody
                let order = payload.order.data
                let hotelParents = await ctx.dispatch('fetchGetParentsByEbgNodeIdAndType', {
                    id: order.hotels[0].id,
                    objectType: 'hotel'
                })

                let resort = null
                let countryName = null
                if (hotelParents.length){
                    resort = hotelParents[0].children[0].children[0].standardizedName
                    countryName = hotelParents[0].children[0].localizedName
                }
                // console.log('cartADVC',cart)
                // console.log('orderADVC',order)
                let TouristGroupAdults = 0
                let TouristGroupKids = 0

                for (const tour of cart.tours) {
                    let hotel = await ctx.dispatch('fetchGeoObjectByEbgNodeIdAndType', {
                        id: tour.hotelId,
                        objectType: 'hotel'
                    })

                    products.push(
                        {
                            id: tour.id,
                            name: hotel.localizedName,
                            price: tour.price.toString(),
                            quantity: 1,
                            child: tour.paxes.childrenCount,
                            categoryId: tour.countryId,
                            categoryName: tour.countryName,
                        })
                    TouristGroupAdults+=tour.paxes.adultsCount
                    TouristGroupKids+=tour.paxes.childrenCount
                    TouristGroupKids+=tour.paxes.infantsCount
                }
                for (const tour of cart.hotels) {
                    let hotel = await ctx.dispatch('fetchGeoObjectByEbgNodeIdAndType', {
                        id: tour.hotelId,
                        objectType: 'hotel'
                    })

                    products.push({
                        id: tour.id,
                        name: hotel.localizedName,
                        price: tour.price.toString(),
                        quantity: 1,
                        child: tour.paxes.childrenCount,
                        categoryId: tour.countryId,
                        categoryName: tour.countryName,
                    })
                    TouristGroupAdults+=tour.paxes.adultsCount
                    TouristGroupKids+=tour.paxes.childrenCount
                    TouristGroupKids+=tour.paxes.infantsCount
                }
                let data = {
                    pageType: 6,
                    user: {
                        email: md5(order.customerInfo.email),  // email в виде хеша или уникальный идентификатор
                        // type: 'old' // old, new - опционально
                    },
                    basketProducts: products,
                    orderInfo: {
                        id: order.id,
                        totalPrice: order.prices[1].amount,
                        // coupon: 'SKIDKA100', // опционально
                        type: getProductType(true),
                        DepartureCity: order.townFrom.name,
                        CountryName: countryName,
                        ResortName: resort,
                        CityName: order.hotels[0].city,
                        HotelType: 'отель',
                        HotelName: order.hotels[0].name,
                        HotelCategory: order.hotels[0].star,
                        MealCode: order.hotels[0].meal,
                        TouristGroupAdults: TouristGroupAdults,
                        TouristGroupKids: TouristGroupKids,
                        CheckInDate: order.checkinDate,
                        productNights: order.nightsCount,
                        RoomCode: order.hotels[0].room
                    }
                }

                console.log('advCake',data)
                window.advcake_data = window.advcake_data || [];

                window.advcake_data.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchAdvCakePaySaccess', e)
            }
        },

    },
    state: {
        sectionOfSite: {
            '/': 'Tours',
            '/searchtour': 'Tours',
            '/avia': 'Flights',
            '/searchcruises': 'Cruises',
            '/searchhotel': 'Hotels',
            '/searchconstructor': 'Сonstructor',
            '/searchexcursions': 'Excursions',
            '/marketplace': 'Marketplace',
            '/searchservices': 'Services',

            //нет таких страниц
            'Order.status': 'Order.status',
            'Holiday.concepts': 'Holiday.concepts',
            'Hot.tours': 'Hot.tours',
            'Sales.offices': 'Sales.offices',
            'Country': 'Country',
            'Early.booking': 'Early.booking',
        }
    },
    mutations: {},
    getters: {}
}
