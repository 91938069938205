export default {
    actions: {

    },
    state: {
        links: [
        ]
    },
    mutations: {
        setBreadcrumbs(state, links){
            state.links = links
        }
    },
    getters: {
        getBreadcrumbs(state){
            return state.links
        }
    }
}
