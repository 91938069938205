import axios from 'axios';
import variables from "../../plugins/variables";

export default {
    actions: {
        async accountSigIn(ctx, post) {
            //console.log('accountSigIn', post);
            let url = variables.routeSigIn;
            try {
                let response = await axios({
                    url,
                    method: "POST",
                    data: post,
                });

                let data = await response.data;
                let errors = data.validationErrors;
                if (typeof errors != "undefined" && errors !== null) {
                    ctx.commit("updateErrors", errors);
                    return errors;
                }
                if (data.is_login) {
                    localStorage.removeItem("yandex_login"); // Удаляем метку авторизации через Яндекс (если нажимали кнопку Яндекса, но не прошли до конца, а потом авторизовались нормально)
                    ctx.getters.setCloseAllPopups(" ");
                    ctx.commit("updateLoginData", data);
                    ctx.dispatch("fetchUser");
                    ctx.dispatch("loadBasket");
                    ctx.dispatch("fetchMainboxAuth", { email: data.email });
                    if (data.role === "customer") {
                        ctx.dispatch("getUserAvatar");
                    }
                    ctx.dispatch("fetchDataLayerAuthSaccess");

                    if (
                        location.pathname === "/basket" ||
                        location.pathname.indexOf("booking") !== -1
                    ) {
                        ctx.dispatch("fetchDataLayerCheckoutStep3");
                    } else {
                        ctx.dispatch("fetchFlocktoryAuth");
                    }
                    //eventBus.$emit('loginSuccess')

                    if (data.role !== "customer") {
                        let useDesk =
                            document.querySelector("#usedesk-messenger");
                        if (useDesk) {
                            useDesk.style.display = "none";
                        }
                    }
                    if (location.pathname === "/wishlist") {
                        window.location.href =
                            "/cabinet/customer?tab=favorite ";
                    }
                    // if (data.role === 'agent') {
                    //     window.location.href = '/cabinet';
                    // }
                    if (data.role !== "customer") {
                        document
                            .querySelector("body")
                            .classList.add("preloader");
                        //для того что бы успело отправится событие авторизации в datalayer
                        setTimeout(function () {
                            window.location.reload();
                        }, 2000);
                    }
                } else {
                    if (data.phoneNumberConfirmed === false)
                        ctx.commit(
                            "updatePhoneNumberConfirmed",
                            data.phoneNumberConfirmed
                        );
                }
            } catch (e) {
                console.log("Ошибка в actions accountSigIn", e);
            }
        },
        async accountSigOut(ctx) {
            let url = variables.routeSigOut;
            try {
                let response = await axios({
                    url,
                    method: "POST",
                });
                if (response.status === 200) {
                    let data = {
                        is_login: false,
                        role: "",
                        user: null,
                    };
                    ctx.commit("updateLoginData", data);
                    // Чистим корзину, не должна остаться после выхода из профиля FUN-3683
                    localStorage.removeItem("myCart");
                    window.location.reload();
                }
            } catch {
                console.log("Ошибка в actions accountSigOut");
            }
        },
        async accountSignUp(ctx, post) {
            let url = variables.routeSignUp;

            try {
                let response = await axios({
                    url,
                    method: "POST",
                    data: post,
                });

                let data = await response.data;
                let errors = data.validationErrors;

                if (typeof errors != "undefined" && errors !== null) {
                    ctx.commit("updateErrors", errors);

                    return errors;
                }

                if (!data.phoneNumberConfirmed) {
                    ctx.commit(
                        "updatePhoneNumberConfirmed",
                        data.phoneNumberConfirmed
                    );
                }
            } catch {
                console.log("Ошибка в actions accountSignUp");
            }
        },
        async accountSignUpCaptcha(ctx, post) {
            let url = variables.routeSignUp;

            try {
                let response = await axios({
                    url,
                    method: "POST",
                    data: post,
                    ...(post.params && { params: post.params }),
                });

                let data = await response.data;
                let errors = data.validationErrors;

                if (typeof errors != "undefined" && errors !== null) {
                    ctx.commit("updateErrors", errors);

                    return errors;
                }

                if (!data.phoneNumberConfirmed) {
                    ctx.commit(
                        "updatePhoneNumberConfirmed",
                        data.phoneNumberConfirmed
                    );
                }
            } catch (e) {
                console.log("Ошибка в actions accountSignUpCaptcha");
                return e;
            }
        },
        async accountPhoneNumberConfirmation(ctx, post) {
            let url = variables.routeRegistrationConfirmPhone;
            try {
                let response = await axios({
                    url,
                    method: "POST",
                    data: post,
                });
                let data = await response.data;
                let status = response.status;
                let errors = data.validationErrors;
                if (typeof errors != "undefined" && errors !== null) {
                    ctx.commit("updateErrors", errors);
                    return errors;
                }

                if (data.phoneNumberConfirmed !== undefined) {
                    ctx.commit(
                        "updatePhoneNumberConfirmed",
                        data.phoneNumberConfirmed
                    );
                }
                return status;
            } catch (e) {
                console.log("Ошибка в actions accountPhoneNumberConfirmation");
            }
        },
        async accountRestorePassword(ctx, post) {
            let url = variables.routeRestorePassword;
            try {
                let response = await axios({
                    url,
                    method: "POST",
                    data: post,
                });
                let data = await response.data;
                let status = response.status;
                // let errors = data.validationErrors;
                // if (typeof (errors) != "undefined" && errors !== null) {
                //     ctx.commit('updateErrors', errors);
                //     return errors;
                // }

                return {
                    status: status,
                };
            } catch (e) {
                console.log("Ошибка в actions accountPhoneNumberConfirmation");
                return {
                    errors: e.response.data.validationErrors,
                };
            }
        },
        async accountChangePassword(ctx, post) {
            let url = variables.routeResetPassword;
            try {
                let response = await axios({
                    url,
                    method: "POST",
                    data: post,
                });
                let data = await response.data;
                let status = response.status;
                let errors = data.validationErrors;
                if (typeof errors != "undefined" && errors !== null) {
                    ctx.commit("updateErrors", errors);
                }
                return { status: status };
            } catch {
                console.log("Ошибка в actions accountPhoneNumberConfirmation");
            }
        },
        async accountCheckIsLogin(ctx) {
            let url = variables.routeCheckIsLogin;
            try {
                let response = await axios({
                    url,
                    method: "POST",
                });
                if (response.status === 200) {
                    // console.log('accountCheckIsLogin: ', response.data)
                    ctx.commit("updateLoginData", response.data);

                    ctx.dispatch("fetchDataLayerAuthSaccess");

                    if (location.search.length === 0) {
                        ctx.dispatch("fetchAdvCakeOnMainPage");
                    }

                    if (response.data.role === "customer") {
                        ctx.dispatch("getUserAvatar");
                    }
                }
            } catch {
                console.log("Ошибка в actions accountCheckIsLogin");
            }
        },
    },
    mutations: {
        updateLoginData(state, data) {
            // console.log('updateLoginData', data);
            state.isLoggin = data.is_login;
            state.role = data.role;
            state.user = data.user;
            if (!data.is_login) {
                localStorage.removeItem("yandex_login"); // Удаляем метку авторизации через Яндекс (если нажимали кнопку Яндекса, но не прошли до конца
            }
        },
        updateErrors(state, errors) {
            state.errorFields = [];
            for (let error in errors) {
                //console.log(errors[error]);
                state.errorFields.push(errors[error]);
            }
        },
        updatePhoneNumberConfirmed(state, value) {
            state.phoneNumberConfirmed = value;
        },
        setAvatar(state, value) {
            state.avatar = value;
        },
    },
    state: {
        isLoggin: null,
        role: "",
        avatar: "",
        errorFields: [],
        phoneNumberConfirmed: undefined,
        user: null,
    },
    getters: {
        getIsLogin: (state) => {
            return state.isLoggin;
        },
        getUserRole: (state) => {
            return state.role;
        },
        getUser: (state) => {
            return state.user;
        },
        getAuthErrors: (state) => {
            return state.errorFields;
        },
        getAuthErrorByFieldName: (state) => (name) => {
            for (let error in state.errorFields) {
                if (name === state.errorFields[error].fieldName) {
                    return state.errorFields[error].message.replace(
                        "Password",
                        "Пароль"
                    );
                }
            }
            return "";
        },
        getPhoneNumberConfirmed: (state) => {
            return state.phoneNumberConfirmed;
        },
        getAvatar: (state) => {
            return state.avatar;
        },
        getMainboxClientId: (state) => {
            switch (state.role) {
                case "agent":
                    return "b2b";
                case "partner":
                    return "partner";
                default:
                    return "b2c";
            }
        },
        getDataLayerUserType: (state) => {
            switch (state.role) {
                case "agent":
                    return "agent";
                case "partner":
                    return "partner";
                default:
                    return "client";
            }
        },
        getFlocktoryUserType: (state) => {
            switch (state.role) {
                case "agent":
                    return "b2b";
                case "partner":
                    return "partner";
                default:
                    return "b2c";
            }
        },
    },
};
