import variables from "../../../plugins/variables";
import axios from 'axios';
import moment from "moment";

export default {
    actions: {
        async flightSearch(ctx, payload) {
            ctx.commit('updateAviaLoadStatus', true)
            const url = variables.routeFlightSearch + payload.data

            try {
                const res = await axios({
                    url,
                    method: 'GET',
                })
                ctx.commit('updateFlightData', res.data)

                //мутация только для получения данных для фильтра
                //полученные перелеты в мутации не обрабатываются
                ctx.commit('updateFlightsFilter', res.data)

                console.log('flight search', res)
            } catch (err) {
                console.log('Ошибка получения ответа от ' + url)
                console.log(err)
            }
            ctx.commit('updateAviaLoadStatus', false)
            eventBus.$emit('clearFlightsDataFilters')
        },
        async getArrivalData(ctx, payload) {
            const url = variables.routeGetAviaArrivalCountries + payload.id
            try {
                const arrivalCountries = await axios({
                    url,
                    method: 'GET'
                });
                ctx.commit('updateArrivalCountries', arrivalCountries.data)
                // console.log('arrival', arrivalCountries)
            } catch {
                console.log('Ошибка получения ответа от ' + variables.routeGetAviaArrivalCountries)
            }

        },
        async fetchAirport(ctx, payload) {
            const url = variables.routeFetchAirport + payload.data
            try {
                const res = await axios({
                    url,
                    method: 'GET',
                })
                ctx.commit('updateAirport', res.data)
                // ctx.commit('updateSearchData', res.data.request)
            } catch {
                console.log('Ошибка получения ответа от ' + url)
            }
        },
        updateFlightSearchDataFromGet(ctx, payload) {
            ctx.commit('updateFlightSearchData', payload)
        },
    },
    mutations: {
        updateFlightData(state, flightsData) {
            state.airlines = []
            state.flights = []
            state.filters = []
            if (!!flightsData.data || flightsData.charters.length > 0) {
                // console.log(flightsData)
                state.searchDone = true
                state.requestData = flightsData.request

                if (!!flightsData.data) {
                    flightsData.data.forEach((item, idx) => {
                        item.type = 'aviaEngine'
                        item.isCharter = false
                        item.isLowCost = !(idx % 2) // для теста ВРЕМЕННО делаем каждый нечетный, пока не будет проходить от ТТЕ
                        item.charterInfo = null
                        item.flightType = 'Регулярный рейс';
                    })
                    flightsData.references.Airlines.forEach(el => {
                        el.code === 'H1' ? el.code = 'HR' : false
                        el.name === 'Hahn Air Systems' ? el.name = 'Hahn Air' : false
                    })
                    state.flights = flightsData.data
                    state.airlines = flightsData.references.Airlines
                    state.timetable = flightsData.references.Timetable
                    state.responseId = flightsData.responseId
                }
                // start charters parsing
                if (!!flightsData.charters && flightsData.charters.length > 0) {
                    flightsData.charters.forEach(charter => {
                        let charterPartnerLogo = charter.PartnerInLogo
                        let airlineCode = charter.FreightName.toString().split(' ')[0]
                        if(!charterPartnerLogo && charter.PartnerInName === 'Ural Airlines') {
                            charterPartnerLogo = 'https://491622.selcdn.ru/airlines/U6.svg'
                        }
                        // есть ли уже такая авиакомпания в списке
                        let airlineExists = state.airlines.some(el => {
                            return el.code === airlineCode
                        })
                        if (!airlineExists) {
                            state.airlines.push({
                                'code': airlineCode,
                                'name': charter.PartnerInName,
                                'logoUrl': charterPartnerLogo || null
                            })
                        }
                        state.flights.push({
                            'index': state.flights.length,
                            'airlines': charter.PartnerInName,
                            'airlinesCode': airlineCode,
                            'prices': [+charter.price, 100, 80], // rub, usd, eur
                            'serviceClass': charter.className,
                            'isCharter': true,
                            'charterInfo': charter,
                            'flightType': 'Чартерный рейс',
                            'type': 'samoTour',
                            'groups': [{
                                // рейс ТУДА
                                'flights': [{
                                    'index': 0,
                                    'duration': charter.FlyTimeMinTo,
                                    'minAvailSeats': charter.BlockCountIn,
                                    'time': 'PT3H40M', // время в пути todo
                                    'segments': [{
                                        'airline': charter.PartnerInName,
                                        'airplane': charter.TranTypeNameIn,
                                        'availSeats': charter.BlockCountIn,
                                        'flightClass': charter.className,
                                        'airlineCode': charter.FreightName.toString().split(' ')[0],
                                        'flightNo': charter.FreightName.toString().split(' ')[1],
                                        'fromDate': moment(charter.DateBeg).format('YYYY-MM-DD'),
                                        'fromTime': charter.SrcTime,
                                        'toDate': moment(charter.DateBeg).format('YYYY-MM-DD'),
                                        'toTime': charter.TrgTime,
                                        'duration': charter.FlyTimeMinTo,
                                        'from': {
                                            'airport': charter.SrcPortName,
                                            'city': charter.SrcTownName,
                                            'code': charter.SrcPortAlias,
                                            'country': '',
                                        },
                                        'to': {
                                            'airport': charter.TrgPortName,
                                            'city': charter.TrgTownName,
                                            'code': charter.TrgPortAlias,
                                            'country': '',
                                        }
                                    }],
                                    'stops': []
                                }],
                                'index': 0,
                                'stopsCount': charter.StopsTo,
                            }],
                            'features': [{
                                'content': 'Багаж ' + charter.bagageInfoTo,
                                'type': 'Baggage',
                                'value': parseInt(charter.bagageInfoTo),
                            }]
                        })
                        // рейс ОБРАТНО
                        if (charter.oneWay !== '1') {
                            state.flights[state.flights.length - 1].groups.push({
                                'flights': [{
                                    'index': 0,
                                    'duration': charter.FlyTimeMinBack,
                                    'minAvailSeats': charter.BlockCountOut,
                                    'time': 'PT3H40M', // время в пути todo
                                    'segments': [{
                                        'airline': charter.PartnerOutName,
                                        'airplane': charter.TranTypeNameOut,
                                        'availSeats': charter.BlockCountOut,
                                        'flightClass': charter.className,
                                        'airlineCode': charter.FreightNameBack.toString().split(' ')[0],
                                        'flightNo': charter.FreightNameBack.toString().split(' ')[1],
                                        'fromDate': moment(charter.DateBegBack).format('YYYY-MM-DD'),
                                        'fromTime': charter.SrcTimeBack,
                                        'toDate': moment(charter.DateBegBack).format('YYYY-MM-DD'),
                                        'toTime': charter.TrgTimeBack,
                                        'duration': charter.FlyTimeMinBack,
                                        'from': {
                                            'airport': charter.SrcPortNameBack,
                                            'city': charter.SrcTownNameBack,
                                            'code': charter.SrcPortAliasBack,
                                            'country': '',
                                        },
                                        'to': {
                                            'airport': charter.TrgPortNameBack,
                                            'city': charter.TrgTownNameBack,
                                            'code': charter.TrgPortAliasBack,
                                            'country': '',
                                        }
                                    }],
                                    'stops': []
                                }],
                                'index': 1,
                                'stopsCount': charter.StopsBack,
                            })
                        }
                    })

                }
                // end charters parsing

                // start собираем данные для фильтров по каждому рейсу
                state.flights.forEach(flight => {
                    flight.groups.forEach(group => {
                        group.flights[0].from = group.flights[0].segments[0].from
                        group.flights[0].fromDate = group.flights[0].segments[0].fromDate
                        group.flights[0].fromTime = group.flights[0].segments[0].fromTime
                        group.flights[0].to = group.flights[0].segments[group.flights[0].segments.length - 1].to
                        group.flights[0].toDate = group.flights[0].segments[group.flights[0].segments.length - 1].toDate
                        group.flights[0].toTime = group.flights[0].segments[group.flights[0].segments.length - 1].toTime
                    })
                    flight.filters = {}
                    // получаем стоимость всего маршрута
                    flight.filters.price = flight.prices[0]
                    // получаем авиакомпанию
                    flight.filters.airline = flight.airlines
                    // получаем тип перелета
                    flight.isCharter ? flight.filters.flightType = 'Чартерный рейс' : flight.filters.flightType = 'Регулярный рейс'
                    // ищем и прокидываем инфу о багаже
                    // нелогично было ложить информацию по багажу в flight.filters
                    // исправлено и вынесено на верхний уровень
                    // по хорошому от flight.filters надо отказаться, т.к. в фильтрах этот объект больше не учавствует
                    // из него может тянуться информаци для отрисовки в компоненте flightElement.vue
                    flight.baggage = {  // по умолчанию
                        'title': 'Неизвестный багаж',
                        'weight': null,
                        'type': 'Все',
                    }
                    // flight.features.forEach(feature => {
                    //     if (feature.type === 'Baggage') {
                    //         flight.filters.baggage.title = feature.content
                    //         flight.filters.baggage.weight = feature.value
                    //         if (Number.isInteger(feature.value) && feature.value > 0) {
                    //             flight.filters.baggage.mark = feature.value
                    //             flight.filters.baggage.type = 'Багаж и ручная кладь'
                    //         } else {
                    //             flight.filters.baggage.mark = 'X'
                    //             flight.filters.baggage.type = 'Без багажа'
                    //         }
                    //     }
                    // })
                    flight.features.forEach(feature => {
                        if(feature.type === 'Baggage'){
                            flight.baggage.title = feature.content
                            flight.baggage.weight = feature.value
                            if(Number.isInteger(feature.value) && feature.value > 0){
                                flight.baggage.type = 'Багаж и ручная кладь'
                            } else {
                                flight.baggage.type = 'Без багажа'
                            }
                        }

                    })
                    // прокидываем кол-во пересадок на каждом маршруте и флаг noStops = true|false
                    flight.filters.stopsTo = flight.groups[0].stopsCount
                    if (flight.groups.length > 1) {
                        flight.filters.stopsFrom = flight.groups[1].stopsCount
                        flight.filters.noStops = flight.groups[0].stopsCount === 0 && flight.groups[1].stopsCount === 0
                        flight.filters.singleStop = flight.groups[0].stopsCount === 1 && flight.groups[1].stopsCount === 1
                        flight.filters.doubleStop = flight.groups[0].stopsCount === 2 && flight.groups[1].stopsCount === 2
                    } else {
                        flight.filters.noStops = flight.groups[0].stopsCount === 0
                        flight.filters.singleStop = flight.groups[0].stopsCount === 1
                        flight.filters.doubleStop = flight.groups[0].stopsCount === 2
                    }
                    if (flight.filters.noStops) {
                        flight.filters.stopsType = 'Без пересадок'
                    } else if (flight.filters.singleStop) {
                        flight.filters.stopsType = '1 пересадка'
                    } else if (flight.filters.doubleStop) {
                        flight.filters.stopsType = '2 пересадки'
                    } else {
                        flight.filters.stopsType = 'Все'
                    }
                    // инфа о времени вылета-прилета, продолжительности полета и остановок
                    // если маршрут в одну сторону
                    const wayTo = flight.groups[0].flights[0]

                    // продолжительность всех остановок на маршруте
                    flight.filters.stopsTime1 = wayTo.stops.reduce((acc, current) => {
                        return acc + current.duration
                    }, 0)
                    // поле для фильтрации (НЕ удалять)
                    flight.filters.maxStopsDuration = flight.filters.stopsTime1
                    // продолжительность рейса/ов (все сегменты, если несколько)
                    flight.filters.flightDuration1 = wayTo.segments.reduce((acc, current) => {
                        return acc + current.duration
                    }, 0)
                    // общая продолжительность - полет + остановки
                    flight.filters.totalDuration1 = flight.filters.flightDuration1 + flight.filters.stopsTime1
                    // дата, время и аэропорт начала маршрута
                    flight.filters.fromTime1    = wayTo.segments[0].fromTime
                    flight.filters.fromDate1    = wayTo.segments[0].fromDate
                    flight.filters.startFrom1   = flight.filters.fromDate1 + 'T' + flight.filters.fromTime1
                    // по дизайну такой формат вывода в фильтре .format('H[ч] mm[мин], D MMM')
                    flight.filters.airportFrom1 = wayTo.segments[0].from.airport
                    flight.filters.cityFrom1    = wayTo.segments[0].from.city
                    // дата, время и аэропорт окончания маршрута
                    // если рейс прямой
                    if (wayTo.segments.length === 1) {
                        flight.filters.toTime1    = wayTo.segments[0].toTime
                        flight.filters.toDate1    = wayTo.segments[0].toDate
                        flight.filters.finishTo1  = flight.filters.toDate1 + 'T' + flight.filters.toTime1
                        flight.filters.airportTo1 = wayTo.segments[0].to.airport
                        flight.filters.cityTo1 = wayTo.segments[0].to.city
                    } else { // если есть пересадки
                        flight.filters.toTime1    = wayTo.segments[wayTo.segments.length - 1].toTime
                        flight.filters.toDate1    = wayTo.segments[wayTo.segments.length - 1].toDate
                        flight.filters.finishTo1  = flight.filters.toDate1 + 'T' + flight.filters.toTime1
                        flight.filters.airportTo1 = wayTo.segments[wayTo.segments.length - 1].to.airport
                        flight.filters.cityTo1 = wayTo.segments[wayTo.segments.length - 1].to.city
                    }
                    // если маршрут туда-обратно
                    if (flight.groups.length > 1) {
                        const wayFrom = flight.groups[1].flights[0]
                        // продолжительность всех остановок на маршруте
                        flight.filters.stopsTime2 = wayFrom.stops.reduce((acc, current) => {
                            return acc + current.duration
                        }, 0)
                        // поле для фильтрации (НЕ удалять)
                        flight.filters.maxStopsDuration = Math.max(flight.filters.stopsTime1,  flight.filters.stopsTime2)
                        // продолжительность рейса/ов (все сегменты, если несколько)
                        flight.filters.flightDuration2 = wayFrom.segments.reduce((acc, current) => {
                            return acc + current.duration
                        }, 0)
                        // общая продолжительность - полет + остановки
                        flight.filters.totalDuration2 = flight.filters.flightDuration2 + flight.filters.stopsTime2
                        // дата, время и аэропорт начала маршрута
                        flight.filters.fromTime2    = wayFrom.segments[0].fromTime
                        flight.filters.fromDate2    = wayFrom.segments[0].fromDate
                        flight.filters.startFrom2   = flight.filters.fromDate2 + 'T' + flight.filters.fromTime2
                        flight.filters.airportFrom2 = wayFrom.segments[0].from.airport
                        flight.filters.cityFrom2 = wayFrom.segments[0].from.city
                        // дата, время и аэропорт окончания маршрута
                        // если рейс прямой
                        if (wayFrom.segments.length === 1) {
                            flight.filters.toTime2    = wayFrom.segments[0].toTime
                            flight.filters.toDate2    = wayFrom.segments[0].toDate
                            flight.filters.finishTo2  = flight.filters.toDate2 + 'T' + flight.filters.toTime2
                            flight.filters.airportTo2 = wayFrom.segments[0].to.airport
                            flight.filters.cityTo2    = wayFrom.segments[0].to.city
                        } else { // если есть пересадки
                            flight.filters.toTime2    = wayFrom.segments[wayFrom.segments.length - 1].toTime
                            flight.filters.toDate2    = wayFrom.segments[wayFrom.segments.length - 1].toDate
                            flight.filters.finishTo2  = flight.filters.toDate2 + 'T' + flight.filters.toTime2
                            flight.filters.airportTo2 = wayFrom.segments[wayFrom.segments.length - 1].to.airport
                            flight.filters.cityTo2    = wayFrom.segments[wayFrom.segments.length - 1].to.city
                        }
                    }
                })
                // end собираем данные для фильтров по каждому рейсу

                let stops = state.flights.map(el => el.filters.stopsType)
                stops = [...new Set(stops)]
                stops.forEach((el, idx) => {
                    if (el === 'Все') {
                        stops.splice(idx, 1)
                    }
                })
                state.stops = Object.assign([], stops)

                let flightTypes = state.flights.map(el => el.flightType)
                state.flightTypes = Object.assign([], [...new Set(flightTypes)])

                // console.log('state', state)
            } else {
                state.flights = []
                state.searchDone = true
            }
        },
        updateFlightsFilter(state, flightsData){
            if(!Array.isArray(flightsData.data)) return

            let departureCity1 = flightsData.data[0].groups[0].flights[0].from.city
            let arrivalCity1 = flightsData.data[0].groups[0].flights[0].to.city

            let departureCity2 = null
            let arrivalCity2 = null
            if (flightsData.data[0].groups.length > 1) {
                departureCity2 = flightsData.data[0].groups[1].flights[0].from.city
                arrivalCity2 = flightsData.data[0].groups[1].flights[0].to.city
            }
            //Данные для отрисовки фильтра. Изменяются только здесь
            let filterData = {
                airports: [
                    // {
                    // iata: 'SVO',
                    // name: 'Шереметьево'
                    // cityName: 'Москва',
                    // country: 'Россия',
                    // terminal: 'B'
                    // }
                ],
                dateTimesTo: [],//Двумерный массив. Содержит массивы с двумя значениями (дата и время вылета и прилета)
                dateTimesFrom: [],//Двумерный массив. Содержит массивы с двумя значениями (дата и время вылета и прилета)
                priceMin: 0,
                priceMax: 0,
                numStopsTo: [],//Пересадки, массив с Numbers
                numStopsFrom: [],//Пересадки, массив с Numbers
                stopDurationsTo: [],//Длительность пересадок, массив с Numbers
                stopDurationsFrom: [],//Длительность пересадок, массив с Numbers
                flightDurationsTo: [],//Время в пути, массив с Numbers
                flightDurationsFrom: [],//Время в пути, массив с Numbers
                baggages: [],
                aviaCompanyNames: [],
                flightTypes: [],
            }

            //Параметры фильтра
            let filterParams = {
                priceMin: 0,
                priceMax: 0,
                numStopsTo: [],
                numStopsFrom: [],
                baggages: [],
                dateTimesTo: [],
                dateTimesFrom: [],
                airports: [],
                aviaCompanyNames: [],
                stopDurationsTo: [],
                stopDurationsFrom: [],
                flightDurationsTo: [],
                flightDurationsFrom: [],
                flightTypes: [],
            }


            let iataAirports = new Set()
            let flightTypes = new Set()
            flightsData.data.forEach(item => {
                const wayTo = item.groups[0].flights[0]
                let wayFrom = null
                const countSegmentsTo = wayTo.segments.length

                //Получение даты вылета и прилета для направления туда и обратно
                let fromDateTime1 = wayTo.segments[0].fromDate + 'T' + wayTo.segments[0].fromTime
                let toDateTime1 = wayTo.segments[countSegmentsTo - 1].toDate + 'T' + wayTo.segments[countSegmentsTo - 1].toTime
                filterData.dateTimesTo.push([fromDateTime1, toDateTime1])
                if(item.groups[1]){
                    wayFrom = item.groups[1].flights[0]
                    const countSegmentsFrom = wayFrom.segments.length
                    let fromDateTime2 = wayFrom.segments[0].fromDate + 'T' + wayFrom.segments[0].fromTime
                    let toDateTime2 = wayFrom.segments[countSegmentsFrom - 1].toDate + 'T' + wayFrom.segments[countSegmentsFrom - 1].toTime
                    filterData.dateTimesFrom.push([fromDateTime2, toDateTime2])
                }

                let price = item.prices[0]

                //Получение типов перелета
                flightTypes.add(item.flightType)

                //Получение всех аэропортов (туда)
                for(let i=0;i<countSegmentsTo;i++){
                    let from = wayTo.segments[i].from
                    let to = wayTo.segments[i].to
                    let airport = {
                        id: null,
                        iata: null,
                        name: null,
                        cityName: null,
                        countryName: null,
                        terminal: null
                    }
                    let airportFrom = Object.assign({}, airport)
                    let airportTo = Object.assign({}, airport)
                    //Получение первого аэропорта сегмента
                    airportFrom.id = from.code
                    airportFrom.iata = from.code
                    airportFrom.name = from.airport
                    airportFrom.cityName = from.city
                    airportFrom.countryName = from.country
                    airportFrom.terminal = from.terminal
                    //Получение второго аэропорта сегмента
                    airportTo.id = to.code
                    airportTo.iata = to.code
                    airportTo.name = to.airport
                    airportTo.cityName = to.city
                    airportTo.countryName = to.country
                    airportTo.terminal = to.terminal

                    if(!iataAirports.has(airportFrom.iata) && airportFrom.cityName === departureCity1) {
                        filterData.airports.push(airportFrom)
                    }
                    if(!iataAirports.has(airportTo.iata) && airportTo.cityName === arrivalCity1) {
                        filterData.airports.push(airportTo)
                    }
                    iataAirports.add(airportFrom.iata)
                    iataAirports.add(airportTo.iata)
                }

                //Получение всех аэропортов (обратно)
                if(wayFrom && Array.isArray(wayFrom.segments)){
                    for(let i=0;i<wayFrom.segments.length;i++){
                        let from = wayFrom.segments[i].from
                        let to = wayFrom.segments[i].to
                        let airport = {
                            id: null,
                            iata: null,
                            name: null,
                            cityName: null,
                            countryName: null,
                            terminal: null
                        }
                        let airportFrom = Object.assign({}, airport)
                        let airportTo = Object.assign({}, airport)
                        //Получение первого аэропорта сегмента
                        airportFrom.id = from.code
                        airportFrom.iata = from.code
                        airportFrom.name = from.airport
                        airportFrom.cityName = from.city
                        airportFrom.countryName = from.country
                        airportFrom.terminal = from.terminal
                        //Получение второго аэропорта сегмента
                        airportTo.id = to.code
                        airportTo.iata = to.code
                        airportTo.name = to.airport
                        airportTo.cityName = to.city
                        airportTo.countryName = to.country
                        airportTo.terminal = to.terminal

                        if(!iataAirports.has(airportFrom.iata) && airportFrom.cityName === departureCity2) {
                            filterData.airports.push(airportFrom)
                        }
                        if(!iataAirports.has(airportTo.iata) && airportTo.cityName === arrivalCity2) {
                            filterData.airports.push(airportTo)
                        }
                        iataAirports.add(airportFrom.iata)
                        iataAirports.add(airportTo.iata)
                    }
                }

                filterData.airports.unshift({
                    id: 'ALL',
                    iata: 'all',
                    name: 'all',
                    cityName: null,
                    countryName: null,
                    terminal: null
                })
                //Получение всех авиакомпаний
                let aviaCompanyName = {
                    id: item.airlinesCode.split(',')[0].trim(),
                    name: item.airlines.trim()
                }
                //Иногда прилетает string, баг со стороны бека
                if (aviaCompanyName.name !== 'string') {
                    filterData.aviaCompanyNames.push(aviaCompanyName)
                }

                // filterData.aviaCompanyNames.push(item.airlines.replace(',','').trim())

                //Иногда прилетает string, баг со стороны бека
                // filterData.aviaCompanyNames = filterData.aviaCompanyNames.filter(aviaCompany => aviaCompany!=='string')
                filterData.aviaCompanyNames.unshift({
                    id: 'ALL',
                    'name': 'all',
                })
                //Получение длительности пересадок (туда)
                let stopDurationTo = wayTo.stops.reduce((acc, current) => {
                    return acc + current.duration
                }, 0)

                if(stopDurationTo) filterData.stopDurationsTo.push(stopDurationTo)
                //Получение длительности пересадок (обратно)
                if(wayFrom && Array.isArray(wayFrom.stops)){
                    let stopDurationFrom = wayFrom.stops.reduce((acc, current) => {
                        return acc + current.duration
                    }, 0)
                    if(stopDurationFrom) filterData.stopDurationsFrom.push(stopDurationFrom)
                }
                //Получение времени в пути (туда). Сумма времени в воздухе и времени на пересадки
                let flightDurationTo = 0
                if(Array.isArray(wayTo.segments)){
                    flightDurationTo = wayTo.segments.reduce((acc, current) => {
                        return acc + current.duration
                    }, 0)
                }
                if(Array.isArray(wayTo.stops)){
                    flightDurationTo = flightDurationTo + wayTo.stops.reduce((acc, current) => {
                        return acc + current.duration
                    }, 0)
                }
                filterData.flightDurationsTo.push(flightDurationTo)

                //Получение времени в пути (обратно). Сумма времени в воздухе и времени на пересадки
                if(wayFrom && Array.isArray(wayFrom.segments)){
                    let flightDurationFrom = 0
                    if(Array.isArray(wayFrom.segments)){
                        flightDurationFrom = wayFrom.segments.reduce((acc, current) => {
                            return acc + current.duration
                        }, 0)
                    }
                    if(Array.isArray(wayFrom.stops)){
                        flightDurationFrom = flightDurationFrom + wayFrom.stops.reduce((acc, current) => {
                            return acc + current.duration
                        }, 0)
                    }
                    filterData.flightDurationsFrom.push(flightDurationFrom)
                }


                //Количество пересадок
                filterData.numStopsTo.push(countSegmentsTo - 1)
                filterData.numStopsFrom.push(countSegmentsTo - 1)
                //Установка минимальной и максимальной цены в рублях
                //Проверка бывает такое что прилетает 0 или отрицательное значение
                //Такие перелеты нам не нужны
                if(price > 0){
                    if(!filterData.priceMin || filterData.priceMin > price) filterData.priceMin = price
                    if(!filterData.priceMax || filterData.priceMax < price) filterData.priceMax = price
                }
            })

            //Доп. обработка полученных значений
            ////Формируется массив с уникальным сочетанием аэропорта и терминала
            let intermediate = []
            filterData.airports = filterData.airports.filter(item => {
                let result = false
                let isIata = false
                let isTerminal = false
                if(intermediate.length === 0){
                    intermediate.push(item)
                    result = true
                } else{
                    isIata = intermediate.some(elem => {
                        return elem.iata === item.iata
                    })
                    isTerminal = intermediate.some(elem => elem.terminal === item.terminal)
                    intermediate.push(item)
                    if(!isIata || !isTerminal) result = true
                }
                return result
            })
            ////Типы перелетов
            flightTypes.forEach(item => {
                let obj = {
                    id: null,
                    name: null,
                }
                switch(item){
                    case 'Чартерный рейс':
                        obj.name = 'Чартерный рейс'
                        obj.id = 1
                        break
                    case 'Регулярный рейс':
                        obj.name = 'Регулярный рейс'
                        obj.id = 2
                        break
                }
                filterData.flightTypes.push(obj)
            })
            filterData.flightTypes.unshift({
                id: -1,
                name: 'Все',
            })

            //Условие чтобы минимальная цена не была равна 0
            if(filterData.priceMin > 200){
                filterData.priceMin = Math.floor(filterData.priceMin / 100) * 100 - 100
            } else {
                filterData.priceMin = Math.floor(filterData.priceMin)
            }

            filterData.priceMax = Math.floor(filterData.priceMax / 100) * 100 + 100
            filterData.priceStep = 100
            filterData.aviaCompanyNames = filterData.aviaCompanyNames.filter((name, index, self) =>
                index === self.findIndex((t) => (
                    t.name === name.name
                ))
            )
            // filterData.aviaCompanyNames = [... new Set(filterData.aviaCompanyNames)]
            filterData.stopDurationsTo.push(0)
            filterData.stopDurationsFrom.push(0)
            filterData.stopDurationsTo = [... new Set(filterData.stopDurationsTo)]
            filterData.flightDurationsTo = [... new Set(filterData.flightDurationsTo)]
            filterData.stopDurationsFrom = [... new Set(filterData.stopDurationsFrom)]
            filterData.flightDurationsFrom = [... new Set(filterData.flightDurationsFrom)]
            filterData.numStopsTo = [... new Set(filterData.numStopsTo)]
            //Добавление варианта 'Все' для пересадок
            filterData.numStopsTo.unshift(-1)
            //Сортировка по возрастанию
            filterData.numStopsTo = filterData.numStopsTo.sort((a,b)=>a-b)
            filterData.numStopsFrom = [... new Set(filterData.numStopsFrom)]
            //Добавление варианта 'Все' для пересадок
            filterData.numStopsFrom.unshift(-1)
            //Сортировка по возрастанию
            filterData.numStopsFrom = filterData.numStopsFrom.sort((a,b)=>a-b)
            filterData.stopDurationsTo = filterData.stopDurationsTo.sort((a,b)=>a-b)
            filterData.flightDurationsTo = filterData.flightDurationsTo.sort((a,b)=>a-b)
            filterData.stopDurationsFrom = filterData.stopDurationsFrom.sort((a,b)=>a-b)
            filterData.flightDurationsFrom = filterData.flightDurationsFrom.sort((a,b)=>a-b)
            //TODO
            filterData.baggages = [
                'Багаж и ручная кладь',
                'Без багажа',
            ]
            //Настройки фильтра по умолчанию
            filterParams.priceMin = filterData.priceMin
            filterParams.priceMax = filterData.priceMax
            filterParams.flightTypes = [...filterData.flightTypes]
            filterParams.numStopsTo = [...filterData.numStopsTo]
            filterParams.numStopsFrom = [...filterData.numStopsFrom]
            filterParams.baggages = [...filterData.baggages]
            filterParams.dateTimesTo = [...filterData.dateTimesTo]
            filterParams.dateTimesFrom = [...filterData.dateTimesFrom]
            filterParams.airports = [...filterData.airports]
            filterParams.aviaCompanyNames = [...filterData.aviaCompanyNames]
            filterParams.stopDurationsTo = [...filterData.stopDurationsTo]
            filterParams.flightDurationsTo = [...filterData.flightDurationsTo]
            filterParams.stopDurationsFrom = [...filterData.stopDurationsFrom]
            filterParams.flightDurationsFrom = [...filterData.flightDurationsFrom]

            state.filterData = filterData
            state.filterParams = filterParams
        },
        //мутация применяется при сбросе фильтров
        updateFilterParams(state){
            state.filterParams = Object.assign({}, state.filterData)
        },
        // updateFlightsFilterOld(state) {
        //     if(state.flights.length === 0) return
        //     state.filters = []
        //     let portsFrom1 = state.flights.map(el => el.filters.airportFrom1)
        //     let portsTo1 = state.flights.map(el => el.filters.airportTo1)
        //
        //     let filters = {
        //         'cityFrom1': {
        //             'title': state.flights[0].filters.cityFrom1,
        //             'airports': [...new Set(portsFrom1)]
        //         },
        //         'cityTo1': {
        //             'title': state.flights[0].filters.cityTo1,
        //             'airports': [...new Set(portsTo1)]
        //         },
        //         'cityFrom2': {},
        //         'cityTo2': {},
        //         'fromDate1': state.flights[0].filters.fromDate1,
        //         'toDate1': state.flights[0].filters.toDate1,
        //         'twoWay': false,
        //     }
        //
        //     if (state.flights[0].groups.length > 1) {
        //         let portsFrom2 = state.flights.map(el => el.filters.airportFrom2)
        //         let portsTo2 = state.flights.map(el => el.filters.airportTo2)
        //         filters.twoWay = true
        //         filters.cityFrom2.title = state.flights[0].filters.cityFrom2
        //         filters.cityFrom2.airports = [...new Set(portsFrom2)]
        //         filters.cityTo2.title = state.flights[0].filters.cityTo2
        //         filters.cityTo2.airports = [...new Set(portsTo2)]
        //         filters.fromDate2 = state.flights[0].filters.fromDate2
        //         filters.toDate2 = state.flights[0].filters.toDate2
        //     }
        //     state.filters = Object.assign([], filters)
        //
        //     // console.log('state', state)
        // },
        updateAviaLoadStatus(state, payload) {
            state.aviaLoadStatus = payload
        },
        // updateSearchDataField
        updateAviaSearchDataField(state, payload) {
            if (payload.direction === 'from') {
                const to = state.searchData[payload.fieldName].toString().split('-')[1]
                state.searchData[payload.fieldName] = payload.value + '-' + to;
                state.searchData.ebgFrom = payload.ebgId
                // провереяем если уже была задана дата Обратно,
                // то в обратном билете изменяем направление на вновь выбраный город
                if (state.searchData.date2) {
                    state.searchData.fromto2 = to + '-' + payload.value
                }
                state.destinationFromIsSet = true
            } else if (payload.direction === 'to') {
                const from = state.searchData[payload.fieldName].toString().split('-')[0]
                state.searchData[payload.fieldName] = from + '-' + payload.value;
                state.searchData.ebgTo = payload.ebgId
                // провереяем если уже была задана дата Обратно,
                // то в обратном билете изменяем направление на вновь выбраный город
                if (state.searchData.date2) {
                    state.searchData.fromto2 = payload.value + '-' + from
                }
                state.destinationToIsSet = true
            }
            state.destinationIsSet = state.destinationToIsSet && state.destinationFromIsSet
            //console.log('state.searchData: ', state.searchData)
        },
        swapSearchData(state) {
            const from = state.searchData.fromto1.toString().split('-')[0]
            const to   = state.searchData.fromto1.toString().split('-')[1]
            state.searchData.fromto1 = `${to}-${from}`
            // если есть билет ОБРАТНО
            if (state.searchData.fromto2) {
                state.searchData.fromto2 = `${from}-${to}`
            }
        },
        updateArrivalCountries(state, data) {
            const arrivalData = []
            data.forEach(country => {
                country.towns.forEach(town => {
                    town.airports.length > 0 ? arrivalData.push(town) : false
                })
            })
            let result = Object.values(arrivalData).sort((a, b) => a.name > b.name ? 1 : -1);
            state.arrivalCities = result
        },
        updateDate1(state, data) {
            state.searchData.date1 = data

        },
        updateDate2(state, data) {
            state.searchData.date2 = data
            if (state.searchData.date2 === 'Invalid date') {
                state.searchData.date2 = ''
                state.searchData.fromto2 = ''
            } else {
                const route = state.searchData.fromto1.toString()
                state.searchData.fromto2 = route.split('-')[1] + '-' + route.split('-')[0]
            }

        },
        updateTourists(state, tourists){
            // let tourists = {
            //     adults: null,
            //     childs: null,
            //     infants: null,
            //     infantsSitting: 0,
            //     class: {
            //         name: 'эконом',
            //         value: 'economy' //'economy', ...
            //     }
            // }
            state.searchData.adults = tourists ? tourists.adults : 1
            state.searchData.children = tourists ? tourists.childs : 0
            state.searchData.infants = tourists ? tourists.infants : 0
            state.searchData.infantsSitting = tourists ? tourists.infantsSitting : 0
            state.searchData.serviceclass = tourists ? tourists.class.value : 'economy'
        },
        //декременты и инкременты лучше не использовать
        //применялись когда не было универсального компонента InputTourists
        //сейчас все заменяется одной мутацией updateTourists
        incrementAdults(state) {
            if (state.searchData.adults <= 3) {
                state.searchData.adults++;
            }
        },
        decrementAdults(state) {
            if (state.searchData.adults > 1) {
                state.searchData.adults--;
                if (state.searchData.infants > state.searchData.adults) {
                    state.searchData.infants--
                }
            }
        },
        incrementChildren(state) {
            state.searchData.children++;
        },
        decrementChildren(state) {
            if (state.searchData.children > 0) {
                state.searchData.children--;
            }
        },
        incrementInfants(state) {
            if (state.searchData.adults > state.searchData.infants) {
                state.searchData.infants++;
            }
        },
        decrementInfants(state) {
            if (state.searchData.infants > 0) {
                state.searchData.infants--;
            }
        },
        updateFlightSearchData(state, payload) {
            state.searchData.fromto1 = payload.fromto1
            state.searchData.date1 = payload.date1
            state.searchData.ebgFrom = payload.ebgFrom
            state.searchData.ebgTo = payload.ebgTo
            state.searchData.adults = +payload.adults
            state.searchData.children = +payload.children
            state.searchData.infants = +payload.infants
            state.searchData.serviceclass = payload.serviceclass
            if (payload.fromto2 !== '') {
                state.searchData.fromto2 = payload.fromto2
            }
            if (payload.date2 !== '') {
                state.searchData.date2 = payload.date2
            }

        }
    },
    state: {
        aviaLoadStatus: false,
        searchDone: false,
        destinationIsSet: false,
        destinationToIsSet: false,
        destinationFromIsSet: false,
        searchData: {
            fromto1: '',
            fromto2: '',
            fromto3: '',
            date1: new Date(),
            date2: '',
            date3: '',
            adults: 1,
            children: 0,
            infants: 0,
            infantsSitting: 0,
            serviceclass: 'economy',
            onlydirect: false,
            salesChannel: 'sales',
            combine: false,
            ebgTo: null,
            ebgFrom: null,
        },
        //Данные для отрисовки фильтра изменяются только в мутации
        filterData: {
            airports: [
                // {
                // iata: 'SVO',
                // name: 'Шереметьево'
                // cityName: 'Москва',
                // country: 'Россия',
                // terminal: 'B'
                // }
            ],
            dateTimesTo: [],//Двумерный массив. Содержит массивы с двумя значениями (дата и время вылета и прилета)
            dateTimesFrom: [],//Двумерный массив. Содержит массивы с двумя значениями (дата и время вылета и прилета)
            priceMin: 0,
            priceMax: 0,
            numStopsTo: [],//Пересадки, массив с Numbers
            numStopsFrom: [],//Пересадки, массив с Numbers
            stopDurationsTo: [],//Длительность пересадок, массив с Numbers
            flightDurationsTo: [],//Время в пути, массив с Numbers
            stopDurationsFrom: [],//Длительность пересадок, массив с Numbers
            flightDurationsFrom: [],//Время в пути, массив с Numbers
            flightTypes: [],
            baggages: [],
            aviaCompanyNames: [],
        },
        //Данные для фильтрации, изменяются при изменении параметров фильтрации
        filterParams: {
            airports: [
                // {
                // iata: 'SVO',
                // name: 'Шереметьево'
                // cityName: 'Москва',
                // country: 'Россия',
                // terminal: 'B'
                // }
            ],
            dateTimesTo: [],//Двумерный массив. Содержит массивы с двумя значениями (дата и время вылета и прилета)
            dateTimesFrom: [],//Двумерный массив. Содержит массивы с двумя значениями (дата и время вылета и прилета)
            priceMin: 0,
            priceMax: 0,
            numStopsTo: [],//Пересадки, массив с Numbers
            numStopsFrom: [],//Пересадки, массив с Numbers
            stopDurationsTo: [],//Длительность пересадок, массив с Numbers
            flightDurationsTo: [],//Время в пути, массив с Numbers
            stopDurationsFrom: [],//Длительность пересадок, массив с Numbers
            flightDurationsFrom: [],//Время в пути, массив с Numbers
            flightTypes: [],
            baggages: [],
            aviaCompanyNames: [],
        },
        stops: [],
        // stops: ['Без пересадок', '1 пересадка', '2 пересадки'],
        baggage: [
            {'id': '0', 'title': 'Багаж и ручная кладь'},
            {'id': '1', 'title': 'Без багажа'},
        ],
        responseId: null,
        flights: [],
        flightTypes: [],
        filters: null,
        airlines: [],
        requestData: [],
        airport: [],
        arrivalCities: [],
        defaultMinFlightPrice: 5000,
        defaultMaxFlightPrice: 100000,
        defaultMaxStopDuration: 0,
        defaultMinStopDuration: 2160,
        defaultMaxFlightDuration1: 2160,
        defaultMinFlightDuration1: 0,
        defaultMaxFlightDuration2: 2160,
        defaultMinFlightDuration2: 0,
    },
    getters: {
        getFlightSearchQuery(state) {
            let searchData = Object.assign({}, state.searchData)
            searchData.children = searchData.children + searchData.infantsSitting
            delete searchData['infantsSitting']
            return '?' + Object.keys(searchData).map(key => key + '=' + searchData[key]).join('&');
        },
        getFlights(state) {
            return state.flights
        },
        getLogoUrl: state => code => {
            let url = null
            let airline = state.airlines.find(airline => airline.code === code)
            if(airline){
                url = airline.logoUrl
            }
            // if (url !== null) {
            //     return url
            // } else {
            //     return variables.imgPath + '/flight/wizz.png'
            // }
            return url
        },
        getFilterData(state){
            return state.filterData
        },
        getFilterParams(state){
            return state.filterParams
        },
        getArrivalCities(state) {
            return state.arrivalCities
        },
        getDate1(state) {
            return state.searchData.date1
        },
        getDate2(state) {
            return state.searchData.date2
        },
        getAviaSearchDone(state) {
            return state.searchDone
        },
        getAviaLoadStatus(state) {
            return state.aviaLoadStatus
        },
        getMinFlightPrice(state) {
            if (state.flights.length > 0) {
                let minPrice = state.flights[0].prices[0]
                state.flights.forEach(function (flight) {
                    if (flight.prices[0] < minPrice) {
                        minPrice = flight.prices[0]
                    }
                })
                return minPrice
            } else {
                return state.defaultMinFlightPrice;
            }
        },
        getMaxFlightPrice(state) {
            if (state.flights.length > 0) {
                let maxPrice = state.flights[0].prices[0]
                state.flights.forEach(function (flight) {
                    if (flight.prices[0] > maxPrice) {
                        maxPrice = flight.prices[0]
                    }
                })
                return maxPrice
            } else {
                return state.defaultMaxFlightPrice;
            }
        },
        getMaxStopsDuration(state) {
            if (state.flights.length > 0) {
                let maxDuration = state.flights[0].filters.maxStopsDuration
                state.flights.forEach( flight => {
                    if (flight.filters.maxStopsDuration > maxDuration) {
                        maxDuration = flight.filters.maxStopsDuration
                    }
                })
                return maxDuration
            } else {
                return state.defaultMaxStopDuration;
            }
        },
        getMinStopsDuration(state) {
            if (state.flights.length > 0) {
                let minDuration = state.flights[0].filters.maxStopsDuration
                state.flights.forEach(function (flight) {
                    if (flight.filters.maxDuration < minDuration) {
                        minDuration = flight.filters.maxDuration
                    }
                })
                return minDuration
            } else {
                return state.defaultMinStopDuration;
            }
        },
        getMaxFlightDuration1(state) {
            if (state.flights.length > 0) {
                let maxDuration = state.flights[0].filters.flightDuration1
                state.flights.forEach( flight => {
                    if (flight.filters.flightDuration1 > maxDuration) {
                        maxDuration = flight.filters.flightDuration1
                    }
                })
                return maxDuration
            } else {
                return state.defaultMaxFlightDuration1;
            }
        },
        getMinFlightDuration1(state) {
            if (state.flights.length > 0) {
                let minDuration = state.flights[0].filters.flightDuration1
                state.flights.forEach( flight => {
                    if (flight.filters.flightDuration1 < minDuration) {
                        minDuration = flight.filters.flightDuration1
                    }
                })
                return minDuration
            } else {
                return state.defaultMinFlightDuration1;
            }
        },
        getMaxFlightDuration2(state) {
            if (state.flights.length > 0) {
                let maxDuration = state.flights[0].filters.flightDuration2
                state.flights.forEach( flight => {
                    if (flight.filters.flightDuration2 > maxDuration) {
                        maxDuration = flight.filters.flightDuration2
                    }
                })
                return maxDuration
            } else {
                return state.defaultMaxFlightDuration2;
            }
        },
        getMinFlightDuration2(state) {
            if (state.flights.length > 0) {
                let minDuration = state.flights[0].filters.flightDuration2
                state.flights.forEach( flight => {
                    if (flight.filters.flightDuration2 < minDuration) {
                        minDuration = flight.filters.flightDuration2
                    }
                })
                return minDuration
            } else {
                return state.defaultMinFlightDuration2;
            }
        },
        getAirlines(state) {
            return state.airlines
        },
        getFlightResponseId(state) {
            return state.responseId
        },

        //лучше использовать getTourists, который возвращает готовый объект
        //для компонента InputTourists
        //и имеет мутацию updateTourists
        getAdultsAvia(state) {
            return state.searchData.adults;
        },
        getChildrenAvia(state) {
            return state.searchData.children;
        },
        getInfants(state) {
            return state.searchData.infants;
        },

        getTourists(state){
            let serviceClass = {
                name: null,
                value: null,
            }
            switch(state.searchData.serviceclass){
                case 'economy':
                    serviceClass.name = 'эконом'
                    serviceClass.value = 'economy'
                    break
                case 'comfort':
                    serviceClass.name = 'комфорт'
                    serviceClass.value = 'comfort'
                    break
                case 'business':
                    serviceClass.name = 'бизнес'
                    serviceClass.value = 'business'
                    break
                case 'first':
                    serviceClass.name = 'первый'
                    serviceClass.value = 'first'
                    break
            }
            return {
                adults: state.searchData.adults,
                childs: state.searchData.children,
                infants: state.searchData.infants,
                infantsSitting: state.searchData.infantsSitting,
                class: serviceClass
            }
        },

        getSearchData(state) {
            return state.searchData
        },
        getFlightRequestData(state) {
            return state.requestData
        },
        getAviaStops(state) {
            return state.stops
        },
        getBaggage(state) {
            return state.baggage
        },
        getAviaFilters(state) {
            return state.filters
        },
        getDestinationStatus(state) {
            return state.destinationIsSet
        },
        getAviaFlightTypes(state) {
            return state.flightTypes
        },

    },
}
