import axios from 'axios';
import variables from "../../plugins/variables";

export default {
    namespaced: true,
    actions: {
        async fetchAgentInfoById({ dispatch, commit, getters, rootGetters }, id) {
            commit('updateErrors', '')
            const url = variables.routAgentStatusChek +'/' + id
                const res = await axios({
                    url,
                    method: 'POST',
                })
                    .then(res=>{
                        const agentInfo = res.data
                        if (agentInfo.length){
                            commit('updateAgentInfo', agentInfo[0])
                            commit('updateAgentInfoIsLoad', true)
                        }else {
                            commit('updateErrors', `Агентство не найдено`)
                        }

                })
                    .catch(err=>{
                        if (err.response) {

                        } else if (err.request) {
                            console.log('ошибка запроса fetchAgentInfoById', err.request)
                        } else {
                            console.log('другие ошибки запроса fetchAgentInfoById', err)
                        }

                })

        },
    },
    mutations: {
        updateAgentInfo(state, agentInfo){
            state.agentInfo = agentInfo
        },
        updateErrors(state, error){
            state.error = error
        },
        updateAgentInfoIsLoad(state, status){
            state.agentInfoIsload = status
        },
    },
    state: {
        agentInfo: null,
        // address: "Драгоманова, 40А, кв71"
        // contractEnd: "2023-07-23T21:00:00Z"
        // contractNo: "10964-21"
        // director: "Сергєєва Тетяна Олексіївна"
        // name: "Сергєєва Тетяна Олексіївна"
        // okpo: "3023903344"
        // status: "Актуально"


        error:'',
        agentInfoIsload: false,
    },
    getters: {
        getAgentInfo(state) {
            return state.agentInfo
        },
        getError(state) {
            return state.error
        },
        getAgentInfoIsLoad(state) {
            return state.agentInfoIsload
        },
    }
}
