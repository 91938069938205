import axios from 'axios';
import variables from "../../plugins/variables";
export default {
    actions: {
        async fetchSaveStringToLog(ctx, str){
            const url = variables.routeLog + '/save-string-to-log'
            try{
                axios({
                    url,
                    method: 'POST',
                    data: {
                        str,
                    }
                })
            }catch(e){
                console.log('Ошибка в fetchSaveStringToLog', e)
            }
        },
        async fetchSaveUngiqueStringToLog(ctx, str){
            const url = variables.routeLog + '/save-unique-string-to-log'
            try{
                axios({
                    url,
                    method: 'POST',
                    data: {
                        str,
                    }
                })
            }catch(e){
                console.log('Ошибка в fetchSaveUniqueStringToLog', e)
            }
        }
    },
    state: {},
    mutations: {},
    getters: {}
}
