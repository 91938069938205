import variables from "../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchAirport(ctx, code){
            //проверка не проводился-ли ранее запрос по этому идентификатору
            if(ctx.state.airportsCodes.indexOf(code) != -1){
                return
            } else {
                ctx.state.airportsCodes.push(code)
            }
            const url = variables.routeAirport
            try {
                const res = await axios({
                    url,
                    method: "POST",
                    data: {
                        code
                    }
                })
                ctx.commit("updateAirports", await res.data)
            } catch {
                console.log('Ошибка получения ответа от ' + url)
            }
        },
    },
    state: {
        airports: [],
        airportsCodes: [],
    },
    mutations: {
        updateAirports(state, airport){
            if(airport){
                //копирование массива из state
                let result = state.airports.slice()
                result.push(airport)
                //запись в state нового массива
                state.airports = result
            }
        }
    },
    getters: {
        getAirportByCode: state => code => {
            let airport = {
                name: null,
                code: null
            }
            try {
                const res = state.airports.find(item => {
                    return item.code == code
                })
                airport.name = res.name
                airport.code = res.code
            } catch {}
            return airport
        }
    }
}
