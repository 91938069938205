import Velocity from "velocity-animate"

//Вспомогательные функции проекта
export default {
    getTimeFromMins: (mins) => {
        let hours = Math.trunc(mins/60)
        let minutes = mins % 60
        return hours + ":" + minutes
    },
    //Функция парсинга дерева. На вход поступает объект и название поля с потомками. На выходе массив объектов.
    parseTree(obj, childName){
        let result = []
        let res_obj = Object.assign({}, obj)
        delete(res_obj[childName])
        result.push(res_obj)
        let children = obj[childName]
        children.forEach(item => {
            let elems = this.parseTree(item, childName)
            result.push.apply(result, elems)
        }, this)
        return result
    },
    //Чистка html, удаление тегов <html/>, <body/>, и inline-стилей
    clearHtml(text){
        let regexp1 = new RegExp('</?html>', 'g')
        let regexp2 = new RegExp('</?body>', 'g')
        let regexp3 = new RegExp('style=\\".*?"', 'g')
        let regexp4 = new RegExp('style=".*?"', 'g')
        text = text || ''
        text = text.replace(regexp1, '')
        text = text.replace(regexp2, '')
        text = text.replace(regexp3, '')
        text = text.replace(regexp4, '')
        return text
    },

    //эта штука на сайте везде с помощью фильтра реализована. declineFilter
    declOfNum(number, words) {
        const cases = [2, 0, 1, 1, 1, 2];
        return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },

    downloadFile(link) {
        const downloadLink = document.createElement("a");
        downloadLink.href = link;
        downloadLink.download = 'download';
        downloadLink.click();
    }
}

export function capitalize(string){
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function getArrayWithUniqueObjs(array1, array2, key){
    if (!array1) return array2
    if (!array2) return array1

    let result = []
    let res = [...array1, ...array2]
    let uniqueKeys = new Set()

    res.forEach(elem => {
        if(!uniqueKeys.has(elem[key])) {
            if(elem[key] !== undefined) uniqueKeys.add(elem[key])
            result.push(elem)
        }
    })

    return result
}

export function uniqueId(){
    return Math.random().toString(16).slice(2)
}

export async function toggleBlock(ref, flag, duration=500){
    //Анимация скрытия и закрытия блока
    //result т.е. состояние блока возвращается после выполнения
    let result = flag
    if(!result){
        Velocity(ref, 'slideDown', {duration: duration})
        result = true
    } else {
        Velocity(ref, 'slideUp', {duration: duration})
        await new Promise(resolve => {
            setTimeout(()=>{
                resolve()
            }, duration)
        })
        result = false
    }
    return result
}

//В метод передается объект тура, возвращается массив с ссылками на изображения
export function getTourImages(tour, cdn, defaultValue = [], max = 5){
    let result = []
    if(!tour || !tour.hotel || !tour.hotel.geoId || !tour.hotel.images) return result

    let images = tour.hotel.images
    if(Array.isArray(images) && images.length > 0){
        let uid = tour.hotel.geoId
        let counter = 0
        images.forEach(image => {
            let link = cdn + '/s500/'+ tour.hotel.geoType +'/' + uid + '/' + image.imageType + '-' + image.resolutionType + '-' + image.fileName
            if(counter < max){
                result.push(link)
            }
            counter++
        })
    }

    return result.length > 0 ? result : defaultValue
}
//В метод передается объект тура, возвращается массив с ссылками на изображения
export function getHotelImages(hotelData, cdn = window.geo_cdn_link, defaultValue = [], max = 5){
    let result = []

    if(!hotelData || !hotelData.images) return result

    let images = hotelData.images

    if(Array.isArray(images) && images.length > 0){
        let uid = hotelData.id
        let counter = 0

        images.forEach(image => {
            let link = cdn + '/'+ hotelData.objectType +'/' + uid + '/' + image.imageType + '-' + image.resolutionType + '-' + image.fileName
            if(counter < max){
                result.push(link)
            }
            counter++
        })
    }

    return result.length > 0 ? result : defaultValue
}

//В метод передается полный объект офферов (в нем справочник) и один оффер. В ответе Объект питания для этого оффера
//Если не получается найти возвращается правильный объект с неустановленными значениями полей
export function getOfferMeal(offersResponse, offer){
    // let samoMeal = {
    //     mealTypeGroupId: 0,
    //     mealTypeId: 0,
    //     mealTypeGroupName: "",
    //     mealTypeName: ""
    // }
    let mealModal = {
        groupId: null,
        name: null,
    }
    let meal = Object.assign({}, mealModal)

    try{
        let res = offersResponse.meals.find(el => el.mealTypeId === offer.mealId)
        meal.groupId = res.mealTypeGroupId
        let name = res.mealTypeGroupName || res.mealTypeName
        meal.name = getMealName(name)
    }catch(e){
        console.log('Ошибка в getOfferMeal', e)
    }
    return meal
}
//В метод передается полный объект офферов (в нем справочник) и один оффер. В ответе Объект room для этого оффера
//Если не получается найти возвращается правильный объект с неустановленными значениями полей
export function getOfferRoom(offersResponse, offer){
    let roomModal = {
        "id": 0,
        "name": ""
    }
    let room = Object.assign({}, roomModal)
    try{
        room = offersResponse.rooms.find(el => el.id === offer.roomId)
    }catch(e){
        console.log('Ошибка в getOfferRoom', e)
    }
    return room
}

//Метод полета в корзину
//$nodeElement - элемент дом дерева откуда мячик летит
//duration - ms, время равное полету в корзину, через это время мячик удаляется
export async function playToBasketball($nodeElement, duration, promise=null , baskeId = 'v-basket'){
    //Если промис не указан, мячик летит в корзину с небольшой задержкой
    if(!promise) promise = new Promise(resolve => {
        setTimeout(()=>{resolve()}, 500)
    })

    let $basket = document.getElementById(baskeId)
    let parentCoords = $nodeElement.getBoundingClientRect()
    let basketCoords = $basket.getBoundingClientRect()
    let widthBall = 20 //Значения могут немного отличаться от стилей чтобы мячик не прыгал
    let heightBall = 20 //Значения могут немного отличаться от стилей чтобы мячик не прыгал
    let startBallCoords = {
        x: 0,
        y: 0,
    }
    let endBallCoords = {
        x: 0,
        y: 0,
    }

    let $ball = document.createElement('div')
    $ball.classList.add('basketball')
    $nodeElement.appendChild($ball)
    $nodeElement.style.position = "relative"
    $ball.style.position = "absolute"

    //Сначала мяч абсолютно позиционируется по родителю
    let startBallTopLeftCoords = {
        x: -widthBall - 10,
        y: parentCoords.height/2 - heightBall/2
    }

    $ball.style.top = startBallTopLeftCoords.y + "px"
    $ball.style.left = startBallTopLeftCoords.x + "px"


    //Мячик не полетит пока промис не разрезолвиться
    //Когда промисс разрезолвиться он установит паузу после которой мячик будет удален
    //Если сработает reject мячик пропадет
    promise.then(resolve => {
        //Теперь переключаем мячик на фиксированное позиционирование
        //Иначе не получится закинуть в корзину, т.к. есть релативные родители
        startBallTopLeftCoords = {
            x: parentCoords.left - widthBall - 10,
            y: parentCoords.top + parentCoords.height/2 - widthBall/2,
        }
        $ball.style.position = 'fixed'
        $ball.style.top = startBallTopLeftCoords.y + "px"
        $ball.style.left = startBallTopLeftCoords.x + "px"

        //Формируем конечные координаты
        endBallCoords.y = basketCoords.y
        endBallCoords.x = basketCoords.x

        //Пауза используется для применения стилей чтобы мячик не прыгал
        //Из-за переключения позиционирования
        //Если изначально использовать фиксированное при скролле он не будет на своем месте
        let pausePromise1 = new Promise(resolve => {
            setTimeout(()=>{resolve()}, 100)
        })
        //Пауза используется для удаления мячика
        let pausePromise2 = new Promise(resolve => {
            setTimeout(()=>{resolve()}, duration + 100)
        })

        pausePromise1.then(() => {
            //Включаем плавное изменение стилей
            $ball.style.transitionDuration = duration + "ms"
            //Кидаем в корзину
            $ball.style.zIndex = '9999'
            $ball.style.top = endBallCoords.y + "px"
            $ball.style.left = endBallCoords.x + "px"
            $ball.style.width = "10px"
            $ball.style.height = "10px"
        })

        pausePromise2.then(() => {
            $ball.remove()
        })

    }).catch(()=>{
        //Эфекты при исчезновении пока отключены
        //Здесь позиционирование зависит от эффекта, числа надо подбирать
        // $ball.style.top = startBallCoords.y - 10 + "px"
        // $ball.style.left = startBallCoords.x + 35 + "px"
        //
        // $ball.classList.add('missingball')

        //Эффекты отключены поэтому задержка минимальная
        //Через 1 секундy мячик просто удаляется
        let pausePromise = new Promise(resolve => {
            setTimeout(()=>{resolve()}, 1)
        })

        pausePromise.then(() => {
            $ball.remove()
        })
    })
}

//Получаем все параметры из текущего url
//Или из переданного
export function getParamsByUrl(url = null){
    let queryUrl = url ? url : location.search
    let queryString = queryUrl.substring(1)
    let res = new URLSearchParams(queryString)
    let urlParams = {
        // pathname: location.pathname
    }

    res.forEach((value, key) => {
        if(value === 'null') value = null
        if(value === 'undefined') value = null
        if(value === '') value = null
        urlParams[key] = value
    })

    return urlParams
}

//Обработчик туркеев
export function getTourKey(priceId){
    let tui = 'TUI::'
    let key =  priceId
    if(!priceId.includes(tui)){
        key = tui + priceId
    }
    return key
}

//Преобразование имени типа питания
//Переводы можно организовать здесь
export function getMealName(mealName){
    let result = null
    switch(mealName){
        case 'Bed & Breakfast':
        case 'BB':
            result = 'Завтрак'
            break
        case 'Half Board':
        case 'HB':
            result = 'Полупансион'
            break
        case 'Full Board':
        case 'FB':
            result = 'Полный пансион'
            break
        case 'All Inclusive':
        case 'AI':
            result = 'Все включено'
            break
        case 'Room Only':
        case 'RO':
            result = 'Без питания'
            break
        case 'Ultra All Inclusive':
        case 'Ultra All Exclusive':
        case 'UAI':
        case 'AUI'://может лишняя строчка
            result = 'Ультра все включено'
            break
        case 'Program':
        case 'PRG':
        case 'По программе':
            result = 'По программе'
            break
        case 'FBT (Лечение)':
        case 'FBT':
            result = 'Лечебное питание'
            break
        default:
            result = mealName
    }
    return result
}

//Ссылка известна, поэтому передавать ее не надо, возвращает объект
export function getMarketingParams(){
    let result = {}
    let urlParams = getParamsByUrl(location.search.substring(1))
    let marketingParams = [
        'utm_campaign',
        'utm_content',
        'utm_medium',
        'utm_source',
        'utm_term',
        'yclid',
        '_openstat',
        'type'
    ]
    for(let paramName in urlParams){
        let isMarketingParam = false
        if(paramName.includes('utm_', 0)){
            isMarketingParam = true
        }
        if(marketingParams.some(param => param === paramName)){
            isMarketingParam = true
        }
        if(isMarketingParam){
            result[paramName] = urlParams[paramName]
        }
    }
    return result
}
//Методу передается объект
//Возвращается полная копия, массивы клонируются (через деструктуризацию)
//Объекты в массивах передаются по символической ссылке так как не планируется их мутация
export function copyObject(object){

    let result = {}

    for(let key in object){
        if(!Array.isArray(object[key])){
            result[key] = object[key]
        } else {
            result[key] = [...object[key]]
        }
    }

    return result
}

// let new_params = {}
// //Удаление пустых значений
// Object.keys(params).forEach(key => {
//     if(params[key] !== '' && params[key] !== null){
//         new_params[key] = params[key]
//     }
// })

export function getProductType(isRU = false) {
    let productType = window.product_type
    if (document.referrer !== '') {
        let refUrl = new URL(document.referrer)
        let paths = refUrl.pathname.split('/')

        if (refUrl.host === document.location.host && paths.length) {
            if (paths[0] !== '' && window.match_product_types[paths[0]]) {
                productType = window.match_product_types[paths[0]]
            } else if (paths[1] && window.match_product_types[paths[1]]) {
                productType = window.match_product_types[paths[1]]
            }
        }

        if (isRU) {
            let matchTypes = {
                'tours': 'Тур',
                'hotels': 'Отель',
                'weekendtours': 'Тур выходного дня',
                'excursion tours': 'Экскурионный тур',
                'sanatorii': 'Санаторий',
                // могут понадобиться в дальнейшем - добавить рус.название
                // 'hottours': '',
                // 'autotours': '',
                // 'market': '',
                // 'cruises': '',
                // 'services': '',
                // 'acrossrussia': '',
            }
            productType = matchTypes[productType]
        }
   }

    return productType
}

//Метод перебирает параметры, и если значение соответствует переданному значению в массиве, то ставит его в null
export function processingParams(params, processingValues = ["undefined", ""]){
    for(let paramName in params){
        if(
            processingValues.some(el => el === params[paramName])
        ){
            params[paramName] = null
        }
    }
    return params
}

export function paxDatalayer() {
    let adults = localStorage.getItem('adults')
    let children = localStorage.getItem('children')
    let pax = adults
    if (children !== 'null' && children !== '') {
        children = children.split(',').length
        pax += '-' + children
    }
    return pax
}

export function visibleElement (target) {
    // Все позиции элемента
    let targetPosition = {
            top: window.scrollY + target.getBoundingClientRect().top,
            left: window.scrollX + target.getBoundingClientRect().left,
            right: window.scrollX + target.getBoundingClientRect().right,
            bottom: window.scrollY + target.getBoundingClientRect().bottom
        },
        // Получаем позиции окна
        windowPosition = {
            top: window.scrollY,
            left: window.scrollX,
            right: window.scrollX + document.documentElement.clientWidth,
            bottom: window.scrollY + document.documentElement.clientHeight
        };

    return targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
        targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
        targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
        targetPosition.left < windowPosition.right
}

//Изменение раскладки. Английские символы в строке, меняются на русские
export function redefiningCharacters(str){
    let result = ''
    if(typeof(str) === 'string'){
        str.split('').forEach(character => {
            switch(character.charCodeAt()){
                case 113:
                    result += 'й'
                    break
                case 119:
                    result += 'ц'
                    break
                case 101:
                    result += 'у'
                    break
                case 114:
                    result += 'к'
                    break
                case 116:
                    result += 'е'
                    break
                case 121:
                    result += 'н'
                    break
                case 117:
                    result += 'г'
                    break
                case 105:
                    result += 'ш'
                    break
                case 111:
                    result += 'щ'
                    break
                case 112:
                    result += 'з'
                    break
                case 91:
                    result += 'х'
                    break
                case 93:
                    result += 'ъ'
                    break
                case 97:
                    result += 'ф'
                    break
                case 115:
                    result += 'ы'
                    break
                case 100:
                    result += 'в'
                    break
                case 102:
                    result += 'а'
                    break
                case 103:
                    result += 'п'
                    break
                case 104:
                    result += 'р'
                    break
                case 106:
                    result += 'о'
                    break
                case 107:
                    result += 'л'
                    break
                case 108:
                    result += 'д'
                    break
                case 59:
                    result += 'ж'
                    break
                case 39:
                    result += 'э'
                    break
                case 122:
                    result += 'я'
                    break
                case 120:
                    result += 'ч'
                    break
                case 99:
                    result += 'с'
                    break
                case 118:
                    result += 'м'
                    break
                case 98:
                    result += 'и'
                    break
                case 110:
                    result += 'т'
                    break
                case 109:
                    result += 'ь'
                    break
                case 44:
                    result += 'б'
                    break
                case 46:
                    result += 'ю'
                    break
                case 96:
                    result += 'ё'
                    break
                default:
                    result += character
            }
        })
    }
    return result
}
export function transliteForUrl(str){
    let result = ''
    let charArray = []
    let converter = {
        'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
        'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
        'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
        'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
        'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
        'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
        'э': 'e',    'ю': 'yu',   'я': 'ya',   ' ': '_',    '-': '-'
    }

    if(typeof(str) === 'string' && str){
        charArray = str.toLowerCase().split('')
    }

    charArray.forEach(char => {
        let transliteChar = converter[char] ? converter[char] : ''
        result += transliteChar
    })

    return result
}
export function createLink(url){
    let a = document.createElement("a");
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
    // document.body.removeChild(a);
}

export function shortText(str,length,endDots=false){
    let result = ''

    if (typeof str === 'string'){
        result = str.slice(0,Number(length))
        if (str.length > length && endDots){
            result += '...'
        }
    }

    return result
}

export function getBookingLink(){
    return window.langUrlPrefix + "/booking/" + window.booking_uuid
}
