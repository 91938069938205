import variables from "../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchUpdateUserCurrencyId(ctx, currencyId) {
            let url = variables.routeCommon + "/changeUserCurrencyId";
            try {
                let res = await axios({
                    url,
                    method: "POST",
                    data: {
                        currencyId: currencyId,
                    },
                });

                // console.log('answer', res)
            } catch (e) {
                console.log("Ошибка в actions fetchUpdateUserCurrencyId", e);
            }
        },
        async fetchGetUserCurrencyId(ctx) {
            let url = variables.routeCommon + "/getUserCurrencyId";
            let result = null;
            try {
                let res = await axios({
                    url,
                    method: "POST",
                });
                let data = await res.data;
                if (data.currencyId) result = data.currencyId;
                return result;
            } catch (e) {
                console.log("Ошибка в actions fetchGetUserCurrencyId", e);
            }
        },
        async fetchLmsEmailsList(ctx) {
            let url = variables.routeAgentLmsEmails;
            try {
                let res = await axios({
                    url,
                    method: "GET",
                });
                ctx.commit("updateLmsEmailsList", await res.data);
            } catch (e) {
                console.log("Ошибка в actions fetchLmsEmailsList", e);
            }
        },
        async fetchAddLmsEmail(ctx, payload) {
            let url = variables.routeAgentLmsEmails;
            try {
                let res = await axios({
                    url,
                    method: "POST",
                    data: payload,
                });
            } catch (e) {
                console.log("Ошибка в actions fetchAddLmsEmail", e);
            }
        },
        async fetchDeleteLmsEmail(ctx, email) {
            let url = variables.routeAgentLmsEmails + "/" + email;
            try {
                let res = await axios({
                    url,
                    method: "DELETE",
                });
            } catch (e) {
                console.log("Ошибка в actions fetchDeleteLmsEmail", e);
            }
        },
        async fetchCheckLmsEmail(ctx, email) {
            if (email) {
                let url = variables.routeAgentLmsEmails + "/" + email;
                try {
                    let res = await axios({
                        url,
                        method: "GET",
                    });
                    return res.data;
                } catch (e) {
                    console.log("Ошибка в actions fetchCheckLmsEmail", e);
                }
            } else {
                return false;
            }
        },
        async fetchGetUserInfo(ctx) {
            let url = variables.routeCommon + "/getUserInfo";
            try {
                let res = await axios({
                    url,
                    method: "POST",
                });
                ctx.commit("updateCommonUserInfo", await res.data);
            } catch (e) {
                console.log("Ошибка в actions fetchGetUserInfo", e);
            }
        },
        async fetchSetCookiesAccepted(ctx) {
            localStorage.setItem("cookieDate", Date.now());
            ctx.commit("updateIsCookiesAccepted", true);
        },
        async fetchCheckIsCookiesAccepted(ctx) {
            let cookieDate = localStorage.getItem("cookieDate");
            // Если записи про кукисы нет или она просрочена на 1 год, то показываем информацию про кукисы
            if (!cookieDate || +cookieDate + 31536000000 < Date.now()) {
                ctx.commit("updateIsCookiesAccepted", false);
            } else {
                ctx.commit("updateIsCookiesAccepted", true);
            }
        },
        async fetchExchangeRates(ctx, payload) {
            let url = variables.routeCommon + "/getExchangeRates";
            try {
                let res = await axios({
                    url,
                    method: "POST",
                    data: payload,
                });
                let data = await res.data;
                return data.currencyRates;
            } catch (e) {
                console.log("Ошибка в actions fetchExchangeRates", e);
            }
        },
    },
    state: {
        popularCategories: [],
        popularCategoriesActiveUuid: null,
        skeletonBlocks: 0,
        userInfo: {
            userId: null,
            address: null,
            birthDate: null,
            email: null,
            fullName: null,
            lastName: null,
            name: null,
            passport: {},
            patronymic: null,
            phone: null,
            role: null,
            Authorization: null,
            userSub: null,
        },
        userCurrencyId: null,
        onlyOneLocale: null,
        diffCabs: null,
        lmsEmails: [],
        currencyByClientId: {
            ru: {
                id: "1",
                name: "RUB",
            },
            cm: {
                id: "1",
                name: "RUB",
            },
            kz: {
                id: "1",
                name: "RUB",
            },
            ua: {
                id: "4",
                name: "UAH",
            },
            by: {
                id: "12",
                name: "BYN",
            },
            ee: {
                id: "3",
                name: "EUR",
            },
            lt: {
                id: "3",
                name: "EUR",
            },
            lv: {
                id: "3",
                name: "EUR",
            },
        },
        isCookiesAccepted: false,
    },
    mutations: {
        setPopularCategories(state, payload) {
            state.popularCategories = payload.data;
            state.popularCategoriesActiveUuid = payload.uuid;
        },
        setSkeletonBlocks(state, payload) {
            state.skeletonBlocks = payload;
        },
        updateLmsEmailsList(state, payload) {
            state.lmsEmails = payload;
        },
        updateCommonUserInfo(state, payload) {
            try {
                let userInfo = {};
                userInfo.userId = payload.userId;
                userInfo.login = payload.login;
                userInfo.address = payload.address;
                userInfo.birthDate = payload.birthDate;
                userInfo.email = payload.email;
                userInfo.fullName = payload.fullName;
                userInfo.lastName = payload.lastName;
                userInfo.name = payload.name;
                userInfo.passport = payload.passport;
                userInfo.patronymic = payload.patronymic;
                userInfo.phone = payload.phone;
                userInfo.role = payload.role;
                userInfo.Authorization = payload.Authorization;
                userInfo.userSub = payload.userSub;
                // console.log(payload)
                state.userInfo = userInfo;
            } catch (e) {
                console.log("Ошибка в мутации updateCommonUserInfo", e);
            }
        },
        updateUserCurrencyId(state, currencyId) {
            this.userCurrencyId = currencyId;
        },
        updateOnlyOneLocale(state, payload) {
            state.onlyOneLocale = payload;
        },
        updateDiffCabs(state, payload) {
            state.diffCabs = payload;
        },
        updateIsCookiesAccepted(state, payload) {
            state.isCookiesAccepted = payload;
        },
    },
    getters: {
        getPopularCategories: (state) => {
            return state.popularCategories;
        },
        getPopularCategoriesActiveUuid: (state) => {
            return state.popularCategoriesActiveUuid;
        },
        getSkeletonBlocks: (state) => {
            return state.skeletonBlocks;
        },
        isCustomer: (state) => {
            return state.userInfo.role === "customer";
        },
        isAgent: (state) => {
            return state.userInfo.role === "agent";
        },
        getCommonUserInfo(state) {
            return state.userInfo;
        },
        getOnlyOneLocale(state) {
            return state.onlyOneLocale;
        },
        getDiffCabs(state) {
            return state.diffCabs;
        },
        getLmsEmails(state) {
            return state.lmsEmails;
        },
        getCurrencyByClientId: (state) => (clientId) => {
            let locale = clientId.split(":")[1];
            if (state.currencyByClientId[locale]) {
                return state.currencyByClientId[locale];
            } else {
                return null;
            }
        },
        getIsCookiesAccepted(state) {
            return state.isCookiesAccepted;
        },
    },
};
