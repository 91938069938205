import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import VueCookies from 'vue-cookies';

export default {
    actions: {
        async fetchMarketBasket(ctx) {
            let user = ctx.getters.getCommonUserInfo
            // Если товар добавлен без учетки, а потом залогинен
            if (user.userSub) {
                // await ctx.dispatch('fetchUserBasket', user.userSub)
            } else {
                // await ctx.dispatch('fetchGuestBasket')
            }
        },
        async marketBasketInit(ctx, userUuid) {
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/init/' + userUuid;
            let data = {
                userId: userUuid
            }
            let res = await axios.post(url, data);
            if (res.status === 201) {
                // console.log('basketUuid', res.data)
                ctx.commit('updateBasketUuid', res.data)
                let user = ctx.getters.getCommonUserInfo
                if (!user.userSub) { //Если не авторизован, создаем куку
                    VueCookies.config('30d')
                    VueCookies.set('fsMarketBasketUuid', res.data)
                }
                await ctx.dispatch('fetchMarketBasket')
            }
        },
        async fetchGuestBasket(ctx) {
            let fsMarketUserGuid = VueCookies.get('fsMarketUserUuid')
            // console.log('fsMarketUserGuid', fsMarketUserGuid)
            if (!fsMarketUserGuid) { //если нет куки пользователя, то ставим её
                let uuid = uuidv4();
                // console.log('Ставим куку')
                VueCookies.config('30d')
                VueCookies.set('fsMarketUserUuid', uuid)
                fsMarketUserGuid = uuid
                // console.log('fsMarketUserUuid', fsMarketUserGuid)
            }
            ctx.commit('updateUserUuid', fsMarketUserGuid)
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/guest-last';
            try {
                let params = {
                    GuestId: fsMarketUserGuid
                }
                let res = await axios.get(url, {params: params})
                // console.log('res.data', res.data, res.status)
                if (res.status === 200 && res.data) {
                    ctx.commit('updateBasket', res.data)
                } else {
                    ctx.dispatch('marketBasketInit', fsMarketUserGuid)
                }
            } catch (e) {
                console.log('Ошибка в action fetchGuestBasket', e)
            }
        },
        async fetchUserBasket(ctx, userSub) {
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/last';
            let token = ctx.getters.getCommonUserInfo.Authorization
            let res = await axios.get(url, {headers: {Authorization: token}});
            if (res.status === 200 && res.data) {
                ctx.commit('updateBasket', res.data)
            } else {
                ctx.dispatch('marketBasketInit', ctx.getters.getCommonUserInfo.userSub)
            }
        },
        async fetchBasketById(ctx, basketId) {
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/' + basketId;
            let res = await axios.get(url)
            if (res.status === 200 && res.data) {
                ctx.commit('updateBasket', res.data)
            }
        },
        async addMarketProductToBasket(ctx, productId) {
            let payload = {
                basketId: ctx.getters.getMarketBasketUuid,
                productId: productId,
                quantity: 1
            }
            let basketItem = ctx.getters.getMarketBasketItems.find(item => item.id === productId)
            if (basketItem) {
                payload.quantity += basketItem.quantity
            }
            // console.log('add product', payload)
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/upsert-item';
            let res = await axios.put(url, payload)
            //console.log('res', res.status)
            if (res.status === 200) {
                ctx.dispatch('fetchBasketById', ctx.getters.getMarketBasketUuid)
            }
        },
        async removeMarketProductToBasket(ctx, productId) {
            let payload = {
                basketId: ctx.getters.getMarketBasketUuid,
                productId: productId
            }
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/remove-item';
            let res = await axios.put(url, payload)
            //console.log('res', res.status)
            if (res.status === 200) {
                ctx.dispatch('fetchBasketById', ctx.getters.getMarketBasketUuid)
            }
        },
        async plusMarketProductQuantity(ctx, productId) {
            let payload = {
                basketId: ctx.getters.getMarketBasketUuid,
                productId: productId,
                quantity: 1
            }
            let basketItem = ctx.getters.getMarketBasketItems.find(item => item.id === productId)
            payload.quantity += basketItem.quantity
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/upsert-item';
            let res = await axios.put(url, payload)
            //console.log('res', res.status)
            if (res.status === 200) {
                ctx.dispatch('fetchBasketById', ctx.getters.getMarketBasketUuid)
            }
        },
        async minusMarketProductQuantity(ctx, productId) {
            let payload = {
                basketId: ctx.getters.getMarketBasketUuid,
                productId: productId,
                quantity: 1
            }
            let basketItem = ctx.getters.getMarketBasketItems.find(item => item.id === productId)
            payload.quantity = basketItem.quantity - 1
            if (payload.quantity > 0) {
                let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/upsert-item';
                let res = await axios.put(url, payload)
                //console.log('res', res.status)
                if (res.status === 200) {
                    ctx.dispatch('fetchBasketById', ctx.getters.getMarketBasketUuid)
                }
            }
        },
        async fetchMarketBasketShippingTemplates(ctx, productsIds) {
            // console.log('productsIds', productsIds)
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketmanagement/shippings/products-templates';
            let params = {
                ProductsIds: productsIds.join('&ProductIds=')
            }
            let res = await axios.get(url + '?ProductsIds=' + productsIds.join('&ProductsIds='))
            // console.log('shipping templates', res.data)
            return res.data;
        },
        async fetchMarketBasketUpdateShipping(ctx, shippingPayload) {
            // console.log('shippingPayload', shippingPayload)
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/update-shipping';
            let res = await axios.patch(url, shippingPayload);
            if (res.status === 200) {
                // console.log('Доставка успешно обновлена')
                ctx.dispatch('fetchMarketBasket')
            }
        },
        async fetchBasketGeoAreas(ctx) {
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketgeo/geo-objects';
            try {
                let res = await axios.get(url, {
                    params: {
                        GeoType: 1,
                    }
                });
                if (res.status === 200) {
                    ctx.commit('updateBasketGeoAreas', res.data)
                }
            } catch {
                console.log('Ошибка в actions fetchBasketGeoAreas')
            }
        },
        async fetchUserShippingAddresses(ctx) {
            let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/shippings/addresses?clientId=' + ctx.getters.getCommonUserInfo.userSub;
            let res = await axios.get(url)
            return res.data
        },
        async fetchMarketBasketApprove(ctx) {
            let result = null;
            // ctx.commit('updateMarketBasketValidationError', ctx.getters.getCommonUserInfo);
            // if (ctx.getters.getCommonUserInfo.Authorization && ctx.getters.getCommonUserInfo.name && ctx.getters.getCommonUserInfo.patronymic &&
            //     ctx.getters.getCommonUserInfo.lastName && ctx.getters.getCommonUserInfo.phone && ctx.getters.getCommonUserInfo.email) {
                let url = process.env.MIX_API_GATE_MARKET + '/api/marketbasket/basket/approve/' + ctx.getters.getMarketBasket.id;
                let data = {
                    firstName: ctx.getters.getCommonUserInfo.name ? ctx.getters.getCommonUserInfo.name : 'test',
                    middleName: ctx.getters.getCommonUserInfo.patronymic ? ctx.getters.getCommonUserInfo.patronymic : 'test',
                    lastName: ctx.getters.getCommonUserInfo.lastName ? ctx.getters.getCommonUserInfo.lastName : 'test',
                    phone: ctx.getters.getCommonUserInfo.phone ? ctx.getters.getCommonUserInfo.phone : 'test',
                    email: ctx.getters.getCommonUserInfo.email ? ctx.getters.getCommonUserInfo.email : 'test',
                }
                let token = ctx.getters.getCommonUserInfo.Authorization;
                let res = await axios.patch(url, data, {headers: {Authorization: token}});
                result = res.data;
            // }
            return result;
        }
    },
    mutations: {
        updateUserUuid(state, uuid) {
            state.userUuid = uuid
        },
        updateBasketUuid(state, uuid) {
            state.basketUuid = uuid
        },
        updateBasket(state, payload) {
            state.basket = payload
            state.basketUuid = payload.id
            state.partnerProfiles = []
            state.basket.basketItems.forEach(item => {
                let index = state.partnerProfiles.findIndex(profile => profile.id === item.partnerProfile.id)
                if (index === -1) {
                    state.partnerProfiles.push(item.partnerProfile)
                }
            })
            // console.log('последняя корзина', state.basket)
        },
        updateBasketGeoAreas(state, data) {
            state.geoBasketAreas = data;
        },
        updateMarketBasketValidationError(state, user) {
            if (!user.Authorization) {
                state.marketBasketValidationError = 'Авторизуйтесь для оплаты';
            } else if (!user.name) {
                state.marketBasketValidationError = 'У пользователя не заполеннно поле "Имя"';
            } else if (!user.patronymic) {
                state.marketBasketValidationError = 'У пользователя не заполеннно поле "Отчество"';
            } else if (!user.lastName) {
                state.marketBasketValidationError = 'У пользователя не заполеннно поле "Фамилия"';
            } else if (!user.phone) {
                state.marketBasketValidationError = 'У пользователя не заполеннно поле "Телефон"';
            } else if (!user.email) {
                state.marketBasketValidationError = 'У пользователя не заполеннно поле "Email"';
            } else {
                state.marketBasketValidationError = '';
            }
        },
    },
    state: {
        userUuid: '',
        basketUuid: '',
        basket: {
            basketItems: [],
            clientId: null,
            id: null,
            price: 0,
        },
        partnerProfiles: [],
        geoBasketAreas: [],
        marketBasketValidationError: '',
    },
    getters: {
        getMarketUserUuid(state) {
            return state.userUuid
        },
        getMarketBasketUuid(state) {
            return state.basketUuid
        },
        getMarketBasket(state) {
            return state.basket
        },
        getMarketBasketItems(state) {
            return state.basket.basketItems
        },
        getPartnerProfiles(state) {
            return state.partnerProfiles
        },
        getProductsByPartnerId: state => id => {
            return state.basket.basketItems.filter(item => item.partnerProfile.id === id);
        },
        getDeliveryBasketRegions(state) {
            return state.geoBasketAreas;
        },
        getMarketBasketValidationError(state) {
            return state.marketBasketValidationError;
        },
    }
}
