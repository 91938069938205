import variables from "../../../plugins/variables";
import axios from 'axios';
import moment from "moment";

export default {
    actions: {
        async fetchTourPartnerCommission(ctx, payload){
            //let url = variables.routeTourPartnerDetails + '/partnerCommission'
            let url = variables.routeTourOptions
            const res = await axios({
                url,
                method: 'POST',
                data: payload
            })
            const data = await res.data
            console.log('data', data)
            return data.comissionPercent
        },
        async fetchTourFreightsByPacket(ctx, priceId){
            let url = variables.routeTourPartnerDetails + '/freightsByPacket/' + priceId
            //console.log(url)
            const res = await axios({
                url,
                method: 'GET'
            })
            const data = await res.data
            //console.log('data', data)
            return data
        },
        async fetchTourPricesRange(ctx, payload){
            let url = variables.routeTourPartnerDetails + '/pricesRange'
            const res = await axios({
                url,
                method: 'POST',
                data: payload
            })
            const data = await res.data
            return data
        },
        async searchTourPriceIdAndPlaces(ctx, payload) {
            let str = '?'
            Object.keys(payload.data).map(function (key) {
                let elem = key + '=' + payload.data[key] + '&'
                if (key === 'minStartDate' || key === 'maxStartDate') {
                    elem = key + '=' + moment(payload.data[key]).format('YYYY-MM-DD') + '&'
                }
                str = str + elem;
            })
            let queryString = str.slice(0, -1);
            //console.log(queryString)

            let url = variables.routeSearchTour + '/' + payload.tab + queryString;
            const fetchTours = await axios({
                url,
                method: 'GET',
            })
            let result = {
                priceId: fetchTours.data.tours[0].priceId,
                hotelAvailability: fetchTours.data.tours[0].hotel.hotelAvailability
            }
            //console.log('result: ', result)
            return result
        },
    },
    mutations: {},
    state: {},
    getters: {},
}
