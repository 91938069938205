import variables from "../../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchAgentSales(ctx){
            //TODO
            return true
            const url = variables.routeAgentSales
            try {
                const res = await axios({
                    url,
                    method: 'POST'
                })
                if(await res.status == 200){
                    ctx.commit('updateAgentSales', await res.data)
                } else {
                    console.log('Ошибочный статус fetchAgentSales')
                }
            } catch {
                console.log('Ошибка получения ответа от ' + url)
            }
        }
    },
    state: {
        agentSales: [],
        agentSalesId: [],
    },
    mutations: {
        updateAgentSales(state, payload){
            let result = []
            try{
                payload.forEach(item => {
                    state.agentSalesId = item.claimId
                    const sale = {
                        amount: null,
                        claimId: null,
                        commiss: null,
                        confirmedDate: null,
                        cost: null,
                        currencyAlias: null,
                        startDate: null,
                        debt: null,
                        invoiceAmount: null,
                        invoiceCommiss: null,
                        invoiceCost: null,
                        invoiceCurrencyAlias: null,
                        invoiceDebt: null,
                        isAccessPay: null,
                        isConfirmed: null,
                        isEarlyCommission: null,
                        isPartPayment: null,
                        isPayTimeLimit: null,
                        nextPaymentDate: null,
                        nextPaymentSum: null,
                        paymentDetail: null,
                        prepaidPercent: null,
                        rateDate: null,
                        status: null,
                        statusFull: null,
                    }

                    sale.amount = item.amount
                    sale.claimId = item.claimId
                    sale.commiss = item.commiss
                    sale.confirmedDate = item.confirmedDate
                    sale.cost = item.cost
                    sale.currencyAlias = item.currencyAlias
                    sale.startDate = item.dateBeg
                    sale.debt = item.debt
                    sale.invoiceAmount = item.invoiceAmount
                    sale.invoiceCommiss = item.invoiceCommiss
                    sale.invoiceCost = item.invoiceCost
                    sale.invoiceCurrencyAlias = item.invoiceCurrencyAlias
                    sale.invoiceDebt = item.invoiceDebt
                    sale.isAccessPay = item.isAccessPay
                    sale.isConfirmed = item.isConfirmed
                    sale.isEarlyCommission = item.isEarlyCommission
                    sale.isPartPayment = item.isPartPayment
                    sale.isPayTimeLimit = item.isPayTimeLimit
                    sale.nextPaymentDate = item.nextPaymentDate
                    sale.nextPaymentSum = item.nextPaymentSum
                    sale.paymentDetail = item.paymentDetail
                    sale.prepaidPercent = item.prepaidPercent
                    sale.rateDate = item.rateDate
                    sale.status = item.status
                    sale.statusFull = item.statusFull

                    result.push(sale)
                })
                state.agentSales = result
            } catch {
                console.log('Ошибка в мутации updateAgentSales')
            }
        }
    },
    getters: {
        getAgentSales: state => {
            let result = null
            try {
                result = state.agentSales
            } catch {}
            return result
        },
        getAgentSaleByClaimId: state => claimId => {
            let result = null
            try {
                result = state.agentSales.find(item => {
                    return item.claimId = claimId
                })
            } catch {}
            return result
        }
    }
}
