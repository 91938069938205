import axios from 'axios';
import variables from "../../plugins/variables";

export default {
    namespaced: true,
    actions: {
        async fetchOrderByPasport({dispatch, commit, getters, rootGetters}, payload) {
            commit('updateErrors', '')
            const url = variables.routOrderStatusCheck
            const res = await axios({
                url,
                method: 'POST',
                params: payload
            })
                .then(res => {
                    const order = res.data.data
                    commit('updateOrder', order)
                    commit('updateOrderIsLoad', true)
                })
                .catch(err => {
                    if (err.response) {
                        if (err.response.status === 404) {
                            commit('updateErrors', `Не корректный номер паспорта`)
                        } else {
                            const error = err.response.data
                            if (error.message) {
                                commit('updateErrors', error.message)
                            } else if (error.Message) {
                                if(error.Message === 'Get get order if partpass is null') {
                                    commit('updateErrors', `У заказа не указан агент`)
                                } else {
                                    commit('updateErrors', error.Message)
                                }
                            }
                        }
                    } else if (err.request) {
                        console.log('ошибка запроса fetchOrderByPasport', err.request)
                    } else {
                        console.log('другие ошибки запроса fetchOrderByPasport', err)
                    }
                });
        },
    },
    mutations: {
        updateOrder(state, order){
            state.order = order
        },
        updateErrors(state, error){
            state.error = error
        },
        updateOrderIsLoad(state, status){
            state.orderIsload = status
        },
    },
    state: {
        order: null,
        error:'',
        orderIsload: false,
    },
    getters: {
        getOrder(state) {
            return state.order
        },
        getError(state) {
            return state.error
        },
        getOrderIsLoad(state) {
            return state.orderIsload
        },
    }
}
