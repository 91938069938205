import variables from "../../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchCustomerPaxesGet(ctx){
            let url = variables.routeCustomerPaxes
            try{
                let res = await axios({
                    url,
                    method: 'GET',
                })
                ctx.commit('updateCustomerPaxes', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchCustomerPaxesGet', e)
            }
        },
        async fetchCustomerPaxCreate(ctx, data){
            let url = variables.routeCustomerPaxes
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: data,
                })
                return await res.data
            }catch(e){
                console.log('Ошибка в actions fetchCustomerPaxCreate', e)
            }
        },
        async fetchCustomerPaxEdit(ctx, data){
            let url = variables.routeCustomerPaxes + '/' + data.id
            try{
                let res = await axios({
                    url,
                    method: 'PATCH',
                    data: data,
                })
                return await res.data
            }catch(e){
                console.log('Ошибка в actions fetchCustomerPaxEdit', e)
            }
        },
        async fetchCustomerPaxDelete(ctx, data){
            let url = variables.routeCustomerPaxes + '/' + data.id
            try{
                let res = await axios({
                    url,
                    method: 'DELETE',
                })
                return await res.data
            }catch(e){
                console.log('Ошибка в actions fetchCustomerPaxDelete', e)
            }
        },
        async fetchAddPaxTemplate(ctx){
            ctx.commit('updateAddPaxTemplate')
        }
    },
    state: {
        customerPaxes: [],
    },
    mutations: {
        updateCustomerPaxes(state, payload){
            let paxes = []
            try{
                if (Object.keys(payload).length !== 0) {
                    payload.forEach(item => {
                        let pax = {
                            id: null,
                            documents: [],
                            nationalityId: null,
                            person: {
                                lastName: null,
                                firstName: null,
                                patronymic: null,
                                birthDate: null,
                                gender: null,
                                identificationNumber: null,
                                ageGroup: null,
                            }
                        }
                        pax.id = item.id
                        pax.documents = item.documents
                        pax.nationalityId = item.nationalityId
                        if(item.person) {
                            pax.person.lastName = item.person.lastName
                            pax.person.firstName = item.person.firstName
                            pax.person.patronymic = item.person.patronymic
                            pax.person.birthDate = item.person.birthDate
                            pax.person.gender = item.person.gender
                            pax.person.identificationNumber = item.person.identificationNumber
                            pax.person.ageGroup = item.person.ageGroup
                        }
                        paxes.push(pax)
                    })
                }

            }catch(e){
                console.log('Ошибка в мутации updateCustomerPaxes', e)
            }
            state.customerPaxes = paxes
        },
        updateAddPaxTemplate(state){
            let pax = {
                id: 'new',
                documents: [],
                nationalityId: 210357,
                person: {
                    lastName: null,
                    firstName: null,
                    patronymic: null,
                    birthDate: null,
                    gender: 1,
                    identificationNumber: null,
                    ageGroup: 0,
                }
            }
            state.customerPaxes.push(pax)
        }
    },
    getters: {
        getCustomerPaxes: state => {
            return state.customerPaxes
        },
        getCustomerPaxById: state => id => {
            let result = null
            try{
                let res = state.customerPaxes
                result = res.find(item => {
                    return item.id === id
                })
            }catch(e){
                console.log('Ошибка в getCustomerPaxById', e)
            }
            return result
        },
        getPaxRegularPassport: state=> (id, nationalityId) =>{
            let result = null
            try{
                let res = state.customerPaxes
                let pax = res.find(item => item.id === id)
                result = pax.documents.find(document=> document.type === 2 && document.nationalityCountryId === nationalityId)
                if(!result){
                    result = pax.documents.find(document => document.type === 2 && document.nationalityCountryId === 0)
                }
                // if(!result){
                //     result = pax.documents.find(document => document.type === 0 && document.nationalityCountryId === 0)
                // }
                //если подходящего паспорта нет
                if(!result){
                    let passport = {
                        type: 2,
                        firstName: "",
                        lastName: "",
                        patronimicName: "",
                        series: "",
                        number: "",
                        address: "",
                        issuingAuthority: "",
                        issueDate: "",
                        expirationDate: null,
                        inn: null,
                        departmentCode: "",
                        nationalityCountryId: nationalityId
                    }
                    pax.documents.push(passport)
                    result = passport
                }
            }catch(e){
                console.log('Ошибка в getCustomerPaxById', e)
            }
            return result
        },
        getPaxForeignPassport: state=> (id, nationalityId) =>{
            let result = null
            try{
                let res = state.customerPaxes
                let pax = res.find(item => item.id === id)
                result = pax.documents.find(document=> document.type === 1 && document.nationalityCountryId === nationalityId)
                if(!result){
                    result = pax.documents.find(document => document.type === 1 && document.nationalityCountryId === 0)
                }
                // if(!result){
                //     result = pax.documents.find(document => document.type === 0 && document.nationalityCountryId === 0)
                // }
                //если подходящего паспорта нет
                if(!result){
                    let passport = {
                        type: 1,
                        firstName: "",
                        lastName: "",
                        patronimicName: "",
                        series: "",
                        number: "",
                        address: "",
                        issuingAuthority: "",
                        issueDate: "",
                        expirationDate: null,
                        inn: null,
                        departmentCode: "",
                        nationalityCountryId: nationalityId
                    }
                    pax.documents.push(passport)
                    result = passport
                }
            }catch(e){
                console.log('Ошибка в getCustomerPaxById', e)
            }
            return result
        },
        getPaxIdCard: state=> (id, nationalityId) =>{
            let result = null
            try{
                let res = state.customerPaxes
                let pax = res.find(item => item.id === id)
                result = pax.documents.find(document=> document.type === 5 && document.nationalityCountryId === nationalityId)
                if(!result){
                    result = pax.documents.find(document => document.type === 5 && document.nationalityCountryId === 0)
                }
                // if(!result){
                //     result = pax.documents.find(document => document.type === 0 && document.nationalityCountryId === 0)
                // }
                //если подходящего паспорта нет
                if(!result){
                    let passport = {
                        type: 5,
                        firstName: "",
                        lastName: "",
                        patronimicName: "",
                        series: "",
                        number: "",
                        address: "",
                        issuingAuthority: "",
                        issueDate: "",
                        expirationDate: null,
                        inn: null,
                        departmentCode: "",
                        nationalityCountryId: nationalityId
                    }
                    pax.documents.push(passport)
                    result = passport
                }
            }catch(e){
                console.log('Ошибка в getCustomerPaxById', e)
            }
            return result
        },
        getBirthCertificate: state=> (id) =>{
            let result = null
            try{
                let res = state.customerPaxes
                let pax = res.find(item => item.id === id)
                result = pax.documents.find(document=> document.type === 3)
                //если подходящего паспорта нет
                if(!result){
                    let passport = {
                        type: 3,
                        firstName: "",
                        lastName: "",
                        patronimicName: "",
                        series: "",
                        number: "",
                        address: "",
                        issuingAuthority: "",
                        issueDate: "",
                        expirationDate: null,
                        inn: null,
                        departmentCode: "",
                    }
                    pax.documents.push(passport)
                    result = passport
                }
            }catch(e){
                console.log('Ошибка в getCustomerPaxById', e)
            }
            return result
        }
    }
}
