import variables from "../../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchInsuranceCreateCalc(ctx, data){
            let url = variables.routeInsurance + '/create-calc'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data,
                })
                ctx.commit('updateInsuranceCurrentDestination', data.destinations[0])
                let payloadTravellers = data.tourists.length
                ctx.commit('updateInsuranceAdults', payloadTravellers)
                return await res.data.result
            }catch(e){
                if (e.response.status === 500 && e.response.data.error.code === 'BadArgument') {
                    return {error: true}
                }
                console.log('Ошибка в actions fetchInsuranceCreateCalc', e)
            }
        },
        async fetchInsuranceGetCalc(ctx, id){
            ctx.commit('updateTourInsurancesLoad', true)
            if(!id) return
            //Очистка insuranceAdditionsAmount
            ctx.state.insuranceAdditionsAmount = {
                tripCancellation: [],
                baggage: [],
                accident: [],
                liability: [],
            }
            ctx.state.tourInsurances = {items:[]}
            let url = variables.routeInsurance + '/get-calc/' + id
            try{
                await new Promise(resolve => {
                    let intervalId = setInterval(()=>{
                        let res = axios({
                            url,
                            method: 'POST',
                        })

                        res.then(response => {
                            let data = response.data
                            ctx.commit('updateTourInsurances', response.data)
                            if(data.isCompleted == true){
                                resolve('complete')
                                clearInterval(intervalId)
                            }
                        })

                    }, 5000)
                })
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceGetCalc', e)
            }
        },
        async fetchConstructorInsuranceGetCalc(ctx, service){
            if(!service.searchId) return
            //Очистка additions
            service.additions.tripCancellation = []
            service.additions.baggage = []
            service.additions.accident = []
            service.additions.liability = []

            let url = variables.routeInsurance + '/get-calc/' + service.searchId
            try{
                await new Promise(resolve => {
                    let intervalId = setInterval(()=>{
                        let res = axios({
                            url,
                            method: 'POST',
                        })

                        res.then(response => {
                            let data = response.data
                            service.response = data
                            ctx.commit('updateConstructorTourInsurances', service)
                            if(data.isCompleted == true){
                                resolve('complete')
                                clearInterval(intervalId)
                            }
                        })

                    }, 5000)
                })
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceGetCalc', e)
            }
        },
        //Actions для перебронирования пакетного тура
        async fetchConstructorPackageInsuranceGetCalc(ctx, service){
            if(!service.searchId) return

            let url = variables.routeInsurance + '/get-calc/' + service.searchId
            try{
                await new Promise(resolve => {
                    let intervalId = setInterval(()=>{
                        let res = axios({
                            url,
                            method: 'POST',
                        })

                        res.then(response => {
                            let data = response.data
                            service.response = data
                            ctx.commit('updateConstructorTourInsurances', service)
                            //Поиск прекращается при первом же результате
                            //Менять логику получения страховки при перебронировании надо здесь
                            // if(data.isCompleted == true){
                            if(Array.isArray(data.items) && data.items.length > 0){
                                resolve('complete')
                                clearInterval(intervalId)
                            }
                        })

                    }, 5000)
                })
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceGetCalc', e)
            }
        },
        async fetchInsuranceDestinations(ctx){
            let url = variables.routeReferenceInsurance + '/destinations'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateInsuranceDestinations', await res.data)
                return await res.data
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceDestinations', e)
            }
        },
        async fetchInsuranceAmountLimits(ctx){
            if(ctx.state.insuranceAmountLimits.length > 0) return
            let url = variables.routeReferenceInsurance + '/insuranceAmountLimits'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateInsuranceAmountLimits', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceAmountLimits', e)
            }
        },
        async fetchInsuranceCompany(ctx){
            if(!ctx.state.isSearchInsuranceCompany) return
            ctx.state.isSearchInsuranceCompany = true
            let url = variables.routeReferenceInsurance + '/insuranceCompany'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateInsuranceCompany', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceCompany', e)
            }
        },
        async fetchInsuranceJourneyType(ctx){
            let url = variables.routeReferenceInsurance + '/journeyType'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateInsuranceJourneyType', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceJourneyType', e)
            }
        },
        async fetchInsuranceProfessionalSports(ctx){
            if(ctx.state.insuranceProfessionalSports.length > 0) return
            let url = variables.routeReferenceInsurance + '/professionalSports'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateInsuranceProfessionalSports', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceProfessionalSports', e)
            }
        },
        async fetchInsuranceSex(ctx){
            if(ctx.state.insuranceSex.length > 0) return
            let url = variables.routeReferenceInsurance + '/sex'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateInsuranceSex', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceSex', e)
            }
        },
        async fetchInsuranceSupActivities(ctx){
            if(ctx.state.insuranceSupActivities.length > 0) return
            let url = variables.routeReferenceInsurance + '/supActivities'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                })
                ctx.commit('updateInsuranceSupActivities', await res.data)
            }catch(e){
                console.log('Ошибка в actions fetchInsuranceSupActivities', e)
            }
        },
        async fetchDownloadInsurance(ctx, hash){
            let url = variables.routeInsurance + '/download'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: {hash: hash},
                    responseType: 'arraybuffer',
                })
                let data = await res.data
                //Скачиваем файл
                let binaryData = [];
                binaryData.push(data);
                const link = document.createElement('a');
                // создаем blobURI
                link.href = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}))
                link.download = 'policy.pdf'
                document.body.append(link)
                link.click()
                link.remove()
                //Удаляем из памяти
                setTimeout(() => window.URL.revokeObjectURL(link.href), 7000);
            }catch(e){
                console.log('Ошибка в actions fetchDownloadInsurance', e)
            }
        },
    },
    state: {
        tourInsurances: {},
        tourInsurancesLoad: false,
        insuranceDestinations: [],
        insuranceCurrentDestination: {},
        insuranceAmountLimits: [],
        insuranceCompany: [],
        insuranceJourneyType: [],
        insuranceProfessionalSports: [],
        insuranceSex: [],
        insuranceSupActivities: [],
        insuranceAdditionsAmount: {
            tripCancellation: [],
            baggage: [],
            accident: [],
            liability: [],
        },
        insuranceOptions: [],
        isSearchInsuranceCompany: false,
        adults: 0,
    },
    mutations: {
        updateTourInsurances(state, payload){
            let result = {
                insuranceCompanyCount: null,
                items: [],
                itemsCount: null,
                searchId: null,
            }
            try{
                //Очитска страховок если новый запрос
                //Создание объкта если раньше не был создан
                if(state.tourInsurances.searchId){
                    if(state.tourInsurances.searchId !== payload.searchId){
                        state.tourInsurances = result
                    }
                }else{
                    state.tourInsurances = result
                }
                //Получение новых страховок
                let insurances = []
                if(payload.items){
                    insurances = payload.items.filter(item => {
                        return !state.tourInsurances.items.some(elem => {
                            return elem.id == item.id
                        })
                    }).filter(item=>{ //Отфильтровываем тестовые страховки
                        return item.hasOffersId !== 111111;
                    })
                }

                //Добавление новых страховок к объекту
                //Также формирование возможных значений для дополнительных опций
                if(insurances.length > 0){
                    let items = state.tourInsurances.items
                    let tripCancellation = new Set(state.insuranceAdditionsAmount.tripCancellation)
                    let baggage = new Set(state.insuranceAdditionsAmount.baggage)
                    let assident = new Set(state.insuranceAdditionsAmount.accident)
                    let liability = new Set(state.insuranceAdditionsAmount.liability)
                    let options = state.insuranceOptions
                    insurances.forEach(item=>{
                        if(item.tripCancellationInsuranceAmount > 0) tripCancellation.add(item.tripCancellationInsuranceAmount)
                        if(item.baggageInsuranceAmount > 0) baggage.add(item.baggageInsuranceAmount)
                        if(item.accidentInsuranceAmount > 0) assident.add(item.accidentInsuranceAmount)
                        if(item.liabilityInsuranceAmount > 0) liability.add(item.liabilityInsuranceAmount)
                        //Добавление новых опций в state
                        if(item.options.length > 0){
                            item.options.forEach(elem => {
                                //elem - опция в пришедших items
                                //el - опция в state
                                if(!options.some(el => {
                                    return el.optionType == elem.optionType
                                })){
                                    let option = {
                                        name: null,
                                        optionType: null
                                    }
                                    option.name = elem.name
                                    option.optionType = elem.optionType
                                    options.push(option)
                                }
                            })
                        }
                    })
                    state.insuranceAdditionsAmount.tripCancellation = [...tripCancellation]
                    state.insuranceAdditionsAmount.baggage = [...baggage]
                    state.insuranceAdditionsAmount.accident = [...assident]
                    state.insuranceAdditionsAmount.liability = [...liability]
                    state.insuranceOptions = [...options]
                    items.push.apply(items, insurances)
                }
                //Обвязка
                state.tourInsurances.insuranceCompanyCount = payload.insuranceCompanyCount
                state.tourInsurances.itemsCount = payload.itemsCount
                state.tourInsurances.searchId = payload.searchId
            }catch(e){
                console.log('Ошибка в мутации updateTourInsurances', e)
            }
        },
        updateConstructorTourInsurances(state, service){
            //апи выдает результаты порционно
            //Здесь происходит не мутация а формирование параметра объекта service
            //Так сделано что бы все лежало в одном месте, т.к. для обычного поиска требуется мутация
            //А так же потому что при использовании асинхронного метода
            //нет возможности обработать промежуточные результаты в компоненте

            let result = {
                insuranceCompanyCount: null,
                items: [],
                itemsCount: null,
                searchId: null,
            }
            try{
                //Получение новых страховок
                let insurances = service.response.items.filter(item => {
                    return !service.insurances.some(elem => {
                        return elem.id == item.id
                    })
                })
                //Добавление новых страховок к объекту
                //Также формирование возможных значений для дополнительных опций
                if(insurances.length > 0){
                    let items = service.insurances
                    let tripCancellation = new Set(service.additions.tripCancellation)
                    let baggage = new Set(service.additions.baggage)
                    let assident = new Set(service.additions.accident)
                    let liability = new Set(service.additions.liability)
                    let options = service.options
                    insurances.forEach(item=>{
                        if(item.tripCancellationInsuranceAmount > 0) tripCancellation.add(item.tripCancellationInsuranceAmount)
                        if(item.baggageInsuranceAmount > 0) baggage.add(item.baggageInsuranceAmount)
                        if(item.accidentInsuranceAmount > 0) assident.add(item.accidentInsuranceAmount)
                        if(item.liabilityInsuranceAmount > 0) liability.add(item.liabilityInsuranceAmount)
                        //Добавление новых опций в service
                        if(item.options.length > 0){
                            item.options.forEach(elem => {
                                //elem - опция в пришедших items
                                //el - опция в state
                                if(!options.some(el => {
                                    return el.optionType == elem.optionType
                                })){
                                    let option = {
                                        name: null,
                                        optionType: null
                                    }
                                    option.name = elem.name
                                    option.optionType = elem.optionType
                                    options.push(option)
                                }
                            })
                        }
                    })
                    service.additions.tripCancellation = [...tripCancellation]
                    service.additions.baggage = [...baggage]
                    service.additions.accident = [...assident]
                    service.additions.liability = [...liability]
                    service.options = [...options]
                    items.push.apply(items, insurances)
                }
                //Обвязка
                // state.tourInsurances.insuranceCompanyCount = payload.insuranceCompanyCount
                // state.tourInsurances.itemsCount = payload.itemsCount
                // state.tourInsurances.searchId = payload.searchId
            }catch(e){
                console.log('Ошибка в мутации updateTourInsurances', e)
            }
        },
        updateInsuranceDestinations(state, payload){
            let result = []
            try{
                payload.forEach(item => {
                    let destination = {
                        alias: null,
                        currencyIsoCode: null,
                        id: null,
                        isGroup: null,
                        isSchengen: null,
                        name: null,
                        needVisa: null,
                        order: null,
                        puntoName: null,
                        recommendationSupActivityIds: [],
                        value: null,
                    }
                    destination.alias = item.alias,
                        destination.currencyIsoCode = item.currencyIsoCode,
                        destination.id = item.id,
                        destination.isGroup = item.isGroup,
                        destination.isSchengen = item.isSchengen,
                        destination.name = item.name,
                        destination.needVisa = item.needVisa,
                        destination.order = item.order,
                        destination.puntoName = item.puntoName,
                        destination.recommendationSupActivityIds = item.recommendationSupActivityIds,
                        destination.value = item.value
                    result.push(destination)
                })
                state.insuranceDestinations = result
            }catch(e){
                console.log('Ошибка в мутации updateInsuranceDestination', e)
            }
        },
        updateInsuranceAmountLimits(state, payload){
            if(!Array.isArray(payload)) return
            let result = []
            try{
                payload.forEach(item => {
                    let amountLimit = {
                        accident: [],
                        baggage: [],
                        currencyIsoCode: null,
                        liability: [],
                        life: [],
                        tripCancellation: [],
                    }
                    amountLimit.accident = item.accident
                    amountLimit.baggage = item.baggage
                    amountLimit.currencyIsoCode = item.currencyIsoCode
                    amountLimit.liability = item.liability
                    amountLimit.life = item.life
                    amountLimit.tripCancellation = item.tripCancellation
                    result.push(amountLimit)
                })
                state.insuranceAmountLimits = result
            }catch(e){
                console.log('Ошибка в мутации updateInsuranceAmountLimits', e)
            }
        },
        updateInsuranceCompany(state, payload){
            let result = []
            try{
                payload.forEach(item => {
                    let company = {
                        id: null,
                        name: null,
                    }
                    company.id = item.id
                    company.name = item.name
                    result.push(company)
                })
                console.log('result', result)
                state.insuranceCompany = result
            }catch(e){
                console.log('Ошибка в мутации updateInsuranceCompany', e)
            }
        },
        updateInsuranceJourneyType(state, payload){
            let result = []
            try{
                payload.forEach(item => {
                    let journeyType = {
                        id: null,
                        name: null,
                        daysCount: [],
                    }
                    journeyType.id = item.id
                    journeyType.name = item.name
                    journeyType.daysCount = item.daysCount
                    result.push(journeyType)
                })
                state.insuranceJourneyType = result
            }catch(e){
                console.log('Ошибка в мутации updateInsuranceJourneyType', e)
            }
        },
        updateInsuranceProfessionalSports(state, payload){
            let result = []
            try{
                payload.forEach(item => {
                    let sport = {
                        id: null,
                        name: null,
                        puntoName: null,
                    }
                    sport.id = item.id
                    sport.name = item.name
                    sport.puntoName = item.puntoName
                    result.push(sport)
                })
                state.insuranceProfessionalSports = result
            }catch(e){
                console.log('Ошибка в мутации updateInsuranceProfessionalSports', e)
            }
        },
        updateInsuranceSex(state, payload){
            let result = []
            try{
                payload.forEach(item => {
                    let sex = {
                        id: null,
                        name: null,
                    }
                    sex.id = item.id
                    sex.name = item.name
                    result.push(sex)
                })
                state.insuranceSex = result
            }catch(e){
                console.log('Ошибка в мутации updateInsuranceSex', e)
            }
        },
        updateInsuranceSupActivities(state, payload){
            let result = []
            try{
                payload.forEach(item => {
                    let activity = {
                        id: null,
                        name: null,
                    }
                    activity.id = item.id
                    activity.name = item.name
                    result.push(activity)
                })
                state.insuranceSupActivities = result
            }catch(e){
                console.log('Ошибка в мутации updateInsuranceSupActivities', e)
            }
        },
        updateTourInsurancesLoad(state, payload){
            state.tourInsurancesLoad = payload
        },
        updateInsuranceAdults(state, payload){
            state.adults = payload
        },
        updateInsuranceCurrentDestination(state, payload){
            state.insuranceCurrentDestination = payload
        }
    },
    getters: {
        getTourInsurances: state => {
            return state.tourInsurances
        },
        getInsurancesSearchId:state =>{
            return state.tourInsurances.searchId
        },
        getInsuranceDestinations: state => {
            return state.insuranceDestinations
        },
        getInsuranceCurrentDestination: state => {
            return state.insuranceCurrentDestination
        },
        getInsuranceAmountLimits: state => {
            return state.insuranceAmountLimits
        },
        getInsuranceCompanyNameById: state => id => {
            let result = null
            try{
                let res = state.insuranceCompany.find(item => {
                    return item.id == id
                })
                if(res) result = res.name
            }catch(e){
                console.log('Ошибка в getters getInsuranceCompanyById', e)
            }
            return result
        },
        getInsuranceJourneyType: state => {
            return state.insuranceJourneyType
        },
        getInsuranceProfessionalSports: state => {
            return state.insuranceProfessionalSports
        },
        getInsuranceSex: state => {
            return state.insuranceSex
        },
        getInsuranceSupActivities: state => {
            return state.insuranceSupActivities
        },
        getInsuranceAdditionsAmount: state => {
            return state.insuranceAdditionsAmount
        },
        getInsuranceOptions: state => {
            return state.insuranceOptions
        },
        getTourInsurancesLoad: state => {
            return state.tourInsurancesLoad
        },
        getTourInsuranceAdults: state =>{
            return state.adults
        },
    }
}
