import variables from "../../plugins/variables";
import axios from 'axios';
export default {
    actions: {
        async fetchTown(ctx, id){
            //проверка не проводился-ли ранее запрос по этому идентификатору
            if(ctx.state.towns.indexOf(id) != -1){
                return
            } else {
                ctx.state.towns.push(id)
            }
            let url = variables.routeCity + '/' + id;
            try {
                const res = await axios({
                    url
                })
                ctx.commit('updateTowns', res.data)
            } catch {
                console.log('Ошибка получения ответа от ' + url)
            }
        }
    },
    state: {
        towns: [],
        townsId: null
    },
    mutations: {
        async updateTowns(state, town){
            //копирование массива из state
            let result = state.towns.slice()
            result.push(await town)
            //запись в state нового массива
            state.towns = result
        }
    },
    getters: {
        getTownById: (state) => id => {
            let town = {
                id: null,
                name: null
            }
            try {
                const res = state.towns.find(item => {
                    return item.ebgNodeId == id
                })
                town.id = res.ebgNodeId
                town.name = res.localizedName
            } catch {}
            return town
        }
    }
}
