
export default {
    actions: {
        async fetchFlocktoryPostCheckout(ctx,payload) {
            try {
                let cart = payload.draft.draftBody
                let order = payload.order.data

                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }

                let products = []

                for (const tour of cart.tours) {

                    products.push({
                        id: tour.id,
                        title: tour.name,
                        "price": tour.price.toString(),
                        image: tour.imgUrl,
                        count: 1,
                    })
                }

                for (const tour of cart.hotels) {
                    products.push({
                        id: tour.id,
                        title: tour.name,
                        "price": tour.price.toString(),
                        image: tour.imgUrl,
                        count: 1,
                    })
                }

                let result = ['postcheckout', {
                    user: {
                        name: order.customerInfo.name,
                        email: order.customerInfo.email,
                        sex: 'm',
                        user_type: ctx.getters.getFlocktoryUserType,
                    },
                    order: {
                        id: order.id,
                        price: order.prices[1].amount,
                        items: [products]
                    }, spot: spot
                }]
                flocktory.push(result);
                console.log('fetchFlocktoryOrderPaySaccess', result)

                // dataLayer.push(result);

            } catch (e) {
                console.log('Ошибка в actions fetchFlocktoryOrderPaySaccess', e)
            }


        },
        //PreCheckout
        async fetchFlocktoryPreCheckout(ctx) {

        },

        async fetchFlocktoryAuth(ctx) {
            try {
                if (ctx.getters.getIsLogin) {

                    let data = {
                        'email': ctx.getters.getIsLogin ? ctx.getters.getUser.email : ''
                    }
                    if (ctx.getters.getUser.fullName && ctx.getters.getUser.fullName.length) {
                        data.name = ctx.getters.getUser.fullName
                    }
                    window.flocktory.push(['addData', {
                        'user': data
                    }]);
                }

            } catch (e) {
                console.log('Ошибка в actions fetchFlocktoryAuth', e)
            }


        },


    },
    state: {},
    mutations: {},
    getters: {}
}
