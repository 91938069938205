import variables from "../../plugins/variables";
import axios from 'axios';


export default {
    actions: {
        async fetchLocalization(ctx) {
            //Проверка позволяет не загружать два раза локализацию
            //При этом для поддержания асинхронности ожидается пока не будут получены данные
            //запрошенные при другом обращении к методу
            //если в теченнии 30 сек. данные так и не будут получены, получаем резолв
            if(ctx.state.isRequestedLocalization){
                let promise = new Promise((resolve)=>{
                    let count = 1000
                    setInterval(()=>{
                        count--
                        if(ctx.state.localizationLoad) resolve()
                        if(!count) resolve()
                    }, 30)
                })
                await promise
                return false
            }
            ctx.state.isRequestedLocalization = true
            const url = variables.routeLocalization
            const res = await axios({
                url,
                method: 'POST'
            })
            const data = await res.data
            ctx.commit('updateLocalization', data)

            const currency = await axios({
                url: variables.routeCurrencyList,
                method: 'POST'
            })
            let currencyData = await currency.data
            //console.log(currencyData)
            if(typeof(currencyData)==='object'){ //Если список валют не пришел с бэка
                currencyData = [
                    {
                        id: 1,
                        name: "RUB",
                        currencyISO: "RUB"
                    },
                    {
                        id: 2,
                        name: "USD",
                        currencyISO: "USD"
                    },
                    {
                        id: 3,
                        name: "EUR",
                        currencyISO: "EUR"
                    },
                    {
                        id: 4,
                        name: "UAH",
                        currencyISO: "UAH"
                    },
                    {
                        id: 11,
                        name: "KZT",
                        currencyISO: "KZT"
                    }
                ]
            }
            ctx.commit('updateLocalizationCurrency', currencyData)
            let result = await data.data
            //LocalizationLoad переключается в true после резолва
            ctx.commit('updateLocalizationLoad', true)
            return result
        },
        async fetchChangeLocalization(ctx, payload) {
            const url = variables.routeChangeLocalization + payload;
            const res = await axios({
                url,
                method: 'POST',
            })
            const data = await res.data
            // ctx.commit('updateLocalization', payload)
            await ctx.dispatch('fetchMainboxChangingClientZoneOnSite', data)
            //window.location = "/"
            window.location.href = data.newUrl
        },
        async fetchChangeDefaultCity(ctx, payload) {
            const url = variables.routeChangeDefaultCity;
            const res = await axios({
                url,
                method: 'POST',
                data:payload
            })
        },
        async fetchChangeLanguage(ctx, payload) {
            const url = variables.routeChangeLocalization + payload.locale + '/' + payload.language;
            const res = await axios({
                url,
                method: 'POST',
            })
            const data = await res.data
            // ctx.commit('updateLocalization', payload)
            // await ctx.dispatch('fetchMainboxChangingClientZoneOnSite', data)
            //window.location = "/"
            window.location.href = data.newUrl
        },

    },
    mutations: {
        updateLocalizationLoad(state, payload) {
            state.localizationLoad = payload;
        },
        updateLocalization(state, payload) {
            state.localization = payload.locale
            state.defaultCity = payload.defaultCity
            state.defaultCityEbgId = payload.defaultCityEbgId
            state.defaultCurrencyId = payload.currencyId
            state.defaultCountry = payload.country
            state.language = payload.accept_language
            state.languagesList = payload.languages_list
            state.lat = payload.lat
            state.lon = payload.lon
        },
        updateLocalizationCurrency(state, payload) {
            if (Array.isArray(payload)) {
                payload.push({
                    id: 12,
                    name: "BYN",
                    currencyISO: "BYN"
                })
                state.currencyList = payload
                payload.forEach(function (item) {
                    if (item.id === parseInt(state.defaultCurrencyId)) {
                        state.defaultCurrencyAlias = item.currencyISO
                    }
                })
            }
        },
    },
    state: {
        isRequestedLocalization: false, //флаг localizationLoad не подходит, так как если предыдущий запрос не выполнился могут нагенерироваться лишние запросы
        localizationLoad: false, // Флаг что локализация уже загружена или загружается в этот момент
        localization: null,
        language: null,
        languagesList:[],
        defaultCity: "",
        defaultCityEbgId: "",
        defaultCurrencyId: 1,
        defaultCountry:'',
        defaultCurrencyAlias: "",
        currencyList: [],
        lat: null,
        lon:null,
    },
    getters: {
        getLocalizationLoad(state) {
            return state.localizationLoad
        },
        getLocalization(state) {
            return state.localization
        },
        getLanguage(state) {
            return state.language
        },
        getLanguagesList(state) {
            return state.languagesList
        },
        getDefaultCity(state) {
            return state.defaultCity
        },
        getDefaultCityEbgId(state) {
            return state.defaultCityEbgId
        },
        getDefaultCurrencyList(state) {
            return state.currencyList
        },
        getDefaultCurrencyId(state) {
            return state.defaultCurrencyId
        },
        getDefaultCurrencyAlias(state) {
            return state.defaultCurrencyAlias
        },
        getDefaultCountry(state) {
            return state.defaultCountry
        },
        getLocalizationLat(state){
            return state.lat
        },
        getLocalizationLon(state){
            return state.lon
        }
    },
}

