import {createLink} from "../../../plugins/helpers";
import variables from "../../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchUserPayment(ctx, paymentConfig){
            console.log(paymentConfig)
            const url = variables.routeUserPayment
            //try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: paymentConfig,
                    // data: {
                    //     orderId: 4489685,
                    //     destinationCountryName: 'Греция',
                    //     description: 'Греция',
                    //     beginDate: '2020-10-21T14:30:00',
                    //     customerEmail: 'test@yandex.ru',
                    //     customerPhoneNumber: '88002000000',
                    //     amount: 40872.7,
                    //     currencyCode: "EUR",
                    //     currencyRate: 80.3,
                    // }
                })
                if(await res.status === 200) {
                    return await res.data
                } else {
                    return false
                }
            //} catch {
             //   console.log('Ошибка в actions fetchUserPayment')
            //}
        },
        async fetchUserPaymentStatus(ctx){
            let url = variables.routeUserPayment + '/pay/status'
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                })
                return await res.data.payStatus
            } catch {
                console.log('Ошибка в actions fetchUserPaymentStatus')
            }
        },
        async fetchPaymentLink(ctx, payload){
            console.log(payload)
            if(['tinkoff', 'ecommpay', 'sberbank', 'sovkombank'].includes(payload.provider)){
                console.log('createLink formUrl', payload.formUrl);
                //createLink(payload.formUrl)
                payload.win.location = payload.formUrl
            }
            if(payload.provider === 'wayforpay' || payload.provider ==='jysan'){
                const form = document.createElement('form');
                form.method = 'post'
                form.action = payload.formUrl
                form.target = "_blank"
                let params = payload.formData

                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        const hiddenField = document.createElement('input');
                        hiddenField.type = 'hidden';
                        hiddenField.name = key;
                        hiddenField.value = params[key];

                        form.appendChild(hiddenField);
                    }
                }

                document.body.appendChild(form);
                form.submit();
            }
            if ( payload.provider === 'oplati' && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                const link = document.createElement('a');
                link.href= 'https://getapp.o-plati.by/map/?app_link=' + payload.formData.DynamicQR
                document.body.appendChild(link)
                link.click()
            } else{
                ctx.commit('updateQrPaymentLink', payload.formData.DynamicQR)
            }
        },
    },
    state: {
        qrPaymentLink: null,
    },
    mutations: {
        updateQrPaymentLink(state, payload){
            state.qrPaymentLink = payload
        }
    },
    getters: {
        getQrPaymentLink(state){
            return state.qrPaymentLink
        }
    }
}
