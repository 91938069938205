import axios from 'axios';
import variables from "../../../plugins/variables";

export default {
    actions: {
        async fetchMarketBlocks(ctx) {
            let url = ctx.state.api + '/api/marketcatalog/catalogblocks/all';
            try {
                let response = await axios.get(url);
                if (response.status === 200) {
                    ctx.commit('updateBlocks', response.data);
                }
                ctx.commit('updateMarketplaceLoadStatus', false);
            } catch (e) {
                ctx.commit('updateMarketplaceLoadStatus', false);
                console.log('Ошибка в actions fetchMarketBlocks', e);
            }
        },
        async fetchFilters(ctx, id) {
            let url = ctx.state.api + '/api/marketcatalog/filters';
            try {
                let response = await axios.get(url, {params: {CategoryId: id}});
                if (response.status === 200) {
                    ctx.commit('updateFilters', response.data);
                }
                ctx.commit('updateMarketplaceLoadStatus', false);
            } catch (e) {
                ctx.commit('updateMarketplaceLoadStatus', false);
                console.log('Ошибка в actions fetchFilters', e);
            }
        },
        async fetchFiltersShop(ctx, shopName) {
            let url = ctx.state.api + '/api/marketcatalog/shop-filters';
            try {
                let response = await axios.get(url, {params: {ShopName: shopName}});
                if (response.status === 200) {
                    ctx.commit('updateFilters', response.data);
                }
                ctx.commit('updateMarketplaceLoadStatus', false);
            } catch (e) {
                ctx.commit('updateMarketplaceLoadStatus', false);
                console.log('Ошибка в actions fetchFilters', e);
            }
        },
        async fetchCategories(ctx) {
            let url = ctx.state.api + '/api/marketcatalog/categories/tree';
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                });
                if (response.status === 200) {
                    ctx.commit('updateCategories', response.data);
                }
            } catch (e) {
                ctx.commit('updateMarketplaceLoadStatus', false);
                console.log('Ошибка в actions fetchCategories', e);
            }
        },
        async fetchProducts(ctx, payload) {
            // Без лоадера и пополнением уже загруженных товаров
            try {
                let url = ctx.state.api + '/api/marketcatalog/products/filtered';
                url = variables.routeCatalogProducts;
                let response = await axios.post(url, payload);
                if (response.status === 200) {
                    ctx.commit('updateProducts', response.data.data);
                }
                ctx.commit('updateMarketplaceLoadStatus', false);
            } catch (e) {
                ctx.commit('updateMarketplaceLoadStatus', false);
                console.log('Ошибка в actions fetchProducts', e);
            }
        },
        async fetchViewedProducts(ctx) {
            let result = null;
            // Без лоадера и пополнением уже загруженных товаров
            try {
                let url = variables.routeViewedProducts;
                let response = await axios.post(url);
                if (response.status === 200) {
                    result = response.data
                }
                return result;
            } catch (e) {
                console.log('Ошибка в actions fetchViewedProducts', e);
                return result;
            }
        },
        async fetchProductsByPage(ctx, payload) {
            // С лоадером и чистка уже загруженных товаров
            ctx.commit('updateMarketplaceLoadStatus', true);
            try {
                let url = ctx.state.api + '/api/marketcatalog/products/filtered';
                url = variables.routeCatalogProducts;
                let response = await axios.post(url, payload);
                if (response.status === 200) {
                    ctx.commit('updateProductsByPage', response.data);
                } else {
                    ctx.commit('updateProductsByPage', {});
                }
                ctx.commit('updateMarketplaceLoadStatus', false);
            } catch (e) {
                ctx.commit('updateProductsByPage', {});
                ctx.commit('updateMarketplaceLoadStatus', false);
                console.log('Ошибка в actions fetchProductsByPage', e);
            }
        },
        async fetchProductsForBlock(ctx, payload) {
            let result = null;
            try {
                let url = ctx.state.api + '/api/marketcatalog/products/filtered';
                url = variables.routeCatalogProducts;
                let response = await axios.post(url, payload);
                if (response.status === 200) {
                    result = response.data
                }
                return result;
            } catch (e) {
                console.log('Ошибка в actions fetchProductsForBlock', e);
                return result;
            }
        },
        async fetchProductsByMore(ctx, payload) {
            // Без лоадера и пополнением уже загруженных товаров
            try {
                let url = ctx.state.api + '/api/marketcatalog/products/filtered';
                url = variables.routeCatalogProducts;
                let response = await axios.post(url, payload);
                if (response.status === 200) {
                    ctx.commit('updateProductsByMore', response.data);
                }
            } catch (e) {
                console.log('Ошибка в actions fetchProductsByMore', e);
            }
        },
        async fetchMarketplaceProductsSearch(ctx, payload) {
            try {
                let url = ctx.state.api + '/api/marketcatalog/products/search';
                url = variables.routeCatalogSearch;
                let response = await axios.post(url, payload);
                if (response.status === 200) {
                    ctx.commit('updateProductsBySearch', response.data);
                } else {
                    ctx.commit('updateProductsBySearch', []);
                }
            } catch (e) {
                ctx.commit('updateProductsBySearch', []);
                console.log('Ошибка в actions fetchProductsByPage', e);
            }
        },
        async fetchProduct(ctx, id) {
            ctx.commit('updateMarketplaceLoadStatus', true);
            try {
                let url = ctx.state.api + '/api/marketcatalog/product/' + id;
                let response = await axios({
                    url,
                    method: 'GET'
                });
                if (response.status === 200) {
                    ctx.commit('updateProduct', response.data);
                }
                ctx.commit('updateMarketplaceLoadStatus', false);
            } catch (e) {
                ctx.commit('updateMarketplaceLoadStatus', false);
                console.log('Ошибка в actions fetchProduct');
            }
        },
        async fetchProductReturnObject(ctx, id) {
            try {
                let url = variables.roureMarketplaceProductData + id
                let response = await axios({
                    url,
                    method: 'GET'
                });
                return response.data;
            } catch (e) {
                console.log('Ошибка в actions fetchProductReturnObject', e);
            }
        },
        async fetchTopCategories(ctx) {
            ctx.commit('updateMarketplaceLoadStatus', true);
            try {
                let url = ctx.state.api + '/api/marketcatalog/categories/tree';
                let response = await axios({
                    url,
                    method: 'GET'
                });
                if (response.status === 200) {
                    ctx.commit('updateTopCategories', response.data);
                }
                ctx.commit('updateMarketplaceLoadStatus', false);
            } catch (e) {
                ctx.commit('updateMarketplaceLoadStatus', false);
                console.log('Ошибка в actions fetchTopCategories', e);
            }
        },
        async fetchSendOrderMail(ctx, payload) {
            let url = variables.routeMarketplaceSendOrderMail;
            try {
                let response = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                });
            } catch (e) {
                console.log('Ошибка в actions fetchSendOrderMail', e);
            }
        },
        async fetchMarketOrder(ctx, payload) {
            const url = variables.routeCustomerMarketOrder;
            try {
                await axios({
                    url,
                    method: 'POST',
                    data: payload,
                });
            } catch (e) {
                console.log('Ошибка в actions fetchMarketOrder');
            }
        },
        async fetchMarketGeoObjects(ctx) {
            let result = [];
            const url = variables.routeMarketplaceGeoObjects;
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                });
                result = response.data;
                return result;
            } catch (e) {
                console.log('Ошибка в actions fetchMarketGeoObjects');
                return result;
            }
        },
        async fetchMarketGeoObject(ctx, data) {
            let result = {};
            const url = variables.routeMarketplaceGeoObject;
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                    params: data,
                });
                result = response.data;
                return result;
            } catch (e) {
                console.log('Ошибка в actions fetchMarketGeoObjects');
                return result;
            }
        },
        async fetchMarketIpLocation(ctx) {
            let result = [];
            const url = variables.routeMarketplaceIpLocation;
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                });
                result = response.data;
                return result;
            } catch (e) {
                console.log('Ошибка в actions fetchMarketGeoObjects');
                return result;
            }
        },
        async fetchProductRatingCreate(ctx, payload) {
            const url = variables.routeProductRatingCreate;
            try {
                await axios({
                    url,
                    method: 'POST',
                    data: payload,
                });
            } catch (e) {
                console.log('Ошибка в actions fetchProductRatingCreate')
            }
        },
        async fetchProductRatingUpdate(ctx, payload) {
            const url = variables.routeProductRatingUpdate;
            try {
                await axios({
                    url,
                    method: 'POST',
                    data: payload,
                });
            } catch (e) {
                console.log('Ошибка в actions fetchProductRatingUpdate')
            }
        },
        async fetchProductRatingModerate(ctx, payload) {
            const url = variables.routeProductRatingModerate;
            try {
                await axios({
                    url,
                    method: 'PUT',
                    data: payload,
                });
            } catch (e) {
                console.log('Ошибка в actions fetchProductRatingModerate')
            }
        },
        async fetchProductRatingList(ctx, payload) {
            const url = variables.routeProductRatingList;
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                    params: payload,
                });
                if (response.status === 200) {
                    ctx.commit('updateProductRatingList', response.data);
                }
            } catch (e) {
                console.log('Ошибка в actions fetchProductRatingList')
            }
        },
        async fetchProductRatingReport(ctx, payload) {
            const url = variables.routeProductRatingReport;
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                    params: payload,
                });
                if (response.status === 200) {
                    ctx.commit('updateProductRatingReport', response.data);
                }
            } catch (e) {
                console.log('Ошибка в actions fetchProductRatingReport')
            }
        },
        async fetchPartnerRatingCreate(ctx, payload) {
            const url = variables.routePartnerRatingCreate;
            try {
                await axios({
                    url,
                    method: 'POST',
                    data: payload,
                });
            } catch (e) {
                console.log('Ошибка в actions fetchPartnerRatingCreate')
            }
        },
        async fetchPartnerRatingUpdate(ctx, payload) {
            const url = variables.routePartnerRatingUpdate;
            try {
                await axios({
                    url,
                    method: 'POST',
                    data: payload,
                });
            } catch (e) {
                console.log('Ошибка в actions fetchPartnerRatingUpdate')
            }
        },
        async fetchPartnerRatingModerate(ctx, payload) {
            const url = variables.routePartnerRatingModerate;
            try {
                await axios({
                    url,
                    method: 'PUT',
                    data: payload,
                    // data: {
                    //     id: 'd3ae87b5-e97d-4960-b8a0-4367211aebba',
                    //     moderationStatus: 1,
                    // },
                });
            } catch (e) {
                console.log('Ошибка в actions fetchPartnerRatingModerate')
            }
        },
        async fetchPartnerRatingList(ctx, payload) {
            const url = variables.routePartnerRatingList;
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                    params: payload,
                });
                if (response.status === 200) {
                    ctx.commit('updatePartnerRatingList', response.data);
                }
            } catch (e) {
                console.log('Ошибка в actions fetchPartnerRatingList')
            }
        },
        async fetchPartnerReport(ctx, payload) {
            const url = variables.routePartnerReport;
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                    params: payload,
                });
                if (response.status === 200) {
                    ctx.commit('updatePartnerReport', response.data);
                }
            } catch (e) {
                console.log('Ошибка в actions fetchPartnerReport')
            }
        },
    },
    mutations: {
        setMarketApiGate(state, api) {
            state.api = api;
        },
        updateProduct(state, data) {
            state.product = data;
        },
        updateFilters(state, data) {
            // let result = data;
            // Удаляем дубликаты
            let used = {};
            let result = data.filter((element, index) => {
                return element.id in used ? 0:(used[element.id]=1);
            });
            result = result.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
            result.forEach(item => {
                if (item.variants) {
                    item.variants = item.variants.sort((a, b) => a.name > b.name ? 1 : -1);
                    item.all = false;
                    item.key = 0;
                    item.variants.forEach(prop => {
                        prop.active = false;
                    });
                }
            });
            state.filters = result;
        },
        updateBlocks(state, data) {
            state.blocks = data;
        },
        updateCategories(state, data) {
            let result = data.sort((a, b) => a.order > b.order ? 1 : -1);
            result.forEach(item => {
                item.children = item.children.sort((a, b) => a.order > b.order ? 1 : -1);
            });
            state.categories = data;
        },
        updateProducts(state, data) {
            if (data) {
                let products = state.products.data.concat(data);
                state.products = data;
                state.products.data = products;
            }
        },
        updateProductsByPage(state, data) {
            state.products = data;
        },
        updateProductsByMore(state, data) {
            if (data) {
                data.data = state.products.data.concat(data.data);
                state.products = data;
            }
        },
        updateProductsBySearch(state, data) {
            if (data) {
                state.productsSearch = [];
                data.forEach(item => {
                    state.productsSearch.push({
                        name: item.name,
                        value: item.id,
                        urlPath: item.urlPath,
                    });
                })
            }
        },
        updateTopCategories(state, data) {
            state.topCategories = data;
        },
        updateMarketplaceLoadStatus(state, payload) {
            state.marketplaceLoadStatus = payload;
        },
        updateProductRatingReport(state, payload) {
            state.productReport = payload;

            // (5 × n5 + 4 × n4 + 3 × n3 + 2 × n2 + 1 × n1) / (n5 + n4 + n3 + n2 + n1)
            let rating = 0;
            if (state.productReport && state.productReport.commentsCount) {
                rating = (5 * state.productReport.fiveStarsRatesCount + 4 * state.productReport.fourStarsRatesCount +
                    3 * state.productReport.threeStarsRatesCount + 2 * state.productReport.twoStarsRatesCount +
                    1 * state.productReport.oneStarRatesCount) / state.productReport.commentsCount;
            }
            state.productAvgRate = Math.round(rating);
        },
        updateProductRatingList(state, payload) {
            state.productRating = payload;
        },
        updatePartnerRatingList(state, payload) {
            state.partnerRating = payload;
        },
        updatePartnerReport(state, payload) {
            state.partnerReport = payload;
        },
    },
    state: {
        api: process.env.MIX_API_GATE_MARKET,
        filters: null,
        categories: [],
        products: {
            data: [],
        },
        productsSearch: [],
        product: null,
        topCategories: [],
        marketplaceLoadStatus: true,
        formFilter: {
            Page: 1,
            PageSize: 20,
            includePriceLimits: true,
            tagsIds: [],
            sortings: [{sortingType: 3}],
            priceFrom: null,
            priceTo: null,
            categoryId: null,
            filters: null,
        },
        mobilePopups: {
            isOpenSort: false,
            isOpenFilter: false,
        },
        productRating: null,
        productReport: null,
        productAvgRate: null,
        partnerRating: null,
        partnerReport: null,
        blocks: [],
        blocksHardCode: [
            {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b1",
                "order": 1,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 1,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "a5239905-4df9-4f9d-804d-cedcb06e019b",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/banner-1.jpg",
                        "imgPathMobile": "/storage/images/marketplace/banner-1_mobile.jpg",
                        "url": "/",
                        "title": "",
                        "categoryId": 9,
                    }, {
                        "id": "a5239905-4df9-4f9d-804d-cedcb06e019b",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/banner-2.jpg",
                        "imgPathMobile": "/storage/images/marketplace/banner-2_mobile.jpg",
                        "url": "/",
                        "title": "",
                        "categoryId": 12,
                    }, {
                        "id": "a5239905-4df9-4f9d-804d-cedcb06e019b",
                        "order": 3,
                        "imgPath": "/storage/images/marketplace/banner-3.jpg",
                        "imgPathMobile": "/storage/images/marketplace/banner-3_mobile.jpg",
                        "url": "/",
                        "title": "",
                        "categoryId": 2404,
                    }, {
                        "id": "a5239905-4df9-4f9d-804d-cedcb06e019b",
                        "order": 4,
                        "imgPath": "/storage/images/marketplace/banner-4.jpg",
                        "imgPathMobile": "/storage/images/marketplace/banner-4_mobile.jpg",
                        "url": "/",
                        "title": "",
                        "categoryId": 2414,
                    },]
                }, {
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/blocks/370х284.png",
                        "url": "/market/hits",
                        "title": "Чемоданы"
                    }]
                }]
            },
            {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b2",
                "order": 2,
                "haveShowMoreBtn": false,
                "title": "Выгодные предложения",
                "allItemsUrl": "/",
                "allItemsText": "Все предложения",
                "icon": "/storage/images/marketplace/icons/flash-sale.svg",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [21186, 20920, 20288, 20973, 21189, 21387],
                    "promotionsIds": [],
                    "mobileSlider": true,
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b3",
                "order": 3,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/blocks/Samsonite.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/Samsonite.png",
                        "url": "/market/search?brand=873&brandName=ООО%20\"САМСОНАЙТ\"&shop=Samsonite",
                    }, {
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/Arnebia.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/Arnebia.png",
                        "url": "/market/search?brand=358&brandName=Арнебия&shop=Arnebiya_OOO",
                    }]
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                "order": 4,
                "haveShowMoreBtn": false,
                "title": "Популярные категории",
                "icon": "/storage/images/marketplace/icons/love.svg",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 3,
                    "childrenSize": 0,
                    "categoriesTagsIds": [1, 6, 2396, 2, 9, 7, 5, 151],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b5",
                "order": 5,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "hideMobile": true,
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/370x301.png",
                        "url": "#",
                        "title": "Чемоданы"
                    }]
                }, {
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [20281, 20635, 20887, 21535],
                    "promotionsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b6",
                "order": 6,
                "haveShowMoreBtn": true,
                "title": "Акции недели",
                "icon": "/storage/images/marketplace/icons/star.svg",
                "allItemsUrl": "/",
                "allItemsText": "Все предложения",
                "hidden": true,
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 4,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": []
                }],
            },
            {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b3",
                "order": 7,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/blocks/750x233-1.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/288х134.png",
                        "url": "/market/Krasota_i_zdorovje/BAD_i_vitaminy/Vitaminy",
                    }, {
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/750x233-2.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/288х134-1.png",
                        "url": "/market/Sumki_i_ryukzaki/Sumki?price=1500|25000&filters=9ab35a8b-8826-44b9-a361-6c7a96f1d176:338aae75-a498-4f0b-af85-c35ad17e2add",
                    }]
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b8",
                "order": 8,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/Suitcases.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/Suitcases.png",
                        "url": "/market/Bagazh/Chemodany?filters=b1161e19-3445-4fd2-9929-3eacbae28297:40c5da30-7c61-48aa-99ea-5aae510ef105,c03b8a68-6605-4981-af18-8ef02b877baf",
                        "title": "Чемоданы"
                    }]
                }, {
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [20763, 21076, 20383, 20756],
                    "promotionsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b9",
                "order": 9,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [20462, 20936, 20289, 20287],
                    "promotionsIds": [],
                }, {
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/bags.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/bags.png",
                        "url": "/market/Sumki_i_ryukzaki/Sumki?filters=f2d9d6c0-2481-492e-8be9-6dcbfeb62f9b:dc85287a-f539-48ec-a7cf-fe4142a45bc0,ae9658a5-8ca3-4b5d-a887-9de98528cc72|cb7cc319-3b42-4874-bda5-b31373d41b90:2431",
                        "title": "Чемоданы"
                    }]
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f710",
                "order": 10,
                "haveShowMoreBtn": false,
                "title": "Специальные предложения",
                "icon": "/storage/images/marketplace/icons/discount.svg",
                "allItemsUrl": "/",
                "allItemsText": "Перейти в категорию",
                "background": ['#EDFAF9', '#FFFCE6', '#FDECEC', '#EDF1FE'],
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/370x626.png",
                        "url": "#",
                        "title": "Чемоданы",
                        "categoryId": 9,
                    }]
                }, {
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [21576, 21403, 21566, 21447, 21554, 21425, 21652, 21063],
                    "promotionsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f711",
                "order": 11,
                "haveShowMoreBtn": false,
                "title": "Возможно, Вам понравится",
                "icon": "/storage/images/marketplace/icons/heart.svg",
                "allItemsUrl": "/market/hits",
                "allItemsText": "Все предложения",
                "allItemsDown": true,
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 2,
                    "childrenSize": 1,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [21369, 21020, 20949, 21557, 20831, 20837, 20731, 21368],
                    "promotionsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f712",
                "order": 12,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "hideMobile": true,
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/blocks/1170х104.jpg",
                        "url": "/weekendtour",
                        "title": "Чемоданы"
                    }]
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f713",
                "order": 13,
                "haveShowMoreBtn": false,
                "title": "Недавно смотрели",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 2,
                    "viewed": true,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [20540, 20972, 20545, 20547, 21101, 20552],
                    "promotionsIds": [],
                }],
            },
        ],
        blocksHardCodeProd: [
            {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b1",
                "order": 1,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 1,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "a5239905-4df9-4f9d-804d-cedcb06e019b",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/banner-1.jpg",
                        "imgPathMobile": "/storage/images/marketplace/banner-1_mobile.jpg",
                        "url": "/",
                        "title": "",
                        "categoryId": 9,
                    }, {
                        "id": "a5239905-4df9-4f9d-804d-cedcb06e019b",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/banner-2.jpg",
                        "imgPathMobile": "/storage/images/marketplace/banner-2_mobile.jpg",
                        "url": "/",
                        "title": "",
                        "categoryId": 12,
                    }, {
                        "id": "a5239905-4df9-4f9d-804d-cedcb06e019b",
                        "order": 3,
                        "imgPath": "/storage/images/marketplace/banner-3.jpg",
                        "imgPathMobile": "/storage/images/marketplace/banner-3_mobile.jpg",
                        "url": "/",
                        "title": "",
                        "categoryId": 2391,
                    }, {
                        "id": "a5239905-4df9-4f9d-804d-cedcb06e019b",
                        "order": 4,
                        "imgPath": "/storage/images/marketplace/banner-4.jpg",
                        "imgPathMobile": "/storage/images/marketplace/banner-4_mobile.jpg",
                        "url": "/",
                        "title": "",
                        "categoryId": 2397,
                    },]
                }, {
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/blocks/370х284.png",
                        "url": "/market/hits",
                        "title": "Чемоданы"
                    }]
                }]
            },
            {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b2",
                "order": 2,
                "haveShowMoreBtn": false,
                "title": "Выгодные предложения",
                "allItemsUrl": "/",
                "allItemsText": "Все предложения",
                "icon": "/storage/images/marketplace/icons/flash-sale.svg",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [21169, 20920, 20288, 20973, 21172, 21370],
                    "promotionsIds": [],
                    "mobileSlider": true,
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b3",
                "order": 3,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/blocks/Samsonite.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/Samsonite.png",
                        "url": "/market/search?brand=873&brandName=ООО%20\"САМСОНАЙТ\"&shop=Название%20магазина",
                    }, {
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/Arnebia.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/Arnebia.png",
                        "url": "/market/search?brand=398&brandName=ООО%20\"Арнебия\"&shop=Arnebiya",
                    }]
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                "order": 4,
                "haveShowMoreBtn": false,
                "title": "Популярные категории",
                "icon": "/storage/images/marketplace/icons/love.svg",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 3,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b5",
                "order": 5,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "hideMobile": true,
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/370x301.png",
                        "url": "#",
                        "title": "Чемоданы"
                    }]
                }, {
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [20281, 20635, 20887, 21518],
                    "promotionsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b6",
                "order": 6,
                "haveShowMoreBtn": true,
                "title": "Акции недели",
                "icon": "/storage/images/marketplace/icons/star.svg",
                "allItemsUrl": "/",
                "allItemsText": "Все предложения",
                "hidden": true,
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 4,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": []
                }],
            },
            {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b3",
                "order": 7,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/blocks/750x233-1.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/288х134.png",
                        "url": "/market/Krasota_i_zdorovje/BAD_i_vitaminy/Vitaminy",
                    }, {
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/750x233-2.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/288х134-1.png",
                        "url": "/market/Sumki_i_ryukzaki/Sumki?price=1500|25000&filters=9ab35a8b-8826-44b9-a361-6c7a96f1d176:338aae75-a498-4f0b-af85-c35ad17e2add",
                    }]
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b8",
                "order": 8,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/Suitcases.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/Suitcases.png",
                        "url": "/market/Bagazh/Chemodany?filters=b1161e19-3445-4fd2-9929-3eacbae28297:40c5da30-7c61-48aa-99ea-5aae510ef105,c03b8a68-6605-4981-af18-8ef02b877baf",
                        "title": "Чемоданы"
                    }]
                }, {
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [20763, 21076, 20383, 20756],
                    "promotionsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f7b9",
                "order": 9,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [20462, 20936, 20289, 20287],
                    "promotionsIds": [],
                }, {
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/bags.png",
                        "imgPathMobile": "/storage/images/marketplace/blocks/mobile/bags.png",
                        "url": "/market/Sumki_i_ryukzaki/Sumki?filters=f2d9d6c0-2481-492e-8be9-6dcbfeb62f9b:dc85287a-f539-48ec-a7cf-fe4142a45bc0,ae9658a5-8ca3-4b5d-a887-9de98528cc72|cb7cc319-3b42-4874-bda5-b31373d41b90:2431",
                        "title": "Чемоданы"
                    }]
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f710",
                "order": 10,
                "haveShowMoreBtn": false,
                "title": "Специальные предложения",
                "icon": "/storage/images/marketplace/icons/discount.svg",
                "allItemsUrl": "/",
                "allItemsText": "Перейти в категорию",
                "background": ['#EDFAF9', '#FFFCE6', '#FDECEC', '#EDF1FE'],
                "items": [{
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 2,
                        "imgPath": "/storage/images/marketplace/blocks/370x626.png",
                        "url": "#",
                        "title": "Чемоданы",
                        "categoryId": 9,
                    }]
                }, {
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 2,
                    "type": 2,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [21559, 21386, 21549, 21430, 21537, 21408, 21635, 21063],
                    "promotionsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f711",
                "order": 11,
                "haveShowMoreBtn": false,
                "title": "Возможно, Вам понравится",
                "icon": "/storage/images/marketplace/icons/heart.svg",
                "allItemsUrl": "/market/hits",
                "allItemsText": "Все предложения",
                "allItemsDown": true,
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 2,
                    "childrenSize": 1,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [21352, 21020, 20949, 21540, 20831, 20837, 20731, 21351],
                    "promotionsIds": [],
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f712",
                "order": 12,
                "haveShowMoreBtn": false,
                "title": "",
                "items": [{
                    "hideMobile": true,
                    "id": "b942e0ea-56b3-4dce-ab5f-0c2ab01c436e",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 5,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                    "images": [{
                        "id": "d6ffbdc0-69a7-4109-bc11-b1f037c287c6",
                        "order": 1,
                        "imgPath": "/storage/images/marketplace/blocks/1170х104.jpg",
                        "url": "/weekendtour",
                        "title": "Чемоданы"
                    }]
                }],
            }, {
                "id": "70662c35-4aa9-4efb-86c1-c82c23b2f713",
                "order": 13,
                "haveShowMoreBtn": false,
                "title": "Недавно смотрели",
                "items": [{
                    "id": "e47338bc-adbf-4692-9572-2d64f1cdf0da",
                    "blockId": "70662c35-4aa9-4efb-86c1-c82c23b2f7b4",
                    "order": 1,
                    "type": 2,
                    "viewed": true,
                    "childrenSize": 0,
                    "categoriesTagsIds": [],
                    "productsTagsIds": [],
                    "promotionsIds": [],
                }],
            },
        ],
        ratingForm: {
            productId: null,
            rate: null,
            userId: null,
            sortingType: null,
            page: 1,
            pageSize: 10,
        },
        ratingPartnerForm: {
            externalAgentId: null,
            userId: null,
            sortingType: null,
            page: 1,
            pageSize: 10,
            interactionRate: null,
            shippingQualityRate: null,
            shippingTimeRate: null,
        },
    },
    getters: {
        getMobilePopups: state => {
            return state.mobilePopups;
        },
        getMarketBlocks: state => {
            if (!state.blocks && state.blocks.length !== 0) {
                return state.blocks;
            } else {
                if (state.api === 'https://apigate.fstravel.com') {
                    return state.blocksHardCodeProd;
                } else {
                    return state.blocksHardCode;
                }
            }
        },
        getFormFilter: state => {
            return state.formFilter;
        },
        getTopCategories: state => {
            return state.topCategories;
        },
        getFilters: state => {
            return state.filters;
        },
        getCategories: state => {
            return state.categories;
        },
        getProducts: state => {
            return state.products;
        },
        getProductsData: state => {
            return state.products.data;
        },
        getProductsSearch: state => {
            return state.productsSearch;
        },
        getProduct: state => {
            return state.product;
        },
        getMarketplaceLoadStatus: state => {
            return state.marketplaceLoadStatus;
        },
        getMarketplaceProductRatings: state => {
            return state.productRating;
        },
        getMarketplaceProductReport: state => {
            return state.productReport;
        },
        getMarketplaceProductCommentsCount: state => {
            let result = 0;
            if(state.productReport && state.productReport.commentsCount){
                result = state.productReport.commentsCount;
            }
            return result;
        },
        getMarketplaceProductRatingForm: state => {
            return state.ratingForm;
        },
        getMarketplacePartnerRatings: state => {
            return state.partnerRating;
        },
        getMarketplacePartnerRatingForm: state => {
            return state.ratingPartnerForm;
        },
        getMarketplaceReport: state => {
            return state.ratingReport;
        },
        getMarketplaceProductAvgRate: state => {
            return state.productAvgRate;
            // let result = 0;
            // if(state.productReport && state.productReport.averageRate) {
            //     result = state.productReport.averageRate;
            // }
            // return result;
        },
    }
}
