import variables from "../../../plugins/variables";
import axios from 'axios';

export default {
    actions: {
        async getFlightOptions(ctx, payload) {
            const url = variables.routeGetFlightOptions + payload.query
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                if (res.data.validationErrors !== undefined) {
                    return {}
                }
                let data = res.data
                ctx.commit('updateFlightOptions', data);
                return data
            } catch (e) {
                console.log('Ошибка в actions getFlightOptions', e)
                return []
            }
        },
        async getFlightAdditionalServices(ctx, payload) {
            const url = variables.routeGetFlightAdditionalServices + '?id=' + payload
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                if (res.data.validationErrors !== undefined) {
                    return []
                }
                return await res.data.additionalServices
            } catch(e) {
                console.log('Ошибка в actions getFlightAdditionalServices', e)
                return []
            }
        },
        async getFlightInsurances(ctx, payload) {
            const url = variables.routeGetFlightInsurances + '?id=' + payload
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                return res.data
            } catch(e) {
                console.log('Ошибка в actions getFlightInsurances', e)
                return []
            }
        },
        async createAviaOrder(ctx, payload) {
            const url = variables.routeAviaOrderCreate
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                // console.log('booking_result: ', res)
                return res.data
            } catch(e) {
                return {
                    errors: [{
                        error: e,
                        userMessage: 'Ошибка метода createAviaOrder ' + url
                    }]
                }
            }
        },
        updateFlightOptionsSearchData(ctx, payload) {
            ctx.commit('updateFlightOptionsSearchData', payload)
        }
    },
    mutations: {
        updateFlightOptionsSearchData(state, data) {
            state.flightOptionsSearchData = data;
            // console.log('state', state)
        },
        updateFlightOptions(state, data) {
            if (data.ancillaryServices !== null) {
                state.flightOptions = data;
                // console.log('state', state)
            } else {
                state.flightOptions = null;
            }
        },
        updateFlightAdditionalOptions(state, data) {
            if (state.additionalOptions.filter(el => el.id === data.id).length > 0) {
                state.additionalOptions = state.additionalOptions.filter(el => el.id !== data.id)
            } else {
                state.additionalOptions.push(data)
            }
        },
        clearFlightSelectedOptions(state) {
            // чистим текущий выбор и данные в драфте для бронирования
            state.selectedSeats = []
            state.additionalOptions = []
            state.selectedAncillaryServices = []
            state.draftFlightSelectedData.seats = []
            state.draftFlightSelectedData.insuranceProductIds = []
            state.draftFlightSelectedData.additionalServiceIds = []
            state.draftFlightSelectedData.ancillaryKey = null
        },
        updateSelectedPassenger(state, data) {
            state.selectedPassenger = {...data}
        },
        updateSelectedSegment(state, data) {
            state.selectedSegment = {...data}
        },
        updateSelectedSeats(state, data) {
            const idx = state.selectedSeats.findIndex(item => item.personUid === data.personUid && item.segmentUid === data.segmentUid)
            if (idx === -1) {
                state.selectedSeats.push({...data})
            } else {
                let seatNumber = state.selectedSeats[idx].number
                state.selectedSeats.splice(idx, 1)
                if (data.number !== seatNumber) state.selectedSeats.push({...data})
            }
        },
        updateFlightPassengers(state, data) {
            let passengers = {}
            let categoryName = ''
            data.forEach((item, index) => {
                if(categoryName === item.ageCategory) {
                    passengers[categoryName].push(item)
                } else {
                    passengers[item.ageCategory] = [item]
                    categoryName = item.ageCategory
                }
                if(item.ageCategory.toLowerCase() === 'adult') {
                    item.title = passengers[categoryName].length + ' Взрослый';
                } else if(item.ageCategory.toLowerCase() === 'child') {
                    item.title = passengers[categoryName].length + ' Ребенок';
                } else {
                    item.title = passengers[categoryName].length + ' Младенец';
                }
            })

            let adult = passengers['ADULT'] !== undefined ? [...passengers['ADULT']] : []
            let child = passengers['CHILD'] !== undefined ? [...passengers['CHILD']] : []
            let infant = passengers['INFANT'] !== undefined ? [...passengers['INFANT']] : []
            state.flightPassengers = adult.concat(child, infant)
        },
        updateSelectedAncillaryServices(state, data) {
            const idx = state.selectedAncillaryServices.findIndex(item => item.offerKey === data.offerKey)
            if (idx === -1) {
                state.selectedAncillaryServices.push({...data})
            } else {
                let offerKey = state.selectedAncillaryServices[idx].offerKey
                state.selectedAncillaryServices.splice(idx, 1)
                if (data.offerKey !== offerKey) state.selectedAncillaryServices.push({...data})
            }
        },
        updatePassengersInFlightDraft(state, data) {
            const dummyData = [
                {
                    firstName: 'Семен',
                    lastName: 'Горбунков',
                    middleName: 'Семенович',
                    patronymicName: 'Семенович',
                    fullName: 'Семен Семенович Горбунков',
                    dateOfBirth: '1980-01-18',
                    sex: 'Male',
                    documentType: 'PASSPORT',
                    documentInfo: '7700123456',
                    documentExpiryDate: '2028-01-18',
                    citizenship: 'RU'
                },
                {
                    firstName: 'Иван',
                    lastName: 'Грозный',
                    middleName: 'Васильевич',
                    patronymicName: 'Васильевич',
                    fullName: 'Иван Васильевич Грозный',
                    dateOfBirth: '1990-01-18',
                    sex: 'Male',
                    documentType: 'PASSPORT',
                    documentInfo: '7799123456',
                    documentExpiryDate: '2028-01-20',
                    citizenship: 'RU'
                }
            ]
            data.forEach((item, index) => {
                let data = { uid: item.uid, ageCategory: item.ageCategory }
                state.draftFlightSelectedData.passengers.push({...data, ...dummyData[index]})
            })
        },
        updateResponseId(state, data) {
            state.draftFlightSelectedData.checkAvailabilityResponseId = data
        },
        updateInsurancesInFlightDraft(state) {
            let insurances = state.additionalOptions.filter(el => el.type === 'insurance').map(el => el.id)
            state.draftFlightSelectedData.insuranceProductIds = Object.assign([], insurances)
        },
        updateAdditinalServicesInFlightDraft(state) {
            let services = state.additionalOptions.filter(el => el.type === 'additional_service').map(el => el.id)
            state.draftFlightSelectedData.additionalServiceIds = Object.assign([], services)
        },
        updateSelectedSeatsInFlightDraft(state) {
            const seats = state.selectedSeats.map(({ personUid, segmentUid, number, rfisc }) => ({ personUid, segmentUid, number, rfisc }))
            state.draftFlightSelectedData.seats = Object.assign([], seats)
        },
        updateAncillaryServicesInFlightDraft(state) {
            state.draftFlightSelectedData.ancillaryKey = state.selectedAncillaryServices.map(el => el.offerKey).join(',')
        },
    },
    state: {
        flightOptions: null,
        flightOptionsSearchData: { // @see http://tvm-docker-01:28500/swagger/index.html - метод FlightSearch
            id: '6ceab822-e60d-4dc0-ac4d-8c3cb1fbbc32', // responseId
            tariff: 0,  // data/index
            index: 0    // data/groups/flights/index
        },
        additionalOptions: [],
        selectedPassenger: {},
        selectedSegment: {},
        selectedSeats: [],
        flightPassengers: [],
        selectedAncillaryServices: [],
        draftFlightSelectedData: {
            checkAvailabilityResponseId: null,
            upsaleResponseIndex: null,   // null для тарифа по умолчанию
            passengers: [
                // пример объекта пассажира
                // {
                //     uid: 'aac04151-6c6e-4110-8ac7-964472af7a1a',
                //     ageCategory: 'Adult',
                //     firstName: 'Семен',
                //     lastName: 'Горбунков',
                //     middleName: 'Семенович',
                //     patronymicName: 'Семенович',
                //     fullName: 'Семен Семенович Горбунков',
                //     dateOfBirth: '1980-01-18',
                //     sex: 'Male',
                //     documentType: 'PASSPORT',
                //     documentInfo: '7700123456',
                //     documentExpiryDate: '2028-01-18',
                //     citizenship: 'RU'
                // }
            ],
            seats: [],
            insuranceProductIds: [],
            additionalServiceIds: [],
            contactName: 'Семен Семенович Горбунков',
            contactEmail: 'vorontsof@inbox.ru',
            contactPhone: '79991234567',
            salesChannel: 'F&S',
            externalOrder: {
                externalId: null,
                externalCustomer: {
                    firstName: null,
                    lastName: null,
                    patronymic: null,
                    birthDate: null,
                    gender: null,
                    phone: null,
                    email: null,
                    userType: null,
                    externalId: null
                }
            },
            ancillaryKey: null,  // string
            changeAgreementToMailing: {
                isNeedToChange: true,
                value: true
            },
            insuranceAgreement: true
        },
    },
    getters: {
        getFlightOptions(state) {
            return state.flightOptions
        },
        getFlightOptionsQuery(state) {
            if (state.flightOptionsSearchData && typeof(state.flightOptionsSearchData) === 'object') {
                return '?' + Object.keys(state.flightOptionsSearchData).map(key => key + '=' + state.flightOptionsSearchData[key]).join('&');
            } else {
                return null
            }
        },
        getFlightAdditionalOptions(state) {
            return state.additionalOptions
        },
        getSelectedPassenger(state) {
            return state.selectedPassenger
        },
        getSelectedSegment(state) {
            return state.selectedSegment
        },
        getSelectedSeats(state) {
            return state.selectedSeats
        },
        getFlightPassengers(state) {
            return state.flightPassengers
        },
        getSelectedAncillaryServices(state) {
            return state.selectedAncillaryServices
        },
        getDraftFlightSelectedData(state) {
            return state.draftFlightSelectedData
        }
    },
}
