import variables from "../../../plugins/variables"
import axios from "axios"

export default {
    actions: {
        async fetchAgentFITClaims(ctx) {
            const url = variables.routeAgentIssue
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                })
                ctx.commit('updateClaims', res.data)
            } catch (e) {
                console.log('Ошибка в actions fetchAgentFITClaims', e)
            }
        },
        async fetchCreateAgentFITClaims(ctx, payload) {
            const url = variables.routeAgentIssue
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                return res.data;
            } catch (e) {
                console.log('Ошибка в actions fetchCreateAgentFITClaims', e)
            }
        },
        async fetchAgentFITClaimsComment(ctx, payload) {
            const url = variables.routeAgentIssueComments + '/' + payload
            try {
                let res = await axios({
                    url,
                    method: 'GET',
                })
                ctx.commit('updateComments', await res.data)
            } catch (e) {
                console.log('Ошибка в actions fetchAgentFITClaimsComment', e)
            }
        },
        async fetchCreateAgentFITClaimsComment(ctx, payload) {
            const url = variables.routeAgentIssueComments + '/' + payload.id
            try {
                let res = await axios({
                    url,
                    method: 'POST',
                    data: payload.text,
                })
                ctx.commit('addComments', res.data)
            } catch (e) {
                console.log('Ошибка в actions fetchCreateAgentFITClaimsComment', e)
            }
        },
    },
    state: {
        claims: [],
        comments: [],
    },
    mutations: {
        updateClaims(ctx, payload) {
            ctx.claims = payload;
        },
        updateComments(ctx, payload) {
            ctx.comments = payload;
        },
        addComments(ctx, payload) {
            ctx.comments.push(payload);
        },
    },
    getters: {
        getAgentFITClaims: state => {
            return state.claims
        },
        getAgentFITClaimsComments: state => {
            return state.comments
        }
    }
}
