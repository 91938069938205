import variables from "../../plugins/variables";

export default {
    state: {
        GoogleMapConfig: {
            clusterStyles: [
                {
                    width: 30,
                    height: 30,
                    textColor: "white",
                    textSize: 10,
                    url: variables.imgPath + '/country/map/cluster.svg'
                }
            ],
            options: {
                maxZoom: 10,
                minZoom: 2,
                disableDefaultUI: true,
                styles: [
                    {"elementType": "geometry", "stylers": [{"color": "#ffffff"}]},
                    {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]},
                    {"elementType": "labels.text", "stylers": [{"color": "#43438b"}]},
                    {"elementType": "labels.text.stroke", "stylers": [{"visibility": "off"}]},
                    {"featureType": "administrative", "elementType": "geometry", "stylers": [{"visibility": "off"}]},
                    {
                        "featureType": "administrative.country", "elementType": "geometry.stroke",
                        "stylers": [{"visibility": "on"}, {"color": "#d2c9f3"}, {"weight": 0.5}]
                    },
                    {"featureType": "administrative.land_parcel", "stylers": [{"visibility": "off"}]},
                    {"featureType": "administrative.locality", "stylers": [{"visibility": "off"}]},
                    {"featureType": "administrative.neighborhood", "stylers": [{"visibility": "off"}]},
                    {"featureType": "administrative.province", "stylers": [{"visibility": "off"}]},
                    {"featureType": "poi", "stylers": [{"visibility": "off"}]},
                    {"featureType": "road", "stylers": [{"visibility": "off"}]},
                    {"featureType": "transit", "stylers": [{"visibility": "off"}]},
                    {"featureType": "water", "elementType": "geometry.fill", "stylers": [{"color": "#d5e5ee"}]},
                    {"featureType": "water", "elementType": "labels.text", "stylers": [{"color": "#4875be"}]}
                ],
                restriction: {
                    latLngBounds: {
                        north: 85,
                        south: -85,
                        west: -180,
                        east: 180
                    }
                },
            }
        }
    },
    getters: {
        getMapOptions(state) {
            return state.GoogleMapConfig;
        }
    },
    actions: {},
    mutations: {}
}
