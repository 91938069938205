import variables from "../../plugins/variables";
import axios from 'axios';

export default {
    actions: {
        async fetchContent(ctx, payload) {
            let url = variables.routeStaticContent + '/page';
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                    responseType: "json"
                })
                ctx.commit('updateContent', res.data)
            } catch (e) {
                console.log(e.response.data)
                console.log('Ошибка получения ответа от ' + url)
            }
        },
    },
    state: {
        content: {
            title: "",
            link: "",
            alias: "",
            body: "",
            description: "",
            keywords: "",
            template_id: 0,
        },
    },
    mutations: {
        async updateContent(state, content) {
            state.content = await content
        },
    },
    getters: {
        getContent(state) {
            return state.content
        },
    }
}
