export default {
    actions: {

    },
    state: {
       currentPage: 'test'
    },
    mutations: {

    },
    getters: {
        getCurrentPage: state => {
            return state.currentPage
        },
        setCurrentPage: state => currentPage => {
            state.currentPage = currentPage
        }
    }
}
