import variables from "../../../plugins/variables"
import axios from "axios"

export default {
    actions: {
        async fetchPlanGet(ctx, id){
            const url = variables.routePlanFact + '/getall'
            ctx.state.isLoadingPlan = true
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        id,
                    }
                })
                if(await res.data.Status == 'Ok'){
                    ctx.commit('updatePlanFacts', await res.data.BaseAgent)
                }
            }catch(e){
                console.log('Ошибка в actions fetchPlanGet', e)
            }
            ctx.state.isLoadingPlan = false
        },
        async fetchPlanCreate(ctx, data){
            const url = variables.routePlanFact + '/create'
            try{
                let res = await axios({
                    url,
                    method: 'POST',
                    data,
                })
                console.log(await res.data)
                console.log('data', data)
                if(await res.data.Status == 'Ok'){
                    return true
                } else {
                    return false
                }
            }catch(e){
                console.log('Ошибка в actions fetchPlanCreate', e)
            }
        },
        async fetchPlanUpdate(ctx){
            const url = variables.routePlanFact + '/update'
            try{

            }catch(e){
                console.log('Ошибка в actions fetchPlanUpdate', e)
            }
        }
    },
    state: {
        planFacts: [],
        planManagers: [],
        isLoadingPlan: false,
        agencyId: null,
    },
    mutations: {
        updatePlanFacts(state, payload){
            try{
                let planFacts = []
                let managers = []
                //Формирование массива менеджеров
                payload.Managers.forEach(item => {
                    let manager = {
                        ManagerId: null,
                        Name: null,
                        SamoAlias: null,
                        SamoID: null,
                    }
                    manager.ManagerId = item.DnmcsGuid
                    manager.Name = item.Name
                    manager.SamoAlias = item.SamoAlias
                    manager.SamoID = item.SamoID
                    managers.push(manager)
                    //Добавление в массив PlanFacts планы менеджеров
                    if(item.PlanFacts.length > 0){
                        item.PlanFacts.forEach(elem => {
                            let planFact = {
                                DnmcsGuid: null, //ID План-факта в Dynamics 365
                                SamoAlias: null,
                                SamoId: null,
                                Name: null,
                                Month: null,
                                Year: null,
                                addcommission: null,
                                addproductsplanexecutionmonth: null, //Выполнение плана, %
                                addproductsplanexecutionseason: null, //Выполнение плана, %
                                averagesalesdepth: null,
                                avg_comission: null,
                                basecommission: null,
                                factaddproductsmonth: null, //Факт продаж доп услуги (месяц)
                                factaddproductsseason: null, //Факт продаж Доп продукт (YTD)
                                factdollars: null, //Факт продаж (месяц), $
                                factdollarsseason: null, //Факт продаж (YTD), $
                                factpax: null, //Факт продаж (месяц), PAX
                                factseasonpax: null, //Факт продаж (YTD), PAX
                                monthdollarsplanexecution: null, //Выполнение плана, %
                                monthpaxplanexecution: null, //Выполнение плана, %
                                paxrbmonth: null, //Факт продаж по РБ (месяц)
                                paxrbseason: null, //Факт продаж по РБ (YTD)
                                planaddproductsmonth: null, //План продаж доп услуги (месяц)
                                planaddproductsseason: null, //План продаж Доп продукт (YTD)
                                plandollars: null, //План продаж (месяц), $
                                planpax: null, //План продаж (месяц), PAX
                                planseasondollars: null, //План продаж (YTD), $
                                planseasonpax: null, //План продаж (YTD), PAX
                                rbmonthratio: null, //% РБ от общего факта (месяц)
                                rbseasonratio: null, //% РБ от общего факта (YTD)
                                seasondollarsplanexecution: null, //Выполнение плана, %
                                seasonpaxplanexecution: null, //Выполнение плана, %
                            }
                            planFact.ManagerId = item.DnmcsGuid
                            planFact.PlanId = elem.DnmcsGuid
                            planFact.Name = item.Name
                            planFact.SamoAlias = item.SamoAlias
                            planFact.SamoId = item.SamoID
                            planFact.Month = elem.Month
                            planFact.Year = elem.Year
                            planFact.addcommission = elem.addcommission
                            planFact.addproductsplanexecutionmonth = elem.addproductsplanexecutionmonth
                            planFact.addproductsplanexecutionseason = elem.addproductsplanexecutionseason
                            planFact.averagesalesdepth = elem.averagesalesdepth
                            planFact.avg_comission = elem.avg_comission
                            planFact.basecommission = elem.basecommission
                            planFact.factaddproductsmonth = elem.factaddproductsmonth
                            planFact.factaddproductsseason = elem.factaddproductsseason
                            planFact.factdollars = elem.factdollars
                            planFact.factdollarsseason = elem.factdollarsseason
                            planFact.factpax = elem.factpax
                            planFact.factseasonpax = elem.factseasonpax
                            planFact.monthdollarsplanexecution = elem.monthdollarsplanexecution
                            planFact.monthpaxplanexecution = elem.monthpaxplanexecution
                            planFact.paxrbmonth = elem.paxrbmonth
                            planFact.paxrbseason = elem.paxrbseason
                            planFact.planaddproductsmonth = elem.planaddproductsmonth
                            planFact.planaddproductsseason = elem.planaddproductsseason
                            planFact.plandollars = elem.plandollars
                            planFact.planpax = elem.planpax
                            planFact.planseasondollars = elem.planseasondollars
                            planFact.planseasonpax = elem.planseasonpax
                            planFact.rbmonthratio = elem.rbmonthratio
                            planFact.rbseasonratio = elem.rbseasonratio
                            planFact.seasondollarsplanexecution = elem.seasondollarsplanexecution
                            planFact.seasonpaxplanexecution = elem.seasonpaxplanexecution
                            planFacts.push(planFact)
                        })
                    }
                })
                //Добавление в массив с планами план агенства
                payload.PlanFacts.forEach(elem => {
                    let planFact = {
                        DnmcsGuid: null, //ID План-факта в Dynamics 365
                        SamoAlias: null,
                        SamoId: null,
                        Name: null,
                        Month: null,
                        Year: null,
                        addcommission: null,
                        addproductsplanexecutionmonth: null, //Выполнение плана, %
                        addproductsplanexecutionseason: null, //Выполнение плана, %
                        averagesalesdepth: null,
                        avg_comission: null,
                        basecommission: null,
                        factaddproductsmonth: null, //Факт продаж доп услуги (месяц)
                        factaddproductsseason: null, //Факт продаж Доп продукт (YTD)
                        factdollars: null, //Факт продаж (месяц), $
                        factdollarsseason: null, //Факт продаж (YTD), $
                        factpax: null, //Факт продаж (месяц), PAX
                        factseasonpax: null, //Факт продаж (YTD), PAX
                        monthdollarsplanexecution: null, //Выполнение плана, %
                        monthpaxplanexecution: null, //Выполнение плана, %
                        paxrbmonth: null, //Факт продаж по РБ (месяц)
                        paxrbseason: null, //Факт продаж по РБ (YTD)
                        planaddproductsmonth: null, //План продаж доп услуги (месяц)
                        planaddproductsseason: null, //План продаж Доп продукт (YTD)
                        plandollars: null, //План продаж (месяц), $
                        planpax: null, //План продаж (месяц), PAX
                        planseasondollars: null, //План продаж (YTD), $
                        planseasonpax: null, //План продаж (YTD), PAX
                        rbmonthratio: null, //% РБ от общего факта (месяц)
                        rbseasonratio: null, //% РБ от общего факта (YTD)
                        seasondollarsplanexecution: null, //Выполнение плана, %
                        seasonpaxplanexecution: null, //Выполнение плана, %
                    }
                    planFact.Name = payload.Name
                    planFact.SamoID = payload.SamoID
                    planFact.SamoAlias = 'Агентство'
                    planFact.ManagerId = null
                    planFact.PlanId = elem.DnmcsGuid
                    planFact.Month = elem.Month
                    planFact.Year = elem.Year
                    planFact.addcommission = elem.addcommission
                    planFact.addproductsplanexecutionmonth = elem.addproductsplanexecutionmonth
                    planFact.addproductsplanexecutionseason = elem.addproductsplanexecutionseason
                    planFact.averagesalesdepth = elem.averagesalesdepth
                    planFact.avg_comission = elem.avg_comission
                    planFact.basecommission = elem.basecommission
                    planFact.factaddproductsmonth = elem.factaddproductsmonth
                    planFact.factaddproductsseason = elem.factaddproductsseason
                    planFact.factdollars = elem.factdollars
                    planFact.factdollarsseason = elem.factdollarsseason
                    planFact.factpax = elem.factpax
                    planFact.factseasonpax = elem.factseasonpax
                    planFact.monthdollarsplanexecution = elem.monthdollarsplanexecution
                    planFact.monthpaxplanexecution = elem.monthpaxplanexecution
                    planFact.paxrbmonth = elem.paxrbmonth
                    planFact.paxrbseason = elem.paxrbseason
                    planFact.planaddproductsmonth = elem.planaddproductsmonth
                    planFact.planaddproductsseason = elem.planaddproductsseason
                    planFact.plandollars = elem.plandollars
                    planFact.planpax = elem.planpax
                    planFact.planseasondollars = elem.planseasondollars
                    planFact.planseasonpax = elem.planseasonpax
                    planFact.rbmonthratio = elem.rbmonthratio
                    planFact.rbseasonratio = elem.rbseasonratio
                    planFact.seasondollarsplanexecution = elem.seasondollarsplanexecution
                    planFact.seasonpaxplanexecution = elem.seasonpaxplanexecution
                    planFacts.unshift(planFact)
                })
                state.planFacts = planFacts
                state.planManagers = managers
                state.agencyId = payload.DnmcsGuid
            }catch(e){
                console.log('Ошибка в мутации updatePlanFacts', e)
            }
        }
    },
    getters: {
        getPlanFacts: state => {
            return state.planFacts
        },
        getPlanManagers: state => {
            return state.planManagers
        },
        getLoadingPlan: state => {
            return state.isLoadingPlan
        },
        getAgencyId: state => {
            return state.agencyId
        },
    }
}
