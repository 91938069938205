import axios from 'axios';
import variables from "../../plugins/variables";

export default {
    actions: {
        async fetchTourOrderPopup(ctx, payload) {
            let url = variables.routSendLead+'/book-tour';
            try {
                let response = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })

                if (response.status === 200) {
                    ctx.commit('updateTourOrderPopupIsSend')
                }else {
                    ctx.commit('updateTourOrderPopupIsError')
                }
            } catch(e) {
                console.log('Ошибка в actions fetchSendLead',e);
            }
        },
        async fetch404lead(ctx, payload) {
            let url = variables.routSendLead+'/page-404';
            try {
                let response = await axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
                if (response.status === 200) {
                    return true
                }else {
                    return false
                }
            } catch(e) {
                console.log('Ошибка в actions fetch404lead',e);
            }
        },
        async fetchAddReview(ctx, payload) {
            let url = variables.routSendLead+'/add-review';
            try {
                return axios({
                    url,
                    method: 'POST',
                    data: payload,
                })
            } catch(e) {
                console.log('Ошибка в actions fetchAddReview',e);
            }
        },

    },
    mutations: {
        updateTourOrderPopupIsSend(state) {
            state.isError = false
            state.isSend = true
        },
        updateTourOrderPopupIsError(state) {
            state.isError = true
        }
    },
    state: {
        isSend: false,
        isError: false,
    },
    getters: {
        getTourOrderPopupIsSend: state => {
            return state.isSend
        },
        getTourOrderPopupIsError: state => {
            return state.isError
        }
    }
}
