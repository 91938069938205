import variables from "../../../plugins/variables";
import axios from "axios";

export default {
    actions: {
        async fetchAgency(ctx, secondRequest=true) {
            //secondRequest разрешает повторный запрос, если в state по агентсву уже есть данные
            if(Object.values(ctx.state.agency).length > 0 && !secondRequest) return
            let url = variables.routeAgency
            try {
                const res = await axios({
                    url,
                    method: 'GET'
                })
                const agency = await res.data
                ctx.commit('updateAgency', agency)
            } catch(e) {
                console.log('Ошибка в action fetchAgency', e)
            }
        },
        async fetchAgencyUpdate(ctx) {
            let url = variables.routeAgency
            try {
                const res = await axios({
                    url,
                    method: 'PATCH',
                    data: ctx.state.agencyChange
                })
                if (await res.status === 200) {
                    ctx.commit('updateDifferent')
                } else {
                    console.log('errors', await res.data.ValidationErrors)
                }
            } catch(e) {
                console.log('Ошибка в action fetchAgencyUpdate', e)
            }
        },
        async fetchAgencyReturn(ctx){
            let url = variables.routeAgency
            ctx.state.isLoadChanged = false
            try {
                const res = await axios({
                    url,
                    method: 'PATCH',
                    data: ctx.state.agency
                })
                if (await res.status === 200) {
                    ctx.state.agencyChange = {...ctx.state.agency}
                    ctx.commit('updateDifferent')
                } else {
                    console.log('errors', await res.data.ValidationErrors)
                }
            } catch(e) {
                console.log('Ошибка в action fetchAgencyReturn', e)
            }
        },
        async fetchAgencyRegistration(ctx,agentData){
            let result = null
            let url = variables.routeSignUp
            try{
                const res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        "role":"agency",
                        "addAgentInfoRequest":ctx.state.newAgency,
                        "addAgentUserRequest": agentData
                    }
                }).then(response=>{
                    result = true
                }).catch(err=>{
                    if (err.response) {
                        // client received an error response (5xx, 4xx)
                        result = err.response.data.validationErrors
                    } else if (err.request) {
                        // client never received a response, or request never left
                    } else {
                        // anything else
                    }
                })
                return result
            }catch(e){
                console.log('Ошибка в action fetchAgencyRegistration', e)
            }
        },
        async fetchPartnerRegistration(ctx, partnerInfo){
            let result = null
            let url = variables.routeSignUp
            let data = ctx.state.newAgency
            data.role = "partner"
            try{
                const res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        role: 'partner',
                        data:{
                            partner:ctx.state.newAgency,
                            manager:partnerInfo
                        },
                    }
                })
                if(await res.status == 200){
                    result = true
                }
                if(await res.status == 203){
                    result = await res.data.validationErrors
                }
                return result
            }catch(e){
                console.log('Ошибка в action fetchAgencyRegistration', e)
            }
        },
        async fetchAgencyImportToTTE(ctx, payload) {
            let url = variables.routeAgencyImport + '/import'
            try {
                const res = await axios({
                    url,
                    method: 'POST',
                    data: {
                        "partnerId": payload.partnerId,
                        "partPassId": payload.partPassId,
                        "agentTitle": payload.agentTitle,
                        "userName": payload.userName,
                    }
                })
                const result = await res.data
                // console.log(result)
                ctx.commit('updateAgencyImport', result)
            } catch(e) {
                console.log('Ошибка в action fetchAgencyImportToTTE', e)
            }
        },
        async fetchAgencyImportStatus(ctx) {
            let url = variables.routeAgencyImport + '/check-status'
            try {
                const res = await axios({
                    url,
                    method: 'GET',
                })
                const result = await res.data
                // console.log(result);
                return result.items
                ctx.commit('updateAgencyImportStatus', result)
            } catch(e) {
                console.log('Ошибка в action fetchAgencyImportStatus', e)
            }
        }
    },
    state: {
        agencyChange: {},
        agency: {},
        newAgency: {
            factAddress: null,
            name: null,
            officialName: null,
            inn: null,
            kpp: null,
            ogrn: null,
            registrationDate: null,
            taxationId: null,
            boss: null,
            directorPositionId: 1,
            worker: null,
            comment: null,
            ownershipId: null,
            activityId: null,
            metro: null,
            phone2: null,
            fax: null,
            fax1: null,
            icq: null,
            icq1: null,
            latinName: null,
            webSite: null,
            direction: null,
            license: null,
            rsv: null,
            registryNumber: null,
            address: null,
            categoryId: null,
            townId: null,
            addressCode: null,
            pAddress: null,
            metroStationId: null,
            phonePrefix: null,
            phone: null,
            phone1: null,
            email: null,
            email1: null,
            accountName: null,
            accountEmail: null,
            accountPhone: null,
            owner: null,
            rs: null,
            bankId: '660',
            okpo: null,
            okonh: null,
            registrationOrgan: null,
            registrationSerie: null,
            registrationNumber: null,
            latitude: null,
            longitude: null,
            emailing:false
        },
        isAgentInfoDifferent: false,
        isLoadChanged: false, //Переключается в true когда в changedInfo в первый раз загружается информация о пользователе,

    },
    mutations: {
        updateAgency(state, agencyInfo) {
            // console.log('agencyInfo', agencyInfo)
            try {
                let changedInfo = {
                    id: null,
                    name:null,
                    accountEmai: null,
                    accountName: null,
                    accountPhone: null,
                    activity: {
                        id: null,
                        title: null,
                        titleLatin: null,
                    },
                    activityId: null,
                    taxation: {
                        id: null,
                        title: null,
                        titleLatin: null,
                    },
                    taxationId: null,
                    inn: null,
                    kpp: null,
                    ogrn: null,
                    registrationDate: null,
                    latinName: null,
                    officialName: null,
                    address: null,
                    addressCode: null,
                    pAddress: null,
                    fAddress: null,
                    direction: null,
                    latitude: null,
                    longitude: null,
                    phonePrefix: null,
                    phone: null,
                    phone1: null,
                    phone2: null,
                    fax: null,
                    fax1: null,
                    email: null,
                    email1: null,
                    website: null,
                    license: null,
                    registrationSerie: null,
                    registrationNumber: null,
                    registryNumber: null,
                    rs: null,
                    rsv: null,
                    okonh: null,
                    okpo: null,
                    boss: null,
                    worker: null,
                    ownership: {
                        id: null,
                        title: null,
                        titleLatin: null,
                    },
                    ownershipId: null,
                    headPartnerId: null,
                    town: {
                        id: null,
                        title: null,
                        titleLatin: null,
                    },
                    townId: null,
                    bank: {
                        id: null,
                        title: null,
                        titleLatin: null
                    },
                    bankId: null,
                    directorPosition: {
                        id: null,
                        title: null,
                        titleLatin: null
                    },
                    directorPositionId: null,
                    registrationOrgan: null,
                }
                changedInfo.id = agencyInfo.id
                changedInfo.name = agencyInfo.name
                changedInfo.accountName = agencyInfo.accountName
                changedInfo.accountEmail = agencyInfo.accountEmail
                changedInfo.accountPhone = agencyInfo.accountPhone
                if(agencyInfo.activity){
                    changedInfo.activity = agencyInfo.activity
                    changedInfo.activityId = agencyInfo.activity.id
                }

                if(agencyInfo.directorPosition){
                    changedInfo.directorPosition = agencyInfo.directorPosition
                    changedInfo.directorPositionId = changedInfo.directorPosition.id
                }
                changedInfo.inn = agencyInfo.inn
                changedInfo.kpp = agencyInfo.kpp
                changedInfo.okonh = agencyInfo.okonh
                changedInfo.ogrn = agencyInfo.ogrn
                if(agencyInfo.taxation){
                    changedInfo.taxation = agencyInfo.taxation
                    changedInfo.taxationId = agencyInfo.taxation.id
                }
                changedInfo.latinName = agencyInfo.latinName
                changedInfo.officialName = agencyInfo.officialName
                changedInfo.address = agencyInfo.address
                changedInfo.addressCode = agencyInfo.addressCode
                changedInfo.pAddress = agencyInfo.pAddress
                changedInfo.fAddress = agencyInfo.fAddress
                changedInfo.direction = agencyInfo.direction
                changedInfo.latitude = agencyInfo.latitude
                changedInfo.longitude = agencyInfo.longitude
                changedInfo.phonePrefix = agencyInfo.phonePrefix
                changedInfo.phone = agencyInfo.phone
                changedInfo.phone1 = agencyInfo.phone1
                changedInfo.phone2 = agencyInfo.phone2
                changedInfo.fax = agencyInfo.fax
                changedInfo.fax1 = agencyInfo.fax1
                changedInfo.email = agencyInfo.email
                changedInfo.email1 = agencyInfo.email1
                changedInfo.website = agencyInfo.website
                changedInfo.license = agencyInfo.license
                changedInfo.registrationDate = agencyInfo.registrationDate
                changedInfo.registrationSerie = agencyInfo.registrationSerie
                changedInfo.registrationNumber = agencyInfo.registrationNumber
                changedInfo.registryNumber = agencyInfo.registryNumber
                changedInfo.registrationOrgan = agencyInfo.registrationOrgan
                changedInfo.rs = agencyInfo.rs
                changedInfo.rsv = agencyInfo.rsv
                changedInfo.okpo = agencyInfo.okpo
                changedInfo.boss = agencyInfo.boss
                changedInfo.worker = agencyInfo.worker
                if(agencyInfo.ownership){
                    changedInfo.ownership = agencyInfo.ownership
                    changedInfo.ownershipId = changedInfo.ownership.id
                }
                changedInfo.headPartnerId = agencyInfo.headPartnerId
                if(agencyInfo.town){
                    changedInfo.town = agencyInfo.town
                    changedInfo.townId = agencyInfo.town.id
                }


                if(agencyInfo.bank){
                    changedInfo.bank = agencyInfo.bank
                    changedInfo.bankId = agencyInfo.bank.id
                }

                state.agencyChange = changedInfo

                //Прописываем стейт при первоначальной загрузке
                if (state.isLoadChanged === false) {
                    // Сохраняем первоначальное состояние в agencyInfo
                    state.agency = {...changedInfo}
                    state.isLoadChanged = true
                }

            } catch(e) {
                console.log('Ошибка в мутации updateAgency', e)
            }
        },
        updateDifferent(state){
            //Проверка на совпадение изначальных данных текущим
            state.isAgentInfoDifferent = JSON.stringify(state.agencyChange) !== JSON.stringify(state.agency);
        },
        updateAgencyImport(state) {

        },
        updateAgencyImportStatus(state) {

        },
    },
    getters: {
        getAgency(state) {
            return state.agency
        },
        getAgencyChange(state) {
            return state.agencyChange
        },
        getIsAgentInfoDifferent(state){
            return state.isAgentInfoDifferent
        },
        getNewAgency: state => {
            return state.newAgency
        }
    }
}
