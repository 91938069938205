import axios from 'axios';
import variables from "../../plugins/variables";

export default {
    namespaced:true,
    actions: {
        async fetchSendFeedback({ dispatch, commit, getters, rootGetters }, data){
            const url = `${variables.routSender}/message/feedback`
                return axios({
                    url,
                    params:data.captcha,
                    method: 'post',
                    data: data.formdata,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },

                });

        },
        async fetchSendLegal({ dispatch, commit, getters, rootGetters },data){
            const url = `${variables.routSender}/message/legal`
            try {
                let response = null

                return axios({
                    url,
                    params:data.captcha,
                    method: 'post',
                    data: data.formdata,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },

                });
            } catch(e) {
                console.log('Ошибка в actions fetchSendLegal',e);
            }

        },

        async fetchSendRawMail({ dispatch, commit, getters, rootGetters },data){
            // Email - куда отправлять
            // Subject - тема письма
            // Header
            // Text - текст письма
            // Footer
            // Signature
            // Html - верстка письма
            // From - ставится в копию
            // Attachments - файлы
            const url = `${variables.routSender}/message/raw`
            try {
                return  axios({
                    url,
                    params:data.captcha,
                    method: 'post',
                    data: data.formdata,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },

                })
            } catch(e) {
                console.log('Ошибка в actions fetchSendRawMail',e);
            }

        },


        async fetchFeedbackCategories({ dispatch, commit, getters, rootGetters }){
          const url = `${variables.routSender}/attributes/FeedbackCategories`
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                })
                if (response.status === 200){
                    commit('updateFeedbackCategories',response.data)
                }
            } catch(e) {
                console.log('Ошибка в actions fetchFeedbackCategories',e);
            }

        },
        async fetchLegalServices({ dispatch, commit, getters, rootGetters }){
            const url = `${variables.routSender}/attributes/LegalServices`
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                })
                if (response.status === 200){
                    commit('updateLegalServices',response.data)
                }
            } catch(e) {
                console.log('Ошибка в actions fetchLegalServices',e);
            }

        },
        async fetchLegalSubjects({ dispatch, commit, getters, rootGetters }){
            const url = `${variables.routSender}/attributes/LegalSubjects`
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                })
                if (response.status === 200){
                    commit('updateLegalSubjects',response.data)
                }
            } catch(e) {
                console.log('Ошибка в actions fetchLegalSubjects',e);
            }

        },
        async fetchApplicants({ dispatch, commit, getters, rootGetters }){
            const url = `${variables.routSender}/attributes/Applicants`
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                })
                if (response.status === 200){
                    commit('updateApplicants',response.data)
                }
            } catch(e) {
                console.log('Ошибка в actions fetchApplicants',e);
            }

        },
        async fetchCountries({ dispatch, commit, getters, rootGetters }){
            const url = `${variables.routSender}/attributes/Countries`
            try {
                let response = await axios({
                    url,
                    method: 'GET',
                })
                if (response.status === 200){
                    commit('updateCountries',response.data)
                }
            } catch(e) {
                console.log('Ошибка в actions fetchCountries',e);
            }

        }


    },
    mutations: {
        updateFeedbackCategories(state, data){
            state.feedbackCategories = data
        },
        updateLegalServices(state, data){
            state.legalServices = data
        },
        updateLegalSubjects(state, data){
            state.legalSubjects = data
        },
        updateApplicants(state, data){
            state.applicants = data
        },
        updateCountries(state, data){
            state.countries = data
        }
    },
    state: {
        feedbackCategories:[],
        legalServices:[],
        legalSubjects:[],
        applicants:[],
        countries:[],

    },
    getters: {
        getLegalServices: state => {
           return state.legalServices
        },
        getLegalSubjects: state => {
            return state.legalSubjects
        },
        getFeedbackCategories: state => {
            return state.feedbackCategories
        },
        getApplicants: state => {
            return state.applicants
        },
        getCountries: state => {
            return state.countries
        }
    }
}
