
export default {
    actions: {
        // async fetchAviaTicketSubjects(ctx) {
        //     const url = variables.routeGetAviaTicketSubjects
        //     try {
        //         const res = await axios({
        //             url,
        //             method: 'GET'
        //         })
        //         console.log('res', res)
        //         let order = await res.data
        //         ctx.commit('updateTicketSubjects', order)
        //         return true
        //     } catch {
        //         console.log('Ошибка в actions fetchAviaTicketSubjects')
        //     }
        // },
        // async fetchAviaTicketStatuses(ctx) {
        //     const url = variables.routeGetAviaTicketStatuses
        //     try {
        //         const res = await axios({
        //             url,
        //             method: 'GET'
        //         })
        //         console.log('res', res)
        //         let order = await res.data
        //         ctx.commit('updateTicketStatuses', order)
        //         return true
        //     } catch {
        //         console.log('Ошибка в actions fetchAviaTicketStatuses')
        //     }
        // },
        showAppealDetails(ctx, status){
            ctx.commit('updateShowAppealDetails', status)
        },
    },
    state: {
        // ticketSubjects: [],
        // ticketStatuses: [],
        showAppealDetails: false
    },
    mutations: {
        // updateTicketSubjects(state, payload) {
        //     state.ticketSubjects = payload
        // },
        // updateTicketStatuses(state, payload) {
        //     state.ticketStatuses = payload
        // },
        updateShowAppealDetails(state, payload) {
            state.showAppealDetails = payload
        }
    },
    getters: {
        getAppealDetailsStatus(state) {
            return state.showAppealDetails
        }
        // getOrders(state) {
        //     return state.orders
        // },
        // getFlightWayBackDetailsById: state => id => {
        //     const order = state.orders.find(order => order.id === id)
        //     return order.flightDetails.orderPositions[0].segmentGroups[1]
        // },

    }
}
