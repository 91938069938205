import variables from "../../plugins/variables"
import axios from "axios"
import helpers from "../../plugins/helpers";
export default {
    actions: {
        async fetchGeoObjectByEbgNodeId(ctx, id) {
            console.error("fetchGeoObjectByEbgNodeId Method deprecated");
            //Не разрешается несколько параллельных запросов
            //т.к. они могут быть одинаковыми
            await Promise.all(ctx.state.geoObjectPromiseContainer);
            ctx.state.counter++;
            let ids = new Set(ctx.state.geoObjectIds);

            if (!ids.has(id)) {
                const url = variables.routeGeoObjectByEbgNodeId + id;
                const object = axios({
                    url,
                    method: "GET",
                });
                ctx.state.geoObjectPromiseContainer.push(object);

                ids.add(id);
                ctx.state.geoObjectIds = [...ids];
            }

            let results = [];
            let container = await Promise.all(
                ctx.state.geoObjectPromiseContainer
            );

            container.forEach((elem) => {
                if (elem && elem.data) results.push(elem.data);
            });

            // console.log('counter', ctx.state.counter)

            return results.find((el) => el.ebgNodeId === +id);
        },
        async fetchGeoObjectByEbgNodeIdAndType(ctx, payload) {
            // let payload = {
            //     id: null,
            //     objectType: null,
            // }
            if (payload.objectType === "region") payload.objectType = "resort";
            //Не разрешается несколько параллельных запросов
            //т.к. они могут быть одинаковыми
            await Promise.all(ctx.state.geoObjectPromiseContainer);
            ctx.state.counter++;
            let ids = new Set(ctx.state.geoObjectIds);

            if (!ids.has(payload.id)) {
                let url = variables.routeGeoObjectByEbgNodeId + payload.id;

                if (payload.objectType) {
                    url = url + "/" + payload.objectType;
                } else {
                    console.error("sssss objectType не передан");
                }

                const object = axios({
                    url,
                    method: "GET",
                });
                ctx.state.geoObjectPromiseContainer.push(object);

                ids.add(payload.id);
                ctx.state.geoObjectIds = [...ids];
            }

            let results = [];
            let container = await Promise.all(
                ctx.state.geoObjectPromiseContainer
            );

            container.forEach((elem) => {
                if (elem && elem.data) results.push(elem.data);
            });

            // console.log('counter', ctx.state.counter)

            return results.find((el) => el.ebgNodeId === +payload.id);
        },
        async fetchGeoObjectByEbgNodeIdBasket(ctx, id) {
            // не менять, не удалять
            console.error("fetchGeoObjectByEbgNodeIdBasket Method deprecated");

            const url = variables.routeGeoObjectByEbgNodeId + id;
            const object = await axios({
                url,
                method: "GET",
            });
            return object.data;
        },
        async fetchGeoObjectByEbgNodeIdBasketAndType(ctx, payload) {
            // не менять, не удалять
            // let payload = {
            //     id: null,
            //     objectType: null,
            // }
            if (payload.objectType === "region") payload.objectType = "resort";
            let url = variables.routeGeoObjectByEbgNodeId + payload.id;

            if (payload.objectType) {
                url = url + "/" + payload.objectType;
            } else {
                console.error("sssss objectType не передан");
            }

            const object = await axios({
                url,
                method: "GET",
            });
            return object.data;
        },

        async fetchHotelByUrlWithDescr(ctx, id) {
            const url = variables.routeHotelByUrlWithDescr + "/" + id;
            const object = await axios({
                url,
                method: "POST",
            });
            return object.data;
        },
        async fetchGeoObjectById(ctx, id) {
            console.error("fetchGeoObjectById Method deprecated");
            const url = variables.routeGeoObjectByEbgNodeId + id;
            const object = await axios({
                url,
                method: "POST",
            });
            return object.data;
        },
        async fetchGeoObjectByIdAndType(ctx, payload) {
            // let payload = {
            //     id: null,
            //     objectType: null,
            // }
            if (payload.objectType === "region") payload.objectType = "resort";
            let url = variables.routeGeoObjectByEbgNodeId + payload.id;

            if (payload.objectType) {
                url = url + "/" + payload.objectType;
            } else {
                console.error("sssss objectType не передан");
            }

            const object = await axios({
                url,
                method: "POST",
            });
            return object.data;
        },

        async fetchGeoCountries(ctx) {
            const url = variables.routeCountries;
            try {
                const res = await axios({
                    url,
                    method: "GET",
                });
                if ((await res.status) == 200) {
                    ctx.commit("updateGeoCountries", await res.data);
                } else {
                    console.log("Ошибочный статус fetchGeoCountries");
                }
            } catch (e) {
                console.log("Ошибка в actions fetchGeoCountries", e);
            }
        },
        async fetchGeoCityByCountries(ctx, countryId, usePreloader = false) {
            //POST - по ebg
            //GET - по uuid
            if (usePreloader) ctx.state.isLoadingCityByCountries = true;

            const url =
                variables.routeGeo + "/city/byCountryId?id=" + countryId;
            try {
                let res = await axios({
                    url,
                    method: "GET",
                });
                return await res.data;
            } catch (e) {
                console.log("Ошибка в actions fetchGeoCityByCountries", e);
            }
            ctx.state.isLoadingCityByCountries = false;
        },
        async fetchGeoCityByCountriesEbg(ctx, countryId, usePreloader = false) {
            //POST - по ebg
            //GET - по uuid
            if (usePreloader) ctx.state.isLoadingCityByCountries = true;
            let result = [];

            const url =
                variables.routeGeo + "/city/byCountryId?id=" + countryId;
            try {
                let res = await axios({
                    url,
                    method: "POST",
                });
                let cities = await res.data;
                if (Array.isArray(Object.values(cities))) {
                    result = Object.values(cities);
                }
            } catch (e) {
                console.log("Ошибка в actions fetchGeoCityByCountries", e);
            }
            ctx.state.isLoadingCityByCountries = false;
            return result;
        },
        async fetchGetCountryGeoObjByCountryName(ctx, countryName) {
            const url = variables.routeGeo + "/search";
            try {
                let res = await axios({
                    url,
                    params: {
                        value: countryName,
                    },
                });
                let objArr = [];
                await res.data.forEach((item) => {
                    let arr = helpers.parseTree(item, "children");
                    objArr.push.apply(objArr, arr);
                });

                console.log("objArr", objArr);

                let country = objArr.find((item) => {
                    if (
                        item.objectType == "Country" &&
                        item.localizedName == countryName
                    ) {
                        return true;
                    } else if (
                        item.objectType == "Country" &&
                        item.standardizedName == countryName
                    ) {
                        return true;
                    }
                });

                return country ? country : null;
            } catch (e) {
                console.log(
                    "Ошибка в actions fetchGetCountryGeoObjByCountryName",
                    e
                );
            }
        },
        async fetchGetCountryGeoObjByCityName(ctx, cityName) {
            const url = variables.routeGeo + "/search";
            try {
                let res = await axios({
                    url,
                    params: {
                        value: cityName,
                    },
                });
                let objArr = [];
                let data = await res.data;

                data.forEach((item) => {
                    let arr = helpers.parseTree(item, "children");
                    objArr.push.apply(objArr, arr);
                });

                let city = objArr.find((item) => {
                    if (
                        item.objectType == "City" &&
                        item.localizedName == cityName
                    ) {
                        return true;
                    } else if (
                        item.objectType == "City" &&
                        item.standardizedName == cityName
                    ) {
                        return true;
                    }
                });

                //Если город не найден, поиск по курортам
                if (!city) {
                    city = objArr.find((item) => {
                        if (
                            item.objectType == "Resort" &&
                            item.localizedName == cityName
                        ) {
                            return true;
                        } else if (
                            item.objectType == "Resort" &&
                            item.standardizedName == cityName
                        ) {
                            return true;
                        }
                    });
                }

                let country = objArr.find(
                    (item) =>
                        item.id == city.parentId && item.objectType == "Country"
                );

                if (!country) {
                    let intermediate = objArr.find(
                        (item) => item.id == city.parentId
                    );
                    country = objArr.find(
                        (item) =>
                            item.id == intermediate.parentId &&
                            item.objectType == "Country"
                    );
                }

                return country ? country : null;
            } catch (e) {
                console.log(
                    "Ошибка в acitons fetchGetCountryGeoObjByCityName",
                    e
                );
            }
        },
        //Метод возвращает массив с одним значением
        //Deprecated (неполные данные, плохая реализация)
        //Новый метод fetchGetCityObjByCityEbg
        async fetchGetCityGeoObjByCityEbg(ctx, id) {
            const url = variables.routeGeo + "/childs/" + id + "/city";
            let result = [];
            let uniqueCityEbg = new Set();
            try {
                //Сначала смотрим в стайте, не получали-ли ранее информацию по этому городу
                result = ctx.state.geoCities.filter(
                    (geoObj) => geoObj.ebgNodeId === id
                );

                if (!result.length) {
                    let res = await axios({
                        url,
                        method: "POST",
                    });

                    if ((await res.status) == 200) {
                        result = Object.values(await res.data);
                    }
                    //только города с уникальным ebg
                    result = result.filter((item) => {
                        let status = false;
                        if (!uniqueCityEbg.has(item.ebgNodeId)) {
                            status = true;
                        }
                        uniqueCityEbg.add(item.ebgNodeId);
                        return status;
                    });

                    //Формируется массив с полученными городами
                    //Чтобы не отправлять запрос повторно
                    result.forEach((cityObj) => {
                        ctx.state.geoCities.push(cityObj);
                    });
                }

                return result;
            } catch (e) {
                console.log("Ошибка в actions fetchGetCityGeoObjByCityEbg", e);
            }
        },
        async fetchGetCityObjByCityEbg(ctx, id) {
            const url = variables.routeCity + "/" + id;
            let result = null;
            try {
                //Сначала смотрим в стайте, не получали-ли ранее информацию по этому городу
                result = ctx.state.geoCities.find(
                    (geoObj) => geoObj.ebgNodeId === id
                );

                if (!result) {
                    const res = await axios({
                        url,
                    });

                    if (res.status == 200) {
                        result = res.data;
                        ctx.state.geoCities.push(result);
                    }
                }
            } catch (e) {
                console.log("Ошибка в actions fetchGetCityObjByCityEbg" + e);
            }
            return result;
        },
        async fetchGetCityGeoObjByCityName(ctx, cityName) {
            const url = variables.routeGeo + "/search";
            try {
                let res = await axios({
                    url,
                    params: {
                        value: cityName,
                    },
                });
                let objArr = [];
                await res.data.forEach((item) => {
                    let arr = helpers.parseTree(item, "children");
                    objArr.push.apply(objArr, arr);
                });

                let city = objArr.find((item) => {
                    if (
                        item.objectType == "City" &&
                        item.localizedName == cityName
                    ) {
                        return true;
                    } else if (
                        item.objectType == "City" &&
                        item.standardizedName == cityName
                    ) {
                        return true;
                    }
                });

                //Если город не найден, поиск по курортам
                if (!city) {
                    city = objArr.find((item) => {
                        if (
                            item.objectType == "Resort" &&
                            item.localizedName == cityName
                        ) {
                            return true;
                        } else if (
                            item.objectType == "Resort" &&
                            item.standardizedName == cityName
                        ) {
                            return true;
                        }
                    });
                }

                return city ? city : null;
            } catch (e) {
                console.log("Ошика в acitons fetchGetCityGeoObjByCityName", e);
            }
        },
        async fetchGetHotelsByCityEbg(ctx, id) {
            const url = variables.routeGeo + "/hotels-by-city";
            try {
                let res = await axios({
                    url,
                    method: "GET",
                    params: {
                        id,
                    },
                });
                if ((await res.status) == 200) {
                    ctx.commit("updateCityHotels", await res.data);
                    return Object.values(await res.data);
                } else {
                    return [];
                }
            } catch (e) {
                console.log("Ошибка в actions fetchGetHotelsByCityEbg", e);
            }
        },
        async fetchGetHotelsByCityUuid(ctx, cityUuid) {
            const url = variables.routeGeo + "/hotels-by-city";
            try {
                let res = await axios({
                    url,
                    method: "POST",
                    data: {
                        id: cityUuid,
                    },
                });
                if ((await res.status) == 200) {
                    ctx.commit("updateCityHotels", await res.data);
                    console.log(Object.values(await res.data));
                    return Object.values(await res.data);
                } else {
                    return [];
                }
            } catch (e) {
                console.log("Ошибка в actions fetchGetHotelsByCityUuid", e);
            }
        },
        async fetchGetHotelsByCountryEbg(ctx, id) {
            const url = variables.routeGeo + "/childs/" + id + "/hotel";
            let result = [];
            let uniqueHotelEbg = new Set();
            try {
                let res = await axios({
                    url,
                    method: "POST",
                });
                if ((await res.status) == 200) {
                    result = Object.values(await res.data);
                }

                //только отели с уникальным ebg
                result = result.filter((item) => {
                    let status = false;
                    if (!uniqueHotelEbg.has(item.ebgNodeId)) {
                        status = true;
                    }
                    uniqueHotelEbg.add(item.ebgNodeId);
                    return status;
                });

                return result;
            } catch (e) {
                console.log("Ошибка в actions fetchGetHotelsByCountryEbg", e);
            }
        },
        async fetchGetHotelByUrl(ctx, friendlyUrl) {
            let url = variables.routeHotelByUrl;
            let res = await axios({
                url,
                method: "POST",
                data: {
                    friendlyUrl: friendlyUrl,
                },
            });
        },
        async fetchObjectByUrl(ctx, objectData) {
            const url = variables.routeObjectByUrl;
            const objectType = objectData.objectType;
            const friendlyUrl = objectData.friendlyUrl;
            let result = [];
            try {
                let res = await axios({
                    url,
                    method: "POST",
                    data: {
                        objectType: objectType,
                        friendlyUrl: friendlyUrl,
                    },
                });
                if ((await res.status) == 200) {
                    result = await res.data;
                }

                return result;
            } catch (e) {
                console.log("Ошибка в actions fetchObjectByUrl", e);
            }
        },
        async fetchGetParentsByEbgNodeId(ctx, id) {
            console.error("fetchGetParentsByEbgNodeId Method deprecated");
            const url = variables.routeGeo + "/parents/" + id;
            let result = [];
            try {
                let res = await axios({
                    url,
                    method: "POST",
                });
                if ((await res.status) == 200) {
                    result = Object.values(await res.data);
                }

                return result;
            } catch (e) {
                console.log("Ошибка в actions fetchGetHotelsByCountryEbg", e);
            }
        },
        async fetchGetParentsByEbgNodeIdAndType(ctx, payload) {
            // let payload = {
            //     id: null,
            //     objectType: null,
            // }
            if (payload.objectType === "region") payload.objectType = "resort";
            let url = variables.routeGeo + "/parents/" + payload.id;
            if (payload.objectType) {
                url = url + "/" + payload.objectType;
            } else {
                console.error("sssss objectType не передан");
            }
            let result = [];
            try {
                let res = await axios({
                    url,
                    method: "POST",
                });
                if ((await res.status) == 200) {
                    result = Object.values(await res.data);
                }

                return result;
            } catch (e) {
                console.log("Ошибка в actions fetchGetHotelsByCountryEbg", e);
            }
        },

        //Получаем массив гео объктов, родителей (не древовидный)
        async fetchParrentArrayByUuid(ctx, uuid) {
            const url = variables.routeParents + uuid;
            try {
                let res = await axios({
                    url,
                });

                let objArr = [];
                await res.data.forEach((item) => {
                    let arr = helpers.parseTree(item, "children");
                    objArr.push.apply(objArr, arr);
                });

                return objArr;
            } catch (e) {
                console.log("Ошибка в actions fetchParrentArrayByUuid", e);
            }
        },
        //Получаем массив гео amenity
        async fetchAmenityList(ctx, hotelRooms){
            if(ctx.state.amenityList.length) return
            try {
                let url = variables.routeGeoAmenityList
                let res = await axios({
                    method: 'GET',
                    url,
                })
                if(Array.isArray(res.data)){
                    ctx.commit('updateAmenityList', res.data)
                    ctx.commit('updateHotelRooms', hotelRooms)
                    ctx.commit('updateMapingAmenityList')
                }
            } catch(e){
                console.log('Ошибка в actions fetchAmenityList', e)
            }
        },
    },
    state: {
        counter: 0,
        geoObjectIds: [],
        geoObjectPromiseContainer: [],
        geoCountries: [],
        geoCities: [],
        isLoadingCityByCountries: false,
        hotelRooms: [],
        amenityList: [],
        mapHotelRooms: [],
    },
    mutations: {
        updateGeoCountries(state, payload) {
            let result = [];
            try {
                for (let key in payload) {
                    const country = {
                        ebgNodeId: null,
                        localizedName: null,
                        standardizedCode: null,
                        standardizedName: null,
                    };
                    country.id = payload[key].id;
                    country.ebgNodeId = payload[key].ebgNodeId;
                    country.localizedName = payload[key].localizedName;
                    country.standardizedCode = payload[key].standardizedCode;
                    country.standardizedName = payload[key].standardizedName;
                    result.push(country);
                }
                state.geoCountries = result;
            } catch (e) {
                console.log("Ошибка в мутации updateGeoCountries", e);
            }
        },
        updateHotelRooms(state, hotelRooms){
            state.hotelRooms = hotelRooms
        },
        updateAmenityList(state, amenityList){
            state.amenityList = amenityList
        },
        updateMapingAmenityList(state) {
            let hotelRooms = new Set(state.hotelRooms)

            hotelRooms.forEach(room => {
                room.amenities = room.amenities.filter(amenity => {
                    let reference = state.amenityList.find(el => el.id === amenity.id)

                    if (reference && amenity.image != null && amenity.name != null) {
                        // amenityModel = {
                        //     id: null,
                        //     imageFile: {},
                        //     name: null,
                        //     payType: null,
                        //     sortOrder: null,
                        // }
                        amenity.image = reference.imageFile
                        amenity.name = reference.name
                        return true
                    }

                    return false
                })
            })

            state.mapHotelRooms = [...hotelRooms]
        }
    },
    getters: {
        getGeoCountries: (state) => {
            return state.geoCountries;
        },
        getIsLoadingCityByCountries: (state) => {
            return state.isLoadingCityByCountries;
        },
        getMapHotelRooms(state) {
            return state.mapHotelRooms
        },
    },
};
