export default {
    actions: {

    },
    state: {
        isShowGlobalLoader: false,
    },
    mutations: {

    },
    getters: {
        setGlobalLoader: state => boolValue => {
            //TODO loader октлючен
            boolValue = false
            state.isShowGlobalLoader = boolValue
        },
        getStatusGlobalLoader: state => {
            return state.isShowGlobalLoader
        }
    }
}
