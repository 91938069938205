import {getProductType, paxDatalayer} from "../../plugins/helpers";
import {convertSamoTourToTour} from "../../plugins/convertObjects.js";

function setGlobalVariables(getters, data) {
    // Заполнение "Глобальных переменных" вынесенно в отдельную функцию для упрощения вноса и коректировки во всех методах сразу
    data.pax = paxDatalayer(); // количество туристов взростые - дети (цифра через дефис)
    data.mainpage = localStorage.getItem('main_page_template');
    data.basketTest = window.basket_template;
    data.searchTourTest = getters.getSearchTourTest;
    data.tour_period = localStorage.getItem('minNightsCount') + '-' + localStorage.getItem('maxNightsCount');
    data.tourCardTest = localStorage.getItem('search-сard-test');
    data.roomType = localStorage.getItem('booking-room-type');
    data.tariff = localStorage.getItem('booking-tariff');
    if (data.tourCardTest === 'new') {
        data.newTourCardContent = localStorage.getItem('tour-сard-content');
    }
    return data;
}
async function js_hash(input_value, salt) {
    // конкат данных и соли
    const concat1 = input_value.concat(salt);
    // Создание sha256
    const textAsBuffer = new TextEncoder().encode(concat1);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
    // sha256 в hex
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const sha256_hex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    // конкат sha256_hex и соли
    const concat2 = sha256_hex.concat(salt)
    // concat2 в sha256_2
    const textAsBuffer2 = new TextEncoder().encode(concat2);
    const hashBuffer2 = await window.crypto.subtle.digest('SHA-256', textAsBuffer2);
    // sha256_2 в hex
    const hashArray2 = Array.from(new Uint8Array(hashBuffer2))
    const sha256_hex2 = hashArray2.map(b => b.toString(16).padStart(2, '0')).join('');
    return sha256_hex2;
};
function checkTourCardContent(href) {
    let result = 'new';
    let hotels = ['/ras-al-khaimah/ras-al-khaimah-beach-hotels/radisson-resort-ras-al-khaimah-marjan-island',
        '/ras-al-khaimah/ras-al-khaimah-city-hotels/doubletree-by-hilton-ras-al-khaimah',
        '/side/kumkoy/side-royal-paradise',
        '/dubai/jumeirah/movenpick-jumeirah-lakes-towers',
        '/ras-al-khaimah/ras-al-khaimah-beach-hotels/the-cove-rotana-resort-ras-al-khaimah',
        '/alanya/konakli/noxinn-club',
        '/dubai/dubai-city-hotels/md-hotel-by-gewan',
        '/ras-al-khaimah/ras-al-khaimah-beach-hotels/bm-beach-resort',
        '/fujairah/khorfakkan/oceanic-khorfakkan-resort-and-spa',
        '/ras-al-khaimah/ras-al-khaimah-beach-hotels/movenpick-resort-al-marjan-island',
        '/hurghada/hurghada/rixos-premium-magawish',
        '/sharm-el-sheikh/nabk/regency-plaza-aqua-park-spa',
        '/sharjah/sharjah-beach-hotels/sahara-beach-resort-spa',
        '/side/manavgat/nergos-garden-hotel',
        '/sharm-el-sheikh/sharm-el-sheikh/amwaj-oyoun-resort-casino',
        '/alanya/avsallar/armas-green-fugla-beach',
        '/antalya/konyaalti/rixos-downtown-antalya',
        '/alanya/turkler/orange-county-resort-hotel-alanya',
        '/antalya/lara/smart-lara-family',
        '/belek/bogazkent/throne-seagate-belek',
        '/dubai/dubai-marina-hotels/jannah-place-dubai-marina',
        '/side/titreyengol/amara-family-resort',
        '/hurghada/soma-bay/albatros-amwaj-abu-soma-ex-amwaj-blue-beach-resort-spa',
        '/sharjah/sharjah-beach-hotels/sheraton-sharjah-beach-resort-spa',
        '/hurghada/soma-bay/aqua-mondo-abu-soma-resort',
        '/alanya/mahmutlar/first-class-hotel',
        '/antalya/lara/nirvana-cosmopolitan',
        '/kemer/beldibi/club-marakesh',
        '/ras-al-khaimah/ras-al-khaimah-beach-hotels/bm-beach-hotel',
        '/sharjah/sharjah-city-hotels/nejoum-al-emarat',
        '/phuket/mai-khao/splash-beach-resort',
        '/phuket/patong/fishermens-harbour-urban-resort',
        '/hurghada/hurghada/albatros-blu-spa',
        '/sharjah/sharjah-city-hotels/four-points-by-sheraton-sharjah',
        '/antalya/kundu/titanic-mardan-palace-hotel',
        '/alanya/konakli/green-paradise-beach-hotel',
        '/kemer/kemer-center/melissa-residence-boutique-hotel',
        '/dubai/dubai-city-hotels/novotel-jumeirah-village-triangle',
        '/fujairah/fujairah-beach-hotels/iberotel-miramar-al-aqah-beach-resort',
        '/sharm-el-sheikh/sharm-el-sheikh/cataract-resort',
        '/hurghada/hurghada/blend-club-aqua-resort',
        '/ajman/ajman-beach-hotels/fairmont-ajman',
        '/dubai/dubai-marina-hotels/signature-hotel-apartments-spa-marina',
        '/ajman/ajman-beach-hotels/wyndham-garden-ajman-corniche',
        '/sharm-el-sheikh/hadaba/sunrise-diamond-beach-resort-grand-select',
        '/phuket/patong/ashlee-plaza-patong-hotel-spa',
        '/sharjah/sharjah-beach-hotels/occidental-sharjah-grand-ex-grand-hotel-sharjah',
        '/sochi/loo/aquagrad',
        '/dubai/dubai-marina-hotels/marina-view-hotel-apartment',
        '/dubai/dubai-jebel-ali/rove-at-the-park',
        '/dubai/deira/riu-dubai'];
    for (let i = 0; i < hotels.length; i++) {
        if (href.includes(hotels[i])) {
            result = 'old';
            break;
        }
    }
    return result;
}

function getBookingLoginParam(isLogin) {
    // booking_no_authorization если пользователь прошел через быструю регистрацию (сейчас совпадает с тем что он не авторизован)
    let result = "booking_no_authorization";
    if (isLogin) {
        // так же разделяем простую авторизацию от Яндексововой
        let yandex_login = localStorage.getItem('yandex_login');
        if (yandex_login === 'true' || yandex_login === 'booking') {
            result = "booking_authorization_yandexid";
        } else {
            result = "booking_authorization";
        }
    }
    return result;
}

export default {
    actions: {
        // Получение города отеля (для России 3го порядка, для остальных стран второго)
        async fetchHotelCity({dispatch, getters, commit}, {cityName, ebgNodeId, countryId}) {

            let key = ebgNodeId
            let result = null

            getters.getDatalayerBrands.forEach(e => {
                if (e.key === key) {
                    result = e.value
                }
            })

            if (result) {
                return result
            }

            commit('updateStopFetch', true)
            result = cityName;
            if (countryId !== 210357) {

                let parents = await dispatch('fetchGetParentsByEbgNodeIdAndType', {
                    id: ebgNodeId,
                    objectType: 'city'
                });

                if (parents && parents[0] && parents[0].children && parents[0].children[0] && parents[0].children[0].children[0]) {
                    result = parents[0].children[0] && parents[0].children[0].children[0].localizedName;
                }

            }

            commit('updateBrands', {'key': key, 'value': result})
            commit('updateStopFetch', false)
            return result;

        },
        //Просмотр товаров в каталоге
        async pushProductImpressions({dispatch, getters}, {products, dataType = 'tours'}) {
            if (getters.getDatalayerStopFetch) {
                return
            }

            try {
                if (dataType === 'raw') {
                    let newProducts = []
                    products.forEach(e => {
                        newProducts.push(convertSamoTourToTour(e))
                    })
                    products = newProducts
                }

                Date.prototype.format = function (format = 'yyyy-mm-dd') {
                    const replaces = {
                        yyyy: this.getFullYear(),
                        mm: ('0' + (this.getMonth() + 1)).slice(-2),
                        dd: ('0' + this.getDate()).slice(-2),
                        hh: ('0' + this.getHours()).slice(-2),
                        MM: ('0' + this.getMinutes()).slice(-2),
                        ss: ('0' + this.getSeconds()).slice(-2)
                    };
                    let result = format;
                    for (const replace in replaces) {
                        result = result.replace(replace, replaces[replace]);
                    }
                    return result;
                };
                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }

                let pushProducts = []
                for (const e of products) {
                    let idx = products.indexOf(e);
                    let startDate = new Date(e.startDate)
                    let endDate = new Date(e.startDate)
                    endDate.setDate(startDate.getDate() + 1);
                    let brand = await dispatch('fetchHotelCity', {cityName: e.hotelCity.name, ebgNodeId: e.hotelCity.id, countryId: e.hotelCountry.id});

                    pushProducts.push({
                        "name": e.hotelName,
                        "id": localStorage.getItem('departureCityId') + '-' + e.hotelCity.id,
                        "price": e.price.value.toString(),
                        "brand": brand, //e.hotelCity.name,
                        "category": e.hotelCountry.name,
                        "variant": `${e.hotelId}.${e.hotelName} (${startDate.format()}).${endDate.format()}-${e.nightsCount}. ${e.meal.name}. ${e.roomName}`,
                        "hotel_id": e.hotelId.toString(),
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": e.hotelCity.id.toString(),
                        "arrivalCity": e.hotelCity.name,
                        "position": idx + 1
                    })
                }
                let data = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "impressions": {
                            "actionField": {"list": "Туры"},
                            "products": pushProducts
                        },
                    },
                    "product_type": getProductType(),
                    "event": "Product Impressions",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "Product Impressions",
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : 'not authorized',
                    "user_email": getters.getIsLogin ? getters.getUser.email : 'not authorized',
                    "user_type": getters.getDataLayerUserType,
                    "user_sex": getters.getIsLogin ? '' : 'not authorized',
                    "spot": spot,
                    "eventNonInteraction": "True"
                }
                data = setGlobalVariables(getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                delete data.tourCardTest;

                dataLayer.push(data);
            } catch (e) {
                console.log('error pushProductImpressions', e, 'DATA', products)
            }

        },

        //Страница товара – Страница Отеля
        async fetchDataLayerProductPage({dispatch, getters}, type) {
            try {
                let product = {}

                if (type === 'hotel') {
                    let hotel = getters.getHotelData
                    let filter = getters.getHotelFilterData
                    let brand = await dispatch('fetchHotelCity', {
                        cityName: getters.getHotelCityData.localizedName,
                        ebgNodeId: getters.getHotelCityData.ebgNodeId,
                        countryId: getters.getHotelCountry.ebgNodeId
                    });

                    product = {
                        "name": hotel.localizedName,
                        "id": localStorage.getItem('departureCityId') + '-' + getters.getHotelCityData.ebgNodeId.toString(),
                        "price": getters.getTourPrice.toString(),
                        "brand": brand,
                        "category": getters.getHotelCountry.localizedName,
                        "variant": `${hotel.ebgNodeId}.${hotel.localizedName}`,
                        "hotel_id": hotel.ebgNodeId.toString(),
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": getters.getHotelCityData.ebgNodeId.toString(),
                        "arrivalCity": getters.getHotelCityData.localizedName
                    }
                }

                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }

                let data = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "detail": {
                            "actionField": {"list": "Отели"},
                            "products": [product]
                        },
                    },
                    "hotel_rate": localStorage.getItem('stars'),
                    "product_type": getProductType(),
                    "event": "Product Details",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "Product Details",
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : 'not authorized',
                    "user_email": getters.getIsLogin ? getters.getUser.email : 'not authorized',
                    "user_type": getters.getDataLayerUserType,
                    "user_sex": getters.getIsLogin ? '' : 'not authorized',
                    "spot": spot,
                    "eventNonInteraction": "True"
                }
                data = setGlobalVariables(getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }

                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerProductPage', e)
            }
        },

        async fetchDataLayerAddToCart({dispatch, getters}, payload) {
            try {
                let product = {}
                if (payload.type === 'hotel') {
                    let hotel = getters.getHotelData
                    let filter = getters.getHotelFilterData
                    // console.log(filter.departureCityId)

                    let brand = await dispatch('fetchHotelCity', {
                        cityName: payload.dataHotel.cityName,
                        ebgNodeId: payload.dataHotel.cityId,
                        countryId: payload.dataHotel.countryId
                    });

                    product = {
                        "name": hotel.localizedName,
                        "id": localStorage.getItem('departureCityId') + '-' + payload.dataHotel.cityId,
                        "price": payload.dataHotel.price.toString(),
                        "brand": brand,
                        "category": payload.dataHotel.countryName,
                        "variant": `${hotel.ebgNodeId}.${hotel.localizedName} (${payload.dataHotel.checkinDate}).${payload.dataHotel.checkoutDate}-${payload.dataHotel.nightsCount}. ${payload.dataHotel.paxes.adultsCount + payload.dataHotel.paxes.childrenCount + payload.dataHotel.paxes.infantsCount}. ${payload.dataHotel.mealName}. ${payload.dataHotel.htplace}`,
                        "quantity": 1,
                        "hotel_id": hotel.ebgNodeId.toString(),
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": payload.dataHotel.cityId.toString(),
                        "arrivalCity": payload.dataHotel.cityName
                    }
                }

                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }
                // console.log('fetchDataLayerAddToCart',product)
                let data = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "add": {
                            "products": [product]
                        },
                    },
                    "hotel_rate": localStorage.getItem('stars'),
                    "product_type": getProductType(),
                    "event": "AddToCart",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "AddToCart",
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : 'not authorized',
                    "user_email": getters.getIsLogin ? getters.getUser.email : 'not authorized',
                    "user_type": getters.getDataLayerUserType,
                    "user_sex": getters.getIsLogin ? '' : 'not authorized',
                    "spot": spot,
                    "eventNonInteraction": "False",
                }
                data = setGlobalVariables(getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);

                localStorage.setItem("product_type", getProductType())
            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerAddToCart', e)
            }


        },

        //checkout step1
        async fetchDataLayerOpenCart({dispatch, getters}) {
            try {
                let cart = getters.getBasket
                let products = []
                for (const tour of cart.tours) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                for (const tour of cart.hotels) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                // console.log('fetchDataLayerOpenCart checkout step1',products)

                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }
                let data = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "checkout": {
                            "actionField": {"step": 1},
                            "products": products
                        },
                    },
                    "hotel_rate": localStorage.getItem('stars'),
                    "product_type": localStorage.getItem("product_type"),
                    "event": "Checkout",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "Checkout Step 1",
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : 'not authorized',
                    "user_email": getters.getIsLogin ? getters.getUser.email : 'not authorized',
                    "user_type": getters.getDataLayerUserType,
                    "user_sex": getters.getIsLogin ? '' : 'not authorized',
                    "spot": spot,
                    "eventNonInteraction": "False"
                }
                data = setGlobalVariables(getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerOpenCart', e)
            }


        },

        //checkout step2
        async fetchDataLayerOpenAuthFormInCart({dispatch, getters}) {
            try {
                let cart = getters.getBasket

                let products = []

                for (const tour of cart.tours) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                for (const tour of cart.hotels) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                // console.log('fetchDataLayerOpenAuthFormInCart checkout step2',products)

                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }
                // console.log(products)
                let data = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "checkout": {
                            "actionField": {"step": 2},
                            "products": products
                        },
                    },
                    "hotel_rate": localStorage.getItem('stars'),
                    "product_type": localStorage.getItem("product_type"),
                    "event": "Checkout",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "Checkout Step 2",
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : 'not authorized',
                    "user_email": getters.getIsLogin ? getters.getUser.email : 'not authorized',
                    "user_type": getters.getDataLayerUserType,
                    "user_sex": getters.getIsLogin ? '' : 'not authorized',
                    "spot": spot,
                    "eventNonInteraction": "False"
                }
                data = setGlobalVariables(getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerOpenAuthFormInCart', e)
            }


        },
        // auth
        async fetchDataLayerAuthSaccess(ctx, payload = null) {
            try {
                let user_name = 'not authorized'
                let user_email = 'not authorized'
                let user_sex = 'not authorized'

                if (payload) {
                    user_name = payload.document.firstName
                    user_email = payload.email
                    user_sex = payload.sex
                }

                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }
                let auth = {
                    "hotel_rate": localStorage.getItem('stars'),
                    "event": "user_authorized",
                    "user_name": ctx.getters.getIsLogin ? ctx.getters.getUser.fullName : user_name,
                    "user_email": ctx.getters.getIsLogin ? ctx.getters.getUser.email : user_email,
                    "user_type": ctx.getters.getDataLayerUserType,
                    "user_sex": ctx.getters.getIsLogin ? '' : user_sex,
                    "spot": spot,
                }
                auth = setGlobalVariables(ctx.getters, auth);
                dataLayer.push(auth);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerAuthSaccess', e)
            }


        },
        //checkout step3
        async fetchDataLayerCheckoutStep3({dispatch, getters}) {
            try {
                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }

                let cart = getters.getBasket

                let products = []

                for (const tour of cart.tours) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                for (const tour of cart.hotels) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }
                // console.log('AuthSaccess checkout step3', products)

                let data = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "checkout": {
                            "actionField": {"step": 3},
                            "products": products
                        },
                    },
                    "hotel_rate": localStorage.getItem('stars'),
                    "product_type": localStorage.getItem("product_type"),
                    "event": "Checkout",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "Checkout Step 3",
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : 'not authorized',
                    "user_email": getters.getIsLogin ? getters.getUser.email : (getters.getUserInfo ? getters.getUserInfo.email : 'not authorized'),
                    "user_type": getters.getDataLayerUserType,
                    "user_sex": getters.getIsLogin ? '' : 'not authorized',
                    "spot": spot,
                    "eventNonInteraction": "False",
                    "param": getBookingLoginParam(getters.getIsLogin),
                }
                data = setGlobalVariables(getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                if (!data.searchTourTest) {
                    delete data.searchTourTest
                }
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerAuthSaccess', e)
            }


        },
        //checkout step4
        async fetchDataLayerReserve({dispatch, getters}) {
            try {
                let cart = getters.getBasket

                let products = []

                for (const tour of cart.tours) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                for (const tour of cart.hotels) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                // console.log('fetchDataLayerReserve checkout step4',products)

                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }
                let data = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "checkout": {
                            "actionField": {"step": 4},
                            "products": products
                        },
                    },
                    "hotel_rate": localStorage.getItem('stars'),
                    "product_type": localStorage.getItem("product_type"),
                    "event": "Checkout",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "Checkout Step 4",
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : 'not authorized',
                    "user_email": getters.getIsLogin ? getters.getUser.email : 'not authorized',
                    "user_type": getters.getDataLayerUserType,
                    "user_sex": getters.getIsLogin ? '' : 'not authorized',
                    "spot": spot,
                    "eventNonInteraction": "False",
                    "param": getBookingLoginParam(getters.getIsLogin),
                }
                data = setGlobalVariables(getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerReserve', e)
            }


        },
        //checkout step5
        async fetchDataLayerOrderCreateSaccess({dispatch, getters}) {
            try {
                let cart = getters.getBasket
                let booking_id = getters.getBasketStatus.backOfficeId
                let products = []

                for (const tour of cart.tours) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                for (const tour of cart.hotels) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                // console.log('fetchDataLayerOrderCreateSaccess checkout step5',products)

                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }
                // console.log(products)
                let data = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "checkout": {
                            "actionField": {"step": 5},
                            "products": products
                        },
                    },
                    "hotel_rate": localStorage.getItem('stars'),
                    "product_type": localStorage.getItem("product_type"),
                    "event": "Checkout",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "Checkout Step 5 | " + booking_id,
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : 'not authorized',
                    "user_email": getters.getIsLogin ? getters.getUser.email : 'not authorized',
                    "user_type": getters.getDataLayerUserType,
                    "user_sex": getters.getIsLogin ? '' : 'not authorized',
                    "spot": spot,
                    "eventNonInteraction": "False",
                    "param": getBookingLoginParam(getters.getIsLogin),
                }
                data = setGlobalVariables(getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerOrderCreateSaccess', e)
            }


        },

        async fetchDataLayerOrderPaySaccess({dispatch, getters}, payload) {
            try {

                let cart = payload.draft.draftBody;
                let order = payload.order.data;
                let products = [];

                for (const tour of cart.tours) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName
                    })
                }

                for (const tour of cart.hotels) {
                    let brand = await dispatch('fetchHotelCity', {cityName: tour.cityName, ebgNodeId: tour.cityId, countryId: tour.countryId});
                    products.push({
                        "name": tour.name,
                        "id": localStorage.getItem('departureCityId') + '-' + tour.cityId,
                        "price": tour.price.toString(),
                        "brand": brand,
                        "category": tour.countryName,
                        "variant": `${tour.hotelId}.${tour.name} (${tour.checkinDate}).${tour.checkoutDate}-${tour.nightsCount}. ${tour.paxes.adultsCount + tour.paxes.childrenCount + tour.paxes.infantsCount}. ${tour.mealName}. ${tour.htplace}`,
                        "hotel_id": tour.hotelId.toString(),
                        "quantity": 1,
                        "departureCity_id": localStorage.getItem('departureCityId').toString(),
                        "departureCity": localStorage.getItem('departureCityName').toString(),
                        "arrivalCity_id": tour.cityId.toString(),
                        "arrivalCity": tour.cityName,
                    })
                }


                //тип устройства
                let spot = 'desktop'
                if (screen.width <= 768 && screen.width > 414) {
                    spot = 'tablet'
                } else if (screen.width <= 414) {
                    spot = 'mobile'
                }


                let result = {
                    "ecommerce": {
                        "currencyCode": getters.getDefaultCurrencyAlias,
                        "purchase": {
                            "actionField": {
                                "id": order.id,
                                "revenue": order.prices[1].catalog,
                                "affiliation": "Online Store",
                                "coupon": "Coupon 1"

                            },

                            "products": products
                        },
                    },
                    "hotel_rate": localStorage.getItem('stars'),
                    "product_type": localStorage.getItem("product_type"),
                    "event": "Purchase",
                    "eventCategory": "Enhanced Ecommerce",
                    "eventAction": "Purchase",
                    "login": getters.getIsLogin,
                    "user_name": getters.getIsLogin ? getters.getUser.fullName : cart.customerInfo.document.firstName,
                    "user_email": getters.getIsLogin ? getters.getUser.email : cart.customerInfo.email,
                    "user_type": getters.getDataLayerUserType,
                    "purchase_type": cart.isOneClickBooking ? "покупка в 1 клик" : "бронирование",
                    "user_sex": getters.getIsLogin ? '' : cart.customerInfo.email.sex,
                    "spot": spot,
                    "eventNonInteraction": "False",
                    "param": getBookingLoginParam(getters.getIsLogin),
                }
                result = setGlobalVariables(getters, result);

                if (products.length) {
                    if (!result.mainpage) {
                        delete result.mainpage
                    }
                    if (!result.basketTest) {
                        delete result.basketTest
                    }
                    dataLayer.push(result);
                }

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerOrderPaySaccess', e)
            }
        },

        fetchDataLayerCountry(ctx) {
            try {
                dataLayer.push({
                    'Country': ctx.state.countries[ctx.getters.getDefaultCountry],
                    "searchTourTest": ctx.getters.getSearchTourTest
                });
            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerCountry', e)
            }
        },

        fetchDataLayerPodborTuraFormSubmit(ctx) {
            try {
                let data = {
                    'event': 'Podbor_tura-form_submit',
                    "searchTourTest": ctx.getters.getSearchTourTest
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerPodborTuraFormSubmit', e)
            }
        },

        fetchDataLayerFilter(ctx, text) {
            try {
                let data = {
                    'event': 'Новая поисковая выдача',
                    'eventCategory': 'Поиск отеля через фильтр',
                    'eventAction': text
                }
                // data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerPodborTuraFormSubmit', e)
            }
        },

        //Воронка 1 (GA) (GTM) - Клик на кнопку "Найти" на главной странице
        async fetchDataLayerSearchTourButton(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'funnel',
                    'eventCategory': 'step 1',
                    'departureCity':localStorage.getItem('departureCityName').toString(),
                    'eventAction': 'search button click',
                    "product_type": window.product_type,
                    'ecommerce': {
                        'products': [
                            {
                                'brand': 'Город (отеля)',
                                'category': 'Страна'
                            }
                        ]

                    }
                }
                let hotelId = (ctx.getters.getSearchParams && ctx.getters.getSearchParams.hotelIds) ? ctx.getters.getSearchParams.hotelIds :
                    (ctx.getters._getArrivalHotelId ? ctx.getters._getArrivalHotelId : ctx.getters._getArrivalHotelId_);
                if (hotelId) {
                    let hotel = await ctx.dispatch('fetchGeoObjectByEbgNodeIdAndType', {
                        id: hotelId,
                        objectType: 'hotel'
                    });

                    if (hotel) {
                        data.searchHotel = hotel.localizedName;
                    }
                }
                data = setGlobalVariables(ctx.getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                delete data.tourCardTest;
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerSearchTourButton', e)
            }
        },

        //Воронка 2 (GA) (GTM) - Клик на кнопку со стоимостью тура на страницах поиска (пример - от 59 556 Р)
        fetchDataLayerSearchTourPriceButtonClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'funnel',
                    'eventCategory': 'step 2',
                    'eventAction': 'hotel price click',
                    "product_type": window.product_type,
                }
                data = setGlobalVariables(ctx.getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);


            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerSearchTourPriceButtonClick', e)
            }
        },

        //Воронка 2 (GA) (GTM) - Клик на кнопку со стоимостью тура на страницах нового поиска (пример - от 59 556 Р)
        fetchDataLayerNewSearchTourPriceButtonClick(ctx, payload) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'funnel',
                    'eventCategory': 'step 2',
                    'eventAction': 'hotel price click',
                    "product_type": window.product_type,
                    'ecommerce': {
                        'products': [
                            {
                                'brand': payload.hotelCity,
                                'category': payload.hotelCountry
                            }
                        ]

                    }
                }
                data = setGlobalVariables(ctx.getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);


            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerSearchTourPriceButtonClick', e)
            }
        },

        // Воронка 5 (GA) - 4 (GTM) - Клик на кнопку корзины в шапке сайта
        // ВАЖНО для аналитиков (на уровне GTM данный трекинг необходимо фильтровать по разделу отелей)
        fetchDataLayerCartClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'funnel',
                    'eventCategory': 'step 5',
                    'eventAction': 'cart click',
                    "product_type": window.product_type,
                }

                data = setGlobalVariables(ctx.getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerCartClickHotelPage', e)
            }
        },

        //Воронка 6 (GA) - 5 (GTM) - Клик на кнопку забронировать в корзине
        fetchDataLayerBookingClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'funnel',
                    'eventCategory': 'step 6',
                    'eventAction': 'booking click',
                    "product_type": localStorage.getItem("product_type"),
                }

                data = setGlobalVariables(ctx.getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerBookingClick', e)
            }
        },

        //Воронка 8 (GA) - 6 (GTM) - Клик на кнопку оплатить на странице бронирования тура
        fetchDataLayerPayClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'funnel',
                    'eventCategory': 'step 8',
                    'eventAction': 'pay click',
                    "product_type": localStorage.getItem("product_type"),
                }
                data = setGlobalVariables(ctx.getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerPayClick', e)
            }
        },

        //Воронка 5 (GA) - (GTM) - Редирект в корзину со страницы отеля
        fetchDataLayerHotelRedirectToBasketClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    "event": "funnel",
                    "eventCategory": "step 5",
                    "eventAction": "redirect to booking",
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerHotelRedirectToBasketClick', e)
            }
        },

        // Переход на заполнение данных о туристах
        fetchDataLayerTouristData(ctx) {
            try {
                let data = {
                    'event': 'Новая корзина',
                    'eventCategory': 'Продолжить бронирование',
                    'eventAction': 'Переход к заполнению данных туристов'
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions fetchDataLayerTouristData', e)
            }
        },

        //weekend hits
        pushDataLayerWeekendHitsClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'click',
                    'eventCategory': 'block',
                    'eventAction': 'weekend hits',
                    "product_type": window.product_type,
                }
                data = setGlobalVariables(ctx.getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);

            } catch (e) {

                console.log('Ошибка в actions pushDataLayerWeekendHitsClick', e)
            }
        },

        //weekend regions
        pushDataLayerWeekendRegionsClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'click',
                    'eventCategory': 'block',
                    'eventAction': 'weekend regions',
                    "product_type": window.product_type,
                }
                data = setGlobalVariables(ctx.getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);


            } catch (e) {
                console.log('Ошибка в actions pushDataLayerWeekendRegionsClick', e)
            }
        },

        //weekend types
        pushDataLayerWeekendTypesClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'click',
                    'eventCategory': 'block',
                    'eventAction': 'weekend types',
                    "product_type": window.product_type,
                }
                data = setGlobalVariables(ctx.getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);


            } catch (e) {
                console.log('Ошибка в actions pushDataLayerWeekendTypesClick', e)
            }
        },

        //weekend map
        pushDataLayerWeekendMapClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'click',
                    'eventCategory': 'block',
                    'eventAction': 'weekend map',
                    "product_type": window.product_type,
                }
                data = setGlobalVariables(ctx.getters, data);

                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);


            } catch (e) {
                console.log('Ошибка в actions pushDataLayerWeekendMapClick', e)
            }
        },

        //weekend price
        pushDataLayerWeekendPriceClick(ctx) {
            try {
                let data = {
                    "hotel_rate": localStorage.getItem('stars'),
                    'event': 'click',
                    'eventCategory': 'block',
                    'eventAction': 'weekend price',
                    "product_type": window.product_type,
                }
                data = setGlobalVariables(ctx.getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);


            } catch (e) {
                console.log('Ошибка в actions pushDataLayerWeekendPriceClick', e)
            }
        },

        // Booking error
        pushDataLayerBookingError(ctx, error) {
            try {
                let data = {
                    'event': 'Новая корзина',
                    'eventCategory': 'booking error',
                    'eventAction': error,
                }
                data = setGlobalVariables(ctx.getters, data);
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                dataLayer.push(data);


            } catch (e) {
                console.log('Ошибка в actions pushDataLayerWeekendPriceClick', e)
            }
        },

        // Нажатие на кнопку "Оплатить"
        pushDataLayerBookingPay(ctx, {booking_id, payment_method, price}) {
            try {
                let data = {
                    'event': 'Новая корзина',
                    'eventCategory': 'Оплатить',
                    'booking_id': booking_id,
                    'payment_method': payment_method,
                    'price': price,
                }
                if (!data.mainpage) {
                    delete data.mainpage
                }
                if (!data.basketTest) {
                    delete data.basketTest
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);


            } catch (e) {
                console.log('Ошибка в actions pushDataLayerWeekendPriceClick', e)
            }
        },

        //определение агент или клиент
        pushDataLayerWhoAreYou(ctx, role) {
            try {
                let data = {'dq_user_type': role}
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);

            } catch (e) {
                console.log('Ошибка в actions pushDataLayerWhoAreYou', e)
            }
        },

        // авторизация через яндекс в корзине
        pushDataLayerYandexAuthInBooking(ctx) {
            try {
                let data = {
                    'event': 'Новая корзина',
                    'eventCategory': 'Авторизовался через Яндекс ID',
                    'eventAction': 'Авторизовался через Яндекс ID'
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions pushDataLayerYandexAuthInBooking', e)
            }
        },

        // Выбор количества ночей в новой карточке тура
        pushDataLayerHotelSetNightsCount(ctx, nightsCount) {
            try {
                let data = {
                    'event': 'Клики по кнопкам',
                    'eventCategory': 'Новая карточка тура / выбор количества ночей',
                    'eventAction': nightsCount
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions pushDataLayerHotelSetNightsCount', e)
            }
        },

        // Выбор питания в новой карточке тура
        pushDataLayerHotelSetMealType(ctx, mealType) {
            try {
                let data = {
                    'event': 'Клики по кнопкам',
                    'eventCategory': 'Новая карточка тура / выбор типа питания',
                    'eventAction': mealType
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions pushDataLayerHotelSetMealType', e)
            }
        },

        // Взаимодействие с картой в новой карточке тура
        pushDataLayerHotelMapActions(ctx) {
            try {
                let data = {
                    'event': 'Клики по кнопкам',
                    'eventCategory': 'Новая карточка тура / взаимодействие с картой',
                    'eventAction': 'Взаимодействовал с картой'
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions pushDataLayerHotelMapActions', e)
            }
        },

        // Изменение даты вылета в новой карточке тура
        pushDataLayerHotelSetDate(ctx) {
            try {
                let data = {
                    'event': 'Клики по кнопкам',
                    'eventCategory': 'Новая карточка тура / изменение даты вылеты',
                    'eventAction': 'Изменил дату вылета'
                }
                data = setGlobalVariables(ctx.getters, data);
                dataLayer.push(data);
            } catch (e) {
                console.log('Ошибка в actions pushDataLayerHotelSetDate', e)
            }
        },

        pushDataLayerMainPage(ctx) {
            try {
                //TODO БЕЗ ТАЙМАУТА СУКА НЕ СТАВИТ!!!
                setTimeout(() => {
                    if (!localStorage.getItem('main_page_template') && window.location.pathname !== '/') {
                        localStorage.setItem('other_incoming', true)
                    }

                    if (window.location.pathname === '/' && !localStorage.getItem('other_incoming')) {
                        localStorage.setItem('main_page_template', window.main_page_template)
                    }
                    if (localStorage.getItem('main_page_template')) {
                        let data = setGlobalVariables(ctx.getters, {});
                        dataLayer.push(data);
                    }
                }, 2000)

            } catch (e) {
                console.log('Ошибка в actions  pushDataLayerMainPage', e)
            }
        },

        pushDataLayerNotEcommerce({dispatch, getters}) {
            try {
                // таймер чтоб были необходимые данные
                setTimeout(async () => {
                    let brand = null;
                    let category = null;
                    if (getters.getHotelCityData.localizedName || getters.getHotelCityData.ebgNodeId) {
                        brand = await dispatch('fetchHotelCity', {
                            cityName: getters.getHotelCityData.localizedName,
                            ebgNodeId: getters.getHotelCityData.ebgNodeId,
                            countryId: getters.getHotelCountry.ebgNodeId
                        });
                        category = getters.getHotelCountry.localizedName;
                    } else {
                        // console.log(getters.getCategoryNotEcommerce, getters.getArrivalCountryId, getters._getArrivalCountryId, getters._getArrivalCountryId_);
                        let countryId = getters.getCategoryNotEcommerce ? getters.getCategoryNotEcommerce : (getters.getArrivalCountryId ? getters.getArrivalCountryId :
                            (getters._getArrivalCountryId ? getters._getArrivalCountryId : getters._getArrivalCountryId_));
                        if (countryId) {
                            let country = await dispatch('fetchGeoObjectByEbgNodeIdAndType', {
                                id: countryId,
                                objectType: 'country'
                            });

                            if (country) {
                                category = country.localizedName;
                            }
                        } else if (getters._getCountry) {
                            category = getters._getCountry.name;
                        } else if (getters._getCountry_) {
                            category = getters._getCountry_.name;
                        }
                        let cityId = getters.getArrivalCityId ? getters.getArrivalCityId : (getters._getArrivalCityId ? getters._getArrivalCityId : getters._getArrivalCityId_)
                        if (cityId) {
                            let city = await dispatch('fetchGeoObjectByEbgNodeIdAndType', {
                                id: cityId,
                                objectType: 'cityId'
                            });

                            if (city) {
                                brand = city.localizedName;
                            }
                        }
                    }

                    let data = {
                        'categoryNotEcommerce': category,
                        'brandNotEcommerce': brand,
                    }
                    data = setGlobalVariables(getters, data);
                    dataLayer.push(data);
                }, 8000);

            } catch (e) {
                console.log('Ошибка в actions  pushDataLayerNotEcommerce', e)
            }
        },
        pushDataLayerBookingErrorPopup({dispatch, getters}, errorText = "unknown error", location = window.location.href) {
            try {
                let error ={
                    'event': 'booking_error_sometimes_it_happens',
                    'eventCategory': location,
                    'eventAction': errorText
                }
                dataLayer.push(error);
                console.log('Отправлена ошибка dataLayer', error)
            } catch (e) {
                console.log('Ошибка в actions   pushDataLayerBookingErrorPopup', e)
            }
        },
        pushDataLayerBookingPriceChangePopup({dispatch, getters}, {text, price}) {
            try {
                let error ={
                    'event': 'show_pop-up_price_changed',
                    'eventCategory': text,
                    'eventAction': price,
                }
                dataLayer.push(error);
                console.log('Отправлена ошибка dataLayer', error)
            } catch (e) {
                console.log('Ошибка в actions   pushDataLayerBookingErrorPopup', e)
            }
        },
        pushDataLayerBookingPromoSuccess({dispatch, getters}, {promoCode, country}) {
            try {
                let error ={
                    'event': 'promo_code_applied',
                    'eventCategory': promoCode,
                    'eventAction': country
                }
                dataLayer.push(error);
            } catch (e) {
                console.log('Ошибка в actions   pushDataLayerBookingPromoSuccess', e)
            }
        },
        async pushDataLayerFormSubmit(ctx, {email, phone, form_id}) {
            try {
                let euid = null;
                let puid = null;
                if(email) {
                    // euid = md5(email);
                    euid = await js_hash(email, 'T_I-*umiW#_FRuBrIfR0cr-Get')
                }
                if(phone) {
                    // Убираем все возможные спецсимволы, оставляем только цифры
                    let phoneNum = phone.replace(/[^0-9]/g, "");
                    if (phoneNum.length < 11) {
                        // phoneNum = '7' + phoneNum;
                    }
                    if (phoneNum.length === 11 && phoneNum[0] === '8') {
                        phoneNum = '7' + phoneNum.substring(1,11);
                    }
                    // puid = md5(phoneNum);
                    puid = await js_hash(phoneNum, 'T_I-*umiW#_FRuBrIfR0cr-Get')
                }
                dataLayer.push({
                    'event': 'form_submit',
                    'form_id': form_id,
                    'eventAction': 'sumbit_success',
                    'euid': euid,
                    'puid': puid,
                });
            } catch (e) {
                console.log('Ошибка в actions   pushDataLayerBookingErrorPopup', e)
            }
        },

        pushDataLayerSearchFilters({dispatch, getters},data){
            // console.log('data',data)

            try {
                if (!data.opt.checked) return
                dataLayer.push({
                    'event': 'Новая поисковая выдача',
                    'eventCategory': data.type,
                    'eventAction': data.opt.name
                });
            } catch (e) {
                console.log('Ошибка в actions   pushDataLayerSearchFilters', e)
            }
        }


    },
    state: {
        stopFetch: false,
        brands: [],
        countries: {
            'RU': 'Россия',
            'BY': 'Беларусь',
            'UA': 'Украина',
            'LT': 'Литва',
            'LV': 'Латвия',
            'EE': 'Эстония',
            'KZ': 'Казахстан'
        },
        categoryNotEcommerce: null,
    },
    mutations: {
        updateSearchTourTest(state, data) {
            localStorage.setItem('search-tour-test', data)
            state.searchTourTest = data;
        },
        updateSearchСardTest(state, data) {
            localStorage.setItem('search-сard-test', data);
        },
        updateTourСardContent(state, data) {
            localStorage.setItem('tour-сard-content', checkTourCardContent(data));
        },
        updateBrands(state, data) {
            state.brands.push(data)
        },

        updateStopFetch(state, bool) {
            state.stopFetch = bool
        },

        updateCategoryNotEcommerce(state, country) {
            state.categoryNotEcommerce = country;
        }

    },
    getters: {
        getSearchTourTest(state) {
            return localStorage.getItem('search-tour-test')
        },
        getDatalayerBrands(state) {
            return state.brands
        },
        getDatalayerStopFetch(state) {
            return state.stopFetch
        },
        getCategoryNotEcommerce(state) {
            return state.categoryNotEcommerce
        }
    }
}
